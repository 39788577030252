import styled from 'styled-components'
import type { Props } from './LoginScreen'
import * as _ from 'styles/helpers'

const BORDER_RADIUS = 32
const BORDER_RADIUS_MOBILE = 8

const StyledLoginScreen = styled.div<Partial<Props & { isMobile: boolean }>>`
	${_.MIXINS.full_height}
	${_.MIXINS.vhc}
  padding: ${({ isMobile }) => (isMobile ? _.rem(8) : _.rem(24))};
	background: rgb(0, 85, 169);
	background: radial-gradient(circle, rgba(0, 128, 254, 1) 30%, rgba(0, 85, 169, 1) 100%);

	.wrapper {
		${_.MIXINS.base_box_shadow}
		display: flex;
		flex-direction: ${({ isMobile }) => isMobile && 'column'};
		width: 100%;
		max-width: ${_.rem(1200)};
		min-height: ${({ isMobile }) => (isMobile ? `calc(100vh - 1rem)` : _.rem(800))};
		background-color: ${_.COLORS.white};
		border-radius: ${({ isMobile }) =>
			isMobile ? _.rem(BORDER_RADIUS_MOBILE) : _.rem(BORDER_RADIUS)};

		&__item {
			flex: ${({ isMobile }) => !isMobile && 1};
			flex-direction: column;
			padding: ${_.rem(24)};
			${_.MIXINS.vhc}

			&--branding {
				flex: ${({ isMobile }) => !isMobile && 0.75};
				padding: ${({ isMobile }) => isMobile && `${_.rem(48)} ${_.rem(24)} ${_.rem(24)}`};
				/* background-color: ${_.COLORS.white_smoke}; */
				background-color: ${({ isMobile }) => !isMobile && '#f3f3f3'};
				border-radius: ${({ isMobile }) =>
					isMobile
						? `${_.rem(BORDER_RADIUS_MOBILE)} ${_.rem(BORDER_RADIUS_MOBILE)} 0 0`
						: `${_.rem(BORDER_RADIUS)} 0 0 ${_.rem(BORDER_RADIUS)}`};
			}
		}
	}

	.form {
		display: flex;
		align-items: center;
		${_.MIXINS.vhc}
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		height: 100%;
		padding: ${({ isMobile }) => !isMobile && `${_.rem(32)} 0`};

		form {
			${_.MIXINS.mod};
			width: 100%;
			max-width: ${_.rem(384)};
		}

		.actions {
			${_.MIXINS.vhc};
			display: flex;
			flex-direction: column;
			button {
				margin: 9px;
			}
		}

		.form-title {
			margin: 0;
			text-align: center;
			font-size: ${({ isMobile }) => (isMobile ? _.rem(24) : _.rem(32))};
			padding: ${({ isMobile }) => (isMobile ? `0 0 ${_.rem(16)} 0` : `${_.rem(32)} 0`)};
		}

		.form__footer {
			${_.MIXINS.vhc};
			margin-top: ${({ isMobile }) => isMobile && _.rem(48)};
			flex-direction: column;

			&-link {
				${_.MIXINS.vhc};

				svg {
					margin-left: ${_.rem(4)};
				}
			}
		}
	}

	.inputs {
		${_.MIXINS.mod};
		margin-bottom: ${_.rem(24)};

		.input {
			margin-bottom: ${_.rem(12)};

			input {
				width: 100%;
				padding: ${_.rem(16)} ${_.rem(24)};
				font-size: ${_.rem(18)};
				background: ${_.COLORS.white_smoke};
				border: 2px solid ${_.COLORS.white_smoke};
				border-radius: ${_.rem(100)};
			}
		}
	}
`

export default StyledLoginScreen
