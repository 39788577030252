import React, { useState, useEffect } from 'react'
import Spinner from 'components/Spinner'
import AddImage from 'images/add_image.png'
import AltRetailerLogo from 'images/vr-new-retail_3.png'
import { Avatar, Grid } from '@mui/material'
import FileUploader from 'components/FileUploader'
import { FaImage, FaRegSmileWink } from 'react-icons/fa'
import styled from 'styled-components'
import TWControls from 'components/controls/TWControls'
import { Form, useForm } from 'components/controls/useForm'
import * as _ from 'styles/helpers'

// const API_PATH = process.env.REACT_APP_CLOUDINARY_PATH
// const API_SERVER = process.env.REACT_APP_CLOUDINARY_SERVER

const PortfolioEditPopup = ({ editPortfolio, recordForEdit }) => {
	const initialValues = {
		...recordForEdit,
	}

	const [image, setImage] = useState(false)
	const [picLoading, setPicLoading] = useState(false)
	const [picLoaded, setPicLoaded] = useState(false)

	const validate = () => {
		let temp = { ...errors }
		temp.assetName = values.assetName ? '' : 'This field is required.'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialValues,
		true,
		validate,
	)

	const submitHandler = e => {
		e.preventDefault()

		console.log(`values: `, values)

		if (validate()) {
			editPortfolio(values)
		}
	}

	useEffect(() => {
		if (recordForEdit != null) setValues({ ...recordForEdit })
	}, [recordForEdit, setValues])

	const uploadImage = async info => {
		setPicLoading(true)
		setImage(info.originalUrl)
		values.image = info.originalUrl
		setPicLoading(false)
	}

	return (
		<StyledAssetEdit>
			<Form onSubmit={submitHandler}>
				{/* <Card
				className="UserProfile__Card"
				header={{
					title: `Edit: ${values.assetName}`,
				}}
			> */}
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TWControls.Input
							size="small"
							name="assetName"
							label="Portfolio Name"
							value={values.assetName}
							onChange={handleInputChange}
							// error={errors.assetName}
						/>
					</Grid>
					<Grid item xs={12}>
						<TWControls.InputMulti
							// size="small"
							name="description"
							label="Notes"
							value={values.description}
							onChange={handleInputChange}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item>
						<TWControls.Switch
							size="small"
							name="isActive"
							label={
								values.isActive
									? 'Active: This portfolio is Active and will appear in your Portfolios list.'
									: 'Archived: This portfolio has been archived and will not appear in your Portfolios list.'
							}
							value={values.isActive}
							onChange={handleInputChange}
						/>
					</Grid>
					{}
				</Grid>
				<div className="AssetEdit__Asset">
					{picLoading ? (
						<Spinner />
					) : values.image ? (
						<img className="AssetEdit__Image" src={values.image} alt="" />
					) : (
						<img className="AssetEdit__Image" src={AltRetailerLogo} alt="" />
					)}
					<FileUploader onChange={info => uploadImage(info)}>
						<FaImage /> {values.image ? `Update Image` : `Add Image`}
					</FileUploader>
				</div>

				<Grid item xs={12}>
					<div className="AssetEdit__Actions">
						<TWControls.Button size="small" type="submit" text="Update" />
					</div>
				</Grid>
				{/* </Card> */}
			</Form>
		</StyledAssetEdit>
	)
}

const StyledAssetEdit = styled.div`
	max-width: ${_.rem(960)};
	margin: 0 auto;

	.AssetEdit {
		&__Card {
			h2 {
				overflow: hidden;
				white-space: nowrap;
			}
		}

		&__password-buttton {
			${_.MIXINS.vhc}
			margin: ${_.rem(16)} 0 ${_.rem(24)};
		}

		&__Asset,
		&__Actions {
			${_.MIXINS.mod}
			${_.MIXINS.vhc}
			flex-direction: column;
			padding: ${_.rem(16)};
		}

		&__Image {
			width: ${_.rem(250)};
			margin-bottom: ${_.rem(10)};
			border-radius: ${_.rem(5)};
			border: ${_.rem(2)} solid #0073e6;
			margin-bottom: ${_.rem(16)};
		}
	}

	input {
		font-size: ${_.rem(18)};
	}

	.input-group {
		${({ isMobile }) =>
			!isMobile &&
			`
      display: flex;
      gap: ${_.rem(16)};
    `}
	}

	.input-group-title {
		margin-top: ${_.rem(16)};
		margin-bottom: ${_.rem(16)};
		font-weight: 400;
		font-size: ${_.rem(14)};
		color: ${_.COLORS.gray_500};
	}
`

export default PortfolioEditPopup
