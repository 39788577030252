import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Location_Edit from './navPages/Location_Edit'

const Locations_Edit = () => {
	const { id } = useParams()

	const { location, loading } = useSelector(state => {
		return {
			loading: state.locations.isLoading,
			location: state.locations.entities[id],
		}
	})

	// TODO: Create skeleton loader
	if (loading || !location) return null

	return <Location_Edit location={location} />
}

export default Locations_Edit
