import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
const LOCATION_FILES = 'locationFiles'

const initialState = {
	entities: {},
	ids: [],
	isLoading: false,
	isInitialized: false,
	error: null,
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

export const getFiles = createAsyncThunk(
	`${LOCATION_FILES}/getFiles`,
	async (payload: any, { getState, rejectWithValue }) => {
		const { locationId, projectId, userId } = payload

		try {
			const { data } = await axios.get(`/api/locationfiles/files`, {
				params: {
					locationid: locationId,
					projectid: projectId,
					userid: userId,
				},
			})
			return data
		} catch (error) {
			console.error(error)
			return rejectWithValue('Failed to get files')
		}
	},
)

export const createFile = createAsyncThunk(
	`${LOCATION_FILES}/create`,
	async (file, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/api/locationFiles`, file, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to create file')
		}
	},
)

export const updateFile = createAsyncThunk(
	`${LOCATION_FILES}/update`,
	async (file: any, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.put(`/api/locationfiles/${file.id}`, file, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to update file')
		}
	},
)

export const deleteFile = createAsyncThunk(
	`${LOCATION_FILES}/delete`,
	async (id: any, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.delete(`/api/locationfiles/${id}`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to delete file')
		}
	},
)

// Extract the key out of the entitiy object
// eslint-disable-next-line no-unused-vars
const removeKey = (key, { [key]: _, ...rest }) => rest

const locationFileSlice = createSlice({
	name: 'locationFiles',
	initialState,
	reducers: {},
	extraReducers: {
		[getFiles.fulfilled as any]: (state, action) => {
			const files = action.payload

			state.ids = files?.map((file, index) => {
				state.entities[file._id] = file
				return file._id
			})
			state.isLoading = false
			state.isInitialized = true

			// const fileMap = files.reduce((lookup, item) => {
			// 	lookup[item._id] = item
			// 	return lookup
			// }, {})
			// state.entities = { ...state.entities, ...fileMap }
			// state.isLoading = false
		},
		[getFiles.pending as any]: state => {
			state.isLoading = true
		},
		[createFile.fulfilled as any]: (state, action) => {
			const newFile = action.payload
			state.entities[newFile._id] = newFile
			state.isInitialized = false
		},
		[updateFile.fulfilled as any]: (state, action) => {
			const file = action.payload
			state.entities[file._id] = file
			state.isInitialized = false
		},
		// [deleteFile.fulfilled as any]: (state, action) => {
		// 	const fileId = action.meta.arg
		// 	state.entities = removeKey(fileId, state.entities)
		// },
		[deleteFile.fulfilled as any]: (state, action) => {
			state.isInitialized = false
		},
	} as any,
})

export default locationFileSlice

// export const { } = locationsSlice.actions;
