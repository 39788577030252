import React from 'react'
import { Grid } from '@mui/material'
import TWControls from './controls/TWControls'
import { Form, useForm } from './controls/useForm'

const UserAddNew = ({ userAdd }) => {
	const initialFValues = {
		id: 0,
		name: '',
		email: '',
	}

	const validate = () => {
		let temp = { ...errors }
		temp.name = values.name ? '' : 'This field is required.'
		temp.email = values.email ? '' : 'Email is not valid.'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialFValues,
		true,
		validate,
	)

	const submitHandler = e => {
		e.preventDefault()

		const fullName = values.name

		if (validate()) {
			if (values.firstName === '' && values.name > '') {
				const nameParts = fullName.split(' ')
				values.firstName = nameParts[0]
			}

			const newUser = values

			userAdd(newUser, resetForm)
		}
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container>
				<Grid item>
					<TWControls.Input
						name="name"
						label="Name"
						value={values.name}
						onChange={handleInputChange}
						error={errors.name}
					/>
					<TWControls.Input
						name="firstName"
						label="First/Nick Name"
						value={values.firstName}
						onChange={handleInputChange}
						error={errors.firstName}
					/>
					<TWControls.Input
						name="email"
						label="Email"
						value={values.email}
						onChange={handleInputChange}
						error={errors.email}
					/>
					<div className="newLocation__imageHolder">
						<TWControls.Button size="small" type="submit" text="Invite" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default UserAddNew
