import type { IChatHeader } from 'features/Chat/Chat.types'

const sortChatUsers = (a, b) => {
	const fa = a.name.toLowerCase()
	const fb = b.name.toLowerCase()

	if (fa < fb) return -1
	if (fa > fb) return 1
	return 0
}

export const formatChatHeadersByUserId = (chatHeaders: IChatHeader[], userId) => {
	return chatHeaders.map(chatHeader => {
		const chatUsers = [
			chatHeader.senderId,
			...chatHeader.receiverIds.map(receiverId => receiverId.receiverId),
		]
			.filter(user => user._id !== userId)
			.sort(sortChatUsers)

		chatHeader.label = chatUsers.map(user => user.name).join(', ')
		chatHeader.avatars = chatUsers.map(user => ({
			name: user.name,
			// @ts-ignore
			src: user.image,
		}))

		return chatHeader
	})
}

export default formatChatHeadersByUserId
