import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const RTEComponent = ({ input, setInput }) => {
	const modules = {
		toolbar: [
			[{ header: [1, 2, 3, 4, 5, 6, false] }],
			['bold', 'italic', 'underline', 'strike'],
			[
				{
					color: ['#F00', 'yellow', '#0F0', 'green', '#00F', '#000', 'grey'],
				},
			],
			[{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
			['link', 'image', 'video'],
			['clean'],
		],
	}

	const formats = [
		'header',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'list',
		'bullet',
		'link',
		'indent',
		'image',
		'code-block',
		'color',
	]

	return (
		<div>
			<ReactQuill
				theme="snow"
				placeholder="add note . . ."
				value={input}
				onChange={setInput}
				className="editor-input"
				modules={modules}
				formats={formats}
			/>
		</div>
	)
}

export default RTEComponent
