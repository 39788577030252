import React from 'react'
import { Grid } from '@mui/material'
import TWControls from '../controls/TWControls'
import { Form } from '../controls/useForm'

const LocationArchivePopup = ({ archiveLocation, location }) => {
	const submitHandler = e => {
		archiveLocation(location)
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container spacing={2}>
				<Grid item className="newLocation__imageHolder" xs={12}>
					<h3 xs={12}>{`${location.assetName}: ${location.city}, ${location.stateProvince}`}</h3>
				</Grid>
				<Grid item xs={12}>
					<p>
						{`Archived locations are removed from the active location list but can be recoverd. All of ${location.assetName} associated notes, partners, tasks, files and images are preserved and will be
						restored when the archived location is recovered.`}{' '}
					</p>
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<TWControls.Button size="small" type="submit" text="Archive" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default LocationArchivePopup
