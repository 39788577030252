import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Popup from 'components/Popup'
import UserProfilePopup from '../admin/UserProfilePopup'
import { Avatar, Grid } from '@mui/material'
import styled from 'styled-components'
import Layout from 'components/Layout'
import Card from 'components/Card'
import * as _ from 'styles/helpers'
import WelcomeImage from '../../images/MDT_2_Lines.png'
import SuccessImage from '../../images/success.jpg'
import { patchUser, updateUser } from 'slices/authSlice'
import swal from 'sweetalert'

const HomeFirstTime = () => {
	const dispatch = useDispatch()

	const [openUserPopup, setOpenUserPopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('')
	const [recordForEdit, setRecordForEdit] = useState(null)
	const [myInvitation, setMyInvitation] = useState(true)
	const [myProfileIsCompleted, setMyProfileIsCompleted] = useState(false)

	const userLogin = useSelector((state: any) => state.userLogin)
	const { userInfo: user } = userLogin
	const userId = user._id

	// const SERVER_BASE_URL =
	// 	process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://app.mydealteams.com'

	useEffect(() => {
		console.log(`user.isInvited: `, user.isInvited)
		console.log(`user.isCompleted: `, user.isCompleted)
		if (user.isInvited) {
			setMyProfileIsCompleted(user.isCompleted)
		} else {
			setMyProfileIsCompleted(true)
		}
	}, [dispatch, user, userId])

	const handelUserProfilePopup = user => {
		setPopupTitle('User Profile')
		setRecordForEdit(user)
		setOpenUserPopup(true)
	}

	const editUserProfile = async (values, resetForm) => {
		console.log(`userId: `, userId)

		debugger

		await dispatch(
			updateUser({
				_id: userId,
				image: values.image,
				name: values.name,
				firstName: values.firstName,
				email: values.email,
				company: values.company,
				title: values.title,
				role: values.role,
				linkedin: values.linkedin,
				instagram: values.instagram,
				facebook: values.facebook,
				twitter: values.twitter,
				isAdmin: values.isAdmin,
				isCompleted: true,
				isGuestOnly: values.isGuestOnly,
				isInvited: values.isInvited,
				isSocial: values.isSocial,
				firstTime: values.firstTime,
			}),
		)

		resetForm()
		setOpenUserPopup(false)
		setRecordForEdit(null)
		setMyProfileIsCompleted(true)

		swal(`User Profile`, `Your User Profile has been completed.`, 'success')
	}

	const handleInvitationReset = async user => {
		const completedUser = user

		console.log(`completedUser: `, completedUser)
		await dispatch(
			patchUser({
				userid: userId,
				updates: { firstTime: false, isGuestOnly: false, isInvited: false, isCompleted: true },
			}),
		)
	}

	return (
		<Layout>
			<Layout.Page>
				<Layout.Body>
					<StyledDashboard>
						<div className="IntroContent">
							<Card className="IntroContent__card IntroContent__card--profile">
								{!myProfileIsCompleted ? (
									<div>
										{/* <div className="IntroContent__card-header">
											<h2>{`Hello ${user.firstName}`}</h2>
										</div> */}
										<div className="IntroContent__card_intro-header">
											<span>
												<img
													className="IntroContent__card-avatar"
													src={WelcomeImage}
													alt="Avatar"
												/>
											</span>
										</div>
										<div
											className="IntroContent__card-body"
											onClick={() => {
												handelUserProfilePopup(user)
											}}
										>
											{/* <p className="IntroContent__card-steps">Click Here. . .</p> */}
											<p className="IntroContent__card-directions">
												Click here to complete your User Profile. . .
											</p>
										</div>
									</div>
								) : (
									<div>
										<div className="IntroContent__card-header">
											<Avatar src={SuccessImage} className="IntroContent__card-header-avatar" />
											<h2>User Profile</h2>
										</div>
										<span className="IntroContent__card-body">
											<Layout.FirstProfileUserCard />
											<p>Your User Profile is now complete!</p>
										</span>
										<div
											className="IntroContent__card-header"
											onClick={() => handleInvitationReset(user)}
										>
											<p className="IntroContent__card-directions">
												Click Here to begin your MYDealTeams experiance!
											</p>
										</div>
									</div>
								)}
							</Card>
						</div>
					</StyledDashboard>
				</Layout.Body>
			</Layout.Page>
			{openUserPopup && (
				<Popup title={popupTitle} openPopup={openUserPopup} setOpenPopup={setOpenUserPopup}>
					<UserProfilePopup recordForEdit={recordForEdit} editUserProfile={editUserProfile} />
				</Popup>
			)}
		</Layout>
	)
}

const StyledDashboard = styled.div`
	.IntroContent {
		display: grid;
		grid-gap: ${_.rem(24)};
		grid-template-columns: repeat(12, [col] 1fr);

		&__card {
			cursor: pointer;
			border-top: ${_.rem(4)} solid ${_.COLORS.tradewind_blue};

			&--disabled {
				pointer-events: none;
				opacity: 0.5;
				h4 {
					/* ${_.MIXINS.vc};
					${_.TYPOGRAPHY.h2};
					text-align: center;
					padding-top: ${_.rem(8)};
					font-size: ${_.rem(16)}; */
					font-weight: 500;
					color: ${_.COLORS.blue_700};
					/* margin: 0;
					grid-gap: ${_.rem(8)}; */
				}
			}
		}

		&__card_intro-header {
			${_.MIXINS.vc};
			justify-content: center;
			margin-bottom: ${_.rem(16)};

			h2 {
				${_.MIXINS.vc};
				${_.TYPOGRAPHY.h2};
				font-size: ${_.rem(36)};
				font-weight: 600;
				color: ${_.COLORS.blue_700};
				margin: 0;
				grid-gap: ${_.rem(8)};
			}

			p {
				margin: 0;
				font-size: ${_.rem(18)};
				font-style: italic;
			}
		}
		&__card-firstName {
			${_.MIXINS.vc};
			justify-content: center;
			margin-bottom: ${_.rem(16)};

			h2 {
				${_.MIXINS.vc};
				${_.TYPOGRAPHY.h2};
				font-size: ${_.rem(36)};
				font-weight: 500;
				color: ${_.COLORS.blue_700};
				margin: 0;
				grid-gap: ${_.rem(8)};
			}
		}

		&__card-header {
			${_.MIXINS.vc};
			justify-content: center;
			margin-bottom: ${_.rem(16)};

			h2 {
				${_.MIXINS.vc};
				${_.TYPOGRAPHY.h2};
				font-size: ${_.rem(28)};
				font-weight: 600;
				color: ${_.COLORS.blue_700};
				margin: 0;
				grid-gap: ${_.rem(8)};
			}

			h4 {
				${_.MIXINS.vc};
				${_.TYPOGRAPHY.h2};
				text-align: center;
				padding-top: ${_.rem(8)};
				font-size: ${_.rem(16)};
				font-weight: 500;
				color: black;
				margin: 0;
				grid-gap: ${_.rem(8)};
			}

			p {
				margin: 0;
				font-size: ${_.rem(14)};
				font-weight: 500;
				font-style: italic;
				color: ${_.COLORS.blue_700};
			}
		}

		&__card-avatar {
			text-align: center;
			height: 100%;
			max-width: ${_.rem(210)};
		}

		&__card-avatar-success {
			/* display: flex;
			flex-direction: column; */
			align-items: center;
			/* justify-content: center; */
			text-align: center;
			height: 75%;
			padding-left: ${_.rem(60)};
			/* max-width: ${_.rem(260)}; */
		}

		&__card-header-avatar {
			align-items: center;
			/* text-align: center; */
			height: 100%;
			max-width: ${_.rem(60)};
		}

		&__card-body {
			text-align: center;
			height: 100%;
			max-height: ${_.rem(240)};
		}

		&__card-steps {
			text-align: center;
			font-size: ${_.rem(56)};
			color: ${_.COLORS.tradewind_blue};
			&--disabled {
				pointer-events: none;
				opacity: 0.2;
				color: ${_.COLORS.blue_600};
			}
		}

		&__card-directions {
			text-align: center;
			font-size: ${_.rem(18)};
			font-weight: 500;
			text-decoration-line: underline;
			color: ${_.COLORS.blue_600};
			/* font-style: italic; */
		}

		&__card-empty {
			${_.MIXINS.vhc};
			height: 100%;

			h3 {
				font-size: ${_.rem(16)};
				font-weight: 300;
				color: rgba(0, 0, 0, 0.54);
			}
		}

		&__card {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			color: ${_.COLORS.black};
			background-color: ${_.COLORS.white};

			&--top {
				align-items: center;
				justify-content: center;
				text-align: center;
				min-height: ${_.rem(220)};
				grid-column: col 5 / span 4;

				> * {
					${_.MIXINS.vhc}
					flex-direction: column
				}
			}

			&--profile {
				/* background-color: ${_.COLORS.gray_300}; */
				min-height: ${_.rem(160)};
				grid-column: col 5 / span 4;
			}

			&--location {
				/* background-color: ${_.COLORS.gray_300}; */
				min-height: ${_.rem(360)};
				grid-column: col 5 / span 4;
			}

			&--partner {
				/* background-color: ${_.COLORS.gray_300}; */
				min-height: ${_.rem(360)};
				grid-column: col 9 / span 4;
			}

			&--bottom {
				align-items: center;
				justify-content: center;
				text-align: center;
				min-height: ${_.rem(120)};
				grid-column: col 5 / span 4;
			}

			&-content {
				padding: 0 ${_.rem(12)};
			}
		}
	}
`

export default HomeFirstTime
