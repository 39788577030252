import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
// import { FaPlus } from 'react-icons/fa'
import Button from 'components/Button'
import Card from '../Card'
import S from '../Card.style'
// import Popup from 'components/Popup'
// import ChainXYApp from 'screens/partnerApps/thirdPartyAppComponents/ChainXYApp'

export interface AppCardProps {
	className?: string
	id: string
	name: string
	description: string
	image: string
	toolAPI?: string // is this supposed to be required?
	isSubscribed: boolean
	openLink?: string
	subscribeLink?: string
	webSite?: string
	submitHandler: Function
}

export const AppCard = ({
	className = '',
	id,
	name,
	description,
	image,
	toolAPI,
	isSubscribed,
	openLink,
	subscribeLink,
	webSite,
	submitHandler,
}: AppCardProps) => {
	// const [openPopup, setOpenPopup] = useState(false)
	// const [popupTitle, setPopupTitle] = useState('Sharing')
	// const [recordForEdit, setRecordForEdit] = useState(null)

	// const banner = {
	// 	name: name,
	// 	description: description,
	// 	toolAPI: toolAPI,
	// 	image: image,
	// }

	const actions = () => {
		if (isSubscribed === true) {
			return (
				// <Button isOutline onClick={submitHandler}>
				// 	<FaPlus /> Add
				// </Button>
				<React.Fragment>
					{openLink && (
						<Button as="a" href={openLink} target="_blank" rel="noopener noreferrer">
							Open App
						</Button>
					)}
					{webSite && (
						<Button as="a" href={webSite} isOutline target="_blank" rel="noopener noreferrer">
							Learn More
						</Button>
					)}
				</React.Fragment>
			)
		} else {
			return (
				<React.Fragment>
					{subscribeLink && (
						<Button as="a" href={subscribeLink} target="_blank" rel="noopener noreferrer">
							Subscribe
						</Button>
					)}
					{webSite && (
						<Button as="a" href={webSite} isOutline target="_blank" rel="noopener noreferrer">
							Learn More
						</Button>
					)}
				</React.Fragment>
			)
		}
	}

	// const openInPopup = detail => {
	// 	setRecordForEdit(detail)
	// 	setOpenPopup(true)
	// }

	return (
		<Card actions={actions()}>
			<StyledAppCard data-testid="AppCard" className={className}>
				<Link to={`/partnerapps/${id}`}>
					<S.Image className="image" src={image} />
					<S.Title
					// onClick={() => {
					// 	setPopupTitle(banner.name)
					// 	openInPopup(banner)
					// }}
					>
						{name}
					</S.Title>
					<S.Description>{description}</S.Description>
				</Link>
			</StyledAppCard>
			{/* <Popup title={popupTitle} openPopup={openPopup} setOpenPopup={setOpenPopup}>
				<ChainXYApp recordForEdit={recordForEdit} />
			</Popup> */}
		</Card>
	)
}

const StyledAppCard = styled.div`
	width: 100%;
`

export default AppCard
