import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { createFile, deleteFile, getFiles } from './locationFileSlice'
import { createImage, deleteImage, getImages } from './locationImageSlice'
import { createNote, getNotes } from './locationNoteSlice'

const initialState = {
	entities: {},
	ids: [],
	isLoading: false,
	isInitialized: false,

	centerTenantEntities: {},
	centerTenantIds: [],
	centerTenantsLoading: false,
	centerTenantsInitialized: false,
	recents: [],
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

export const initializeLocations = createAsyncThunk(
	'locations/initialize',
	async (_, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.get('/api/locations', prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to initialize locations')
		}
	},
)

export const initializeMyCenters = createAsyncThunk(
	'locations/initializeMyCenters',
	async (_, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.get('/api/locations/mycreatedcenters', prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to initialize locations')
		}
	},
)

export const initializeArchivedLocations = createAsyncThunk(
	'locations/initializeArchived',
	async (_, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.get('/api/locations/archived', prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to initialize locations')
		}
	},
)

export const initializeActiveLocations = createAsyncThunk(
	'locations/initializeActive',
	async (payload, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.get('/api/locations/active', prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to initialize locations')
		}
	},
)

export const initializeTenantLocations = createAsyncThunk(
	'locations/initializeActive',
	async (payload, { getState, rejectWithValue }) => {
		try {
			return axios.get('/api/locations/tenants', prepConfig(getState)).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize tenants')
		}
	},
)

export const addPartnerToLocation = createAsyncThunk(
	'locations/addPartner',
	async (payload: any, { getState, rejectWithValue }) => {
		const { locationId, partner } = payload

		try {
			const { data } = await axios.post(
				`/api/locations/${locationId}/addpartners`,
				{ partnerId: partner._id, partnerUserId: partner.partner, locationId },
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to add partner')
		}
	},
)

export const removePartnerFromLocation = createAsyncThunk(
	'locations/removePartnerFromLocation',
	async (payload: any, { getState, rejectWithValue }) => {
		const { locationId, partnerId } = payload

		try {
			const { data } = await axios.post(
				`/api/locations/${locationId}/removePartner`,
				{ partnerId },
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to add partner')
		}
	},
)

export const createLocation = createAsyncThunk(
	'locations/create',
	async (payload: any, { getState, rejectWithValue }) => {
		const { newLocation: location } = payload

		try {
			const { data } = await axios.post(`/api/locations/`, location, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to create location')
		}
	},
)

export const listRecentLocations = createAsyncThunk(
	'locations/listRecent',
	async (_, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.get('/api/locations/recent', prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to find location')
		}
	},
)

export const patchLocation = createAsyncThunk(
	'locations/patch',
	async (payload: any, { getState, rejectWithValue }) => {
		const { locationId, updates } = payload

		try {
			const { data } = await axios.patch(
				`/api/locations/${locationId}/patch`,
				updates,
				prepConfig(getState),
			)
			return data
		} catch (error) {
			console.error(error)
			return rejectWithValue('Unable to patch location')
		}
	},
)

export const updateLocation = createAsyncThunk(
	'locations/update',
	async (location: any, { getState, rejectWithValue }) => {
		if (location && location._id) {
			try {
				const { data } = await axios.put(
					`/api/locations/${location._id}`,
					location,
					prepConfig(getState),
				)
				return data
			} catch (error) {
				console.error(error)
				return rejectWithValue('Unable to update location')
			}
		}
		return rejectWithValue('Malformed location')
	},
)

export const getLocationByID = createAsyncThunk(
	'locations/getLocationByID',
	async (locationId, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.get(`/api/locations/${locationId}`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to update location')
		}
	},
)

export const deleteLocation = createAsyncThunk(
	'locations/delete',
	async (locationId, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.delete(`/api/locations/${locationId}`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to delete location')
		}
	},
)

export const getCenterTenants = createAsyncThunk(
	'centers/getTenants',
	async (centerId, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.get(`/api/centerTenants/${centerId}`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to get tenants')
		}
	},
)

// ------------------------------------------------------
// Create Bulk, Fleet and Tenant locations
//-------------------------------------------------------
// Bulk Locations

export const createBulkLocations = createAsyncThunk(
	'locations/createBulkLocations',
	async (payload: any, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/api/locations/bulk`, payload, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to create Bulk locations')
		}
	},
)

// Extract the key out of the entitiy object
// eslint-disable-next-line no-unused-vars
const removeKey = (key, { [key]: _, ...rest }) => rest

const locationsSlice = createSlice({
	name: 'locations',
	initialState,
	reducers: {},
	extraReducers: {
		[initializeLocations.fulfilled as any]: (state, action) => {
			const locations = action.payload

			state.recents = []
			state.ids = locations?.map((loc, index) => {
				state.entities[loc._id] = loc

				if (index < 4) {
					state.recents.push(loc._id)
				}
				return loc._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeLocations.pending as any]: state => {
			state.isLoading = true
		},
		[initializeLocations.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},

		[initializeMyCenters.fulfilled as any]: (state, action) => {
			const locations = action.payload

			state.recents = []
			state.ids = locations?.map((loc, index) => {
				state.entities[loc._id] = loc
				return loc._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeMyCenters.pending as any]: state => {
			state.isLoading = true
		},
		[initializeMyCenters.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},

		[initializeArchivedLocations.fulfilled as any]: (state, action) => {
			const locations = action.payload

			state.ids = locations?.map((loc, index) => {
				state.entities[loc._id] = loc
				return loc._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeArchivedLocations.pending as any]: state => {
			state.isLoading = true
		},
		[initializeArchivedLocations.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},

		[initializeActiveLocations.fulfilled as any]: (state, action) => {
			const locations = action.payload

			state.recents = []
			state.ids = locations?.map((loc, index) => {
				state.entities[loc._id] = loc

				if (index < 4) {
					state.recents.push(loc._id)
				}
				return loc._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeActiveLocations.pending as any]: state => {
			state.isLoading = true
		},
		[initializeActiveLocations.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},

		[initializeTenantLocations.fulfilled as any]: (state, action) => {
			const locations = action.payload

			state.recents = []
			state.ids = locations?.map((loc, index) => {
				state.entities[loc._id] = loc

				if (index < 4) {
					state.recents.push(loc._id)
				}
				return loc._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeTenantLocations.pending as any]: state => {
			state.isLoading = true
		},
		[initializeTenantLocations.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},

		[addPartnerToLocation.fulfilled as any]: (state, action) => {
			const location = action.payload
			state.entities[location._id] = location
		},
		[addPartnerToLocation.rejected as any]: (state, action) => {
			console.log(action)
		},
		[removePartnerFromLocation.fulfilled as any]: (state, action) => {
			const partners = action.payload
			state.entities[action.meta.arg.locationId].partners = partners
		},
		[createLocation.fulfilled as any]: (state, action) => {
			const location = action.payload
			state.entities[location._id] = location

			// handle case where this location is a child of a center
			if (location.parentid) {
				let parentTenantRef = state.entities[location.parentid].tenants
				if (!parentTenantRef) {
					parentTenantRef = []
				}
				parentTenantRef.unshift(location._id)
			}
		},
		[createLocation.rejected as any]: (state, action) => {
			const location = action.payload
			state.entities[location._id] = location
		},
		[listRecentLocations.fulfilled as any]: (state, action) => {
			const recents = action.payload

			if (recents.length) {
				state.recents = recents.map(loc => loc)
			}
			state.isLoading = false
		},
		[listRecentLocations.pending as any]: (state, action) => {
			state.isLoading = true
		},
		[listRecentLocations.rejected as any]: (state, action) => {
			state.error = 'Unable to get locations'
			state.isLoading = false
		},
		[getLocationByID.fulfilled as any]: (state, action) => {
			// works for any asset type since all types are stored under entities
			state.entities[action.meta.arg] = { ...action.payload, ...state.entities[action.meta.arg] }
			state.isLoading = false
		},
		[getLocationByID.rejected as any]: (state, action) => {
			state.error = action.error
		},
		[deleteLocation.fulfilled as any]: (state, action) => {
			const id = action.meta.arg
			state.entities = removeKey(id, state.entities)
			state.ids = state.ids.filter(stateId => stateId !== id)
		},
		[updateLocation.fulfilled as any]: (state, action) => {
			const location = action.payload
			state.entities[location._id] = { ...action.payload, ...state.entities[location._id] }
			state.isInitialized = false
		},
		[patchLocation.fulfilled as any]: (state, action) => {
			const location = action.payload
			state.entities[location._id] = { ...action.payload, ...state.entities[location._id] }
			state.isInitialized = false
		},
		[getCenterTenants.fulfilled as any]: (state, action) => {
			const tenants = action.payload

			state.centerTenantIds = tenants?.map((loc, index) => {
				state.centerTenantEntities[loc._id] = loc
				return loc._id
			})
			state.centerTenantsLoading = false
			state.centerTenantsInitialized = true

			// const parentRef = state.centerEntities[action.meta.arg]
			// let tenantIds = tenants.map(t => {
			// 	if (!state.centerEntities[t._id]) {
			// 		state.centerEntities[t._id] = t
			// 	}
			// 	return t._id
			// })
			// parentRef.tenants = tenantIds
			// state.tenantsPending = false
		},
		[getCenterTenants.pending as any]: state => {
			state.centerTenantsLoading = true
		},
		[getCenterTenants.rejected as any]: (state, action) => {
			state.centerTenantsLoading = false
			state.centerTenantsInitialized = true
			state.error = action.error.message
		},
		[getNotes.fulfilled as any]: (state, action) => {
			const notes = action.payload
			const locationId = action.meta.arg
			const locationRef = state.entities[locationId]
			if (locationRef && notes?.length) {
				const locationNoteIds = notes.map(n => n._id)
				locationRef.notes = locationNoteIds
			}
		},
		[createNote.fulfilled as any]: (state, action) => {
			const newNote = action.payload
			const existingNotes = state.entities[newNote.locationid].notes
			if (existingNotes) {
				existingNotes.unshift(newNote._id)
			} else {
				state.entities[newNote.locationid].notes = [newNote._id]
			}
		},
		[getImages.fulfilled as any]: (state, action) => {
			const images = action.payload
			const locationId = action.meta.arg
			const locationRef = state.entities[locationId]
			if (locationRef && images?.length) {
				const locationImageIds = images.map(n => n._id)
				locationRef.images = locationImageIds
			}
		},
		[createImage.fulfilled as any]: (state, action) => {
			const image = action.payload
			const locationId = image.locationid
			const locationRef = state.entities[locationId]
			if (locationRef && image) {
				if (locationRef.images) {
					locationRef.images.push(image._id)
				} else {
					locationRef.images = [image._id]
				}
			}
		},
		[deleteFile.fulfilled as any]: (state, action) => {
			const locationId = action.payload.locationId
			const fileId = action.meta.arg
			const updatedFileIds = state.entities[locationId]?.images?.filter((oldFileId: any) => {
				return oldFileId !== fileId
			})
			state.entities[locationId].images = updatedFileIds
		},
		[deleteImage.fulfilled as any]: (state, action) => {
			const locationId = action.payload.locationId
			const imageId = action.meta.arg
			const updatedImageIds = state.entities[locationId]?.images?.filter((imgId: any) => {
				return imgId !== imageId
			})
			state.entities[locationId].images = updatedImageIds
		},
		[getFiles.fulfilled as any]: (state, action) => {
			const files = action.payload
			const locationId = action.meta.arg
			const locationRef = state.entities[locationId]
			if (locationRef && files?.length) {
				const locationFileIds = files.map(n => n._id)
				locationRef.files = locationFileIds
			}
		},
		[createFile.fulfilled as any]: (state, action) => {
			const file = action.payload
			const locationId = file.locationid
			const locationRef = state.entities[locationId]
			if (locationRef && file) {
				if (locationRef.files) {
					locationRef.files.push(file._id)
				} else {
					locationRef.files = [file._id]
				}
			}
		},

		// ------------------------------------------------------
		// Create Bulk, Fleet and Tenant locations
		//-------------------------------------------------------
		// Bulk Locations

		[createBulkLocations.pending as any]: state => {
			state.isLoading = true
		},
		[createBulkLocations.fulfilled as any]: (state, action) => {
			const locations = action.payload
			state.ids = locations?.map((loc, index) => {
				state.entities[loc._id] = loc
				return loc._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[createBulkLocations.rejected as any]: (state, action) => {
			const location = action.payload
			state.entities[location._id] = location
		},
	} as any,
})

export default locationsSlice

// export const { } = locationsSlice.actions;
