import styled from 'styled-components'
import type { Props } from './Button'
import * as _ from 'styles/helpers'

interface ICreateButtonStyles {
	bg: string
	bgHover: string
	fg: string
	isOutline?: boolean
	isGhost?: boolean
}

export const createButtonStyles = ({
	bg,
	bgHover,
	fg,
	isOutline = false,
	isGhost = false,
}: ICreateButtonStyles) => `
	color: ${isOutline ? (isGhost ? fg : bg) : fg};
	background-color: ${isOutline ? 'transparent' : bg};
	${isOutline ? `box-shadow: inset 0 0 0 ${_.rem(1.5)} ${bg}` : ''};

	&:hover {
    color: ${_.COLORS.white};
    color: ${isOutline && ''};
    color: ${isGhost && fg};
		background-color: ${isOutline ? bg : bgHover};
    text-decoration: none;
	}
`

const StyledButton = styled.button<Partial<Props>>`
	display: inline-block;
	vertical-align: middle;
	margin: 0 ${_.rem(8)} 0 0;
	padding: ${_.rem(4)} ${_.rem(12)};
	font-size: ${_.rem(16)};
	/* font-weight: ${_.FONTS.bold}; */
	white-space: nowrap;
	text-align: center;
	text-decoration: none;
	border: none;
	border-radius: ${_.rem(_.BASE_BORDER_RADIUS)};
	cursor: pointer;

	> div {
		${_.MIXINS.vhc};
		min-height: ${_.rem(24)};

		> svg {
			font-size: ${_.rem(14)};
			margin-right: ${_.rem(8)};
			margin-left: ${_.rem(-4)};
		}
	}

	${props =>
		props.isLarge &&
		`
    padding: ${_.rem(12)} ${_.rem(20)};
	  font-size: ${_.rem(20)};

    > div > svg {
      font-size: ${_.rem(20)};
    }
    `}

	/* this is so buttons can match height with inputs */
  ${props =>
		props.isInput &&
		`
    padding: ${_.rem(4)} ${_.rem(8)};
    `}

	${props =>
		props.variant === 'primary' &&
		createButtonStyles({
			bg: _.COLORS.blue_500,
			bgHover: _.COLORS.blue_600,
			fg: _.COLORS.white,
			isOutline: props.isOutline,
		})}


	${props =>
		props.variant === 'text' &&
		createButtonStyles({
			bg: _.COLORS.white,
			bgHover: _.COLORS.tradewind_blue,
			fg: _.COLORS.tradewind_blue,
			isOutline: props.isOutline,
		})}

	${props =>
		props.variant === 'success' &&
		createButtonStyles({
			bg: _.COLORS.green_500,
			bgHover: _.COLORS.green_600,
			fg: _.COLORS.white,
			isOutline: props.isOutline,
		})}

	${props =>
		props.variant === 'danger' &&
		createButtonStyles({
			bg: _.COLORS.red_500,
			bgHover: _.COLORS.red_600,
			fg: _.COLORS.white,
			isOutline: props.isOutline,
		})}

	${props =>
		props.variant === 'ghost' &&
		createButtonStyles({
			bg: _.COLORS.gray_100,
			bgHover: _.COLORS.gray_200,
			fg: _.COLORS.blue_500,
			isOutline: props.isOutline,
			isGhost: true,
		})}

	${props =>
		props.variant === 'ghost-red' &&
		createButtonStyles({
			bg: _.COLORS.red_500,
			bgHover: _.COLORS.red_600,
			fg: _.COLORS.red_500,
			isOutline: props.isOutline,
			isGhost: true,
		})}

	&:disabled {
		opacity: 0.5;
		pointer-events: none;
	}
`

export default StyledButton
