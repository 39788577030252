import React from 'react'
import { useChat } from 'features/Chat'
import Spinner from 'components/Spinner'

const ChatSpinnerContainer = () => {
	const {
		state: { isLoading },
	} = useChat()

	if (isLoading) return <Spinner />
	return null
}

export default ChatSpinnerContainer
