import React, { useState } from 'react'
import { Table, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'
import styled from 'styled-components'

const StyledTable = styled(Table)`
	& thead th {
		font-weight: 600;
		color: #444;
		border-bottom: 2px solid #027fff;
	}

	& thead th:hover span {
		color: #444;
	}

	& tbody td {
		font-weight: 400;
	}

	& tbody tr:hover {
		background-color: #fffbf2;
		cursor: pointer;
	}
`

export default function useTableNoPage(records, headerCells, filterFn) {
	const page = 0
	const rowsPerPage = 9999999
	const [order, setOrder] = useState()
	const [orderBy, setOrderBy] = useState()

	const TblContainer = props => <StyledTable>{props.children}</StyledTable>

	const TblHeader = props => {
		const handleSortRequest = cellId => {
			const isAsc = orderBy === cellId && order === 'asc'
			setOrder(isAsc ? 'desc' : 'asc')
			setOrderBy(cellId)
		}

		return (
			<TableHead>
				<TableRow>
					{headerCells.map(headerCell => (
						<TableCell
							key={headerCell.id}
							sortDirection={orderBy === headerCell.id ? order : false}
						>
							{headerCell.disableSorting ? (
								headerCell.label
							) : (
								<TableSortLabel
									// className={classes.headerLabel}
									active={orderBy === headerCell.id}
									direction={orderBy === headerCell.id ? order : 'asc'}
									onClick={() => {
										handleSortRequest(headerCell.id)
									}}
								>
									{headerCell.label}
								</TableSortLabel>
							)}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
		)
	}

	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index])
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0])
			if (order !== 0) return order
			return a[1] - b[1]
		})
		return stabilizedThis.map(el => el[0])
	}

	function getComparator(order, orderBy) {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy)
	}

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1
		}
		if (b[orderBy] > a[orderBy]) {
			return 1
		}
		return 0
	}

	const recordsAfterPagingAndSorting = () => {
		return stableSort(filterFn.fn(records), getComparator(order, orderBy)).slice(
			page * rowsPerPage,
			(page + 1) * rowsPerPage,
		)
	}

	return {
		TblContainer,
		TblHeader,
		recordsAfterPagingAndSorting,
	}
}
