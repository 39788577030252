export const GRID_GAP = 24
const MIN_CARD_WIDTH = 240
const MAX_CARD_WIDTH = 320

const getGridDims = (numColumns: number) => ({
	minWidth: MIN_CARD_WIDTH * numColumns + GRID_GAP * (numColumns - 1),
	maxWidth: MAX_CARD_WIDTH * numColumns + GRID_GAP * (numColumns - 1),
})

export default getGridDims
