import React from 'react'
import { useSelector } from 'react-redux'
import ArchivedLocations from '../components/ArchivedLocations'

const ArchivedLocationsLibrary = () => {
	const userLogin = useSelector(state => state.userLogin)
	const { userInfo: user } = userLogin
	const userId = user._id

	return <ArchivedLocations userId={userId} />
}

export default ArchivedLocationsLibrary
