import styled from 'styled-components'
import type { Props } from './Details'
import * as _ from 'styles/helpers'

const StyledDetails = styled.div<Partial<Props>>``

export const StyledDetailsTable = styled.div<Partial<Props>>`
	.edit-button {
		display: flex;
		justify-content: flex-end;

		button {
			margin: 0;
			padding: 0 ${_.rem(4)};
			box-shadow: none;
			opacity: 0;

			&:hover {
				background-color: transparent;
			}

			&:focus {
				opacity: 1;
			}

			svg {
				margin: 0;
				font-size: 18px;
			}
		}
	}

	.table-container {
		padding: 16px;
		background-color: white;
		margin-bottom: 15px;
		box-shadow: inset 0 0 0 0.0625rem #edf2f7, 0 0.125rem 0.0625rem -0.0625rem rgb(0 0 0 / 20%),
			0 0.0625rem 0.0625rem 0 rgb(0 0 0 / 14%), 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 12%);
		border-radius: 5px;
		overflow: hidden;

		.table-row {
			padding-bottom: 25px;
			th,
			td {
				padding: 8px;
				padding-left: 16px;
				font-size: 13px;
			}

			&:hover .edit-button button {
				opacity: 1;
			}
		}

		.table__header {
			display: flex;
			font-size: 22px;
			/* margin-bottom: 5px; */
		}

		.table__title {
			width: 100%;
			/* margin-left: 10px; */
			/* padding-bottom: 0px; */
			background-color: #e7fdfe;
		}

		.table__title > h2 {
			margin: 5px;
			font-size: 16px;
			font-weight: 600;
		}
	}
	/* .DetailsTable__table-cell {
		padding: 8px;
	} */
	// MUI overrides
	.MuiTableHead-root {
		border-bottom: 2px solid ${_.COLORS.tradewind_blue};
	}
	.MuiTableCell-head {
		font-weight: 600;
	}
`

export const StyledDetailsHeader = styled.div`
	margin-bottom: ${_.rem(24)};
`
export const StyledSibarList = styled.div`
	margin-bottom: ${_.rem(10)};
`

export default StyledDetails
