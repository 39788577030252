import React, { useState, useEffect } from 'react'
import TWControls from 'components/controls/TWControls'
import { Form, useForm } from 'components/controls/useForm'
import Spinner from 'components/Spinner'
import { Grid, InputAdornment, Paper } from '@mui/material'
import { FaImage } from 'react-icons/fa'
import { Search as SearchIcon } from '@mui/icons-material'
import FileUploader from 'components/FileUploader'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
	input: {
		display: 'none',
	},
}))

const LocationTenantEdit = ({ addEditTenant, recordForEdit }) => {
	const classes = useStyles()
	const [address, setAddress] = useState('')
	const [picLoading, setPicLoading] = useState(false)
	const [picLoaded, setPicLoaded] = useState(false)

	const initialFValues = {
		_id: 0,
		assetName: '',
		address: '',
		city: '',
		stateProvince: '',
		postalCode: '',
		centerLocation: '',
		totalSqft: '',
		estimatedSales: '',
		latitude: '',
		longitude: '',
		isTenantOnly: true,
		image: '',
	}

	const validate = () => {
		let temp = { ...errors }
		temp.assetName = values.assetName ? '' : 'This field is required.'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialFValues,
		true,
		validate,
	)

	const submitHandler = e => {
		e.preventDefault()
		if (validate() && picLoaded) {
			addEditTenant(values, resetForm)
		}
	}

	useEffect(() => {
		if (recordForEdit != null) {
			setValues({ ...recordForEdit })
		}
	}, [recordForEdit, setValues])

	const handleSelect = async value => {
		const results = await geocodeByAddress(value)
		const latLng = await getLatLng(results[0])
		const place = results[0]

		setAddress(value)

		var newName = value.split(',')
		values.assetName = newName[0]
		values.address = ''
		values.postalCode = ''
		values.city = ''
		values.stateProvince = ''
		values.country = ''
		values.latitude = ''
		values.longitude = ''

		for (const component of place.address_components) {
			const componentType = component.types[0]

			switch (componentType) {
				case 'street_number': {
					values.address = `${component.long_name} ${values.address}`
					break
				}
				case 'route': {
					values.address += component.short_name
					break
				}
				case 'postal_code': {
					values.postalCode = `${component.long_name}${values.postalCode}`
					break
				}
				case 'postal_code_suffix': {
					values.postalCode = `${values.postalCode}-${component.long_name}`
					break
				}
				case 'locality':
					values.city = component.long_name
					break
				case 'administrative_area_level_1': {
					values.stateProvince = component.short_name
					break
				}
				case 'country':
					values.country = component.short_name
					break
				default:
					break
			}
		}
		values.latitude = latLng.lat
		values.longitude = latLng.lng
		setAddress('')
	}

	const uploadImage = async info => {
		setPicLoading(true)
		values.image = info.originalUrl
		setPicLoading(false)
		setPicLoaded(true)
	}

	const closeForm = () => {
		setPicLoaded(true)
	}

	return (
		<Form onSubmit={submitHandler}>
			<PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
				{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
					<div>
						<TWControls.Input
							size="small"
							type="search"
							// label="Search"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							{...getInputProps()}
						/>

						<Paper>
							{loading ? <div> ...loading</div> : null}

							{suggestions.map(suggestion => {
								const style = suggestion.active
									? { backgroundColor: '#41b6e6' }
									: { backgroundColor: '#FFF' }

								return (
									<div {...getSuggestionItemProps(suggestion, { style })}>
										{suggestion.description}
									</div>
								)
							})}
						</Paper>
					</div>
				)}
			</PlacesAutocomplete>

			<Grid container spacing={2}>
				<Grid item xs={6}>
					<TWControls.Input
						size="small"
						name="assetName"
						label="Tenant Name"
						value={values.assetName}
						error={errors.assetName}
						onChange={handleInputChange}
					/>
					<TWControls.Input
						size="small"
						name="address"
						label="Address"
						value={values.address}
						onChange={handleInputChange}
						error={errors.address}
					/>
					<TWControls.Input
						size="small"
						name="city"
						label="City"
						value={values.city}
						onChange={handleInputChange}
						error={errors.city}
					/>
					<TWControls.Input
						size="small"
						name="stateProvince"
						label="State/Prov"
						value={values.stateProvince}
						onChange={handleInputChange}
						error={errors.stateProvince}
					/>
					<TWControls.Input
						size="small"
						name="postalCode"
						label="Postal Code"
						value={values.postalCode}
						onChange={handleInputChange}
						error={errors.postalCode}
					/>
				</Grid>
				<Grid item xs={6}>
					<TWControls.Input
						size="small"
						name="centerLocation"
						label="Location in Center"
						value={values.centerLocation}
						error={errors.centerLocation}
						onChange={handleInputChange}
					/>
					<TWControls.Input
						size="small"
						name="totalSqft"
						label="Tenant SqFt."
						value={values.totalSqft}
						error={errors.totalSqft}
						onChange={handleInputChange}
					/>
					<TWControls.Input
						size="small"
						name="estimatedSales"
						label="Estimated Tenant Sales"
						value={values.estimatedSales}
						error={errors.estimatedSales}
						onChange={handleInputChange}
					/>
					<TWControls.Input
						size="small"
						name="latitude"
						label="Latitude"
						value={values.latitude}
						error={errors.latitude}
						onChange={handleInputChange}
					/>
					<TWControls.Input
						size="small"
						name="longitude"
						label="Longitude"
						value={values.longitude}
						error={errors.longitude}
						onChange={handleInputChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<TWControls.CheckBox
						size="small"
						name="isTenantOnly"
						label="Tenant Only"
						value={values.isTenantOnly}
						error={errors.isTenantOnly}
						onChange={handleInputChange}
					/>
				</Grid>
				<Grid item>
					<div className="newLocation__imageHolder">
						{picLoading ? (
							<Spinner />
						) : values.image ? (
							<img className="newLocation__image" src={values.image} alt="" />
						) : null}
					</div>
					<div className="newLocation__imageHolder">
						<FileUploader onChange={info => uploadImage(info)}>
							<FaImage />
							Add Image
						</FileUploader>
					</div>
				</Grid>
				<Grid item xs={12}>
					{values._id === 0 ? (
						<div className="newLocation__imageHolder">
							<TWControls.Button size="small" type="submit" text="Add Tenant" onClick={closeForm} />
						</div>
					) : (
						<div className="newLocation__imageHolder">
							<TWControls.Button size="small" type="submit" text="Update" onClick={closeForm} />
						</div>
					)}
				</Grid>
			</Grid>
		</Form>
	)
}

export default LocationTenantEdit
