import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import TWControls from 'components/controls/TWControls'
import { Form, useForm } from 'components/controls/useForm'
import * as _ from 'styles/helpers'

const BulkLocationEditPopup = ({ editLocation, recordForEdit }) => {
	const initialValues = {
		...recordForEdit,
	}

	const validate = () => {
		let temp = { ...errors }
		temp.assetName = values.assetName ? '' : 'This field is required.'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialValues,
		true,
		validate,
	)

	const submitHandler = () => {
		console.log(`values: `, values)

		if (validate()) {
			editLocation(values)
		}
	}

	useEffect(() => {
		if (recordForEdit != null) setValues({ ...recordForEdit })
	}, [recordForEdit, setValues])

	return (
		<StyledAssetEdit>
			<Form onSubmit={submitHandler}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<TWControls.Input
							size="small"
							name="assetName"
							label="Location Name"
							value={values.assetName}
							onChange={handleInputChange}
						/>
					</Grid>
					<Grid item xs={6}>
						<TWControls.Input
							size="small"
							name="address"
							label="Address"
							value={values.address}
							onChange={handleInputChange}
						/>
					</Grid>

					<Grid item xs={6}>
						<TWControls.Input
							size="small"
							name="city"
							label="City"
							value={values.city}
							onChange={handleInputChange}
						/>
					</Grid>

					<Grid item xs={3}>
						<TWControls.Input
							size="small"
							name="stateProvince"
							label="State/Prov"
							value={values.stateProvince}
							onChange={handleInputChange}
						/>
					</Grid>

					<Grid item xs={3}>
						<TWControls.Input
							size="small"
							name="postalCode"
							label="Postal Code"
							value={values.postalCode}
							onChange={handleInputChange}
						/>
					</Grid>
					<Grid item xs={12}>
						<div className="AssetEdit__Actions">
							<TWControls.Button size="small" type="submit" text="Update" />
						</div>
					</Grid>
				</Grid>
			</Form>
		</StyledAssetEdit>
	)
}

const StyledAssetEdit = styled.div`
	max-width: ${_.rem(960)};
	margin: 0 auto;

	.AssetEdit {
		&__Card {
			h2 {
				overflow: hidden;
				white-space: nowrap;
			}
		}

		&__password-buttton {
			${_.MIXINS.vhc}
			margin: ${_.rem(16)} 0 ${_.rem(24)};
		}

		&__Asset,
		&__Actions {
			${_.MIXINS.mod}
			${_.MIXINS.vhc}
			flex-direction: column;
			padding: ${_.rem(16)};
		}

		&__Image {
			width: ${_.rem(250)};
			margin-bottom: ${_.rem(10)};
			border-radius: ${_.rem(5)};
			border: ${_.rem(2)} solid #0073e6;
			margin-bottom: ${_.rem(16)};
		}
	}

	input {
		font-size: ${_.rem(18)};
	}

	.input-group {
		${({ isMobile }) =>
			!isMobile &&
			`
      display: flex;
      gap: ${_.rem(16)};
    `}
	}

	.input-group-title {
		margin-top: ${_.rem(16)};
		margin-bottom: ${_.rem(16)};
		font-weight: 400;
		font-size: ${_.rem(14)};
		color: ${_.COLORS.gray_500};
	}
`

export default BulkLocationEditPopup
