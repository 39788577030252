import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
const PROJECT_NOTE = 'project_note'

const initialState = {
	entities: {},
	isLoading: false,
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

export const getProjectNotes = createAsyncThunk(
	`${PROJECT_NOTE}/getNotes`,
	async (projectid, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.get(`/api/projectNotes/${projectid}`, prepConfig(getState))
			// const { data } = await axios.get(`/api/projectNotes/${projectId}`, prepConfig(getState))
			return data
		} catch (error) {
			console.error(error)
			return rejectWithValue('Failed to get notes')
		}
	},
)

export const createProjectNote = createAsyncThunk(
	`${PROJECT_NOTE}/create`,
	async (projectNote, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/api/projectNotes`, projectNote, prepConfig(getState))
			// const { data } = await axios.post(`/api/projectNotes`, note, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to get notes')
		}
	},
)

export const updateProjectNote = createAsyncThunk(
	`${PROJECT_NOTE}/update`,
	async (note: any, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.put(`/api/projectNotes/${note._id}`, note, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to create center')
		}
	},
)

const projectNoteSlice = createSlice({
	name: 'project',
	initialState,
	reducers: {},
	extraReducers: {
		[getProjectNotes.fulfilled as any]: (state, action) => {
			const projectNotes = action.payload
			const noteMap = projectNotes.reduce((lookup, item) => {
				lookup[item._id] = item
				return lookup
			}, {})
			state.entities = { ...state.entities, ...noteMap }
			state.isLoading = false
		},
		[getProjectNotes.pending as any]: state => {
			state.isLoading = true
		},
		[createProjectNote.fulfilled as any]: (state, action) => {
			const newNote = action.payload
			state.entities[newNote._id] = newNote
		},
		[updateProjectNote.fulfilled as any]: (state, action) => {
			const updatedNote = action.payload
			state.entities[updatedNote._id] = updatedNote
		},
	} as any,
})

export default projectNoteSlice

// export const { } = projectsSlice.actions;
