import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const SLICE_NAME = 'apiKeys'

const initialState = {
	entity: {},
	id: [],
	isLoading: false,
	isInitialized: false,
}

export const initializeApiKey = createAsyncThunk(
	`${SLICE_NAME}/initialize`,
	async (payload: any, { getState, rejectWithValue }) => {
		const params = {
			params: {
				name: payload,
			},
		}
		try {
			return axios.get('/api/apiKeys/apiname', params).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize apiKey')
		}
	},
)

const apiKeysSlice = createSlice({
	name: `${SLICE_NAME}`,
	initialState,
	reducers: {},
	extraReducers: {
		[initializeApiKey.fulfilled as any]: (state, action) => {
			const apiKey = action.payload
			state.id = apiKey._id
			state.entity = apiKey
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeApiKey.pending as any]: state => {
			state.isLoading = true
		},
		[initializeApiKey.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},
	} as any,
})

export default apiKeysSlice
