import LocationNotes from '../navPages/LocationNotes'
import LocationProjects from '../navPages/LocationProjects'
import LocationAnalytics from '../navPages/LocationAnalytics'
import LocationMaps from '../navPages/LocationMaps'
import LocationImages from '../navPages/LocationImages'
import Locations_Edit from '../navPages/Location_Edit'
import LocationAddTenants from '../navPages/LocationAddTenants'
import LocationTenants from '../navPages/LocationTenants'
import routes from 'constants/routes'

const getLocationCenterRoutes = (id: string) => [
	{
		linkText: 'Projects ',
		linkTo: `${routes.LOCATIONS}/${id}/projects`,
		linkPattern: `${routes.LOCATIONS}/:id/projects`,
		component: LocationProjects,
	},
	{
		linkText: 'Tenants',
		linkTo: `${routes.LOCATIONS}/${id}/tenants`,
		linkPattern: `${routes.LOCATIONS}/:id/tenants`,
		component: LocationTenants,
	},
	{
		linkText: 'Notes ',
		linkTo: `${routes.LOCATIONS}/${id}/notes`,
		linkPattern: `${routes.LOCATIONS}/:id/notes`,
		component: LocationNotes,
	},
	{
		linkText: 'Map',
		linkTo: `${routes.LOCATIONS}/${id}/maps`,
		linkPattern: `${routes.LOCATIONS}/:id/maps`,
		component: LocationMaps,
	},
	{
		linkText: 'Demographics',
		linkTo: `${routes.LOCATIONS}/${id}/analytics`,
		linkPattern: `${routes.LOCATIONS}/:id/analytics`,
		component: LocationAnalytics,
	},
	// {
	// 	linkText: 'Images',
	// 	linkTo: `${routes.LOCATIONS}/${id}/images`,
	// 	linkPattern: `${routes.LOCATIONS}/:id/images`,
	// 	component: LocationImages,
	// },

	/*
		Redirect routes
	*/
	{
		linkPattern: `${routes.LOCATIONS}/:id/edit`,
		component: Locations_Edit,
	},
	// {
	// 	linkPattern: `${routes.LOCATIONS}/:id/addtenants`,
	// 	component: LocationAddTenants,
	// },
	{
		redirectFrom: `${routes.LOCATIONS}/:id`,
		redirectTo: `${routes.LOCATIONS}/${id}/projects`,
	},
]

export default getLocationCenterRoutes
