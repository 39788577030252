import axios from 'axios'
import {
	CENTER_TENNANTS_UPLOAD_REQUEST,
	CENTER_TENNANTS_UPLOAD_SUCCESS,
	CENTER_TENNANTS_UPLOAD_FAIL,
} from '../constants/centerTenantConstants'

export const uploadCenterTenants = (centerid, tenants) => async (dispatch, getState) => {
	try {
		dispatch({
			type: CENTER_TENNANTS_UPLOAD_REQUEST,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}
		const { data } = await axios.post(`/api/centerTenants/uploaded`, tenants, config)

		dispatch({
			type: CENTER_TENNANTS_UPLOAD_SUCCESS,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: CENTER_TENNANTS_UPLOAD_FAIL,
			payload:
				error.response && error.response.data.message ? error.response.data.message : error.message,
		})
	}
}
