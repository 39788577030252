import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { tasksService } from 'services'

const SLICE_NAME = 'tasks'

const initialState = {
	entities: {},
	ids: [],
	isLoading: false,
	isInitialized: false,
	error: null,
	tasks: {
		loading: true,
		data: [],
		error: null,
	},
	subtasks: {
		loading: true,
		data: [],
		error: null,
	},
	assignedTasks: {
		loading: true,
		data: [],
		error: null,
	},
	assignedTaskCount: {
		loading: true,
		taskCount: 0,
		error: null,
	},
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

// CREATE
export const createTask = createAsyncThunk(
	`${SLICE_NAME}/createTask`,
	async (task: any) => (await tasksService().createTask(task)) || {},
)

// READ
export const getTasks = createAsyncThunk(
	`${SLICE_NAME}/getTasks`,
	async ({
		userid,
		packageid,
		projectid,
	}: {
		userid: string
		packageid: string
		projectid: string
	}) => (await tasksService().getTasks({ userid, packageid, projectid })) || [],
)

export const initializeTasks = createAsyncThunk(
	`${SLICE_NAME}/initialize`,
	async (payload: any, { getState, rejectWithValue }) => {
		const { userid, packageid, projectid } = payload
		try {
			return axios
				.get('/api/tasks/mypackageid', {
					params: {
						userid,
						packageid,
						projectid,
					},
				})
				.then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize tasks')
		}
	},
)

export const initializeRecentTasks = createAsyncThunk(
	`${SLICE_NAME}/initializeRecent`,
	async (payload: any, { getState, rejectWithValue }) => {
		const { userid } = payload

		try {
			return axios.get('/api/tasks/myrecent', { params: { userid } }).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize recent tasks')
		}
	},
)

// UPDATE
export const updateTask = createAsyncThunk(
	`${SLICE_NAME}/updateTask`,
	async (task: any) => (await tasksService().updateTask(task)) || {},
)

// DELETE
export const deleteTask = createAsyncThunk(
	`${SLICE_NAME}/deleteTask`,
	async (id: string) => (await tasksService().deleteTask(id)) || {},
)

// CREATE SUBTASK
export const createSubtask = createAsyncThunk(
	`${SLICE_NAME}/createSubtask`,
	async (task: any) => (await tasksService().createSubtask(task)) || {},
)

// READ SUBTASKS
export const getSubtasks = createAsyncThunk(
	`${SLICE_NAME}/getSubtasks`,
	async ({ userid, packageid }: { userid: string; packageid: string }) =>
		(await tasksService().getSubtasks({ userid, packageid })) || [],
)

export const initializeAggregatedTasks = createAsyncThunk(
	`${SLICE_NAME}/initializeAggregated`,
	async (payload: any, { getState, rejectWithValue }) => {
		const { userId, backDays, forwardDays } = payload

		try {
			const { data } = await axios.get(`/api/aggregations/tasks`, {
				params: {
					userId: userId,
					backDays: backDays,
					forwardDays: forwardDays,
				},
			})
			return data
		} catch (error) {
			return rejectWithValue('Failed to add partner')
		}
	},
)

// READ
export const initializeAssignedTasks = createAsyncThunk(
	`${SLICE_NAME}/initializeAssigned`,
	async (payload: any, { getState, rejectWithValue }) => {
		const { userId } = payload

		try {
			const { data } = await axios.get(`/api/tasks/myassigned`, {
				params: {
					assignedTo: userId,
				},
			})
			return data
		} catch (error) {
			return rejectWithValue('Failed to add partner')
		}
	},
)

// READ
export const initializeAssignedTaskCount = createAsyncThunk(
	`${SLICE_NAME}/initializeAssignedCount`,
	async (payload: any, { getState, rejectWithValue }) => {
		const { userId } = payload

		try {
			const { data } = await axios.get(`/api/tasks/myassigned`, {
				params: {
					assignedTo: userId,
				},
			})
			return data
		} catch (error) {
			return rejectWithValue('Failed to add partner')
		}
	},
)

// LOAD TEMPLATE
export const loadTemplateIntoTasks = createAsyncThunk(
	`${SLICE_NAME}/loadTemplateIntoTasks`,
	async (template: any) => (await tasksService().loadTemplateIntoTasks(template)) || {},
)

const tasksSlice = createSlice({
	name: SLICE_NAME,
	initialState,
	reducers: {},
	extraReducers: {
		// READ TASKS
		[getTasks.pending as any]: () => ({ ...initialState }),
		[getTasks.fulfilled as any]: (state, action) => ({
			...state,
			tasks: {
				loading: false,
				data: action.payload,
			},
		}),
		[getTasks.rejected as any]: (state, action) => ({
			...state,
			tasks: {
				loading: false,
				error: action.error,
			},
		}),

		[initializeTasks.pending as any]: state => {
			state.isLoading = true
		},
		[initializeTasks.fulfilled as any]: (state, action) => {
			const tasks = action.payload

			state.ids = tasks?.map((task, index) => {
				state.entities[task._id] = task
				return task._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeTasks.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},

		[initializeRecentTasks.pending as any]: state => {
			state.isLoading = true
		},
		[initializeRecentTasks.fulfilled as any]: (state, action) => {
			const tasks = action.payload
			state.ids = tasks?.map((task, index) => {
				state.entities[task._id] = task
				return task._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeRecentTasks.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},

		// READ SUBTASKS
		[getSubtasks.pending as any]: () => ({ ...initialState }),
		[getSubtasks.fulfilled as any]: (state, action) => ({
			...state,
			subtasks: {
				loading: false,
				data: action.payload,
			},
		}),
		[getSubtasks.rejected as any]: (state, action) => ({
			...state,
			subtasks: {
				loading: false,
				error: action.error,
			},
		}),

		// READ ALL MY TASKS
		[initializeAggregatedTasks.pending as any]: () => ({ ...initialState }),
		[initializeAggregatedTasks.fulfilled as any]: (state, action) => {
			const tasks = action.payload

			state.ids = tasks?.map((task, index) => {
				state.entities[task._id] = task
				return task._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeAggregatedTasks.rejected as any]: (state, action) => ({
			...state,
			assignedTasks: {
				loading: false,
				error: action.error,
			},
		}),

		// READ ALL MY TASKS
		[initializeAssignedTaskCount.pending as any]: () => ({ ...initialState }),
		[initializeAssignedTaskCount.fulfilled as any]: (state, action) => ({
			...state,
			assignedTaskCount: {
				loading: false,
				taskCount: action.payload.length,
			},
		}),

		[initializeAssignedTasks.pending as any]: () => ({ ...initialState }),
		[initializeAssignedTasks.fulfilled as any]: (state, action) => {
			const tasks = action.payload

			state.ids = tasks?.map((task, index) => {
				state.entities[task._id] = task
				return task._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeAssignedTasks.rejected as any]: (state, action) => ({
			...state,
			assignedTasks: {
				loading: false,
				error: action.error,
			},
			[getTasks.pending as any]: () => ({ ...initialState }),
		}),

		[initializeAssignedTaskCount.rejected as any]: (state, action) => ({
			...state,
			assignedTaskCount: {
				loading: false,
				error: action.error,
			},
		}),

		[getTasks.pending as any]: () => ({ ...initialState }),

		[updateTask.fulfilled as any]: (state, action) => {
			state.isInitialized = false
		},
	},
})

// export const {} = tasksSlice.actions
export default tasksSlice.reducer
