import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import Button from 'components/Button'
import TWControls from './controls/TWControls'
import { Form, useForm } from './controls/useForm'

const TemplateAddEdit = ({ recordForEdit, handleSubmit }) => {
	const templateTypes = [
		{ id: 1 },
		{ selectValue: 'Tasks Template' },
		{ id: 2 },
		{ selectValue: 'Site Details Template' },
	]

	const defaultValues = {
		_id: 0,
		templateName: '',
		description: '',
		templateType: '',
	}

	const initialValues = {
		...defaultValues,
		...recordForEdit,
	}

	const validate = () => {
		let temp = { ...errors }
		temp.templateName = values.templateName ? '' : 'This field is required.'
		// temp.description = values.description ? '' : 'This field is required.'
		temp.templateType = values.templateType ? '' : 'This field is required.'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialValues,
		true,
		validate,
	)

	const submitHandler = e => {
		e.preventDefault()

		if (validate()) handleSubmit(values, resetForm)
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container>
				<Grid item>
					<TWControls.Input
						name="templateName"
						label="Template Name"
						value={values.templateName}
						onChange={handleInputChange}
						error={errors.templateName}
					/>
					<TWControls.SelectType
						name="templateType"
						label="Template Type"
						value={values.templateType}
						onChange={handleInputChange}
						error={errors.templateType}
						options={templateTypes}
					/>
					<TWControls.InputMulti
						name="description"
						label="Description"
						value={values.description}
						onChange={handleInputChange}
						error={errors.description}
					/>
					{values._id === 0 ? (
						<div className="newLocation__imageHolder">
							<TWControls.Button size="small" type="submit" text="Add Template" />
						</div>
					) : (
						<div className="newLocation__imageHolder">
							<TWControls.Button size="small" type="submit" text="Update" />
						</div>
					)}
				</Grid>
			</Grid>
		</Form>
	)
}

export default TemplateAddEdit
