import axios from 'axios'
import store from 'store'

const axiosInterceptor = baseURL => {
	const interceptor = axios.create({ baseURL })

	let userInfo = ''
	if (store) userInfo = store.getState().userLogin.userInfo

	interceptor.interceptors.request.use(
		config => {
			// Attach CSRF tokens, request headers to request
			if (userInfo) config.headers.Authorization = `Bearer ${userInfo.token}`
			// console.log({ config })
			return config
		},
		error => {
			return Promise.reject(error)
		},
	)

	interceptor.interceptors.response.use(
		response => {
			// Store CSRF tokens for subsequent requests
			// console.log({ response })
			return response
		},
		error => {
			return Promise.reject(error)
		},
	)

	return interceptor
}

export default axiosInterceptor
