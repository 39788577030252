import { axiosInterceptor, apiCall } from 'services'
import { API_BASE_URL } from './constants'

const myPartnersService = () => {
	const api = axiosInterceptor(API_BASE_URL)

	// CREATE
	const addMyPartner = async (partner: any) => await apiCall(api.post(`/mypartners`, partner))

	// READ
	const initializeMyPartners = async () => await apiCall(api.get(`/mypartners`))
	const getMyPendingPartners = async (id: string) =>
		await apiCall(api.get(`/mypartners/pending/${id}`))

	// UPDATE
	const updateMyPartner = async (partner: any) => await apiCall(api.put(`/mypartners`, partner))

	// DELETE

	//OTHER
	const acceptMyPartner = async ({
		partnershipId,
		isAccepted,
	}: {
		partnershipId: string
		isAccepted: boolean
	}) =>
		await apiCall(
			api.patch(`/mypartners/accept`, {
				partnershipId: partnershipId,
				updates: {
					isAccepted: isAccepted,
				},
			}),
		)

	return {
		addMyPartner,
		initializeMyPartners,
		getMyPendingPartners,
		updateMyPartner,
		acceptMyPartner,
	}
}

export default myPartnersService
