import React from 'react'
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import StyledImageGallery from './ImageGallery.style'

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: '#0080fe',
		boxShadow: theme.shadows[5],
		fontSize: 14,
	},
}))

interface LocationImage {
	_id: string
	url: string
	type: string
	note: string
}

export interface Props {
	className?: string
	locationImages: LocationImage[]
	handleDeleteImage: Function
	handlePreviewImage: Function
}

const isImage = (type: string) => ['jpg', 'jpeg', 'png', 'gif'].includes(type)

const LocationImage = ({
	locationImage,
	handleDeleteImage,
	handlePreviewImage,
}: {
	locationImage: LocationImage
	handleDeleteImage: Function
	handlePreviewImage: Function
}) => {
	return (
		<div
			className={`LocationImage ${
				isImage(locationImage.type) ? '' : 'LocationImage--video'
			}`.trim()}
		>
			{isImage(locationImage.type) ? (
				// <a href={locationImage.url} target="_blank" rel="noopener noreferrer">
				<div>
					<LightTooltip
						title={
							<React.Fragment>
								{locationImage.note > '' ? locationImage.note : 'Click to add note'}
							</React.Fragment>
						}
					>
						<div
							className="LocationImage__img"
							onClick={() => handlePreviewImage(locationImage)}
							style={{
								backgroundImage: `url(${locationImage.url}-/preview/600x480/-/quality/smart_retina/-/progressive/yes/)`,
								backgroundColor: '#edf2f6',
							}}
						/>
					</LightTooltip>
				</div>
			) : (
				// </a>
				<video className="LocationImage__video" src={locationImage.url} controls />
			)}

			{/* <button
				className="LocationImage__delete-button"
				onClick={() => handlePreviewImage(locationImage)}
				aria-label="Delete image"
			>
				<FaTimes aria-hidden />
			</button> */}
		</div>
	)
}

const ImageGallery = ({
	className = '',
	locationImages,
	handleDeleteImage,
	handlePreviewImage,
}: Props) => {
	return (
		<StyledImageGallery data-testid="ImageGallery" className={className}>
			<ul>
				{locationImages.map(locationImage => {
					return (
						<li key={locationImage._id}>
							<LocationImage
								locationImage={locationImage}
								handleDeleteImage={handleDeleteImage}
								handlePreviewImage={handlePreviewImage}
							/>
						</li>
					)
				})}
			</ul>
		</StyledImageGallery>
	)
}

export default ImageGallery
