import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import {
	Avatar,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	TableBody,
	TableCell,
	TableRow,
} from '@mui/material'
import AltRetailerLogo from 'images/vr-new-retail_3.png'

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import {
	Archive as ArchiveIcon,
	DeleteForever as DeleteForeverIcon,
	Edit as EditIcon,
	MoreHoriz as MoreHorizIcon,
} from '@mui/icons-material'
import useTable from 'components/controls/useTable'
import Layout from 'components/Layout'
import Spinner from 'components/Spinner'
import Message from 'components/Message'
import Popup from 'components/Popup'
import {
	deletePortfolio,
	initializeArchivedPortfolios,
	updatePortfolio,
} from 'slices/portfolioSlice'
import styled from 'styled-components'
import * as _ from 'styles/helpers'
import PortfolioDeletePopup from 'components/popups/PortfolioDeletePopup'
import PortfolioEditPopup from 'components/popups/PortfolioEditPopup'
import { Button } from '@material-ui/core'

const headerCells = [
	// { id: 'Image', label: '', disableSorting: true },
	{ id: 'assetName', label: 'Portfolio' },
	{ id: 'description', label: 'Notes' },
	{ id: 'locations', label: 'Locations' },
	{ id: 'isActive', label: 'Status' },
	{ id: 'reLoad', label: 'Edit', disableSorting: true },
	{ id: 'reLoad', label: 'Delete', disableSorting: true },
	{ id: 'reLoad', label: 'Archive', disableSorting: true },
]

const StyledDiv = styled.div`
	& > * {
		margin: 0.5rem;
	}
`

const options = ['...', 'Edit/Modify', 'Delete']
const ITEM_HEIGHT = 56

const PortfoliosAdminPopup = () => {
	const dispatch = useDispatch()

	const searchInputRef = useRef(null)
	const [searchInputValue, setSearchInputValue] = useState('')
	const [openModifyPopup, setOpenModifyPopup] = useState(false)
	const [openArchivePopup, setOpenArchivePopup] = useState(false)
	const [openDeletePopup, setOpenDeletePopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('')
	const [recordForEdit, setRecordForEdit] = useState(null)

	const [filterFn, setFilterFn] = useState({
		fn: items => {
			return items
		},
	})

	const { entities: portfolios, error, ids: portfolioIds, isLoading, isInitialized } = useSelector(
		state => state.portfolios,
	)
	const records = portfolioIds.map(id => portfolios[id])

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo: user } = userLogin

	const userId = user._id

	// useEffect(() => {
	// 	if (!isInitialized) {
	// 		dispatch(initializePortfoliosPopup())
	// 	}
	// }, [dispatch, isInitialized])

	// useEffect(() => {
	// 	// if (!isInitialized) {
	// 	dispatch(initializePortfoliosPopup())
	// 	// }
	// }, [dispatch, isInitialized])

	const recordCount = records.length > 0

	const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
		records,
		headerCells,
		filterFn,
	)
	const handleSearch = e => {
		const { value } = e.target
		setSearchInputValue(value)
		setFilterFn({
			fn: items => {
				if (value === '') return items
				return items.filter(x => x.assetName.toLowerCase().includes(value.toLowerCase()))
			},
		})
		// set focus to search input after rerender
		setTimeout(() => {
			searchInputRef.current.focus()
		}, 0)
	}

	const hasRows = records ? records.length : null

	const handleEditPortfolio = async newPortfolio => {
		// e.preventDefault()

		const portfolioId = newPortfolio._id

		newPortfolio.updateNote = 'Edited Portfolio.'
		await dispatch(updatePortfolio(newPortfolio))

		dispatch(
			initializeArchivedPortfolios({
				portfolioId: portfolioId,
			}),
		)

		setOpenModifyPopup(false)
	}

	const handleDeletePortfolio = async newPortfolio => {
		const portfolioId = newPortfolio._id

		await dispatch(deletePortfolio(portfolioId))

		dispatch(
			initializeArchivedPortfolios({
				portfolioId: portfolioId,
			}),
		)

		setOpenDeletePopup(false)
	}

	const handleArchiveClick = portfolio => {
		setPopupTitle(`Edit/Modify Portfolio: ${portfolio.assetName}`)
		setRecordForEdit(portfolio)
		setOpenModifyPopup(true)
	}

	return (
		<StyledDiv>
			<Layout>
				<Layout.Page>
					<Layout.Body>
						{isLoading ? (
							<Spinner containerHeight="50vh" />
						) : !records ? (
							<Message variant="danger">{error}</Message>
						) : error ? (
							<Message variant="danger">{error}</Message>
						) : recordCount ? (
							<>
								<div>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<Layout.PageToolbar_NoPlus
												searchInputRef={searchInputRef}
												searchInputValue={searchInputValue}
												searchInputOnChange={handleSearch}
											/>

											<TblContainer>
												<TblHeader />
												<TableBody>
													{recordsAfterPagingAndSorting().map(portfolio => (
														<TableRow key={portfolio.assetName}>
															{/* <TableCell>
																{!portfolio.image ? (
																	<Avatar src={AltRetailerLogo} />
																) : (
																	<Avatar src={portfolio.image} />
																)}
															</TableCell> */}

															<TableCell>{portfolio.assetName}</TableCell>
															<TableCell>{portfolio.description}</TableCell>
															<TableCell>{portfolio.locations.length}</TableCell>
															<TableCell>{portfolio.isActive ? 'Active' : 'In Active'}</TableCell>
															<TableCell>
																<IconButton
																	color="primary"
																	onClick={() => handleArchiveClick(portfolio)}
																>
																	<EditIcon />
																</IconButton>
															</TableCell>
															<TableCell>
																<IconButton
																	color="primary"
																	onClick={() => handleArchiveClick(portfolio)}
																>
																	<ArchiveIcon />
																</IconButton>
															</TableCell>
															<TableCell>
																<Button
																	color="primary"
																	onClick={() => handleArchiveClick(portfolio)}
																	size="small"
																>
																	{/* <DeleteForeverIcon /> */}
																	{portfolio.isActive ? 'Active' : 'In Active'}
																</Button>
															</TableCell>
															{/* <TableCell>
																<MenuButton
																	portfolio={portfolio}
																	setPopupTitle={setPopupTitle}
																	setRecordForEdit={setRecordForEdit}
																	setOpenDeletePopup={setOpenDeletePopup}
																	setOpenModifyPopup={setOpenModifyPopup}
																/>
															</TableCell> */}
														</TableRow>
													))}
												</TableBody>
											</TblContainer>
											{hasRows > 4 ? <TblPagination /> : null}
										</Grid>
									</Grid>
								</div>
							</>
						) : null}
					</Layout.Body>
				</Layout.Page>
			</Layout>

			{openModifyPopup ? (
				<Popup title={popupTitle} openPopup={openModifyPopup} setOpenPopup={setOpenModifyPopup}>
					<PortfolioEditPopup editPortfolio={handleEditPortfolio} recordForEdit={recordForEdit} />
				</Popup>
			) : null}

			{openDeletePopup ? (
				<Popup title={popupTitle} openPopup={openDeletePopup} setOpenPopup={setOpenDeletePopup}>
					<PortfolioDeletePopup deletePortfolio={handleDeletePortfolio} portfolio={recordForEdit} />
				</Popup>
			) : null}
		</StyledDiv>
	)
}
const StyledDropzone = styled.div`
	.TableContent {
		display: flex;
		align-items: center;
		justify-content: center;
		h2 {
			${_.MIXINS.vc};
			${_.TYPOGRAPHY.h2};
			font-size: ${_.rem(24)};
			font-weight: 400;
			color: ${_.COLORS.blue_800};
			padding-top: ${_.rem(10)};
			padding-left: ${_.rem(10)};
		}
	}
`

const MenuButton = ({
	portfolio,
	setPopupTitle,
	setRecordForEdit,
	setOpenArchivePopup,
	setOpenDeletePopup,
	setOpenModifyPopup,
}) => {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = (option, portfolio) => {
		switch (option) {
			case 'Delete':
				setPopupTitle(`Delete Portfolio: ${portfolio.assetName}`)
				setRecordForEdit(portfolio)
				setOpenDeletePopup(true)
				setAnchorEl(null)
				break

			case 'Edit/Modify':
				setPopupTitle(`Edit/Modify Portfolio: ${portfolio.assetName}`)
				setRecordForEdit(portfolio)
				setOpenModifyPopup(true)
				setAnchorEl(null)
				break

			default:
				setAnchorEl(null)
		}
		setAnchorEl(null)
	}

	return (
		<>
			<IconButton
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				color="primary"
				size="small"
				onClick={handleClick}
			>
				<MoreHorizIcon />
			</IconButton>
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
						width: '15ch',
						color: 'blue',
					},
				}}
			>
				{options.map(option => (
					<MenuItem
						key={option}
						selected={option === '...'}
						onClick={() => handleClose(option, portfolio)}
					>
						{option}
					</MenuItem>
				))}
			</Menu>
		</>
	)
}

export default PortfoliosAdminPopup
