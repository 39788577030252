import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import TWControls from '../controls/TWControls'
import { Form, useForm } from '../controls/useForm'

const DetailAddPopup = ({ addDetail, recordForEdit }) => {
	const detailTypes = [
		{ id: 1 },
		{ selectValue: 'Text' },
		{ id: 2 },
		{ selectValue: 'Number' },
		{ id: 3 },
		{ selectValue: 'Currency' },
		{ id: 4 },
		{ selectValue: 'Date' },
	]

	const detail = recordForEdit
	const initialValues = {
		...detail,
	}

	const validate = () => {
		let temp = { ...errors }
		temp.detailLabel = values.detailLabel ? '' : 'This field is required.'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialValues,
		true,
		validate,
	)

	const submitHandler = e => {
		e.preventDefault()
		if (validate()) {
			addDetail(values, resetForm)
		}
	}

	useEffect(() => {
		if (detail != null) setValues({ ...detail })
	}, [detail, setValues])

	return (
		<Form onSubmit={submitHandler}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TWControls.Input
						name="detailLabel"
						label="Detail Label"
						value={values.detailLabel}
						onChange={handleInputChange}
						error={errors.detailLabel}
					/>
					<TWControls.SelectType
						name="detailType"
						label="Detail Type"
						value={values.detailType}
						onChange={handleInputChange}
						options={detailTypes}
						error={errors.detailType}
					/>

					{values.detailType === 'Date' ? (
						<TWControls.DatePicker
							name="detail"
							label="Initial Date"
							value={values.detail}
							onChange={handleInputChange}
							error={errors.detail}
						/>
					) : (
						<TWControls.Input
							name="detail"
							label="Initial Value"
							value={values.detail}
							onChange={handleInputChange}
							error={errors.detail}
						/>
					)}
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<TWControls.Button size="small" type="submit" text="Add Detail" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default DetailAddPopup
