import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MapLocationRGM from 'components/MapLocationRGM'
import { Grid } from '@mui/material'
import { FaRegCircle } from 'react-icons/fa'
import Layout from 'components/Layout/Layout'
import ListAction from 'features/Actions/components/ListAction'
import { initializeApiKey } from 'slices/apiKeysSlice'

const PortfolioMapsScreen = ({ locations }) => {
	const dispatch = useDispatch()

	const { entity: apiKey } = useSelector(state => state.apiKey)

	useEffect(() => {
		dispatch(initializeApiKey('REACT_APP_GOOGLE_API_KEY'))
	}, [dispatch])

	// TODO: Create skeleton loader
	// if (loading || !apiKey) return null

	var mapLat = 0
	var mapLong = 0
	var zoomLevel = 10

	// if (!isInitialized) return null
	const locationPins = locations.map(item => {
		return {
			id: item._id,
			lat: item.latitude,
			lng: item.longitude,
		}
	})
	if (locationPins.length > 1) {
		const arrayLat = locationPins.map(function (pin) {
			return pin.lat
		})
		const arrayLong = locationPins.map(function (pin) {
			return pin.lng
		})
		mapLat = (Math.max.apply(null, arrayLat) + Math.min.apply(null, arrayLat)) / 2
		mapLong = (Math.min.apply(null, arrayLong) + Math.max.apply(null, arrayLong)) / 2
	} else {
		mapLat = locationPins[0]?.lat || 39.5
		mapLong = locationPins[0]?.lng || -98.35
		if (mapLat === 39.5) zoomLevel = 10
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<MapLocationRGM
					apiKey={apiKey}
					asset={null}
					centerPin={[mapLat, mapLong]}
					useRings={true}
					ringRadius={null}
					allRings={null}
					markers={locationPins}
					useCenterPin={false}
					zoom={zoomLevel}
				/>
			</Grid>
		</Grid>
	)
}

export default PortfolioMapsScreen
