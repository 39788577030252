import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import {
	Avatar,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	TableBody,
	TableCell,
	TableRow,
} from '@mui/material'
import AltRetailerLogo from 'images/vr-new-retail_3.png'

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material'
import useTable from 'components/controls/useTable'
import Layout from 'components/Layout'
import Spinner from 'components/Spinner'
import Message from 'components/Message'
import Popup from 'components/Popup'
import {
	deleteLocation,
	initializeArchivedLocations,
	patchLocation,
	updateLocation,
} from 'slices/locationSlice'
import styled from 'styled-components'
import * as _ from 'styles/helpers'
import LocationDeletePopup from 'components/popups/LocationDeletePopup'
import LocationEditPopup from 'components/popups/LocationEditPopup'

const headerCells = [
	{ id: 'Image', label: '', disableSorting: true },
	{ id: 'assetName', label: 'Location Name' },
	{ id: 'address', label: 'Address' },
	{ id: 'city', label: 'City' },
	{ id: 'stateProvince', label: 'State' },
	{ id: 'postalCode', label: 'Postal Code' },
	{ id: 'isActive', label: 'Status' },
	{ id: 'isPOIOnly', label: 'POI Only' },
	{ id: 'isTenantOnly', label: 'Tenant Only' },
	{ id: 'reLoad', label: 'Actions', disableSorting: true },
]

const StyledDiv = styled.div`
	& > * {
		margin: 0.5rem;
	}
`

const options = ['...', 'Edit/Modify', 'Delete']
const ITEM_HEIGHT = 56

const ArchivedProjects = () => {
	const dispatch = useDispatch()

	const searchInputRef = useRef(null)
	const [searchInputValue, setSearchInputValue] = useState('')
	const [openModifyPopup, setOpenModifyPopup] = useState(false)
	const [openArchivePopup, setOpenArchivePopup] = useState(false)
	const [openDeletePopup, setOpenDeletePopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('')
	const [recordForEdit, setRecordForEdit] = useState(null)

	const [filterFn, setFilterFn] = useState({
		fn: items => {
			return items
		},
	})

	const { entities: locations, error, ids: locationIds, isLoading, isInitialized } = useSelector(
		state => state.locations,
	)
	const records = locationIds.map(id => locations[id])

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo: user } = userLogin

	const userId = user._id

	useEffect(() => {
		if (!isInitialized) {
			dispatch(initializeArchivedLocations())
		}
	}, [dispatch, isInitialized])

	useEffect(() => {
		// if (!isInitialized) {
		dispatch(initializeArchivedLocations())
		// }
	}, [dispatch, isInitialized])

	const recordCount = records.length > 0

	const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
		records,
		headerCells,
		filterFn,
	)
	const handleSearch = e => {
		const { value } = e.target
		setSearchInputValue(value)
		setFilterFn({
			fn: items => {
				if (value === '') return items
				return items.filter(x => x.assetName.toLowerCase().includes(value.toLowerCase()))
			},
		})
		// set focus to search input after rerender
		setTimeout(() => {
			searchInputRef.current.focus()
		}, 0)
	}

	const hasRows = records ? records.length : null

	const handleEditLocation = async newLocation => {
		// e.preventDefault()

		const locationId = newLocation._id
		const postalCode = newLocation.postalCode.substring(0, 5)

		const params = {
			params: {
				postalCode: postalCode,
			},
		}

		let c = ''
		try {
			let { data } = await axios.get(`/api/postalcodedemographics/postalcodecbsa`, params)
			c = data
		} catch (e) {
			console.error(e)
		}

		if (newLocation.locationType === 'Retail Center') {
			newLocation.assetType = 'center'
		}
		newLocation.updateNote = 'Edited Location.'
		await dispatch(updateLocation(newLocation))

		dispatch(
			initializeArchivedLocations({
				locationId: locationId,
			}),
		)

		setOpenModifyPopup(false)
	}

	const handleDeleteLocation = async newLocation => {
		const locationId = newLocation._id

		await dispatch(deleteLocation(locationId))

		dispatch(
			initializeArchivedLocations({
				locationId: locationId,
			}),
		)

		setOpenDeletePopup(false)
	}

	return (
		<StyledDiv>
			<Layout>
				<Layout.Page>
					<Layout.Body>
						{isLoading ? (
							<Spinner containerHeight="50vh" />
						) : !records ? (
							<Message variant="danger">{error}</Message>
						) : error ? (
							<Message variant="danger">{error}</Message>
						) : recordCount ? (
							<>
								<div>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<Layout.PageToolbar_NoPlus
												searchInputRef={searchInputRef}
												searchInputValue={searchInputValue}
												searchInputOnChange={handleSearch}
											/>

											<TblContainer>
												<TblHeader />
												<TableBody>
													{recordsAfterPagingAndSorting().map(location => (
														<TableRow key={location.assetName}>
															<TableCell>
																{!location.image ? (
																	<Avatar src={AltRetailerLogo} />
																) : (
																	<Avatar src={location.image} />
																)}
															</TableCell>

															<TableCell>{location.assetName}</TableCell>
															<TableCell>{location.address}</TableCell>
															<TableCell>{location.city}</TableCell>
															<TableCell>{location.stateProvince}</TableCell>
															<TableCell>{location.postalCode}</TableCell>
															<TableCell>{location.isActive ? 'Active' : 'In Active'}</TableCell>
															<TableCell>{location.isPOiOnly ? 'Yes' : 'No'}</TableCell>
															<TableCell>{location.isTenantOnly ? 'Yes' : 'No'}</TableCell>
															<TableCell>
																<MenuButton
																	location={location}
																	setPopupTitle={setPopupTitle}
																	setRecordForEdit={setRecordForEdit}
																	setOpenDeletePopup={setOpenDeletePopup}
																	setOpenModifyPopup={setOpenModifyPopup}
																/>
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</TblContainer>
											{hasRows > 4 ? <TblPagination /> : null}
										</Grid>
									</Grid>
								</div>
							</>
						) : null}
					</Layout.Body>
				</Layout.Page>
			</Layout>

			{openModifyPopup ? (
				<Popup title={popupTitle} openPopup={openModifyPopup} setOpenPopup={setOpenModifyPopup}>
					<LocationEditPopup editLocation={handleEditLocation} recordForEdit={recordForEdit} />
				</Popup>
			) : null}

			{openDeletePopup ? (
				<Popup title={popupTitle} openPopup={openDeletePopup} setOpenPopup={setOpenDeletePopup}>
					<LocationDeletePopup deleteLocation={handleDeleteLocation} location={recordForEdit} />
				</Popup>
			) : null}
		</StyledDiv>
	)
}
const StyledDropzone = styled.div`
	.TableContent {
		display: flex;
		align-items: center;
		justify-content: center;
		h2 {
			${_.MIXINS.vc};
			${_.TYPOGRAPHY.h2};
			font-size: ${_.rem(24)};
			font-weight: 400;
			color: ${_.COLORS.blue_800};
			padding-top: ${_.rem(10)};
			padding-left: ${_.rem(10)};
		}
	}
`

const MenuButton = ({
	location,
	setPopupTitle,
	setRecordForEdit,
	setOpenArchivePopup,
	setOpenDeletePopup,
	setOpenModifyPopup,
}) => {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = (option, location) => {
		switch (option) {
			case 'Delete':
				setPopupTitle(`Delete Location: ${location.assetName}`)
				setRecordForEdit(location)
				setOpenDeletePopup(true)
				setAnchorEl(null)
				break

			case 'Edit/Modify':
				setPopupTitle(`Edit/Modify Location: ${location.assetName}`)
				setRecordForEdit(location)
				setOpenModifyPopup(true)
				setAnchorEl(null)
				break

			default:
				setAnchorEl(null)
		}
		setAnchorEl(null)
	}

	return (
		<>
			<IconButton
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				color="primary"
				size="small"
				onClick={handleClick}
			>
				<MoreHorizIcon />
			</IconButton>
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
						width: '15ch',
						color: 'blue',
					},
				}}
			>
				{options.map(option => (
					<MenuItem
						key={option}
						selected={option === '...'}
						onClick={() => handleClose(option, location)}
					>
						{option}
					</MenuItem>
				))}
			</Menu>
		</>
	)
}

export default ArchivedProjects
