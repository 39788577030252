import React from 'react'
import { Grid } from '@mui/material'
import TWControls from '../controls/TWControls'
import { Form } from '../controls/useForm'

const ProjectDeletePopup = ({ deleteProject, project }) => {
	const submitHandler = e => {
		deleteProject(project)
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<h2 className="newLocation__imageHolder">WARNING! </h2>
				</Grid>
				<Grid item xs={12}>
					<p>
						This will delete this project and all of the project's notes, partner relationships,
						tasks, files and images. Deleted projects are not recoverable.{' '}
					</p>
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<TWControls.Button color="secondary" size="small" type="submit" text="Delete" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default ProjectDeletePopup
