import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
	entities: {},
	ids: [],
	isLoading: false,
	isInitialized: false,
	sentEntities: {},
	sentIds: [],
	receivedEntities: {},
	receivedIds: [],
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

export const getChatHeadersByUID = createAsyncThunk(
	'chats/getChatHeadersByUID',
	async (payload, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.get(`/api/chats/headers`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to update chat')
		}
	},
)

export const getChatsSentByUserId = createAsyncThunk(
	'chats/getSentChats',
	async (payload, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.get(`/api/chats/sentbyuser`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('No Chats Found')
		}
	},
)

export const getChatsReceivedByUserId = createAsyncThunk(
	'chats/getReceivedChats',
	async (payload, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.get(`/api/chats/receivedbyuser`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('No Chats Found')
		}
	},
)

// Extract the key out of the entitiy object
// eslint-disable-next-line no-unused-vars
const removeKey = (key, { [key]: _, ...rest }) => rest

const chatsSlice = createSlice({
	name: 'chats',
	initialState,
	reducers: {},
	extraReducers: {
		[getChatHeadersByUID.fulfilled as any]: (state, action) => {
			const chats = action.payload

			state.ids = chats?.map((chat, index) => {
				state.entities[chat._id] = chat

				return chat._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[getChatHeadersByUID.pending as any]: state => {
			state.isLoading = true
		},
		[getChatHeadersByUID.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},

		[getChatsSentByUserId.fulfilled as any]: (state, action) => {
			const chats = action.payload

			state.sentIds = chats?.map((chat, index) => {
				state.sentEntities[chat._id] = chat

				return chat._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[getChatsSentByUserId.pending as any]: state => {
			state.isLoading = true
		},
		[getChatsSentByUserId.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},

		[getChatsReceivedByUserId.fulfilled as any]: (state, action) => {
			const chats = action.payload

			state.receivedIds = chats?.map((chat, index) => {
				state.receivedEntities[chat._id] = chat

				return chat._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[getChatsReceivedByUserId.pending as any]: state => {
			state.isLoading = true
		},
		[getChatsReceivedByUserId.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},
	} as any,
})

export default chatsSlice

// export const { } = chatsSlice.actions;
