import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
const LOCATION_NOTE = 'locationNote'

const initialState = {
	entities: {},
	ids: [],
	isLoading: false,
	isInitialized: false,
	error: null,
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

export const getNotes = createAsyncThunk(
	`${LOCATION_NOTE}/getNotes`,
	async (payload: any, { getState, rejectWithValue }) => {
		const { locationId, projectId, userId } = payload

		try {
			const { data } = await axios.get(`/api/locationnotes/notes`, {
				params: {
					locationid: locationId,
					projectid: projectId,
					// userid: userId,
				},
			})

			// debugger

			return data
		} catch (error) {
			console.error(error)
			return rejectWithValue('Failed to get notes')
		}
	},
)

export const createNote = createAsyncThunk(
	`${LOCATION_NOTE}/create`,
	async (note, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/api/locationNotes`, note, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to get notes')
		}
	},
)

export const updateNote = createAsyncThunk(
	`${LOCATION_NOTE}/update`,
	async (note: any, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.put(`/api/locationNotes/${note._id}`, note, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to create center')
		}
	},
)

const locationNoteSlice = createSlice({
	name: 'locations',
	initialState,
	reducers: {},
	extraReducers: {
		[getNotes.fulfilled as any]: (state, action) => {
			const notes = action.payload

			state.ids = notes?.map((note, index) => {
				state.entities[note._id] = note
				return note._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[getNotes.pending as any]: state => {
			state.isLoading = true
		},
		[getNotes.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
		},
		[createNote.pending as any]: state => {
			state.isLoading = true
			state.isInitialized = false
		},
		[createNote.fulfilled as any]: (state, action) => {
			const newNote = action.payload
			state.entities[newNote._id] = newNote
		},
		[updateNote.fulfilled as any]: (state, action) => {
			const updatedNote = action.payload
			state.entities[updatedNote._id] = updatedNote
		},
	} as any,
})

export default locationNoteSlice

// export const { } = locationsSlice.actions;
