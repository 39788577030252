import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import Button from 'components/Button'
import { Form, useForm } from 'components/controls/useForm'
import TWControls from 'components/controls/TWControls'

const TemplateSiteDetailPopup = ({ recordForEdit = {}, handleSubmit, deleteDetail }) => {
	const [touchedInputs, setTouchedInputs] = useState([])

	const detailTypes = [
		{ id: 1 },
		{ selectValue: 'Text' },
		{ id: 2 },
		{ selectValue: 'Number' },
		{ id: 3 },
		{ selectValue: 'Currency' },
		{ id: 4 },
		{ selectValue: 'Date' },
	]

	const isValid = (_errors = errors) => {
		const _isValid = Object.values(_errors).every(x => x === '')

		if (values._id === 0) {
			return (
				touchedInputs.includes('detailLabel') &&
				touchedInputs.includes('description') &&
				touchedInputs.includes('detailType') &&
				_isValid
			)
		}

		// Update Detail form
		return _isValid
	}

	const validate = () => {
		const temp = { ...errors }

		temp.detailLabel = values.detailLabel ? '' : 'This field is required.'
		temp.description = values.description ? '' : 'This field is required.'
		// temp.detailType = values.detailType ? '' : 'This field is required.'

		setErrors({ ...temp })

		return isValid(temp)
	}

	const defaultValues = {
		_id: 0,
		detailLabel: '',
		description: '',
		detailType: '',
	}

	const initialValues = {
		...defaultValues,
		...recordForEdit,
	}

	const {
		values,
		setValues,
		errors,
		setErrors,
		handleInputChange,
		_handleInputChange,
		resetForm,
	} = useForm(initialValues, true, validate)

	const isNewDetailForm = values._id === 0

	const submitHandler = e => {
		e.preventDefault()
		if (validate()) handleSubmit(values, deleteDetail, resetForm)
	}

	/*
    TODO:
    This is horrible -- need to set up form validation
  */
	let curr = {}

	const handleOnInput = (e, id) => {
		if (!touchedInputs.includes(id)) setTouchedInputs([...touchedInputs, id])

		const { name, value } = e.target
		curr = { name, value }

		setTimeout(() => {
			validate()
		}, 0)
	}

	useEffect(() => {
		_handleInputChange(curr)

		setTimeout(() => {
			validate()
		}, 0)
		// eslint-disable-next-line
	}, [touchedInputs])

	return (
		<Form onSubmit={submitHandler}>
			<Grid container>
				<Grid item>
					<TWControls.Input
						name="detailLabel"
						label="Detail Label"
						value={values.detailLabel}
						onChange={handleInputChange}
						onInput={e => {
							handleOnInput(e, 'detailLabel')
						}}
						onBlur={e => {
							handleOnInput(e, 'detailLabel')
						}}
						error={touchedInputs.includes('detailLabel') && errors.detailLabel}
					/>
					<TWControls.Input
						name="description"
						label="Description"
						value={values.description}
						onChange={handleInputChange}
						onInput={e => {
							handleOnInput(e, 'description')
						}}
						onBlur={e => {
							handleOnInput(e, 'description')
						}}
						error={touchedInputs.includes('description') && errors.description}
					/>
					<TWControls.SelectType
						name="detailType"
						label="Detail Type"
						value={values.detailType}
						onChange={handleInputChange}
						onInput={e => {
							handleOnInput(e, 'detailType')
						}}
						onBlur={e => {
							handleOnInput(e, 'detailType')
						}}
						error={touchedInputs.includes('detailType') && errors.detailType}
						options={detailTypes}
					/>

					<div className="newLocation__imageHolder">
						<Button
							type="submit"
							variant={deleteDetail ? 'danger' : 'success'}
							// isLarge
							// disabled={isNewDetailForm && !isValid()}
						>
							{isNewDetailForm ? 'Add Detail' : deleteDetail ? 'Delete Detail' : 'Update'}
						</Button>
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default TemplateSiteDetailPopup
