import styled from 'styled-components'
import * as _ from 'styles/helpers'
import type { Props } from './CardGrid'
import getGridDims, { GRID_GAP } from './utils/getGridDims'

const StyledCardGrid = styled.div<Partial<Props>>`
	.CardGrid__header {
		margin-bottom: ${_.rem(GRID_GAP)};
	}

	.CardGrid__container {
		margin: auto;
	}

	.CardGrid__grid {
		display: grid;
		gap: ${_.rem(GRID_GAP)};
	}

	&.grid-of-1 {
		.CardGrid__container {
			max-width: ${_.rem(getGridDims(1).maxWidth)};
		}
	}

	&.grid-of-2 {
		.CardGrid__container {
			max-width: ${_.rem(getGridDims(2).maxWidth)};
		}
		.CardGrid__grid {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	&.grid-of-3 {
		.CardGrid__container {
			max-width: ${_.rem(getGridDims(3).maxWidth)};
		}
		.CardGrid__grid {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	&.grid-of-4 {
		.CardGrid__container {
			max-width: ${_.rem(getGridDims(4).maxWidth)};
		}
		.CardGrid__grid {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	&.grid-of-5 {
		.CardGrid__container {
			max-width: ${_.rem(getGridDims(5).maxWidth)};
		}
		.CardGrid__grid {
			grid-template-columns: repeat(5, 1fr);
		}
	}

	&.grid-of-6 {
		.CardGrid__container {
			grid-template-columns: repeat(6, 1fr);
		}
		.CardGrid__grid {
			max-width: ${_.rem(getGridDims(6).maxWidth)};
		}
	}
`

export default StyledCardGrid
