import { configureStore } from '@reduxjs/toolkit'
import reduxLogger from 'redux-logger'
import rootReducer from './slices/index'

const logger = reduxLogger.createLogger

const userInfoFromStorage = localStorage.getItem('userInfo')
	? JSON.parse(localStorage.getItem('userInfo'))
	: null

const initialState = {
	userLogin: { userInfo: userInfoFromStorage },
}

const store = configureStore(
	{
		reducer: rootReducer,
	},
	{
		middleware: getDefaultMiddleware => getDefaultMiddleware().concat(logger),
	},
	// {
	// 	initialState,
	// },
)

export default store
