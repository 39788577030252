import React, { useEffect } from 'react'
import Avatar from 'react-avatar'
import { Grid } from '@mui/material'
import TWControls from '../controls/TWControls'
import { Form, useForm } from '../controls/useForm'

const PartnerRemove = ({ updatePartner, recordForEdit, isOwner }) => {
	const editPartner = {
		_id: recordForEdit.partner._id,
		removeId: recordForEdit._id,
		user: recordForEdit.partner.user,
		partner: recordForEdit.partner.partner,
		trustedPartner: recordForEdit.partner.trustedPartner,
		isAccepted: recordForEdit.partner.isAccepted,
		isSuspended: recordForEdit.partner.isSuspended,
		image: recordForEdit.partnerUserId.image,
		name: recordForEdit.partnerUserId.name,
		company: recordForEdit.partnerUserId.company,
		title: recordForEdit.partnerUserId.title,
		email: recordForEdit.partnerUserId.email,
	}

	const initialFValues = {
		_id: 0,
		removeId: '',
		user: '',
		partner: '',
		trustedPartner: false,
		isAccepted: false,
		isSuspended: false,
		image: '',
		name: '',
		company: '',
		title: '',
		email: '',
	}

	const validate = () => {
		let temp = { ...errors }
		temp.name = values.name ? '' : 'This field is required.'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialFValues,
		true,
		validate,
	)

	const submitHandler = e => {
		updatePartner(values)
	}

	useEffect(() => {
		if (editPartner != null) {
			setValues({ ...editPartner })
		}
	}, [recordForEdit, setValues])

	return (
		<Form onSubmit={submitHandler}>
			<div className="TEMP_USER_REVIEW_POPUP">
				{isOwner ? (
					<div>
						<Grid container className="body">
							<Grid item xs={12}>
								<div className="body">
									<div className="avatar">
										<Avatar src={values.image} />
									</div>
									<div className="content">
										<h2 className="name">{values.name}</h2>
										<h3 className="title">{`${values.title} - ${values.company}`}</h3>
										<p>{values.email}</p>
										<br></br>
									</div>
								</div>
							</Grid>
							<Grid className="newLocation__imageHolder" item xs={12}>
								<p>{`This action will remove ${values.name} as a partners in this project.`} </p>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<div className="newLocation__imageHolder">
								<TWControls.Button
									color="secondary"
									size="small"
									type="submit"
									text="Remove Partner"
								/>
							</div>
						</Grid>
					</div>
				) : (
					<div>
						<Grid container className="body">
							<Grid item xs={12}>
								<div className="body">
									<div className="avatar">
										<Avatar src={values.image} />
									</div>
									<div className="content">
										<h2 className="name">{values.name}</h2>
										<h3 className="title">{`${values.title} - ${values.company}`}</h3>
										<p>{values.email}</p>
										<br></br>
									</div>
								</div>
							</Grid>
							<Grid className="newLocation__imageHolder" item xs={12}>
								<p>
									{`You must be the project owner/originator to remove partners from this project.`}{' '}
								</p>
							</Grid>
						</Grid>
					</div>
				)}
			</div>
		</Form>
	)
}

export default PartnerRemove
