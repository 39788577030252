export const CENTER_TENNANT_CREATE_REQUEST = 'CENTER_TENNANT_CREATE_REQUEST'
export const CENTER_TENNANT_CREATE_SUCCESS = 'CENTER_TENNANT_CREATE_SUCCESS'
export const CENTER_TENNANT_CREATE_FAIL = 'CENTER_TENNANT_CREATE_FAIL'

export const CENTER_TENNANT_DELETE_REQUEST = 'CENTER_TENNANT_DELETE_REQUEST'
export const CENTER_TENNANT_DELETE_SUCCESS = 'CENTER_TENNANT_DELETE_SUCCESS'
export const CENTER_TENNANT_DELETE_FAIL = 'CENTER_TENNANT_DELETE_FAIL'

export const CENTER_TENNANT_SHOW_ALL_REQUEST = 'CENTER_TENNANT_SHOW_ALL_REQUEST'
export const CENTER_TENNANT_SHOW_ALL_SUCCESS = 'CENTER_TENNANT_SHOW_ALL_SUCCESS'
export const CENTER_TENNANT_SHOW_ALL_FAIL = 'CENTER_TENNANT_SHOW_ALL_FAIL'
export const CENTER_TENNANT_SHOW_ALL_RESET = 'CENTER_TENNANT_SHOW_ALL_RESET'

export const CENTER_TENNANT_MY_LIST_REQUEST = 'CENTER_TENNANT_MY_LIST_REQUEST'
export const CENTER_TENNANT_MY_LIST_SUCCESS = 'CENTER_TENNANT_MY_LIST_SUCCESS'
export const CENTER_TENNANT_MY_LIST_FAIL = 'CENTER_TENNANT_MY_LIST_FAIL'
export const CENTER_TENNANT_MY_LIST_RESET = 'CENTER_TENNANT_MY_LIST_RESET'

export const CENTER_TENNANT_UPDATE_REQUEST = 'CENTER_TENNANT_UPDATE_REQUEST'
export const CENTER_TENNANT_UPDATE_SUCCESS = 'CENTER_TENNANT_UPDATE_SUCCESS'
export const CENTER_TENNANT_UPDATE_FAIL = 'CENTER_TENNANT_UPDATE_FAIL'
export const CENTER_TENNANT_UPDATE_RESET = 'CENTER_TENNANT_UPDATE_RESET'

export const CENTER_TENNANT_DETAILS_REQUEST = 'CENTER_TENNANT_DETAILS_REQUEST'
export const CENTER_TENNANT_DETAILS_SUCCESS = 'CENTER_TENNANT_DETAILS_SUCCESS'
export const CENTER_TENNANT_DETAILS_FAIL = 'CENTER_TENNANT_DETAILS_FAIL'

export const CENTER_TENNANTS_UPLOAD_REQUEST = 'CENTER_TENNANTS_UPLOAD_REQUEST'
export const CENTER_TENNANTS_UPLOAD_SUCCESS = 'CENTER_TENNANTS_UPLOAD_SUCCESS'
export const CENTER_TENNANTS_UPLOAD_FAIL = 'CENTER_TENNANTS_UPLOAD_FAIL'
