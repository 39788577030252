import React, { useState } from 'react'
import { CardGrid, PortfolioCard } from 'components/Card'
import { useDispatch, useSelector } from 'react-redux'
import Popup from 'components/Popup'
import PortfolioArchivePopup from 'components/popups/PortfolioArchivePopup'
import PortfolioDeletePopup from 'components/popups/PortfolioDeletePopup'
import { deletePortfolio, initializePortfolios, patchPortfolio } from 'slices/portfolioSlice'
import PortfolioBlank from 'images/Portfolio_Blank.png'

const PortfoliosCards = ({ portfolios, userId }) => {
	const dispatch = useDispatch()
	// const userLogin = useSelector(state => state.userLogin)
	// const { userInfo } = userLogin

	const [openArchivePopup, setOpenArchivePopup] = useState(false)
	const [openDeletePopup, setOpenDeletePopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('')
	const [recordForEdit, setRecordForEdit] = useState(null)

	const menuOwner = portfolio => [
		{
			title: 'Edit Portfolio',
			link: portfolio.owner === userId && `/portfolios/${portfolio._id}/edit`,
		},
		{
			title: 'Archive',
			callback: () => {
				setPopupTitle(`Archive: ${portfolio.assetName}`)
				setRecordForEdit(portfolio)
				setOpenArchivePopup(true)
			},
		},
		{
			title: 'Delete',
			callback: () => {
				setPopupTitle(`Delete: ${portfolio.assetName}`)
				setRecordForEdit(portfolio)
				setOpenDeletePopup(true)
			},
		},
	]

	const menuPartners = portfolio => [
		{
			title: 'Edit Portfolio',
			link: portfolio.owner === userId && `/portfolios/${portfolio._id}/edit`,
		},
		{
			title: 'Archive',
			callback: () => {
				setPopupTitle(`Archive: ${portfolio.assetName}`)
				setRecordForEdit(portfolio)
				setOpenArchivePopup(true)
			},
		},
	]

	const handleArchivePortfolio = async newPortfolio => {
		const portfolioId = newPortfolio._id

		await dispatch(
			patchPortfolio({
				portfolioId: portfolioId,
				updates: { isActive: false, updateNote: 'Portfolio Archived' },
			}),
		)

		dispatch(
			initializePortfolios({
				portfolioId: portfolioId,
			}),
		)

		setOpenArchivePopup(false)
	}

	const handleDeletePortfolio = async newPortfolio => {
		const portfolioId = newPortfolio._id

		await dispatch(deletePortfolio(portfolioId))

		dispatch(
			initializePortfolios({
				portfolioId: portfolioId,
			}),
		)

		setOpenDeletePopup(false)
	}

	return (
		<div>
			<div>
				<CardGrid>
					{portfolios.map((portfolio, index) => (
						<PortfolioCard
							key={index}
							image={portfolio.image ? portfolio.image : PortfolioBlank}
							name={portfolio.assetName}
							description={portfolio.description}
							locations={portfolio.locations.length}
							linkUrl={`/portfolios/${portfolio._id}`}
							isOwner={portfolio.owner._id === userId ? true : false}
							menuItems={
								portfolio.owner._id === userId ? menuOwner(portfolio) : menuPartners(portfolio)
							}
							ownerImage={portfolio.owner.image}
						/>
					))}
				</CardGrid>
			</div>

			{openArchivePopup ? (
				<Popup title={popupTitle} openPopup={openArchivePopup} setOpenPopup={setOpenArchivePopup}>
					<PortfolioArchivePopup
						archivePortfolio={handleArchivePortfolio}
						portfolio={recordForEdit}
					/>
				</Popup>
			) : null}

			{openDeletePopup ? (
				<Popup title={popupTitle} openPopup={openDeletePopup} setOpenPopup={setOpenDeletePopup}>
					<PortfolioDeletePopup deletePortfolio={handleDeletePortfolio} portfolio={recordForEdit} />
				</Popup>
			) : null}
		</div>
	)
}

export default PortfoliosCards
