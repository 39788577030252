import React, { ReactNode } from 'react'
import StyledMessage from './Message.style'

export interface Props {
	className?: string
	children: ReactNode
	variant?: 'primary' | 'success' | 'danger' | 'warning' | 'info'
}

const Message = ({ className = '', children, variant = 'primary' }: Props) => {
	return (
		<StyledMessage data-testid="Message" className={className} variant={variant}>
			{children}
		</StyledMessage>
	)
}

export default Message
