import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
	entities: {},
	ids: [],
	recents: [],
	isLoading: false,
	isInitialized: false,
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

export const initializeFleets = createAsyncThunk(
	'fleets/initialize',
	async (_, { getState, rejectWithValue }) => {
		try {
			return axios.get(`/api/fleets/sharedfleets`, prepConfig(getState)).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize fleets')
		}
	},
)

export const createFleet = createAsyncThunk(
	'fleets/create',
	async (fleet, { getState, rejectWithValue }) => {
		try {
			return axios.post(`/api/fleets`, fleet, prepConfig(getState)).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize fleets')
		}
	},
)

export const updateFleet = createAsyncThunk(
	'fleets/update',
	async (payload: any, { getState, rejectWithValue }) => {
		try {
			return axios
				.put(`/api/fleets/${payload._id}`, payload, prepConfig(getState))
				.then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize fleets')
		}
	},
)

export const addLocationToFleet = createAsyncThunk(
	'fleets/addLocation',
	async (payload: any, { getState, rejectWithValue }) => {
		const { fleetId, locationId } = payload

		try {
			const { data } = await axios.post(
				`/api/fleets/${fleetId}/locations`,
				{ locationId },
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to add location')
		}
	},
)

export const removeLocationFromFleet = createAsyncThunk(
	'fleets/removeLocationFromFleet',
	async (payload: any, { getState, rejectWithValue }) => {
		const { locationId, fleetId } = payload

		try {
			const { data } = await axios.delete(
				`/api/fleets/${fleetId}/locations/${locationId}`,
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to add partner')
		}
	},
)

export const addPartnerToFleet = createAsyncThunk(
	'fleets/addPartner',
	async (payload: any, { getState, rejectWithValue }) => {
		const { fleetId, partner } = payload

		try {
			const { data } = await axios.post(
				`/api/fleets/${fleetId}/addpartners`,
				{ partnerId: partner._id, partnerUserId: partner.partner, fleetId },
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to add partner')
		}
	},
)

export const removePartnerFromFleet = createAsyncThunk(
	'fleets/removePartner',
	async (payload: any, { getState, rejectWithValue }) => {
		const { fleetId, partnerId } = payload

		try {
			const { data } = await axios.post(
				`/api/fleets/${fleetId}/removePartner`,
				{ partnerId },
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to remove partner')
		}
	},
)

export const getUpdatedFleet = createAsyncThunk(
	'fleets/getUpdate',
	async (fleetId, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.get(`/api/fleets/${fleetId}`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to update fleet')
		}
	},
)

const fleetSlice = createSlice({
	name: 'fleets',
	initialState,
	reducers: {},
	extraReducers: {
		[initializeFleets.fulfilled as any]: (state, action) => {
			const fleets = action.payload

			state.recents = []
			state.ids = fleets?.map((loc, index) => {
				state.entities[loc._id] = loc

				if (index < 4) {
					state.recents.push(loc._id)
				}
				return loc._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeFleets.pending as any]: state => {
			state.isLoading = true
		},
		[initializeFleets.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},
		[createFleet.fulfilled as any]: (state, action) => {
			const fleet = action.payload
			state.entities[fleet._id] = fleet
			state.ids.push(fleet._id)
			state.recents.unshift(fleet._id)

			if (state.recents.length > 4) {
				state.recents.pop()
			}
		},
		[updateFleet.fulfilled as any]: (state, action) => {
			const fleet = action.payload
			const fleetId = fleet._id
			state.entities[fleetId] = fleet
		},
		[getUpdatedFleet.fulfilled as any]: (state, action) => {
			// works for any asset type since all types are stored under entities
			state.entities[action.meta.arg] = { ...action.payload, ...state.entities[action.meta.arg] }
			state.isLoading = false
		},
		[getUpdatedFleet.rejected as any]: (state, action) => {
			state.error = action.error
		},
		[addLocationToFleet.fulfilled as any]: (state, action) => {
			const locations = action.payload
			state.entities[action.meta.arg.fleetId].locations = locations
		},
		[addLocationToFleet.rejected as any]: (state, action) => {
			state.error = 'Something went wrong'
		},
		[addPartnerToFleet.fulfilled as any]: (state, action) => {
			const fleet = action.payload
			state.entities[fleet._id] = fleet
		},
		[addPartnerToFleet.rejected as any]: (state, action) => {
			console.log(action)
		},
		[removeLocationFromFleet.fulfilled as any]: (state, action) => {
			const locations = action.payload
			state.entities[action.meta.arg.fleetId].locations = locations
		},
		[removePartnerFromFleet.fulfilled as any]: (state, action) => {
			const partners = action.payload
			state.entities[action.meta.arg.fleetId].partners = partners
		},
	} as any,
})

export default fleetSlice
