import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from 'components/Layout'
import useTableNoPage from 'components/controls/useTableNoPage'
import Spinner from 'components/Spinner'
import Popup from 'components/Popup'
import TemplateAddEdit from 'components/TemplateAddEdit'
import TemplatesTable from './TemplatesTable'
import {
	createTemplate,
	initializeTemplates,
	updateTemplate,
	deleteTemplate,
} from 'slices/templateSlice'

const headerCells = [
	{ id: 'templateName', label: 'Template Name' },
	{ id: 'createdBy', label: 'Created By' },
	{ id: 'templateType', label: 'Template Type' },
	{ id: 'createdAt', label: 'Date Created' },
	{ id: 'updatedAt', label: 'Date Updated' },
	{ label: 'Delete' },
]

const Templates = ({ openPopup, setOpenPopup }) => {
	const dispatch = useDispatch()

	const searchInputRef = useRef(null)
	const [searchInputValue, setSearchInputValue] = useState('')

	const [filterFn, setFilterFn] = useState({
		fn: items => {
			return items
		},
	})

	const [popupTitle, setPopupTitle] = useState(false)
	var templateType = ''

	const {
		entities: templateEntities,
		ids: templateIds,
		isInitialized: templatesInitialized,
	} = useSelector(state => state.templates)
	const templates = templateIds.map(id => templateEntities[id])

	// const userLogin = useSelector(state => state.userLogin)
	// const { userInfo: user } = userLogin

	// const userId = user._id

	const isEmpty = templates?.length === 0

	useEffect(() => {
		if (!templatesInitialized) {
			dispatch(initializeTemplates())
		}
	}, [dispatch, templatesInitialized])

	const records = templates
	const { TblContainer, TblHeader, recordsAfterPagingAndSorting } = useTableNoPage(
		records,
		headerCells,
		filterFn,
	)

	const handleAddTemplate = async (template, resetForm) => {
		const templateId = template._id
		const templateName = template.templateName
		const description = template.description

		switch (template.templateType) {
			case 'Tasks Template':
				templateType = 'task'
				break
			case 'Site Details Template':
				templateType = 'sitedetail'
				break
			default:
				break
		}

		if (templateId === 0) {
			dispatch(
				createTemplate({
					templateName: templateName,
					description: description,
					templateType: templateType,
				}),
			)
		} else {
			dispatch(
				updateTemplate({
					_id: templateId,
					templateName: templateName,
					description: description,
					templateType: templateType,
				}),
			)
		}

		resetForm()
		setOpenPopup(false)
		dispatch(initializeTemplates())
	}

	const handleSearch = e => {
		const { value } = e.target
		setSearchInputValue(value)
		setFilterFn({
			fn: items => {
				if (value === '') return items
				return items.filter(x => x.assetName.toLowerCase().includes(value.toLowerCase()))
			},
		})
		// set focus to search input after rerender
		setTimeout(() => {
			searchInputRef.current.focus()
		}, 0)
	}

	const handleDeleteDetail = template => {
		if (window.confirm('Are you sure?')) {
			dispatch(deleteTemplate(template._id))
		}
		dispatch(initializeTemplates())
	}

	return (
		<React.Fragment>
			<Layout.PageToolbar
				buttonText="Add Template"
				buttonOnClick={() => setOpenPopup(true)}
				searchInputRef={searchInputRef}
				searchInputValue={searchInputValue}
				searchInputOnChange={handleSearch}
			/>
			{templatesInitialized ? (
				<>
					<TemplatesTable
						templates={recordsAfterPagingAndSorting()}
						TblContainer={TblContainer}
						TblHeader={TblHeader}
						handleDeleteDetail={handleDeleteDetail}
					/>
					<Popup title={popupTitle} openPopup={openPopup} setOpenPopup={setOpenPopup}>
						<TemplateAddEdit handleSubmit={handleAddTemplate} />
					</Popup>
				</>
			) : (
				<Spinner containerHeight="50vh" />
			)}
		</React.Fragment>
	)
}

export default Templates
