import styled from 'styled-components'
import * as _ from 'styles/helpers'

interface Props {
	isScrolled: boolean
}

const StyledApp = styled.div<Partial<Props>>`
	.App__header {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		/* background-color: ${_.COLORS.background}; */
		background-color: rgba(255, 255, 255, 0.9);
		backdrop-filter: blur(10px);
		z-index: 9999;

		${props =>
			props.isScrolled &&
			`
      box-shadow: 0 0 ${_.rem(20)} 0 rgba(0, 0, 0, 0.25);
    `}
	}

	.App__main {
		@media ${_.MQ.before_nav} {
			padding: ${_.rem(_.HEADER_HEIGHT_MOBILE)} 0;
		}

		padding: ${_.rem(_.HEADER_HEIGHT)} 0;
	}
`

export default StyledApp
