import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Popup from 'components/Popup'
import UserProfilePopup from '../admin/UserProfilePopup'
import LocationEditPopup from 'components/popups/LocationEditPopup'
import { Avatar, Grid } from '@mui/material'
import styled from 'styled-components'
import Layout from 'components/Layout'
import Card from 'components/Card'
import * as _ from 'styles/helpers'
import WelcomeImage from '../../images/MDT_Line.png'
import SuccessImage from '../../images/success.jpg'
import { logout, patchUser, updateUser } from 'slices/authSlice'
import {
	createLocation,
	initializeActiveLocations,
	patchLocation,
	updateLocation,
} from 'slices/locationSlice'
import { createProject, initializeMyProjects, initializeProjects } from 'slices/projectSlice'
import swal from 'sweetalert'
import LocationAddNew from 'screens/locations/components/LocationAddNew'
import UserAddNew from 'components/UserAddNew'
import ProjectAddPopup from 'components/popups/ProjectAddPopup'
import ProjectOnePopup from 'components/popups/ProjectOnePopup'

const HomeFirstTime = () => {
	const dispatch = useDispatch()

	const [openEditPopup, setOpenEditPopup] = useState(false)
	const [openUserPopup, setOpenUserPopup] = useState(false)
	const [openLocationPopup, setOpenLocationPopup] = useState(false)
	const [openPopupGuide, setOpenPopupGuide] = useState(false)
	const [openProjectPopup, setOpenProjectPopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('')
	const [recordForEdit, setRecordForEdit] = useState(null)
	const [myFirstTime, setMyFirstTime] = useState(true)
	const [myProfileIsCompleted, setMyProfileIsCompleted] = useState(false)
	const [myLocationIsCompleted, setMyLocationIsCompleted] = useState(false)
	const [myProjectIsCompleted, setMyProjectIsCompleted] = useState(false)

	const userLogin = useSelector((state: any) => state.userLogin)
	const { userInfo: user } = userLogin
	const userId = user._id

	const {
		entities: locations,
		ids: locationIds,
		isInitialized: locationsInitialized,
	} = useSelector((state: any) => state.locations)
	const firstLocation = locationIds.map(id => locations[id])

	const {
		entities: myProjectEntities,
		ids: myProjectIds,
		isInitialized: myProjectsInitialized,
	} = useSelector((state: any) => state.projects)
	const firstProject = myProjectIds.map(id => myProjectEntities[id])

	const SERVER_BASE_URL =
		process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://app.mydealteams.com'

	useEffect(() => {
		if (!locationsInitialized) {
			dispatch(initializeActiveLocations())
		}

		if (!myProjectsInitialized) {
			dispatch(initializeMyProjects())
		}

		if (myFirstTime) {
			setMyProfileIsCompleted(user.isCompleted)
			setMyLocationIsCompleted(firstLocation.length > 0)
			setMyProjectIsCompleted(firstProject.length > 0)
		} else {
			setMyProfileIsCompleted(true)
			setMyLocationIsCompleted(true)
			setMyProjectIsCompleted(true)
		}
	}, [dispatch, myProjectsInitialized, user, userId, locationsInitialized])

	const handelUserProfilePopup = user => {
		setPopupTitle('User Profile')
		setRecordForEdit(user)
		setOpenUserPopup(true)
	}

	const handelLocationAddPopup = () => {
		setPopupTitle(`Add a location by typing a location name in the 'Location Search' field below.`)
		setOpenPopupGuide(true)
		setOpenLocationPopup(true)
	}

	const handelProjectAddPopup = () => {
		setRecordForEdit(firstLocation[0])
		setPopupTitle('New Project')
		setOpenProjectPopup(true)
	}

	const handleLocationEditPopup = location => {
		setPopupTitle('Edit Location')
		setRecordForEdit(location)
		setOpenEditPopup(true)
	}

	const editLocation = async values => {
		const newLocation = values

		if (values.locationType === 'Retail Center') {
			newLocation.assetType = 'center'
		}
		newLocation.updateNote = 'Edited Location.'
		await dispatch(updateLocation(newLocation))

		setOpenEditPopup(false)
	}

	const editUserProfile = async (values, resetForm) => {
		await dispatch(
			updateUser({
				_id: userId,
				isAdmin: values.isAdmin,
				image: values.image,
				name: values.name,
				firstName: values.firstName,
				email: values.email,
				company: values.company,
				title: values.title,
				role: values.role,
				linkedin: values.linkedin,
				instagram: values.instagram,
				facebook: values.facebook,
				twitter: values.twitter,
				firstTime: values.firstTime,
				isCompleted: true,
				isGuestOnly: values.isGuestOnly,
			}),
		)

		resetForm()
		setOpenUserPopup(false)
		setRecordForEdit(null)
		setMyProfileIsCompleted(true)

		swal(`User Profile`, `Your User Profile has been completed.`, 'success')
	}

	const locationAdd = async (location, resetForm) => {
		const newLocation = location
		newLocation.owner = userId

		if (newLocation.locationType) {
			const locationType = newLocation.locationType.toLocaleLowerCase()
			const newAssetType = locationType.split(' ')

			if (newAssetType[1]) newLocation.assetType = newAssetType[1]
		}
		await dispatch(createLocation({ newLocation }))
		await dispatch(initializeActiveLocations())
		resetForm()
		setOpenLocationPopup(false)
		setMyLocationIsCompleted(true)

		swal(`${newLocation.assetName}`, `Your first Location has been added.`, 'success')
	}

	const addProject = async (newProject, resetForm) => {
		const locationId = firstLocation[0]._id
		const locationImage = firstLocation[0].image

		await dispatch(
			createProject({
				owner: userId,
				projectName: newProject.projectName,
				projectDescription: newProject.projectDescription,
				projectType: newProject.projectType,
				projectAsset: locationId,
				image: locationImage,
				updateNote: 'New Project',
			}),
		)
		dispatch(
			initializeProjects({
				locationId: locationId,
			}),
		)

		dispatch(
			patchLocation({ locationId: locationId, updates: { updateNote: 'New Project(s) Added' } }),
		)

		resetForm()
		setOpenProjectPopup(false)
		setRecordForEdit(null)
		setMyProjectIsCompleted(true)

		swal(`${newProject.projectName}`, `Your first Project has been added.`, 'success')
	}

	const handleFirstTimeReset = async user => {
		const completedUser = user

		await dispatch(
			updateUser({
				_id: user._id,
				isAdmin: completedUser.isAdmin,
				image: completedUser.image,
				name: completedUser.name,
				firstName: completedUser.firstName,
				email: completedUser.email,
				company: completedUser.company,
				title: completedUser.title,
				role: completedUser.role,
				linkedin: completedUser.linkedin,
				instagram: completedUser.instagram,
				facebook: completedUser.facebook,
				twitter: completedUser.twitter,
				isCompleted: completedUser.isCompleted,
				firstTime: false,
				isGuestOnly: completedUser.isGuestOnly,
			}),
		)

		// const userid = userId
		// setTimeout(() => {
		// 	dispatch(patchUser({ userid, updates: { firstTime: false } }))
		// }, 1500)

		// setTimeout(() => {
		// 	dispatch(initializeActiveLocations())
		// }, 100)

		// setTimeout(() => {
		// 	swal(
		// 		`Initial Setup Complete!`,
		// 		`Congratulations! You've successfullly completed the initial setup. You'll now be logged out and invited to log back in again`,
		// 		'success',
		// 	)
		// }, 100)
	}

	return (
		<Layout>
			<Layout.Page>
				<Layout.Body>
					<StyledDashboard>
						<div className="IntroContent">
							<Card className="IntroContent__card IntroContent__card--top">
								<div className="IntroContent__card-firstName">
									<h2>{`Hello ${user.firstName}`}</h2>
								</div>
								<div className="IntroContent__card_intro-header">
									<span>
										<img className="IntroContent__card-avatar" src={WelcomeImage} alt="Avatar" />
									</span>
								</div>
								<div className="IntroContent__card-header">
									<p>We'll now do some setup work that will set you up for success! </p>
								</div>
								<div className="IntroContent__card-header">
									<Grid container spacing={3}>
										<Grid
											item
											xs={4}
											className={` ${myProfileIsCompleted && 'IntroContent__card--disabled'}`}
										>
											<h4>
												#1 Start by clicking on the User Profile card below to complete your
												profile.
											</h4>
										</Grid>
										<Grid
											item
											xs={4}
											className={` ${myProfileIsCompleted ? '' : 'IntroContent__card--disabled'}`}
										>
											<h4>#2 Next click on the Location card to build your first location.</h4>
										</Grid>
										<Grid
											item
											xs={4}
											className={` ${myLocationIsCompleted ? '' : 'IntroContent__card--disabled'}`}
										>
											<h4>
												#3 And finally you'll want to create your first Project. This is where all
												of your work will be done.
											</h4>
										</Grid>
									</Grid>
								</div>
							</Card>
							<Card className="IntroContent__card IntroContent__card--profile">
								{!myProfileIsCompleted ? (
									<div>
										<div className="IntroContent__card-header">
											<h2>User Profile</h2>
										</div>
										<div
											className="IntroContent__card-body"
											onClick={() => {
												handelUserProfilePopup(user)
											}}
										>
											<p className="IntroContent__card-steps">Step 1</p>
											<p className="IntroContent__card-directions">
												Let's complete your User Profile.
											</p>
										</div>
									</div>
								) : (
									<div>
										<div className="IntroContent__card-header">
											<Avatar src={SuccessImage} className="IntroContent__card-header-avatar" />
											<h2>User Profile</h2>
										</div>
										<div
											className="IntroContent__card-body"
											onClick={() => {
												handelUserProfilePopup(user)
											}}
										>
											<Layout.FirstProfileUserCard />
											<p>Good job! Your User Profile is now complete!</p>
										</div>
									</div>
								)}
							</Card>
							<Card
								className={`IntroContent__card IntroContent__card--location ${
									myProfileIsCompleted ? '' : 'IntroContent__card--disabled'
								}`}
							>
								{!myLocationIsCompleted ? (
									<div>
										<div className="IntroContent__card-header">
											<h2>Location</h2>
										</div>
										<div
											className="IntroContent__card-body"
											onClick={() => {
												handelLocationAddPopup()
											}}
										>
											<p
												className={`IntroContent__card-steps ${
													myProfileIsCompleted ? '' : 'IntroContent__card-steps--disabled'
												}`}
											>
												Step 2
											</p>
											{myProfileIsCompleted && (
												<p className="IntroContent__card-directions">Now let's add a location.</p>
											)}
										</div>
									</div>
								) : (
									<div>
										<div className="IntroContent__card-header">
											<Avatar src={SuccessImage} className="IntroContent__card-header-avatar" />
											<h2>Location</h2>
										</div>
										<div
											className="IntroContent__card-body"
											onClick={() => {
												handleLocationEditPopup(firstLocation[0])
											}}
										>
											<Layout.FirstLocationCard />
											<p>Good job! You've created your first location!</p>
										</div>
									</div>
								)}
							</Card>
							<Card
								className={`IntroContent__card IntroContent__card--partner ${
									myProfileIsCompleted && myLocationIsCompleted
										? ''
										: 'IntroContent__card--disabled'
								}`}
							>
								{!myProjectIsCompleted ? (
									<div>
										<div className="IntroContent__card-header">
											<h2>Project</h2>
										</div>
										<div
											className="IntroContent__card-body"
											onClick={() => {
												handelProjectAddPopup()
											}}
										>
											<p
												className={`IntroContent__card-steps ${
													myProfileIsCompleted && myLocationIsCompleted
														? ''
														: 'IntroContent__card-steps--disabled'
												}`}
											>
												Step 3
											</p>
											{myProfileIsCompleted && myLocationIsCompleted && (
												<p className="IntroContent__card-directions">
													Finally we'll add a project and we can then get to work!.
												</p>
											)}
										</div>
									</div>
								) : (
									<div>
										<div className="IntroContent__card-header">
											<Avatar src={SuccessImage} className="IntroContent__card-header-avatar" />
											<h2>Project</h2>
										</div>
										<div className="IntroContent__card-body">
											<Layout.FirstProjectCard />
											<p>Great! You now have a project. Let's get to the fun part!</p>
										</div>
									</div>
								)}
							</Card>
							{myProfileIsCompleted && myLocationIsCompleted && myProjectIsCompleted && (
								<Card className="IntroContent__card IntroContent__card--bottom">
									<div className="IntroContent__card_intro-header">
										<h2>Congratulations! You have completed your initial setup.</h2>
									</div>
									<div
										className="IntroContent__card-header"
										onClick={() => handleFirstTimeReset(user)}
									>
										<p className="IntroContent__card-directions">
											Click Here to begin your MYDealTeams experiance!
										</p>
									</div>
								</Card>
							)}
						</div>
					</StyledDashboard>
				</Layout.Body>
			</Layout.Page>
			{openUserPopup ? (
				<Popup title={popupTitle} openPopup={openUserPopup} setOpenPopup={setOpenUserPopup}>
					<UserProfilePopup recordForEdit={recordForEdit} editUserProfile={editUserProfile} />
				</Popup>
			) : null}
			{openLocationPopup ? (
				<Popup title={popupTitle} openPopup={openLocationPopup} setOpenPopup={setOpenLocationPopup}>
					<LocationAddNew locationAdd={locationAdd} popupGuide={openPopupGuide} />
				</Popup>
			) : null}
			{openProjectPopup ? (
				<Popup title={popupTitle} openPopup={openProjectPopup} setOpenPopup={setOpenProjectPopup}>
					<ProjectOnePopup addProject={addProject} firstLocation={recordForEdit} />
				</Popup>
			) : null}
			{openEditPopup ? (
				<Popup title={popupTitle} openPopup={openEditPopup} setOpenPopup={setOpenEditPopup}>
					<LocationEditPopup editLocation={editLocation} recordForEdit={recordForEdit} />
				</Popup>
			) : null}
		</Layout>
	)
}

const StyledDashboard = styled.div`
	.IntroContent {
		display: grid;
		grid-gap: ${_.rem(24)};
		grid-template-columns: repeat(12, [col] 1fr);

		&__card {
			cursor: pointer;
			border-top: ${_.rem(4)} solid ${_.COLORS.tradewind_blue};

			&--disabled {
				pointer-events: none;
				opacity: 0.5;
				h4 {
					/* ${_.MIXINS.vc};
					${_.TYPOGRAPHY.h2};
					text-align: center;
					padding-top: ${_.rem(8)};
					font-size: ${_.rem(16)}; */
					font-weight: 500;
					color: ${_.COLORS.blue_700};
					/* margin: 0;
					grid-gap: ${_.rem(8)}; */
				}
			}
		}

		&__card_intro-header {
			${_.MIXINS.vc};
			justify-content: center;
			margin-bottom: ${_.rem(16)};

			h2 {
				${_.MIXINS.vc};
				${_.TYPOGRAPHY.h2};
				font-size: ${_.rem(36)};
				font-weight: 600;
				color: ${_.COLORS.blue_700};
				margin: 0;
				grid-gap: ${_.rem(8)};
			}

			p {
				margin: 0;
				font-size: ${_.rem(18)};
				font-style: italic;
			}
		}
		&__card-firstName {
			${_.MIXINS.vc};
			justify-content: center;
			margin-bottom: ${_.rem(16)};

			h2 {
				${_.MIXINS.vc};
				${_.TYPOGRAPHY.h2};
				font-size: ${_.rem(42)};
				font-weight: 500;
				color: ${_.COLORS.blue_700};
				margin: 0;
				grid-gap: ${_.rem(8)};
			}
		}

		&__card-header {
			${_.MIXINS.vc};
			justify-content: center;
			margin-bottom: ${_.rem(16)};

			h2 {
				${_.MIXINS.vc};
				${_.TYPOGRAPHY.h2};
				font-size: ${_.rem(28)};
				font-weight: 600;
				color: ${_.COLORS.blue_700};
				margin: 0;
				grid-gap: ${_.rem(8)};
			}

			h4 {
				${_.MIXINS.vc};
				${_.TYPOGRAPHY.h2};
				text-align: center;
				padding-top: ${_.rem(8)};
				font-size: ${_.rem(16)};
				font-weight: 500;
				color: black;
				margin: 0;
				grid-gap: ${_.rem(8)};
			}

			p {
				margin: 0;
				font-size: ${_.rem(20)};
				font-weight: 500;
				font-style: italic;
				color: ${_.COLORS.blue_700};
			}
		}

		&__card-avatar {
			text-align: center;
			height: 100%;
			max-width: ${_.rem(560)};
		}

		&__card-avatar-success {
			/* display: flex;
			flex-direction: column; */
			align-items: center;
			/* justify-content: center; */
			text-align: center;
			height: 75%;
			padding-left: ${_.rem(60)};
			/* max-width: ${_.rem(260)}; */
		}

		&__card-header-avatar {
			align-items: center;
			/* text-align: center; */
			height: 100%;
			max-width: ${_.rem(60)};
		}

		&__card-body {
			text-align: center;
			height: 100%;
			max-height: ${_.rem(240)};
		}

		&__card-steps {
			text-align: center;
			font-size: ${_.rem(56)};
			color: ${_.COLORS.tradewind_blue};
			&--disabled {
				pointer-events: none;
				opacity: 0.2;
				color: ${_.COLORS.blue_600};
			}
		}

		&__card-directions {
			text-align: center;
			font-size: ${_.rem(18)};
			font-weight: 500;
			text-decoration-line: underline;
			color: ${_.COLORS.blue_600};
			/* font-style: italic; */
		}

		&__card-empty {
			${_.MIXINS.vhc};
			height: 100%;

			h3 {
				font-size: ${_.rem(16)};
				font-weight: 300;
				color: rgba(0, 0, 0, 0.54);
			}
		}

		&__card {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			color: ${_.COLORS.black};
			background-color: ${_.COLORS.white};

			&--top {
				align-items: center;
				justify-content: center;
				text-align: center;
				min-height: ${_.rem(220)};
				grid-column: col / span 12;

				> * {
					${_.MIXINS.vhc}
					flex-direction: column
				}
			}

			&--profile {
				/* background-color: ${_.COLORS.gray_300}; */
				min-height: ${_.rem(360)};
				grid-column: col / span 4;
			}

			&--location {
				/* background-color: ${_.COLORS.gray_300}; */
				min-height: ${_.rem(360)};
				grid-column: col 5 / span 4;
			}

			&--partner {
				/* background-color: ${_.COLORS.gray_300}; */
				min-height: ${_.rem(360)};
				grid-column: col 9 / span 4;
			}

			&--bottom {
				align-items: center;
				justify-content: center;
				text-align: center;
				min-height: ${_.rem(120)};
				grid-column: col / span 12;
			}

			&-content {
				padding: 0 ${_.rem(12)};
			}
		}
	}
`

export default HomeFirstTime
