import React from 'react'
import { MdAdd } from 'react-icons/md'
import StyledStartChatButton from './StartChatButton.style'

export interface Props {
	className?: string
	isDisabled: boolean
	onClick: Function
}

const StartChatButton = ({ className = '', isDisabled = false, onClick }: Props) => {
	return (
		<StyledStartChatButton
			data-testid="StartChatButton"
			aria-label="Start new chat"
			className={className}
			type="submit"
			variant="ghost"
			onClick={onClick}
			disabled={isDisabled}
		>
			<MdAdd />
		</StyledStartChatButton>
	)
}

export default StartChatButton
