import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import FileUploader from 'components/FileUploader'
import PortfolioFileList from './components/PortfolioFileList'
import { Grid } from '@mui/material'
import Layout from 'components/Layout/Layout'
import { useParams } from 'react-router-dom'
import { createPortfolioFile, getPortfolioFiles } from 'slices/portfolioFileSlice'

const PortfolioFilesScreen = () => {
	const { id } = useParams()
	const dispatch = useDispatch()

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	const { loading, isInitialized, portfolio } = useSelector(state => {
		return {
			isInitialized: state.portfolios.isInitialized,
			loading: state.portfolios.isLoading,
			portfolio: state.portfolios.entities[id],
		}
	})

	const sendPortfolioFile = async info => {
		const elements = info.name.split('.')
		const fileExt = elements.slice(elements.length - 1).toString()

		dispatch(
			createPortfolioFile({
				userid: userInfo._id,
				portfolioid: portfolio._id,
				uuid: info.uuid,
				name: info.name,
				size: info.size,
				url: info.originalUrl,
				source: info.source,
				type: fileExt,
				secured: true,
				partners: portfolio.partners,
			}),
		)
	}

	useEffect(() => {
		if (isInitialized) {
			dispatch(getPortfolioFiles(id))
		}
	}, [dispatch, isInitialized, id])

	// // TODO: Create skeleton loader
	if (loading || !portfolio) return null

	return (
		<div>
			<React.Fragment>
				<Grid container spacing={3}>
					<Grid item xs={9}>
						{/* <FileList userId={userInfo._id} locationId={location._id} location={location} /> */}
						<PortfolioFileList
							userId={userInfo._id}
							portfolioId={portfolio._id}
							portfolio={portfolio}
						/>
					</Grid>
					<Grid item xs={3}>
						<Layout.SidebarItem title="Actions" hasNoMaxHeight>
							<ul>
								<li className="actionList__item">
									<FileUploader fileType="document" onChange={info => sendPortfolioFile(info)} />
								</li>
							</ul>
						</Layout.SidebarItem>
					</Grid>
				</Grid>
			</React.Fragment>
		</div>
	)
}

export default PortfolioFilesScreen
