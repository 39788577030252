import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FaPlus, FaFileAlt } from 'react-icons/fa'
import { Grid } from '@mui/material'
import MapLocationPopup from 'components/popups/MapLocationPopup'
import Popup from 'components/Popup'
import Button from 'components/Button'
import swal from 'sweetalert'
import Layout from 'components/Layout/Layout'
import useTableNoPage from 'components/controls/useTableNoPage'
import PortfolioLocationsTable from './Portfolios/PortfolioLocationsTable'
import useMediaQuery from 'hooks/useMediaQuery'
import * as _ from 'styles/helpers'
import ListAction from 'features/Actions/components/ListAction'
import LocationAddNew from 'screens/locations/components/LocationAddNew'
import LocationsCards from 'screens/locations/components/Locations/LocationsCards'
import Placeholder from 'components/Placeholder'
import PortfolioLocationRemovePopup from 'components/popups/PortfolioLocationRemovePopup'
import EmptyLogo from 'images/empty_folder.png'
import { initializeUsers } from 'slices/usersSlice'
import { removeLocationFromPortfolio } from 'slices/portfolioSlice'
import { createLocation, initializeActiveLocations } from 'slices/locationSlice'
// import { initializeApiKey } from 'slices/apiKeysSlice'

const headerCells = [
	{ id: 'image', label: '', disableSorting: true },
	{ id: 'assetName', label: 'Location' },
	{ id: 'address', label: 'Address' },
	{ id: 'city', label: 'City' },
	{ id: 'stateProv', label: 'State' },
	{ id: 'postalCode', label: 'Zip Code' },
	{ id: 'remove', label: '', disableSorting: true },
]

const PortfolioLocationsList = ({ apiKey, userId }) => {
	const { id: portfolioId } = useParams()
	const dispatch = useDispatch()

	const history = useHistory()

	const searchInputRef = useRef(null)
	const [searchInputValue, setSearchInputValue] = useState('')

	const [filterFn, setFilterFn] = useState({
		fn: items => {
			return items
		},
	})

	const [openMap, setOpenMap] = useState(false)
	const [openPopup, setOpenPopup] = useState(false)
	const [openRemovePopup, setOpenRemovePopup] = useState(false)
	const [recordForEdit, setRecordForEdit] = useState(null)
	const [center, setCenter] = useState([])
	const [popupTitle, setPopupTitle] = useState(null)
	const [mapTitle, setMapTitle] = useState(null)

	const { success } = useSelector(state => {
		return {
			success: state.userList.isInitialized,
		}
	})

	const { portfolio, isInitialized } = useSelector(state => {
		return {
			isLoading: state.portfolios.isLoading,
			portfolio: state.portfolios.entities[portfolioId] || {},
			error: state.portfolios.error,
			isInitialized: state.portfolios.isInitialized,
		}
	})

	const ownerId = portfolio.owner._id
	const isOwner = ownerId === userId

	useEffect(() => {
		// if (!isInitialized) {
		dispatch(initializeActiveLocations())
		// }
		if (!success) {
			dispatch(initializeUsers())
		}
	}, [dispatch, success])

	var portfolioLocations = []
	if (portfolio.locations) {
		portfolioLocations = portfolio.locations.map(item => {
			return item.location
		})
	}

	const recordCount = portfolioLocations.length
	const records = portfolioLocations

	const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTableNoPage(
		records,
		headerCells,
		filterFn,
	)

	const _handleRemoveLocation = location => {
		setPopupTitle(`Remove Location`)
		setRecordForEdit(location)
		setOpenRemovePopup(true)
	}

	const locationAdd = (location, resetForm) => {
		dispatch(
			createLocation({
				owner: userId,
				...location,
			}),
		)
		dispatch(initializeActiveLocations())
		resetForm()
		setOpenPopup(false)
	}

	const openInMap = location => {
		setCenter([location.latitude, location.longitude])
		setRecordForEdit(location)
		const title =
			`${location.assetName}` +
			` - ` +
			`${location.address}` +
			`, ` +
			`${location.city}` +
			`, ` +
			`${location.stateProvince}`
		setMapTitle(title)
		setOpenMap(true)
	}

	const locationRemove = location => {
		setPopupTitle(`Remove Location`)
		setRecordForEdit(location)
		setOpenRemovePopup(true)
	}

	const addDropLocationsHandler = () => {
		if (userId === ownerId) {
			history.push(`/portfolios/${portfolioId}/addlocations`)
		} else {
			swal({
				title: `Add Location Error!`,
				text: `You must be the portfolio originator of ${portfolio.assetName} to add a new location.`,
				icon: 'error',
				button: 'Ok',
			})
		}
	}

	const handleRemoveLocation = location => {
		dispatch(
			removeLocationFromPortfolio({
				locationId: location._id,
				portfolioId,
			}),
		)
		dispatch(initializeActiveLocations())
		setOpenRemovePopup(false)
	}

	const hasLocations = records.length > 0
	const isMobile = useMediaQuery(_.MQ.before_nav)

	return (
		<div>
			<Grid container spacing={3}>
				<Grid item xs={9}>
					{hasLocations ? (
						<div>
							{isMobile ? (
								<LocationsCards locations={recordsAfterPagingAndSorting()} />
							) : (
								<PortfolioLocationsTable
									portfolioName={portfolio.assetName}
									locations={recordsAfterPagingAndSorting()}
									locationRemove={locationRemove}
									openInMap={openInMap}
									TblContainer={TblContainer}
									TblHeader={TblHeader}
									TblPagination={TblPagination}
									recordCount={recordCount}
									userId={userId}
									isOwner={isOwner}
								/>
							)}
						</div>
					) : (
						<Placeholder aspectRatio="16:9" isFlexColumn isTransparent>
							<h2 className="h2">We don't have any locations, yet.</h2>
							<div>
								<img className="details__avatar" src={EmptyLogo} alt="Avatar" />
							</div>
							{isOwner && (
								<div>
									<Button onClick={addDropLocationsHandler} variant="success" isLarge>
										<FaPlus /> Start Here to Add a Location
									</Button>
								</div>
							)}{' '}
						</Placeholder>
					)}
				</Grid>
				<Grid item xs={3}>
					<Layout.SidebarItem title="Actions" hasNoMaxHeight>
						<ul>
							{isOwner && (
								<li className="actionList__item">
									<ListAction
										handleAction={addDropLocationsHandler}
										actionLable={'Add Location'}
										actionIcon={<FaPlus />}
									/>
								</li>
							)}{' '}
						</ul>
					</Layout.SidebarItem>
				</Grid>
			</Grid>

			<Popup title={mapTitle} openPopup={openMap} setOpenPopup={setOpenMap}>
				<MapLocationPopup apiKey={apiKey} location={recordForEdit} center={center} />
			</Popup>

			<Popup title="New Location" openPopup={openPopup} setOpenPopup={setOpenPopup}>
				<LocationAddNew locationAdd={locationAdd} user={userId} />
			</Popup>

			<Popup title={popupTitle} openPopup={openRemovePopup} setOpenPopup={setOpenRemovePopup}>
				<PortfolioLocationRemovePopup
					locationRemove={handleRemoveLocation}
					location={recordForEdit}
				/>
			</Popup>
		</div>
	)
}

export default PortfolioLocationsList
