import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Edit as EditIcon } from '@mui/icons-material'
import {
	LocalMall as LocalMallIcon,
	Storefront as StorefrontIcon,
	MoreHoriz as MoreHorizIcon,
} from '@mui/icons-material'

import { Avatar, IconButton, Menu, MenuItem } from '@mui/material'

import Button from 'components/Button'
import Card from '../Card'
import S from '../Card.style'

export interface BusinessCardWrapperProps {
	linkUrl?: string
	children: any
}

export interface BusinessCardProps {
	className?: string
	name: string
	address1: string
	address2: string
	image: string
	isCenter?: boolean
	linkUrl?: string
	editUrl?: string
	menuItems?: {
		title: string
		link?: string
		callback?: Function
	}[]
	isHorizontal?: boolean
	isOwner?: boolean
	ownerImage?: string
}

const BusinessCardWrapper = ({ linkUrl, children }: BusinessCardWrapperProps) => {
	if (linkUrl) return <Link to={linkUrl}>{children}</Link>
	return children
}

export const BusinessCard = ({
	className = '',
	name,
	address1,
	address2,
	image,
	isCenter,
	editUrl,
	menuItems,
	linkUrl,
	isHorizontal = false,
	isOwner,
	ownerImage,
}: BusinessCardProps) => {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)

	const handleClose = callback => {
		setAnchorEl(null)
		if (typeof callback === 'function') callback()
	}

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const actions = () => {
		if (editUrl)
			return (
				<Button as={Link} isOutline to={editUrl}>
					Edit
				</Button>
			)
		if (menuItems) {
			return (
				<>
					{isOwner ? (
						<IconButton color="inherit" size="medium" onClick={handleClick} disabled={!isOwner}>
							<MoreHorizIcon />
						</IconButton>
					) : (
						<Avatar src={ownerImage} />
					)}
					<Menu
						id="long-menu"
						anchorEl={anchorEl}
						keepMounted
						open={open}
						onClose={handleClose}
						PaperProps={{
							style: {
								width: '15ch',
								color: 'blue',
							},
						}}
					>
						<MenuItem onClick={() => handleClose(undefined)}>...</MenuItem>
						{menuItems.map((menuItem, index) => (
							<MenuItem key={index} onClick={() => handleClose(menuItem.callback)}>
								{menuItem.link && <Link to={menuItem.link}>{menuItem.title}</Link>}
								{menuItem.callback && menuItem.title}
							</MenuItem>
						))}
					</Menu>
				</>
			)
		}
	}

	return (
		<Card actions={actions()} isCenteredText>
			<StyledBusinessCard
				data-testid="BusinessCard"
				className={className}
				isHorizontal={isHorizontal}
			>
				<BusinessCardWrapper linkUrl={linkUrl}>
					<div className="BusinessCard__body">
						<div className="BusinessCard__content">
							<S.Title>{name}</S.Title>
							<S.Description>
								{address1}
								<br />
								{address2}
							</S.Description>
							{isCenter ? <LocalMallIcon /> : <StorefrontIcon />}
						</div>
						<div>
							<S.Image className="BusinessCard__Image" src={image} />
						</div>
					</div>
				</BusinessCardWrapper>
			</StyledBusinessCard>
		</Card>
	)
}

const StyledBusinessCard = styled.div<Partial<BusinessCardProps>>`
	width: 100%;

	a:hover {
		text-decoration: none;
	}

	.BusinessCard__body {
		${({ isHorizontal }) =>
			isHorizontal &&
			`
      display: flex;
    `}
	}

	.BusinessCard__Image {
		${({ isHorizontal }) =>
			isHorizontal
				? `
      margin: -16px;
      margin-left: 0;
      padding-top: 25%;
      flex: 1;
    `
				: `
      margin: 1rem -1rem -1rem -1rem;
    `}
	}

	.BusinessCard__content {
		${({ isHorizontal }) =>
			isHorizontal &&
			`
      flex: 250px 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: -16px;
      margin-right: 0;
      padding: 16px;
    `}
	}
`

export default BusinessCard
