import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const SLICE_NAME = 'demographics'

const initialState = {
	byPostalCode: {
		entity: {},
		id: [],
		isLoading: false,
		isInitialized: false,
		error: null,
	},
	byCity: {
		entity: {},
		id: [],
		isLoading: false,
		isInitialized: false,
		error: null,
	},
	byCBSA: {
		entity: {},
		id: [],
		isLoading: false,
		isInitialized: false,
		error: null,
	},
}

//READ

export const initializeDemosByPostalCode = createAsyncThunk(
	`${SLICE_NAME}/initializeByPostalCode`,
	async (payload: any, { getState, rejectWithValue }) => {
		const params = {
			params: {
				postalCode: payload,
			},
		}
		try {
			return axios.get('/api/postalcodedemographics/postalcode', params).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize postal code demographics')
		}
	},
)

export const initializeDemosByCity = createAsyncThunk(
	`${SLICE_NAME}/initializeByCity`,
	async (payload: any, { getState, rejectWithValue }) => {
		const { uCaseCity, uCaseState } = payload
		const params = {
			params: {
				city: uCaseCity,
				state: uCaseState,
			},
		}
		try {
			return axios.get(`/api/postalcodedemographics/city`, params).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize city demographics')
		}
	},
)

export const initializeDemosByCBSA = createAsyncThunk(
	`${SLICE_NAME}/initializeByCBSA`,
	async (payload: any, { getState, rejectWithValue }) => {
		const cbsa = payload
		const params = {
			params: {
				cbsa: cbsa,
			},
		}
		try {
			return axios.get(`/api/postalcodedemographics/cbsa`, params).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize cbsa demographics')
		}
	},
)

//UPDATE

//DELETE

//OTHER

const demographicsSlice = createSlice({
	name: SLICE_NAME,
	initialState,
	reducers: {},
	extraReducers: {
		[initializeDemosByPostalCode.pending as any]: state => {
			state.byPostalCode.isLoading = true
		},
		[initializeDemosByPostalCode.fulfilled as any]: (state, action) => {
			const demographic = action.payload
			state.byPostalCode.id = demographic._id
			state.byPostalCode.entity = demographic
			state.byPostalCode.isLoading = false
			state.byPostalCode.isInitialized = true
		},
		[initializeDemosByPostalCode.rejected as any]: (state, action) => {
			state.byPostalCode.isLoading = false
			state.byPostalCode.isInitialized = true
			state.byPostalCode.error = action.error.message
		},

		[initializeDemosByCity.pending as any]: state => {
			state.byCity.isLoading = true
		},
		[initializeDemosByCity.fulfilled as any]: (state, action) => {
			const demographic = action.payload
			state.byCity.id = demographic._id
			state.byCity.entity = demographic
			state.byCity.isLoading = false
			state.byCity.isInitialized = true
		},
		[initializeDemosByCity.rejected as any]: (state, action) => {
			state.byCity.isLoading = false
			state.byCity.isInitialized = true
			state.byCity.error = action.error.message
		},
		[initializeDemosByCBSA.pending as any]: state => {
			state.byCBSA.isLoading = true
		},

		[initializeDemosByCBSA.fulfilled as any]: (state, action) => {
			const demographic = action.payload
			state.byCBSA.id = demographic._id
			state.byCBSA.entity = demographic
			state.byCBSA.isLoading = false
			state.byCBSA.isInitialized = true
		},
		[initializeDemosByCBSA.rejected as any]: (state, action) => {
			state.byCBSA.isLoading = false
			state.byCBSA.isInitialized = true
			state.byCBSA.error = action.error.message
		},
	} as any,
})

export default demographicsSlice
