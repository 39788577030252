import React, { useState } from 'react'
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material'
import TWControls from '../controls/TWControls'
import { Form, useForm } from '../controls/useForm'
import RTEComponent from 'screens/locations/contentPages/RTEComponent'

const PostAddPopup = ({ addNote, postGroup }) => {
	const [input, setInput] = useState('')
	const [value, setValue] = useState('public')

	const initialValues = {
		id: 0,
		content: '',
		postGroup: postGroup,
	}

	console.log(`Popup - postGroup: `, postGroup)

	const validate = () => {
		let temp = { ...errors }
		temp.content = values.content ? '' : 'This field is required.'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialValues,
		true,
		validate,
	)

	const submitHandler = e => {
		e.preventDefault()

		values.content = input

		if (validate()) {
			addNote(values, resetForm)
		}
	}

	const handlePostType = event => {
		setValue(event.target.value)
		console.log(`value: `, value)
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<RTEComponent input={input} setInput={setInput} />
				</Grid>
				{/* <Grid item xs={12}>
					<FormControl className="newLocation__imageHolder">
						<FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
						<RadioGroup
							row
							aria-labelledby="demo-row-radio-buttons-group-label"
							name="row-radio-buttons-group"
							value={value}
							onChange={handlePostType}
						>
							<FormControlLabel value="public" control={<Radio />} label="Public" />
							<FormControlLabel value="group" control={<Radio />} label="Private Group" />
						</RadioGroup>
					</FormControl>
				</Grid> */}
				{/* {value === 'group' && (
					<Grid item xs={12}>
						<FormControl className="newLocation__imageHolder">
							<FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
							<RadioGroup
								aria-labelledby="demo-row-radio-buttons-group-label"
								name="position"
								// defaultValue="one"
							>
								<FormControlLabel
									value="one"
									size="small"
									control={<Radio />}
									label="Target South West"
									labelPlacement="end"
								/>
								<FormControlLabel
									value="two"
									control={<Radio />}
									label="The Complainers"
									labelPlacement="end"
								/>
							</RadioGroup>
						</FormControl>
					</Grid>
				)} */}

				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<TWControls.Button size="small" type="submit" text="Post" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default PostAddPopup
