import React, { useEffect, useRef, useState } from 'react'
import { Grid } from '@mui/material'
import Layout from 'components/Layout'
import SidebarCommunityAccordian from 'components/Sidebars/SidebarCommunityAccordian'
import EmptyLogo from 'images/Carto.mp4'

const Carto_Video = () => {
	return (
		<Layout>
			<Layout.Page>
				<Layout.Body>
					<Grid>
						<div>
							<video src={EmptyLogo} />
						</div>
					</Grid>
				</Layout.Body>
			</Layout.Page>
		</Layout>
	)
}

export default Carto_Video
