import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaTelegramPlane, FaUserPlus } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { Grid } from '@mui/material'
import swal from 'sweetalert'
import { addMyPartner, initializeMyPartners, updateMyPartner } from 'slices/myPartnersSlice'
import { CardGrid, PartnerProjectCard } from 'components/Card'
import Button from 'components/Button'
import Popup from 'components/Popup'
import PartnerRemovePopup from 'components/popups/PartnerRemovePopup'
import PartnerSettingsPopup from 'components/popups/PartnerSettingsPopup'
import Layout from 'components/Layout/Layout'
import ListAction from 'features/Actions/components/ListAction'
import Placeholder from 'components/Placeholder'
// import EmptyLogo from 'images/no-partners.png'
import EmptyLogo from 'images/empty_folder.png'
import { h2 } from 'styles/helpers/_typography'
import { removePartnerFromProject } from 'slices/projectSlice'
import PartnerReminderPopup from 'components/popups/PartnerReminderPopup'
import {
	emailInviteNewUser,
	emailInviteNewUsercc,
	emailReInviteExistingUser,
	emailReInviteExistingUsercc,
} from 'functions/loginRegisterEmails'

const PartnerProjectCards = ({ partnerType, location, project }) => {
	const dispatch = useDispatch()
	const history = useHistory()

	const SERVER_BASE_URL =
		process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://app.mydealteams.com'

	const [isOwner, setIsOwner] = useState(false)
	const [openReInvitePopup, setOpenReInvitePopup] = useState(false)
	const [openRemovePopup, setOpenRemovePopup] = useState(false)
	const [openSettingsPopup, setOpenSettingsPopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('')
	const [recordForEdit, setRecordForEdit] = useState(null)

	const { _id: locationId, owner } = location
	const ownerId = owner._id

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin
	const userId = userInfo._id

	const fromUser = {
		id: userInfo._id,
		name: userInfo.name,
		email: userInfo.email,
	}

	const userEntities = useSelector(state => state.userList.entities)

	const projectId = project._id
	const assetPartners = project.partners

	const menuItems = partner => {
		if (partner.partner.isAccepted) {
			return [
				{
					title: 'Partner Settings',
					callback: () => {
						if (ownerId === userId) {
							setIsOwner(true)
						}
						setPopupTitle(`Partner Settings`)
						setRecordForEdit(partner)
						setOpenSettingsPopup(true)
					},
				},
				{
					title: 'Remove Partner',
					callback: () => {
						if (ownerId === userId) {
							setIsOwner(true)
						}
						setPopupTitle(`Remove Partner`)
						setRecordForEdit(partner)
						setOpenRemovePopup(true)
					},
				},
			]
		} else {
			return [
				{
					title: 'Re-Invite Partner',
					callback: () => {
						if (ownerId === userId) {
							setIsOwner(true)
						}
						setPopupTitle(`Re-Invite Partner`)
						setRecordForEdit(partner)
						setOpenReInvitePopup(true)
					},
				},
			]
		}
	}
	useEffect(() => {
		dispatch(initializeMyPartners())
	}, [dispatch])

	const handelRemindPartner = async partner => {
		const toUserId = partner.partnerUserId._id

		const toUser = {
			name: partner.partnerUserId.name,
			firstName: partner.partnerUserId.firstName,
			email: 'info@mydealteams.com',
			// email: partner.partnerUserId.email,
		}

		swal({
			title: `Partner Invited!`,
			text: `An email has been sent to ${toUser.name} remind them of your partnership invitation.`,
			icon: 'success',
			button: 'Ok',
		})

		const pushLink = `${SERVER_BASE_URL}/login`
		// const pushLink = `${SERVER_BASE_URL}/registerconfirm/?id=${toUserId}&name=${toUser.name}&firstname=${toUser.firstName}&email=${toUser.email}`

		emailReInviteExistingUser({ fromUser, toUser, location, pushLink })
		emailReInviteExistingUsercc({ fromUser, toUser, location, pushLink })

		// setOpenReInvitePopup(false)
		// setRecordForEdit(null)
	}

	const handleRemovePartner = async partner => {
		const partnerId = partner.removeId
		await dispatch(removePartnerFromProject({ projectId, partnerId }))
		dispatch(initializeMyPartners())
		setOpenRemovePopup(false)
	}

	const handleUpdatePartner = async partner => {
		await dispatch(updateMyPartner(partner))
		dispatch(initializeMyPartners())
		setOpenSettingsPopup(false)
	}

	const addDropPartnersHandler = () => {
		if (userId === ownerId) {
			history.push(`/${partnerType}/${locationId}/projects/${projectId}/addpartners`)
		} else {
			swal({
				title: `Add Partner Error!`,
				text: `You must be the project originator of ${location.assetName} project to add a new Partner to the team.`,
				icon: 'error',
				button: 'Ok',
			})
		}
	}

	return (
		<div>
			<Grid container spacing={3}>
				<Grid item xs={9}>
					{assetPartners.length === 0 ? (
						<Placeholder aspectRatio="16:9" isFlexColumn isTransparent>
							<h2 className="h2">We don't have any partners, yet.</h2>
							<div>
								<img className="details__avatar" src={EmptyLogo} alt="Avatar" />
							</div>

							{/* <br></br>
							<div>
								<Button onClick={addDropPartnersHandler} variant="success" isLarge>
									<FaUserPlus /> Start Here to Add a New Partner
								</Button>
							</div> */}
						</Placeholder>
					) : (
						<CardGrid>
							{assetPartners.map(assetPartner => {
								const partner = userEntities[assetPartner.partnerUserId._id]
								if (!partner) return <h2> You have no partners assigned to this project.</h2>
								return (
									<PartnerProjectCard
										key={assetPartner.partnerUserId._id}
										name={assetPartner.partnerUserId.name}
										title={assetPartner.partnerUserId.title}
										companyName={assetPartner.partnerUserId.company}
										isAccepted={assetPartner.partner.isAccepted}
										avatarUrl={assetPartner.partnerUserId.image}
										menuItems={menuItems(assetPartner)}
										// actions={
										// 	userId === ownerId || userId === assetPartner.partnerUserId.partner ? (
										// 		<Button isOutline onClick={() => openInSettingsPopup(assetPartner)}>
										// 			Review
										// 		</Button>
										// 	) : null
										// }
									/>
								)
							})}
						</CardGrid>
					)}
				</Grid>
				<Grid item xs={3}>
					<Layout.SidebarItem title="Actions" hasNoMaxHeight>
						<ul>
							<li className="actionList__item">
								<ListAction
									handleAction={addDropPartnersHandler}
									actionLable={'Add Partner'}
									actionIcon={<FaUserPlus />}
								/>
							</li>
						</ul>
					</Layout.SidebarItem>
				</Grid>
			</Grid>
			{openReInvitePopup ? (
				<Popup title={popupTitle} openPopup={openReInvitePopup} setOpenPopup={setOpenReInvitePopup}>
					<PartnerReminderPopup partner={recordForEdit} remindPartner={handelRemindPartner} />
				</Popup>
			) : null}{' '}
			{openRemovePopup ? (
				<Popup title={popupTitle} openPopup={openRemovePopup} setOpenPopup={setOpenRemovePopup}>
					<PartnerRemovePopup
						recordForEdit={recordForEdit}
						updatePartner={handleRemovePartner}
						isOwner={isOwner}
					/>
				</Popup>
			) : null}{' '}
			{openSettingsPopup ? (
				<Popup title={popupTitle} openPopup={openSettingsPopup} setOpenPopup={setOpenSettingsPopup}>
					<PartnerSettingsPopup
						recordForEdit={recordForEdit}
						updatePartner={handleUpdatePartner}
						isOwner={isOwner}
					/>
				</Popup>
			) : null}{' '}
		</div>
	)
}

export default PartnerProjectCards
