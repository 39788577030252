import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { siteDetailsService } from 'services'

const SLICE_NAME = 'siteDetails'

const initialState = {
	loading: true,
	isInitialized: false,
	siteDetails: [],
	error: null,
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

// CREATE
export const createSiteDetail = createAsyncThunk(
	`${SLICE_NAME}/createSiteDetail`,
	async (siteDetail: any) => (await siteDetailsService().createSiteDetail(siteDetail)) || {},
)

// READ
// export const getSiteDetails = createAsyncThunk(
// 	`${SLICE_NAME}/getSiteDetails`,
// 	async (projectId: any) => (await siteDetailsService().getSiteDetails({projectId})) || [],
// )

export const getSiteDetails = createAsyncThunk(
	`${SLICE_NAME}/getSiteDetails`,
	async (payload: any, { getState, rejectWithValue }) => {
		const { projectId } = payload

		try {
			const { data } = await axios.get(
				`/api/siteDetails/projectid/${projectId}`,
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to initialize projects')
		}
	},
)

// UPDATE
export const updateSiteDetail = createAsyncThunk(
	`${SLICE_NAME}/updateSiteDetail`,
	async (siteDetail: any) => (await siteDetailsService().updateSiteDetail(siteDetail)) || {},
)

// DELETE
export const deleteSiteDetail = createAsyncThunk(
	`${SLICE_NAME}/deleteSiteDetail`,
	async (id: string) => (await siteDetailsService().deleteSiteDetail(id)) || {},
)

// LOAD TEMPLATE
export const loadTemplateIntoSiteDetails = createAsyncThunk(
	`${SLICE_NAME}/loadTemplateIntoSiteDetails`,
	async (template: any) => (await siteDetailsService().loadTemplateIntoSiteDetails(template)) || {},
)

const siteDetailsSlice = createSlice({
	name: SLICE_NAME,
	initialState,
	reducers: {},
	extraReducers: {
		[getSiteDetails.pending as any]: () => ({ ...initialState }),
		[getSiteDetails.fulfilled as any]: (state, action) => ({
			...state,
			loading: false,
			isInitialized: true,
			siteDetails: action.payload,
		}),
		[getSiteDetails.rejected as any]: (state, action) => ({
			...state,
			loading: false,
			error: action.error,
		}),
		[deleteSiteDetail.fulfilled as any]: (state, action) => ({
			...state,
			isInitialized: false,
		}),
	},
})

// export const {} = siteDetailsSlice.actions
export default siteDetailsSlice.reducer
