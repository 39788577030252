import React from 'react'
import { Form, useForm } from '../controls/useForm'
import { Button, Grid } from '@mui/material'
import { FaTimes } from 'react-icons/fa'
import TWControls from 'components/controls/TWControls'

const ImagePreviewPopup = ({ image, updateImageHandler, deleteImageHandler }) => {
	const initialValues = {
		...image,
	}

	const validate = () => {
		let temp = { ...errors }
		// temp.name = values.name ? '' : 'This field is required.'
		// temp.email = / .+@.+..+/.test(values.email) ? '' : 'Field must be an email'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialValues,
		true,
		validate,
	)

	const submitHandler = e => {
		e.preventDefault()
		updateImageHandler(values, resetForm)
	}

	const removeImageHandler = e => {
		e.preventDefault()
		deleteImageHandler(values, resetForm)
	}
	return (
		<Form onSubmit={submitHandler}>
			<Grid container>
				<Grid item xs={12}>
					{/* <div className="newLocation__imageHolder">
						<h3>{image.name}</h3>
					</div> */}
					<div>{image && <img className="preview__image" src={image.url} alt="" />}</div>
					<TWControls.InputMulti
						name="note"
						label="Note"
						value={values.note}
						onChange={handleInputChange}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<div className="newLocation__imageHolder">
					<Button size="small" variant="outlined" color="error" onClick={removeImageHandler}>
						Remove Image
					</Button>
					<Button size="small" variant="contained" color="primary" type="submit">
						Update Image
					</Button>
				</div>
			</Grid>
		</Form>
	)
}

export default ImagePreviewPopup
