import React from 'react'
import Spinner from 'components/Spinner'
import ChatAddressBar from '../ChatAddressBar'
import chatAddressBarFixture from '../ChatAddressBar/ChatAddressBar.fixtures'
import ChatInput from '../ChatInput'
import chatInputFixture from '../ChatInput/ChatInput.fixtures'
import ChatMessageList from '../ChatMessageList'
import chatMessageListFixture from '../ChatMessageList/ChatMessageList.fixtures'
import ChatWindowHeader from '../ChatWindowHeader'
import chatWindowHeaderFixture from '../ChatWindowHeader/ChatWindowHeader.fixtures'
import StyledChatWindow from './ChatWindow.style'

export interface Props {
	className?: string
	children?: React.ReactNode
	startNewChat?: boolean
	hasCurrentMessages?: boolean
	isLoading?: boolean
}

const ChatWindow = ({
	className = '',
	children,
	startNewChat = false,
	hasCurrentMessages = false,
	isLoading = false,
}: Props) => {
	const shouldDisplayChatInput = startNewChat || hasCurrentMessages

	return (
		<StyledChatWindow data-testid="ChatWindow" className={className}>
			<div>
				{children ? (
					children
				) : (
					<>
						{hasCurrentMessages && <ChatWindowHeader {...chatWindowHeaderFixture} />}
						{!hasCurrentMessages && startNewChat && <ChatAddressBar {...chatAddressBarFixture} />}
						{hasCurrentMessages && <ChatMessageList {...chatMessageListFixture} />}
						{isLoading && <Spinner />}
						{shouldDisplayChatInput && <ChatInput {...chatInputFixture} />}
					</>
				)}
			</div>
		</StyledChatWindow>
	)
}

export default ChatWindow
