import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Button from 'components/Button'
import Popup from 'components/Popup'
import TemplateTaskPopup from 'components/popups/TemplateTaskPopup'
import useTableNoPage from 'components/controls/useTableNoPage'
import { MdAddTask } from 'react-icons/md'
import { DeleteOutline as DeleteIcon } from '@mui/icons-material'
import { RiCloseCircleLine } from 'react-icons/ri'
// import { StyledDetailsTable } from '../TemplateDetails.style'

import { IconButton, TableBody, TableCell, TableRow, Tooltip } from '@mui/material'
import {
	createTemplateTask,
	initializeTemplateTasks,
	updateTemplateTask,
	deleteTemplateTask,
} from 'slices/templateSlice'
import { StyledDetailsTable } from 'features/TemplateDetails/TemplateDetails.style'

const headerCells = [
	{ id: 'taskName', label: 'Subtask' },
	{ id: 'seq', label: 'Seq' },
	{ id: 'taskDays', label: 'Task Days' },
	{ id: 'description', label: 'Notes' },
	{ id: 'delete', label: 'Delete', align: 'right' },
]

function TemplateTask({ templateTask, subtasks }) {
	const templateId = templateTask.templateId
	const templateTaskId = templateTask._id

	const dispatch = useDispatch()

	const [filterFn, setFilterFn] = useState({
		fn: items => {
			return items
		},
	})

	const [openTaskPopup, setOpenTaskPopup] = useState(false)
	const [openSubtaskPopup, setOpenSubtaskPopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('Update Task')
	const [recordForEdit, setRecordForEdit] = useState(null)

	const newSubtasks = subtasks.filter(subtask => subtask.parentId === templateTaskId)
	const taskSeq = newSubtasks.length + 1

	const records = newSubtasks

	const { TblContainer, TblHeader, recordsAfterPagingAndSorting } = useTableNoPage(
		records,
		headerCells,
		filterFn,
	)

	const openInPopup = task => {
		setRecordForEdit(task)
		setOpenTaskPopup(true)
	}

	const openSubtaskInPopup = subtask => {
		setRecordForEdit(subtask)
		setOpenSubtaskPopup(true)
	}

	const addEditSubtask = async (subtask, resetForm) => {
		const taskName = subtask.taskName
		const seq = subtask.seq
		const taskDays = subtask.taskDays
		const description = subtask.description

		if (subtask._id === 0) {
			dispatch(
				createTemplateTask({
					templateId: templateId,
					parentId: templateTaskId,
					taskName: taskName,
					seq: seq,
					taskDays: taskDays,
					description: description,
				}),
			)
			dispatch(initializeTemplateTasks(templateId))
		} else {
			dispatch(
				updateTemplateTask({
					_id: subtask._id,
					templateId: templateId,
					parentId: templateTaskId,
					taskName: taskName,
					seq: seq,
					taskDays: taskDays,
					description: description,
				}),
			)
		}

		resetForm()
		setOpenSubtaskPopup(false)
		setRecordForEdit(null)
		await dispatch(initializeTemplateTasks(templateId))
	}

	const editTask = async (task, resetForm) => {
		const taskName = task.taskName
		const seq = task.seq
		const taskDays = task.taskDays
		const description = task.description

		dispatch(
			updateTemplateTask({
				_id: task._id,
				templateId: templateId,
				parentId: null,
				taskName: taskName,
				seq: seq,
				taskDays: taskDays,
				description: description,
			}),
		)

		await dispatch(initializeTemplateTasks(templateId))

		resetForm()
		setOpenTaskPopup(false)
		setRecordForEdit(null)
	}

	const deleteTaskHandler = async taskDelete => {
		const id = taskDelete._id
		const parentId = taskDelete.parentId

		if (window.confirm('Are you sure?')) {
			await dispatch(deleteTemplateTask(id))
		}
	}

	return (
		<StyledDetailsTable data-testid="DetailsTable">
			<div className="task">
				<div className="task__header">
					<div className="task__info">
						<Tooltip title="Click to change sequence.">
							<h3
								onClick={() => {
									setPopupTitle('Update Task')
									openInPopup(templateTask)
								}}
							>
								{templateTask.seq}
							</h3>
						</Tooltip>
						<Tooltip title={templateTask.description}>
							<h2
								key={templateTaskId}
								onClick={() => {
									setPopupTitle('Update Task')
									openInPopup(templateTask)
								}}
							>
								{templateTask.taskName}
							</h2>
						</Tooltip>
					</div>
					<div className="task__button">
						<Tooltip title={`Add new Subtask.`}>
							<IconButton
								className="feed__icon"
								color="primary"
								size="small"
								onClick={() => {
									setPopupTitle(`New Subtask`)
									setRecordForEdit(null)
									setOpenSubtaskPopup(true)
								}}
							>
								<MdAddTask />
							</IconButton>
						</Tooltip>
						<Tooltip title={`Delete Task`}>
							<IconButton
								className="feed__icon"
								color="primary"
								size="small"
								onClick={() => deleteTaskHandler(templateTask)}
							>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</div>
				</div>

				<div>
					{newSubtasks.length > 0 ? (
						<TblContainer>
							<TblHeader />
							<TableBody>
								{recordsAfterPagingAndSorting().map(subtask => (
									<TableRow key={subtask._id} className="table-row">
										<TableCell
											onClick={() => {
												setPopupTitle('Update Subtask')
												openSubtaskInPopup(subtask)
											}}
										>
											<div>{subtask.taskName}</div>
										</TableCell>
										<TableCell
											onClick={() => {
												setPopupTitle('Update Subtask')
												openSubtaskInPopup(subtask)
											}}
										>
											<div>{subtask.seq}</div>
										</TableCell>
										<TableCell
											onClick={() => {
												setPopupTitle('Update Subtask')
												openSubtaskInPopup(subtask)
											}}
										>
											<div>{subtask.taskDays}</div>
										</TableCell>
										<TableCell
											onClick={() => {
												setPopupTitle('Update Subtask')
												openSubtaskInPopup(subtask)
											}}
										>
											<div>{subtask.description}</div>
										</TableCell>
										<Tooltip title={`Delete Subtask`}>
											<TableCell align="left">
												{/* <IconButton
												className="feed__icon"
												color="primary"
												size="small"
												onClick={() => deleteTaskHandler(subtask)}
											>
												<DeleteIcon />
											</IconButton> */}
												<div className="edit-button">
													<Button
														onClick={() => deleteTaskHandler(subtask)}
														variant="ghost-red"
														isOutline
														aria-label="Delete"
													>
														<RiCloseCircleLine />
													</Button>
												</div>
											</TableCell>
										</Tooltip>
									</TableRow>
								))}
							</TableBody>
						</TblContainer>
					) : null}
				</div>

				{openTaskPopup ? (
					<Popup title={popupTitle} openPopup={openTaskPopup} setOpenPopup={setOpenTaskPopup}>
						<TemplateTaskPopup
							recordForEdit={recordForEdit}
							addEditTask={editTask}
							isSubtask={false}
						/>
					</Popup>
				) : null}

				{openSubtaskPopup ? (
					<Popup title={popupTitle} openPopup={openSubtaskPopup} setOpenPopup={setOpenSubtaskPopup}>
						<TemplateTaskPopup
							recordForEdit={recordForEdit}
							addEditTask={addEditSubtask}
							isSubtask={true}
							taskSeq={taskSeq}
						/>
					</Popup>
				) : null}
			</div>
		</StyledDetailsTable>
	)
}

export default TemplateTask
