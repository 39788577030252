import moment from 'moment'

const getFormattedDateTime = dateTime => {
	const NOW = moment()
	const MOMENT = moment(dateTime)

	const TODAY = NOW.clone().startOf('day')
	const YESTERDAY = NOW.clone().subtract(1, 'days').startOf('day')
	const A_WEEK_OLD = NOW.clone().subtract(7, 'days').startOf('day')

	const isToday = (() => MOMENT.isSame(TODAY, 'd'))()
	const isYesterday = (() => MOMENT.isSame(YESTERDAY, 'd'))()
	const isWithinAWeek = (() => MOMENT.isAfter(A_WEEK_OLD))()
	const isTwoWeeksOrMore = !isWithinAWeek

	if (isToday) return MOMENT.format('LT')
	if (isYesterday) return 'Yesterday'
	if (isWithinAWeek) return MOMENT.format('dddd')
	if (isTwoWeeksOrMore) return MOMENT.format('M/D/YY')
	return ''
}

export default getFormattedDateTime
