import React from 'react'
import { Grid } from '@mui/material'
import TWControls from '../controls/TWControls'
import { Form } from '../controls/useForm'

const RemoveDocumentPopup = ({ removeDocument, document }) => {
	const submitHandler = e => {
		removeDocument(document)
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<h2 className="newLocation__imageHolder">NOTICE. </h2>
				</Grid>
				<Grid item xs={12}>
					<p className="newLocation__imageHolder">
						This removes a document from the project file library but does not delete the original
						document.{' '}
					</p>
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<TWControls.Button variant="danger" size="small" type="submit" text="Remove Document" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default RemoveDocumentPopup
