import styled from 'styled-components'
import type { Props } from './PartnerAvatar'

const StyledPartnerAvatar = styled.div<Partial<Props>>`
	position: relative;
	display: inline-block;

	&:after {
		${({ isOnline, size = 40 }) =>
			isOnline &&
			`
    position: absolute;
		bottom: ${+size * 0.05}px;
		right: ${+size * 0.05}px;
		width: ${+size * 0.175}px;
		height: ${+size * 0.175}px;
		border-radius: 50%;
		background-color: #44b700;
		box-shadow: 0 0 0 ${+size * 0.0375}px #fff;
		content: '';
  `}
	}
`

export default StyledPartnerAvatar
