import styled from 'styled-components'
import type { Props } from './ChatMessage'
import * as _ from 'styles/helpers'

const StyledChatMessage = styled.div<Partial<Props>>`
	display: flex;
	/* justify-content: ${({ isAlt }) => (isAlt ? 'flex-start' : 'flex-end')}; */
	margin-bottom: ${_.rem(4)};

	.ChatMessage {
		&__avatar {
			position: relative;
			top: ${_.rem(30)};
			width: ${_.rem(40)};
			height: ${_.rem(40)};
			margin-right: ${_.rem(12)};
		}

		&__inner {
			max-width: 75%;
		}

		&__body {
			position: relative;
			width: fit-content;
			padding: ${_.rem(8)} ${_.rem(12)};
			font-size: ${_.rem(16)};
			color: ${_.COLORS.black};
			/* color: ${_.COLORS.white}; */
			background-color: ${_.COLORS.gray_100};
			/* background-color: ${({ isAlt }) =>
				isAlt ? `${_.COLORS.green_500}` : `${_.COLORS.tradewind_blue_a11y}`}; */
			border-radius: ${_.rem(4)};

			&:after {
				content: '';
				position: absolute;
				right: 100%;
				/* left: ${({ isAlt }) => !isAlt && '100%'}; */
				/* right: ${({ isAlt }) => isAlt && '100%'}; */
				top: 50%;
				margin-top: ${_.rem(-6)};
				width: 0;
				height: 0;
				border-color: transparent ${_.COLORS.gray_100} transparent transparent;
				/* border-color: ${({ isAlt }) =>
					isAlt
						? `transparent ${_.COLORS.green_500} transparent transparent;`
						: `transparent ${_.COLORS.tradewind_blue_a11y} transparent transparent;`}; */
				border-style: solid;
				border-width: ${_.rem(6)} ${_.rem(6)} ${_.rem(6)} 0;
				/* transform: ${({ isAlt }) => !isAlt && 'rotate(180deg)'}; */
			}
		}

		&__meta {
			margin-top: ${_.rem(8)};
			margin-bottom: ${_.rem(2)};
			font-size: ${_.rem(11)};
			color: ${_.COLORS.gray_600};

			&__username {
				font-size: ${_.rem(13)};
				font-weight: ${_.FONTS.bold};
				color: ${_.COLORS.black};
				margin-right: ${_.rem(4)};
			}
		}
	}
`

export default StyledChatMessage
