import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
	entities: {},
	ids: [],
	isLoading: false,
	isInitialized: false,
	needsGeometry: false,
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

export const createBulkLocations = createAsyncThunk(
	'bulklocations/createMany',
	async (payload: any, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/api/bulklocations`, payload, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to create location')
		}
	},
)

export const initializeBulkLocations = createAsyncThunk(
	'bulklocations/initialize',
	async (payload, { getState, rejectWithValue }) => {
		try {
			return axios.get('/api/bulklocations', prepConfig(getState)).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize locations')
		}
	},
)

export const getLocationByID = createAsyncThunk(
	'bulklocations/getLocationByID',
	async (locationId, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.get(`/api/bulklocations/${locationId}`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to update location')
		}
	},
)

export const updateBulkLocation = createAsyncThunk(
	'bulklocations/update',
	async (location: any, { getState, rejectWithValue }) => {
		if (location && location._id) {
			try {
				const { data } = await axios.put(
					`/api/bulklocations/${location._id}`,
					location,
					prepConfig(getState),
				)
				return data
			} catch (error) {
				console.error(error)
				return rejectWithValue('Unable to update location')
			}
		}
		return rejectWithValue('Malformed location')
	},
)

export const deleteOneLocation = createAsyncThunk(
	'bulklocations/delete',
	async (locationId, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.delete(`/api/bulklocations/${locationId}`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to delete location')
		}
	},
)
export const deleteAllLocations = createAsyncThunk(
	'bulklocations/delete',
	async (locationId, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.delete(`/api/bulklocations`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to delete location')
		}
	},
)

// Extract the key out of the entitiy object
// eslint-disable-next-line no-unused-vars
const removeKey = (key, { [key]: _, ...rest }) => rest

const bulkLocationsSlice = createSlice({
	name: 'locations',
	initialState,
	reducers: {},
	extraReducers: {
		[createBulkLocations.pending as any]: state => {
			state.isLoading = true
		},
		[createBulkLocations.fulfilled as any]: (state, action) => {
			const locations = action.payload
			state.ids = locations?.map((loc, index) => {
				state.entities[loc._id] = loc
				return loc._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[createBulkLocations.rejected as any]: (state, action) => {
			const location = action.payload
			state.entities[location._id] = location
		},

		[initializeBulkLocations.fulfilled as any]: (state, action) => {
			const locations = action.payload
			state.ids = locations?.map((loc, index) => {
				state.entities[loc._id] = loc

				return loc._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeBulkLocations.pending as any]: state => {
			state.isLoading = true
		},
		[initializeBulkLocations.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},

		[getLocationByID.fulfilled as any]: (state, action) => {
			state.entities[action.meta.arg] = { ...action.payload, ...state.entities[action.meta.arg] }
			state.isLoading = false
		},
		[getLocationByID.rejected as any]: (state, action) => {
			state.error = action.error
		},

		[updateBulkLocation.fulfilled as any]: (state, action) => {
			const location = action.payload
			state.entities[location._id] = { ...action.payload, ...state.entities[location._id] }
		},

		[deleteOneLocation.fulfilled as any]: (state, action) => {
			const id = action.meta.arg
			state.entities = removeKey(id, state.entities)
			state.ids = state.ids.filter(stateId => stateId !== id)
		},

		[deleteAllLocations.fulfilled as any]: (state, action) => {
			state.entities = {}
			state.ids = []
		},
	} as any,
})

export default bulkLocationsSlice

// export const { } = bulkLocationsSlice.actions;
