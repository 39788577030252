import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
	entities: {},
	ids: [],
	isLoading: false,
	isInitialized: false,
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

export const createMyPartnerApp = createAsyncThunk(
	'myPartnerApps/create',
	async (partnerApp, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/api/mypartnerApps`, partnerApp, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to create partnerApp')
		}
	},
)

export const initializeMyPartnerApps = createAsyncThunk(
	'myPartnerApps/initialize',
	async (payload, { getState, rejectWithValue }) => {
		try {
			return axios.get('/api/mypartnerApps', prepConfig(getState)).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize partnerApps')
		}
	},
)

export const updateMyPartnerApp = createAsyncThunk(
	'myPartnerApps/update',
	async (partnerApp: any, { getState, rejectWithValue }) => {
		if (partnerApp && partnerApp._id) {
			try {
				const { data } = await axios.put(
					`/api/mypartnerApps/${partnerApp._id}`,
					partnerApp,
					prepConfig(getState),
				)
				return data
			} catch (error) {
				console.error(error)
				return rejectWithValue('Unable to update partnerApp')
			}
		}
		return rejectWithValue('Malformed partnerApp')
	},
)

export const deleteMyPartnerApp = createAsyncThunk(
	'myPartnerApps/delete',
	async (partnerAppId, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.delete(
				`/api/mypartnerApps/${partnerAppId}`,
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to delete partnerApp')
		}
	},
)

// Extract the key out of the entitiy object
// eslint-disable-next-line no-unused-vars
const removeKey = (key, { [key]: _, ...rest }) => rest

const partnerAppSlice = createSlice({
	name: 'myPartnerApps',
	initialState,
	reducers: {},
	extraReducers: {
		[createMyPartnerApp.fulfilled as any]: (state, action) => {
			const partnerApp = action.payload
			state.entities[partnerApp._id] = partnerApp
		},
		[createMyPartnerApp.rejected as any]: (state, action) => {
			const partnerApp = action.payload
			state.entities[partnerApp._id] = partnerApp
		},
		[initializeMyPartnerApps.fulfilled as any]: (state, action) => {
			const partnerApps = action.payload

			state.ids = partnerApps?.map((app, index) => {
				state.entities[app._id] = app
				return app._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeMyPartnerApps.pending as any]: state => {
			state.isLoading = true
		},
		[initializeMyPartnerApps.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},
		[deleteMyPartnerApp.fulfilled as any]: (state, action) => {
			const id = action.meta.arg
			state.entities = removeKey(id, state.entities)
			state.ids = state.ids.filter(stateId => stateId !== id)
		},
		[updateMyPartnerApp.fulfilled as any]: (state, action) => {
			const partnerApp = action.payload
			state.entities[partnerApp._id] = { ...action.payload, ...state.entities[partnerApp._id] }
		},
	} as any,
})

export default partnerAppSlice
