import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'components/Spinner'
import Layout from 'components/Layout'
import PartnerAppCards from './PartnerAppCards'
import { initializePartnerApps } from 'slices/partnerAppSlice'

const PartnerApps_Index = ({ history }) => {
	const dispatch = useDispatch()

	const {
		entities: appEntities,
		ids: partnerAppIds,
		isLoading: partnerAppsLoading,
		isInitialized: partnerAppsInitialized,
	} = useSelector(state => state.partnerApps)
	const partnerApps = partnerAppIds.map(id => appEntities[id])

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	useEffect(() => {
		dispatch(initializePartnerApps())
	}, [dispatch, history, userInfo])

	return (
		<React.Fragment>
			<Layout.PageToolbar
				buttonText="Explore Apps"
				// buttonOnClick={() => setOpenPopup(true)}
				// searchInputRef={searchInputRef}
				// searchInputValue={searchInputValue}
				// searchInputOnChange={handleSearch}
			/>
			{partnerAppsInitialized ? (
				<div>
					<PartnerAppCards partnerApps={partnerApps} />
				</div>
			) : (
				<Spinner containerHeight="50vh" />
			)}
		</React.Fragment>
	)
}

export default PartnerApps_Index
