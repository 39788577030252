import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Company_Profile_Edit from './navPages/Company_Profile_Edit'
import { initializeCompany } from 'slices/companySlice'

const Company_Profile = () => {
	const dispatch = useDispatch()

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	const userId = userInfo._id
	const companyid = userInfo.companyid

	const { entity: company, isLoading } = useSelector(state => state.company)

	useEffect(() => {
		dispatch(initializeCompany(companyid))
	}, [dispatch])

	// // TODO: Create skeleton loader
	if (isLoading || !company) return null

	return (
		<div>
			{/* <h2>Hello Company</h2> */}

			<Company_Profile_Edit company={company} userId={userId} />
		</div>
	)
}
export default Company_Profile
