import React from 'react'
import { Avatar } from '@mui/material'
import StyledChatMessage from './ChatMessage.style'

export type TMessage = {
	body: string
	username?: string
	time?: string
	roomId?: string
	avatar?: string
}

export interface Props {
	className?: string
	style?: string
	message: TMessage
	isAlt?: Boolean
	avatar?: string
}

const ChatMessage = ({ className = '', style, message, isAlt = false, avatar }: Props) => {
	const { body, time, username } = message
	const showAvatar = username || avatar
	const showMeta = username || time

	// console.log(`avatar: `, avatar)

	return (
		<StyledChatMessage data-testid="ChatMessage" className={className} isAlt={isAlt} style={style}>
			<div className="ChatMessage__avatar">
				{showAvatar && <Avatar alt={username} src={avatar} sx={{ width: 40, height: 40 }} />}
			</div>
			<div className="ChatMessage__inner">
				{showMeta && (
					<div className="ChatMessage__meta">
						{username && <span className="ChatMessage__meta__username">{username}</span>}
						{time && <span>{time}</span>}
					</div>
				)}
				<div className="ChatMessage__body">{body}</div>
			</div>
		</StyledChatMessage>
	)
}

export default ChatMessage
