import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
	entities: {},
	ids: [],
	isLoading: false,
	isInitialized: false,
	tasks: {
		entities: {},
		ids: [],
		isLoading: false,
		isInitialized: false,
		error: null,
	},
	siteDetails: {
		entities: {},
		ids: [],
		isLoading: false,
		isInitialized: false,
		error: null,
	},
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

export const createTemplate = createAsyncThunk(
	'templates/create',
	async (template, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/api/templates/`, template, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to create template')
		}
	},
)

export const createTemplateSiteDetail = createAsyncThunk(
	'templates/createSiteDetail',
	async (sitedetail, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.post(
				`/api/templates/sitedetail`,
				sitedetail,
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to create template')
		}
	},
)

export const createTemplateTask = createAsyncThunk(
	'templates/createTask',
	async (task, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/api/templates/task`, task, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to create template task')
		}
	},
)

export const createTemplateSubtask = createAsyncThunk(
	'templates/createSubtask',
	async (subtask, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/api/templates/subtasks`, subtask, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to create template')
		}
	},
)

export const initializeTemplates = createAsyncThunk(
	'templates/initialize',
	async (payload, { getState, rejectWithValue }) => {
		try {
			return axios.get('/api/templates', prepConfig(getState)).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize templates')
		}
	},
)

export const initializeTemplateTasks = createAsyncThunk(
	'templates/initializeTasks',
	async (templateId: any, { getState, rejectWithValue }) => {
		try {
			return axios
				.get(`/api/templates/${templateId}/tasks`, prepConfig(getState))
				.then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize template tasks')
		}
	},
)

export const initializeTemplateSiteDetails = createAsyncThunk(
	'templates/initializeSiteDetails',
	async (templateId: any, { getState, rejectWithValue }) => {
		try {
			return axios
				.get(`/api/templates/${templateId}/sitedetails`, prepConfig(getState))
				.then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize template site details')
		}
	},
)

export const updateTemplate = createAsyncThunk(
	'templates/update',
	async (template: any, { getState, rejectWithValue }) => {
		if (template && template._id) {
			try {
				const { data } = await axios.put(
					`/api/templates/${template._id}`,
					template,
					prepConfig(getState),
				)
				return data
			} catch (error) {
				console.error(error)
				return rejectWithValue('Unable to update template')
			}
		}
		return rejectWithValue('Malformed template')
	},
)

export const updateTemplateTask = createAsyncThunk(
	'templates/updateTask',
	async (task: any, { getState, rejectWithValue }) => {
		if (task && task._id) {
			try {
				const { data } = await axios.put(
					`/api/templates/${task._id}/task`,
					task,
					prepConfig(getState),
				)
				return data
			} catch (error) {
				console.error(error)
				return rejectWithValue('Unable to update task')
			}
		}
		return rejectWithValue('Malformed template task')
	},
)

export const updateTemplateSiteDetail = createAsyncThunk(
	'templates/updateSiteDetail',
	async (sitedetail: any, { getState, rejectWithValue }) => {
		if (sitedetail && sitedetail._id) {
			try {
				const { data } = await axios.put(
					`/api/templates/${sitedetail._id}/sitedetail`,
					sitedetail,
					prepConfig(getState),
				)
				return data
			} catch (error) {
				console.error(error)
				return rejectWithValue('Unable to update template')
			}
		}
		return rejectWithValue('Malformed template')
	},
)

export const deleteTemplate = createAsyncThunk(
	'templates/delete',
	async (templateId, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.delete(`/api/templates/${templateId}`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to delete template')
		}
	},
)

export const deleteTemplateSiteDetail = createAsyncThunk(
	'templates/deleteSiteDetail',
	async (sitedetailId, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.delete(
				`/api/templates/${sitedetailId}/sitedetail`,
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to delete site detail')
		}
	},
)

export const deleteTemplateTask = createAsyncThunk(
	'templates/deleteTask',
	async (taskId, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.delete(`/api/templates/${taskId}/task`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to delete site detail')
		}
	},
)

// Extract the key out of the entitiy object
// eslint-disable-next-line no-unused-vars
const removeKey = (key, { [key]: _, ...rest }) => rest

const templatesSlice = createSlice({
	name: 'templates',
	initialState,
	reducers: {},
	extraReducers: {
		[createTemplate.fulfilled as any]: (state, action) => {
			const template = action.payload
			state.entities[template._id] = template
			state.isInitialized = false
		},
		[createTemplate.rejected as any]: (state, action) => {
			const template = action.payload
			state.entities[template._id] = template
		},

		[createTemplateSiteDetail.fulfilled as any]: (state, action) => {
			const sitedetail = action.payload
			state.siteDetails.entities[sitedetail._id] = sitedetail
		},
		[createTemplateSiteDetail.rejected as any]: (state, action) => {
			const sitedetail = action.payload
			state.siteDetails.entities[sitedetail._id] = sitedetail
		},

		[createTemplateTask.fulfilled as any]: (state, action) => {
			const task = action.payload
			state.tasks.entities[task._id] = task
		},
		[createTemplateTask.rejected as any]: (state, action) => {
			const task = action.payload
			state.tasks.entities[task._id] = task
		},

		[createTemplateSubtask.fulfilled as any]: (state, action) => {
			const subtask = action.payload
			state.subtask.entities[subtask._id] = subtask
		},
		[createTemplateSubtask.rejected as any]: (state, action) => {
			const subtask = action.payload
			state.subtask.entities[subtask._id] = subtask
		},

		[initializeTemplates.fulfilled as any]: (state, action) => {
			const templates = action.payload

			state.ids = templates?.map((item, index) => {
				state.entities[item._id] = item
				return item._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeTemplates.pending as any]: state => {
			state.isLoading = true
		},
		[initializeTemplates.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},

		[initializeTemplateTasks.fulfilled as any]: (state, action) => {
			const tasks = action.payload

			state.tasks.ids = tasks?.map((item, index) => {
				state.tasks.entities[item._id] = item
				return item._id
			})
			state.tasks.isLoading = false
			state.tasks.isInitialized = true
		},
		[initializeTemplateTasks.pending as any]: state => {
			state.tasks.isLoading = true
		},
		[initializeTemplateTasks.rejected as any]: (state, action) => {
			state.tasks.isLoading = false
			state.tasks.isInitialized = true
			state.tasks.error = action.error.message
		},

		[initializeTemplateSiteDetails.fulfilled as any]: (state, action) => {
			const siteDetails = action.payload

			state.siteDetails.ids = siteDetails?.map((item, index) => {
				state.siteDetails.entities[item._id] = item
				return item._id
			})
			state.siteDetails.isLoading = false
			state.siteDetails.isInitialized = true
		},
		[initializeTemplateSiteDetails.pending as any]: state => {
			state.siteDetails.isLoading = true
		},
		[initializeTemplateSiteDetails.rejected as any]: (state, action) => {
			state.siteDetails.isLoading = false
			state.siteDetails.isInitialized = true
			state.siteDetails.error = action.error.message
		},

		[updateTemplate.fulfilled as any]: (state, action) => {
			const template = action.payload
			state.entities[template._id] = { ...action.payload, ...state.entities[template._id] }
		},

		[updateTemplateTask.fulfilled as any]: (state, action) => {
			const task = action.payload
			state.task.entities[task._id] = {
				...action.payload,
				...state.task.entities[task._id],
			}
		},

		[updateTemplateSiteDetail.fulfilled as any]: (state, action) => {
			const sitedetail = action.payload
			state.siteDetails.entities[sitedetail._id] = {
				...action.payload,
				...state.siteDetails.entities[sitedetail._id],
			}
		},

		[deleteTemplate.fulfilled as any]: (state, action) => {
			const id = action.meta.arg
			state.entities = removeKey(id, state.entities)
			state.ids = state.ids.filter(stateId => stateId !== id)
		},

		[deleteTemplateTask.fulfilled as any]: (state, action) => {
			const id = action.meta.arg
			state.tasks.entities = removeKey(id, state.tasks.entities)
			state.tasks.ids = state.tasks.ids.filter(stateId => stateId !== id)
		},

		[deleteTemplateSiteDetail.fulfilled as any]: (state, action) => {
			const id = action.meta.arg
			state.siteDetails.entities = removeKey(id, state.siteDetails.entities)
			state.siteDetails.ids = state.siteDetails.ids.filter(stateId => stateId !== id)
		},
	} as any,
})

export default templatesSlice
