import TemplateSiteDetail_Edit from './TemplateSiteDetail_Edit'
import TemplateTask_Edit from './TemplateTask_Edit'
import routes from 'constants/routes'

const getTemplateRoutes = (id: string) => [
	{
		// linkText: 'Build ',
		linkTo: `${routes.TEMPLATES}/${id}/task`,
		linkPattern: `${routes.TEMPLATES}/:id/task`,
		component: TemplateTask_Edit,
	},
	{
		// linkText: 'Build ',
		linkTo: `${routes.TEMPLATES}/${id}/sitedetail`,
		linkPattern: `${routes.TEMPLATES}/:id/sitedetail`,
		component: TemplateSiteDetail_Edit,
	},
	// {
	// 	redirectFrom: `${routes.TEMPLATES}/:id`,
	// 	redirectTo: `${routes.TEMPLATES}/${id}/tasks`,
	// },
]

export default getTemplateRoutes
