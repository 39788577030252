import React, { useState, useRef } from 'react'
import { Chart as ChartJS } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { getPerc, getNumberAsThousandsStr } from '../utils'
import { ANIMATION_DURATION, CHART_HEIGHT } from './constants'

export interface Props {
	data: any
	height?: number
}

const PieChart = ({ data, height = CHART_HEIGHT }: Props) => {
	const [isHovering, setIsHovering] = useState(false)

	const chartRef = useRef<ChartJS>(null)

	const pieChartOptions = {
		responsive: false,
		onHover: function (event, activeElements) {
			const _isHovering = activeElements.length > 0
			const timeout = _isHovering ? 0 : ANIMATION_DURATION
			setTimeout(() => {
				setIsHovering(activeElements.length > 0)
			}, timeout)
		},
		animation: {
			duration: ANIMATION_DURATION,
			easing: 'easeOutQuart',
			onComplete: function () {
				const chart = chartRef.current
				const ctx = chart?.ctx

				if (ctx && !isHovering) {
					ctx.textAlign = 'center'
					ctx.textBaseline = 'bottom'
					ctx.fillStyle = '#fff'
					ctx.font = 'bold 12px Inter, sans-serif'

					chart.data.datasets.forEach((dataset, index) => {
						for (let i = 0; i < dataset.data.length; i++) {
							const meta = chart.getDatasetMeta(index) as any
							const model = meta.data[i]
							const midRadius = model.innerRadius + (model.outerRadius - model.innerRadius) / 2
							const startAngle = model.startAngle
							const endAngle = model.endAngle
							const midAngle = startAngle + (endAngle - startAngle) / 2
							const x = midRadius * Math.cos(midAngle)
							const y = midRadius * Math.sin(midAngle)
							const percentage = getPerc(dataset.data[i], meta.total)
							const percentageStr = `${percentage}%`

							// @ts-ignore
							if (percentage > 15) ctx.fillText(percentageStr, model.x + x, model.y + y)
						}
					})
				}
			},
		},
		plugins: {
			tooltip: {
				callbacks: {
					label: context => `${context.label}: ${getNumberAsThousandsStr(context.raw)}`,
				},
			},
		},
	}

	// @ts-ignore
	return <Pie ref={chartRef} options={pieChartOptions} data={data} height={height} />
}

export default PieChart
