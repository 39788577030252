import styled from 'styled-components'
import type { Props } from './Actions'
import * as _ from 'styles/helpers'

const StyledActions = styled.div<Partial<Props>>``

export const StyledActionsTable = styled.div<Partial<Props>>`
	.edit-button {
		display: flex;
		justify-content: flex-end;

		button {
			margin: 0;
			padding: 0 ${_.rem(4)};
			box-shadow: none;
			opacity: 0;

			&:hover {
				background-color: transparent;
			}

			&:focus {
				opacity: 1;
			}

			svg {
				margin: 0;
				font-size: 18px;
			}
		}
	}

	.table-row {
		&:hover .edit-button button {
			opacity: 1;
		}
	}

	// MUI overrides
	.MuiTableHead-root {
		border-bottom: 2px solid ${_.COLORS.tradewind_blue};
	}
	.MuiTableCell-head {
		font-weight: 600;
	}
`

export const StyledActionsHeader = styled.div`
	margin-bottom: ${_.rem(24)};
`
export const StyledSibarList = styled.div`
	margin-bottom: ${_.rem(10)};
`

export default StyledActions
