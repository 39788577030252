export default {
	LOGIN: '/login',
	REGISTER: '/register',
	EMAIL_CONFIRM: '/emailconfirm',
	REGISTER_CONFIRM: '/registerconfirm',
	FORGOT_PASSWORD: '/forgotpassword',
	CHANGE_PASSWORD: '/changepassword',
	CONFIRM_PASS_EMAIL: '/confirmpasswordrequest',

	HOME: '/',
	LOCATION_ADMIN_TOOLS: '/locationadmintools',
	PROFILE: '/profile',
	COMPANY: '/company',
	COMPANY_PROFILE: '/companyprofile',
	USER_PROFILE: '/userprofile',
	TEMPLATES: '/templates',
	TASKTEMPLATES: '/tasktemplates',
	SITEDETAILTEMPLATES: '/sitedetailtemplates',
	APPS: '/apps',
	CREDIT_CARDS: '/creditcards',

	ARCHIVE_LIBRARY: '/archivelibrary',
	CHAT: '/chats',
	COMMUNITY: '/community',
	FLEET: '/fleet',
	LOCATIONS: '/locations',
	LOCATION_PROJECTS: '/locationprojects',
	CENTERS: '/centers',
	PORTFOLIOS: '/portfolios',
	PROJECTS: '/projects',
	PARTNERAPPS: '/partnerapps',
	TASKS: '/tasks',
	PARTNERS: '/partners',
	UPDATE_TASK: `/updatetask`,

	ADMIN: '/admin',
}
