import React from 'react'
import { Grid } from '@mui/material'
import TWControls from '../controls/TWControls'
import { Form, useForm } from '../controls/useForm'

const ProjectAddPopup = ({ addProject }) => {
	const defaultValues = {
		projectName: '',
		projectDescription: '',
		projectType: '',
	}

	const initialValues = {
		...defaultValues,
	}

	const validate = () => {
		let temp = { ...errors }
		temp.projectName = values.projectName ? '' : 'This field is required.'
		temp.projectDescription = values.projectDescription ? '' : 'This field is required.'
		// temp.projectType = values.projectType ? '' : 'This field is required.'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialValues,
		true,
		validate,
	)

	const submitHandler = e => {
		e.preventDefault()
		if (validate()) {
			addProject(values, resetForm)
		}
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TWControls.Input
						name="projectName"
						label="Project Name"
						value={values.projectName}
						onChange={handleInputChange}
						error={errors.projectName}
					/>
				</Grid>
				<Grid item xs={12}>
					<TWControls.Input
						name="projectDescription"
						label="Description"
						value={values.projectDescription}
						onChange={handleInputChange}
						error={errors.projectDescription}
					/>
				</Grid>
				<Grid item xs={12}>
					<TWControls.Input
						name="projectType"
						label="Project Type"
						value={values.projectType}
						onChange={handleInputChange}
						error={errors.projectType}
					/>
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<TWControls.Button size="small" type="submit" text="Add Project" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default ProjectAddPopup
