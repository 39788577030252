// @ts-ignore
export const getPerc = (a, b) => +parseFloat((a / b) * 100).toFixed(2)

const fixedNum = n => +parseFloat(n).toFixed(2)
const asThousands = n => n / 1000
const getNumberAsThousands = n => fixedNum(asThousands(n))

export const getNumberAsThousandsStr = n => {
	const numStr = `${getNumberAsThousands(n)}`
	if (numStr.split('.')[1] === '00') return `${n}k`
	return `${+numStr}k`
}
