import { rem } from './_functions'
import { BP, COLORS } from './_variables'

// MEDIA QUERIES
const createMQ = ({ min, max }: { min?: number | undefined; max?: number | undefined }) => {
	if (!min && !max) return ''
	let MQ = 'only screen and'
	if (min && !max) return `${MQ} (min-width: ${rem(min)})`
	if (!min && max) return `${MQ} (max-width: ${rem(max - 1)})`
	if (min && max) return `${MQ} (min-width: ${rem(min)}) and (max-width: ${rem(max - 1)})`
	return MQ
}

export const mediaQueries = {
	mobile_only: createMQ({ max: BP.small }),
	small: createMQ({ min: BP.small }),
	small_and_below: createMQ({ max: BP.medium }),
	small_only: createMQ({ min: BP.small, max: BP.medium }),
	medium: createMQ({ min: BP.medium }),
	medium_and_below: createMQ({ max: BP.large }),
	medium_only: createMQ({ min: BP.medium, max: BP.large }),
	large: createMQ({ min: BP.large }),
	large_and_below: createMQ({ max: BP.xlarge }),
	large_only: createMQ({ min: BP.large, max: BP.xlarge }),
	xlarge: createMQ({ min: BP.xlarge }),
}

export const MQ = {
	...mediaQueries,
	nav: mediaQueries.large,
	before_nav: mediaQueries.medium_and_below,
	mobile_and_below: mediaQueries.medium_and_below,
	tablet_and_below: mediaQueries.large_and_below,
}

const showMqHelper = true
export const MediaQueryHelper = `
body {
	${
		showMqHelper &&
		process.env.NODE_ENV === 'development' &&
		`
		&:after {
			position: fixed;
			bottom: ${rem(16)};
			right: ${rem(16)};
			color: ${COLORS.white};
			background-color: ${COLORS.black};
			padding: ${rem(12)};
			border-radius: ${rem(4)};
			font-family: monospace;
			font-size: ${rem(16)};
			line-height: 1;
			z-index: 9999;

			${Object.entries(mediaQueries).reduce((prev, next) => {
				if (!next[1].includes('min') || next[1].includes(') and (')) return prev
				return (prev += `@media ${next[1]} { content: "${next[0]}"; }`)
			}, '')}
		}
	`
	};
}
`
