import { rem } from './_functions'
import { MQ } from './_mediaQueries'

/*
	mixin with args example
	==
	export const wh = (w: number, h = w) => `
		width: ${w};
		height: ${h};
	`
*/

// vertically centered
export const vc = `
	display: flex;
	align-items: center;
`

// vertically and horizontally centered
export const vhc = `
	${vc}
	justify-content: center;
`

// Remove margin-top from :first-child
// and margin-bottom from :last-child
export const mod = `
	> *:first-child {
		margin-top: 0 !important;
	}
	> *:last-child {
		margin-bottom: 0 !important;
	}
`

export const section_padding_y_condensed = `
	padding-top: ${rem(80)};
	padding-bottom: ${rem(80)};
`

export const section_padding_y_full = `
	padding-top: ${rem(128)};
	padding-bottom: ${rem(128)};
`

export const section_padding_y = `
	${section_padding_y_condensed};

	@media ${MQ.small} {
		${section_padding_y_full};
	}
`

export const container_padding_x = `
	padding-left: ${rem(24)};
	padding-right: ${rem(24)};
`

export const full_height = `
	min-height: 100vh;
`

export const base_box_shadow = `
	box-shadow: 0 ${rem(6)} ${rem(19)} ${rem(-3)} rgb(0 0 0 / 20%);
`

export const ul_reset = `
	margin: 0;
	padding: 0;

	li {
		list-style: none;
	}
`
