import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaTasks } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import {
	Avatar,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
} from '@mui/material'
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material/'
import Layout from 'components/Layout'
import Message from '../Message'
import { initializeRecentTasks } from 'slices/tasksSlice'

function SidebarPendingTasksProject() {
	const dispatch = useDispatch()

	const { userInfo: user } = useSelector(state => state.userLogin)
	const userid = user._id

	const {
		entities: taskEntities,
		ids: taskIds,
		isLoading: tasksLoading,
		isInitialized: tasksInitialized,
		error,
	} = useSelector(state => state.tasks)
	const tasks = taskIds.map(id => taskEntities[id])

	useEffect(() => {
		dispatch(initializeRecentTasks({ userid }))
	}, [dispatch])

	const isEmpty = tasks?.length === 0 || !tasks.some(task => task.packageType === 'location')

	return (
		<div>
			<Layout.SidebarItem title="Pending Tasks" icon={<FaTasks />} isEmpty={isEmpty} noPadding>
				{tasksLoading ? (
					<Layout.SidebarItemLoading />
				) : error ? (
					<Message variant="danger">{error}</Message>
				) : isEmpty ? (
					<span>No Pending Tasks</span>
				) : (
					<List dense>
						{tasks.map((task, index) => {
							if (task.packageType === 'location') {
								return (
									<ListItem
										key={index}
										component={Link}
										to={`/locations/${task.packageid._id}/projects/${task.projectid}/tasks`}
									>
										<ListItemAvatar>
											<Avatar src={task.assignedTo.image} />
										</ListItemAvatar>
										<ListItemText primary={task.taskName} secondary={task.packageid.assetName} />
										<ListItemSecondaryAction className="secondary-action">
											<IconButton edge="end" aria-label="delete">
												<ChevronRightIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								)
							}
							return null
						})}
					</List>
				)}
			</Layout.SidebarItem>
		</div>
	)
}

export default SidebarPendingTasksProject
