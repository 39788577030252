import React, { useState } from 'react'
import TWControls from 'components/controls/TWControls'
import { Form } from 'components/controls/useForm'
import { Checkbox, FormGroup, FormControlLabel, Grid } from '@mui/material'

const centerOptions = [
	{ selectId: '1', selectValue: 'Center 1' },
	{ selectId: '2', selectValue: 'Center 2' },
	{ selectId: '3', selectValue: 'Center 3' },
	{ selectId: '4', selectValue: 'Center 4' },
]

const BulkLocationsAddPopup = ({ handlePostBulkLocations, locationCount, returnStatus }) => {
	const [active, setActive] = useState(false)
	const [fleet, setFleet] = useState(false)
	const [tenant, setTenant] = useState(false)

	const submitHandler = e => {
		handlePostBulkLocations(active)
	}

	const handleActiveChange = e => {
		console.log(`e.target.checked: `, e.target.checked)

		setActive(e.target.checked)
	}
	const handleFleetChange = e => {
		console.log(`e.target.checked: `, e.target.checked)

		setFleet(e.target.checked)
	}
	const handleTenantChange = e => {
		console.log(`e.target.checked: `, e.target.checked)

		setTenant(e.target.checked)
	}

	return (
		<Form sx={{ m: 1, minWidth: 360 }} onSubmit={submitHandler}>
			<Grid container spacing={2}>
				<Grid item className="newLocation__imageHolder" xs={12}>
					<h3 xs={12}>{`Posting ${locationCount}  Locations.`}</h3>
				</Grid>
				<Grid item xs={12}>
					<p>
						{`There will be ${locationCount} locations posted as individual locations to your Locations Catalogue. By default all locations will be uploaded with the status shown below as:`}{' '}
					</p>
					<div className="newLocation__imageHolder">
						<FormGroup className="newLocation__imageHolder">
							<FormControlLabel
								control={
									<Checkbox
										checked={active}
										onChange={handleActiveChange}
										inputProps={{ 'aria-label': 'controlled' }}
									/>
								}
								label={active ? `Active` : `In Active`}
							/>
						</FormGroup>
					</div>
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<TWControls.Button size="small" type="submit" text="Post" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default BulkLocationsAddPopup
