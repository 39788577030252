import styled from 'styled-components'
import * as _ from 'styles/helpers'
import type { Props } from './ChatWindowHeader'

const StyledChatWindowHeader = styled.div<Partial<Props>>`
	display: flex;
	align-items: center;
	grid-gap: ${_.rem(8)};
	justify-content: space-between;
	border-bottom: ${_.rem(1)} solid rgba(0, 0, 0, 0.12);
	padding-bottom: ${_.rem(8)};

	.ChatWindowHeader__label {
		margin: 0;
		font-size: ${_.rem(18)};
		line-height: 1.125;
		font-weight: 600;
	}
`

export default StyledChatWindowHeader
