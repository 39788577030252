import React, { useState } from 'react'
import styled from 'styled-components'

export function useForm(initialValues, validateOnChange = false, validate) {
	const [values, setValues] = useState(initialValues)
	const [errors, setErrors] = useState({})

	const handleInputChange = e => {
		const { name, value } = e.target
		setValues({
			...values,
			[name]: value,
		})
		if (validateOnChange) validate({ [name]: value })
	}

	const _handleInputChange = ({ name, value }) => {
		setValues({
			...values,
			[name]: value,
		})
		if (validateOnChange) validate({ [name]: value })
	}

	const handleCheckboxChange = (e, id) => {
		const { name, checked } = e.target

		Object.keys(values).forEach(i => {
			if (values[i].id === id) values[i][name] = checked
		})

		setValues({
			...values,
		})
	}

	const handleRadioChange = (e, id) => {
		const { name, value } = e.target

		Object.keys(values).forEach(i => {
			if (values[i].id === id) {
				if (values[i][name] === value) values[i][name] = ''
				else values[i][name] = value
			}
		})

		setValues({
			...values,
		})
	}

	const resetForm = () => {
		setValues(initialValues)
		setErrors({})
	}

	return {
		values,
		setValues,
		errors,
		setErrors,
		handleInputChange,
		_handleInputChange,
		handleCheckboxChange,
		handleRadioChange,
		resetForm,
	}
}

const StyledDiv = styled.div`
	& .MuiFormControl-root {
		width: 100%;
		margin-bottom: 1.5rem;
	}
`

export function Form(props) {
	const { children, ...other } = props
	return (
		<StyledDiv>
			<form autoComplete="off" {...other}>
				{props.children}
			</form>
		</StyledDiv>
	)
}
