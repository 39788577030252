import React from 'react'
import { useChat, ChatMessageList } from 'features/Chat'

const ChatMessageListContainer = () => {
	const {
		state: { hasCurrentMessages, currentMessages },
	} = useChat()

	if (!hasCurrentMessages) return null

	return <ChatMessageList currentMessages={currentMessages} />
}

export default ChatMessageListContainer
