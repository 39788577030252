import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FaPaperclip, FaTasks } from 'react-icons/fa'
import Layout from 'components/Layout'

import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material'
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material/'
import { initializeTemplates } from 'slices/templateSlice'

function SidebarTemplatesAccordian() {
	const dispatch = useDispatch()

	const {
		entities: templateEntities,
		ids: templateIds,
		isInitialized: templatesInitialized,
	} = useSelector(state => state.templates)
	const newTemplates = templateIds.map(id => templateEntities[id])

	const taskTemplates = newTemplates.filter(item => item.templateType === 'task')
	const siteDetailTemplates = newTemplates.filter(item => item.templateType !== 'task')

	useEffect(() => {
		dispatch(initializeTemplates())
	}, [dispatch])

	return (
		<Layout.SidebarItem title="Templates" hasNoMaxHeight>
			{templatesInitialized && (
				<React.Fragment>
					<h5 className="SidebarItem__section-title">
						<FaTasks /> Tasks
					</h5>
					<List dense disablePadding>
						{taskTemplates.map(template => (
							<ListItem
								key={template._id}
								component={Link}
								to={`/templates/${template._id}/${template.templateType}`}
							>
								<ListItemText
									primary={template.templateName}
									secondary={
										template.description.length > 48
											? template.description.substring(0, 48) + '...'
											: template.description
									}
									style={{ textDirection: 'none' }}
								/>
								<ListItemSecondaryAction className="secondary-action">
									<IconButton edge="end" aria-label="delete">
										<ChevronRightIcon />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						))}
					</List>
				</React.Fragment>
			)}
			{templatesInitialized && (
				<React.Fragment>
					<h5 className="SidebarItem__section-title">
						<FaPaperclip /> Site Details
					</h5>
					<List dense disablePadding>
						{siteDetailTemplates.map(template => (
							<ListItem
								key={template._id}
								component={Link}
								to={`/templates/${template._id}/${template.templateType}`}
							>
								<ListItemText
									primary={template.templateName}
									secondary={
										template.description.length > 48
											? template.description.substring(0, 48) + '...'
											: template.description
									}
								/>
								<ListItemSecondaryAction className="secondary-action">
									<IconButton edge="end" aria-label="delete">
										<ChevronRightIcon />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						))}
					</List>
				</React.Fragment>
			)}
		</Layout.SidebarItem>
	)
}

export default SidebarTemplatesAccordian
