import React, { useEffect, useState } from 'react'
import { Avatar, Grid, TableBody, TableCell, TableRow } from '@mui/material'
import TWControls from '../controls/TWControls'
import { Radio } from '@mui/material'
import { Form, useForm } from '../controls/useForm'
import useTableNoPage from 'components/controls/useTableNoPage'

const headerCells = [
	{ id: 'image', label: '' },
	{ id: 'name', label: 'Partner' },
	{ id: 'company', label: 'Company' },
	{ id: 'email', label: 'Email' },
	{ id: 'primary', label: 'Primary' },
	{ id: 'cc', label: 'CC' },
	{ id: 'bcc', label: 'BCC' },
]

const CommunicationPartners = ({ partners, handlePartnerComms, recordForEdit }) => {
	const initialValues = {
		...partners,
	}

	const [filterFn, setFilterFn] = useState({
		fn: items => {
			return items
		},
	})

	const validate = () => {
		let temp = { ...errors }

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleRadioChange, resetForm } = useForm(
		initialValues,
		true,
		validate,
	)

	const records = partners
	const { TblContainer, TblHeader, TblPagination } = useTableNoPage(records, headerCells, filterFn)

	useEffect(() => {
		if (partners != null) setValues({ ...partners })
	}, [partners, setValues])

	const submitHandler = e => {
		e.preventDefault()
		handlePartnerComms(resetForm)
	}
	return (
		<Form onSubmit={submitHandler}>
			<Grid container>
				<>
					<Grid item>
						<div>
							<TblContainer>
								<TblHeader />
								<TableBody>
									{Object.keys(values).map(i => (
										<TableRow key={i}>
											<TableCell>
												<Avatar src={values[i].image}></Avatar>
											</TableCell>
											<TableCell>{values[i].name}</TableCell>
											<TableCell>{values[i].company}</TableCell>
											<TableCell>{values[i].email}</TableCell>
											<TableCell>
												<Radio
													checked={values[i].emailOption === 'primary'}
													onClick={e => handleRadioChange(e, values[i].id)}
													value="primary"
													name="emailOption"
													inputProps={{ 'aria-label': 'Primary' }}
												/>
											</TableCell>
											<TableCell>
												<Radio
													checked={values[i].emailOption === 'cc'}
													onClick={e => handleRadioChange(e, values[i].id)}
													value="cc"
													name="emailOption"
													inputProps={{ 'aria-label': 'CC' }}
												/>
											</TableCell>
											<TableCell>
												<Radio
													checked={values[i].emailOption === 'bcc'}
													onClick={e => handleRadioChange(e, values[i].id)}
													value="bcc"
													name="emailOption"
													inputProps={{ 'aria-label': 'BCC' }}
												/>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</TblContainer>
						</div>
						<div className="newLocation__imageHolder">
							<TWControls.Button size="small" type="submit" text="Compose"></TWControls.Button>
						</div>
					</Grid>
				</>
			</Grid>
		</Form>
	)
}

export default CommunicationPartners
