import styled from 'styled-components'
import type { Props } from './Message'
import * as _ from 'styles/helpers'

const StyledMessage = styled.div<Partial<Props>>`
	margin-bottom: ${_.rem(16)};
	padding: ${_.rem(8)} ${_.rem(16)};
	border-radius: ${_.rem(_.BASE_BORDER_RADIUS)};
	color: ${props => (props.variant === 'warning' ? _.COLORS.rich_black : _.COLORS.white)};

	/* variants */
	background-color: ${props => props.variant === 'primary' && _.COLORS.primary};
	background-color: ${props => props.variant === 'success' && _.COLORS.success};
	background-color: ${props => props.variant === 'danger' && _.COLORS.danger};
	background-color: ${props => props.variant === 'warning' && _.COLORS.warning};
	background-color: ${props => props.variant === 'info' && _.COLORS.info};
`

export default StyledMessage
