import { axiosInterceptor, apiCall } from 'services'
import { API_BASE_URL } from './constants'

const authService = () => {
	const api = axiosInterceptor(API_BASE_URL)

	// READ
	const login = async (payload: { email: string; password: string }) =>
		await apiCall(api.post('/users/login', payload))

	const getUserDetails = async (id: string) => await apiCall(api.get(`/users/${id}`))

	// UPDATE
	const updateUser = async (user: any) => await apiCall(api.put(`/users`, user))

	//OTHER
	const registerNewUser = async (payload: {
		name: string
		firstName: string
		email: string
		password: string
	}) => await apiCall(api.post('/users', payload))

	return {
		login,
		getUserDetails,
		updateUser,
		registerNewUser,
	}
}

export default authService
