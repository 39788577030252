import React from 'react'
import { useChat, ChatWindowHeader } from 'features/Chat'

const ChatWindowHeaderContainer = () => {
	const {
		state: { hasCurrentMessages, currentChatHeader },
	} = useChat()

	if (!hasCurrentMessages) return null

	return <ChatWindowHeader label={currentChatHeader?.label} />
}

export default ChatWindowHeaderContainer
