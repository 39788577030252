import React from 'react'
import { Grid } from '@mui/material'
import TWControls from '../controls/TWControls'
import { Form } from '../controls/useForm'

const LocationDeletePopup = ({ deleteLocation, location }) => {
	const submitHandler = e => {
		deleteLocation(location)
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<h2 className="newLocation__imageHolder">WARNING! </h2>
				</Grid>
				<Grid item className="newLocation__imageHolder" xs={12}>
					<h3 xs={12}>{`Deleted locations are not recoverable!`}</h3>
				</Grid>
				<Grid item xs={12}>
					<p>
						{`This will permanately delete the ${location.city}, ${location.stateProvince} ${location.assetName} location record. All associated projects, notes, partner
						relationships, tasks, files and images will be permanantly deleted as well.`}{' '}
					</p>
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<TWControls.Button color="secondary" size="small" type="submit" text="Delete" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default LocationDeletePopup
