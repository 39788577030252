import styled from 'styled-components'
import type { CardProps } from './Card'
import * as _ from 'styles/helpers'

const Card = styled.div<Partial<CardProps>>`
	display: flex;
	flex-direction: column;
	${({ noElevation }) =>
		!noElevation &&
		`box-shadow: 0 0 0 ${_.rem(1)} ${_.COLORS.gray_300}, rgb(10 10 10 / 15%) 0 0.5em 1em -0.125em`};
	border-radius: ${_.rem(5)};
	/* overflow: hidden; */

	${({ header }) => header && `border-top: ${_.rem(4)} solid ${_.COLORS.tradewind_blue};`}
`

const Header = styled.div`
	${_.MIXINS.vc};
	justify-content: space-between;
	padding: 0 ${_.rem(16)};
	min-height: ${_.rem(56)};

	h2 {
		${_.MIXINS.vc};
		${_.TYPOGRAPHY.h2};
		font-size: ${_.rem(22)};
		margin: 0;
		grid-gap: ${_.rem(8)};
	}

	p {
		margin: 0;
		font-size: ${_.rem(11)};
		font-style: italic;
	}
`

const Content = styled.div<Partial<CardProps>>`
	${_.MIXINS.mod}
	flex: 1;
	padding: ${({ noPadding }) => (!noPadding ? `${_.rem(16)}` : 0)};
	${({ header }) => header && `padding-top: 0`};
	${({ isCentered }) =>
		isCentered &&
		`
      ${_.MIXINS.vhc}
      flex-direction: column;`}
	aspect-ratio: ${({ isSquare }) => isSquare && `1 / 1`};
	aspect-ratio: ${({ isLandscape }) => isLandscape && `16 / 9`};
	text-align: ${({ isCenteredText }) => (isCenteredText ? 'center' : 'left')};
`

const Actions = styled.div`
	${_.MIXINS.vhc}
	text-align: center;
	padding: ${_.rem(12)} ${_.rem(16)};
	border-top: ${_.rem(1)} solid ${_.COLORS.gray_100};

	> *:last-child {
		margin-right: 0;
	}
`

const Image = styled.div<{ src: string }>`
	height: 0;
	padding-top: 75%;
	margin: ${_.rem(-16)} ${_.rem(-16)} ${_.rem(16)} ${_.rem(-16)};
	background-size: cover;
	background-position: center;
	background-image: url(${({ src }) => src});
`

const Title = styled.h3`
	margin: 0 0 ${_.rem(4)};
	font-size: ${_.rem(18)};
	font-weight: 600;
	color: ${_.COLORS.black};
`

const Location = styled.p`
	margin: 0;
	font-size: ${_.rem(14)};
	line-height: 1.4;
	color: ${_.COLORS.gray_700};
`
const Description = styled.p`
	margin: 0;
	font-size: ${_.rem(12)};
	line-height: 1.4;
	color: ${_.COLORS.gray_500};
`

const StyledCard = {
	Card,
	Header,
	Content,
	Actions,
	Image,
	Title,
	Location,
	Description,
}

export default StyledCard
