import React from 'react'
import { Grid } from '@mui/material'
import TWControls from '../controls/TWControls'
import { Form } from '../controls/useForm'

const ProjectArchivePopup = ({ archiveProject, project }) => {
	const submitHandler = e => {
		archiveProject(project)
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<p>
						Archived projects are removed from the active project list but can be recoverd. All of
						the project's notes, partners, tasks, files and images are preserved and will be
						restored when the archived project is recovered.{' '}
					</p>
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<TWControls.Button size="small" type="submit" text="Archive" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default ProjectArchivePopup
