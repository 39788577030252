import React, { useEffect } from 'react'
import { Link, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FaLongArrowAltRight } from 'react-icons/fa'
import useMediaQuery from 'hooks/useMediaQuery'
import * as _ from 'styles/helpers'
// import Logo from 'components/Logo'
import LoginForm from './LoginForm'
import routes from 'constants/routes'
import ChangePassword from './ChangePassword'
import EmailConfirmForm from './EmailConfirm'
import ForgotPassword from './ForgotPassword'
import RegistrationForm from './RegistrationForm'
import StyledLoginScreen from './LoginScreen.style'
import RegisterConfirmForm from './RegisterConfirm'

export interface Props {
	className?: string
}

const LoginScreen = ({ className = '', ...props }: Props) => {
	const isMobile = useMediaQuery(_.MQ.medium_and_below)

	// props from react-router
	const { location, history }: any = props

	const userLogin = useSelector((state: any) => state.userLogin)
	const { userInfo } = userLogin

	const redirect = location.search ? location.search.split('=')[1] : routes.HOME
	useEffect(() => {
		if (userInfo) {
			history.push(redirect)
		}
	}, [history, userInfo, redirect])

	const isLoggedIn = userInfo !== null && userInfo !== undefined

	if (isLoggedIn) return null

	return (
		<StyledLoginScreen data-testid="LoginScreen" className={className} isMobile={isMobile}>
			<div className="wrapper">
				{/* <div className="wrapper__item wrapper__item--branding">
					<Logo isGlyph size={isMobile ? 100 : undefined} />
				</div> */}
				<div className="wrapper__item">
					<div className="form">
						<Route path={`${routes.LOGIN}`}>
							<div></div>
							<LoginForm />
							<div className="form__footer">
								<Link to={`${routes.REGISTER}`} className="form__footer-link">
									Create your account <FaLongArrowAltRight />
								</Link>
							</div>
						</Route>
						<Route path={`${routes.REGISTER}`}>
							<div></div>
							<RegistrationForm />
							<div className="form__footer">
								Have an account?&nbsp;
								<Link to={routes.LOGIN} className="form__footer-link">
									Login
								</Link>
							</div>
						</Route>
						<Route path={`${routes.FORGOT_PASSWORD}`}>
							<div></div>
							<ForgotPassword />
							<div className="form__footer">
								Don't have an account?
								<Link to={`${routes.REGISTER}`} className="form__footer-link">
									Create your account <FaLongArrowAltRight />
								</Link>
							</div>
						</Route>
						<Route path={`${routes.CHANGE_PASSWORD}`}>
							<div></div>
							<ChangePassword />
							<div className="form__footer">
								Have an account?&nbsp;
								<Link to={routes.LOGIN} className="form__footer-link">
									Login
								</Link>
							</div>
						</Route>
						<Route path={`${routes.EMAIL_CONFIRM}`}>
							<div></div>
							<EmailConfirmForm />
							<div className="form__footer">
								Have an account?&nbsp;
								<Link to={routes.LOGIN} className="form__footer-link">
									Login
								</Link>
							</div>
						</Route>
						<Route path={`${routes.REGISTER_CONFIRM}`}>
							<div></div>
							<RegisterConfirmForm />
							<div className="form__footer">
								Have an account?&nbsp;
								<Link to={routes.LOGIN} className="form__footer-link">
									Login
								</Link>
							</div>
						</Route>
					</div>
				</div>
			</div>
		</StyledLoginScreen>
	)
}

export default LoginScreen
