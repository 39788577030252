import React from 'react'
import { CardGrid, AppCard } from 'components/Card'

const PartnerApps_Index = ({ partnerApps }) => {
	const submitHandler = id => {
		// if (window.confirm(`Are you sure? - ${id}`)) {
		// 	dispatch(addMyPartnerApp({ partnerApp: id }))
		// }
	}

	return (
		<CardGrid>
			{partnerApps.map(app => (
				<AppCard
					key={app._id}
					id={app._id}
					name={app.appName}
					description={app.description}
					image={app.image}
					// subscribedTo={app.subscribedTo}
					subscribeLink={app.subscribeLink}
					webSite={app.webSite}
					isSubscribed={app.subscribedTo}
					// submitHandler={() => submitHandler(app._id)}
				/>
			))}
		</CardGrid>
	)
}

export default PartnerApps_Index
