import React from 'react'
import Layout from 'components/Layout'
import Tasks from './components/Tasks/Tasks'
import SidebarTasksRecent from 'components/Sidebars/SidebarTasksRecent'

const Tasks_Index = () => {
	return (
		<Layout>
			<Layout.Page>
				<Layout.Sidebar>
					<SidebarTasksRecent />
				</Layout.Sidebar>
				<Layout.Body>
					<Layout.PageHeader>
						<h2>Tasks</h2>
					</Layout.PageHeader>
					<Tasks />
				</Layout.Body>
			</Layout.Page>
		</Layout>
	)
}

export default Tasks_Index
