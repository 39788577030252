import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import useTableNoPage from 'components/controls/useTableNoPage'
import LocationAddNew from 'screens/locations/components/LocationAddNew'
import Popup from 'components/Popup'
import { createLocation, initializeActiveLocations } from 'slices/locationSlice'
import { addLocationToPortfolio, updatePortfolio } from 'slices/portfolioSlice'
import Layout from 'components/Layout/Layout'
import useMediaQuery from 'hooks/useMediaQuery'
import * as _ from 'styles/helpers'
import PortfolioAddLocationsTable from './components/Portfolios/PortfolioAddLocationsTable'

const headerCells = [
	{ id: 'Image', label: 'Image', disableSorting: true },
	{ id: 'assetName', label: 'Location' },
	{ id: 'address', label: 'Address' },
	{ id: 'city', label: 'City' },
	{ id: 'stateProvince', label: 'State' },
	{ id: 'postalCode', label: 'Zip Code' },
	{ id: 'add_delete', label: 'Add', disableSorting: true },
]

const PortfolioAddLocations = ({ history, match }) => {
	const { id: portfolioId } = useParams()

	const dispatch = useDispatch()

	const searchInputRef = useRef(null)

	const [openPopup, setOpenPopup] = useState(false)
	const [searchInputValue, setSearchInputValue] = useState('')
	const [searchCityInputValue, setSearchCityInputValue] = useState('')
	const [searchStateInputValue, setSearchStateInputValue] = useState('')

	const [filterFn, setFilterFn] = useState({
		fn: items => {
			return items
		},
	})

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo: user } = userLogin
	const userId = user._id

	const { locations, isInitialized: locationsInitialized } = useSelector(state => {
		const locationIds = state.locations.ids
		const locations = locationIds.map(id => state.locations.entities[id])
		const { error, isInitialized } = state.locations
		return { locations, error, isInitialized }
	})

	const { portfolio, isInitialized: success } = useSelector(state => {
		return {
			isLoading: state.portfolios.isLoading,
			portfolio: state.portfolios.entities[portfolioId] || {},
			error: state.portfolios.error,
			isInitialized: state.portfolios.isInitialized,
		}
	})

	useEffect(() => {
		if (!locationsInitialized) {
			dispatch(initializeActiveLocations())
		}
	}, [success, dispatch])

	const addLocation = async locationId => {
		if (!portfolioId) {
			window.alert('You must enter a Portfolio Name')
		} else {
			await dispatch(addLocationToPortfolio({ portfolioId, locationId }))
		}
	}

	var portfolioLocations = []
	if (portfolio.locations) {
		portfolioLocations = portfolio.locations.map(item => {
			return {
				_id: item.location._id,
			}
		})
	}

	const filteredLocations = locations.filter(location => {
		return !portfolioLocations.some(f => {
			return f._id === location._id
		})
	})

	const recordCount = filteredLocations.length
	const records = filteredLocations

	const cityLocations = records.filter((obj, index) => {
		return index === records.findIndex(o => obj.id === o.id && obj.city === o.city)
	})

	const cityList = cityLocations
		.map(location => {
			return {
				selectValue: location.city,
			}
		})
		.sort((a, b) => a.selectValue.localeCompare(b.selectValue))

	const stateLocations = records.filter((obj, index) => {
		return (
			index === records.findIndex(o => obj.id === o.id && obj.stateProvince === o.stateProvince)
		)
	})

	const stateList = stateLocations
		.map(location => {
			return {
				selectValue: location.stateProvince,
			}
		})
		.sort((a, b) => a.selectValue.localeCompare(b.selectValue))

	const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTableNoPage(
		records,
		headerCells,
		filterFn,
	)

	const handleCitySearch = e => {
		const { value } = e.target
		setSearchCityInputValue(value)
		setFilterFn({
			fn: items => {
				if (value === '') return items
				return items.filter(x => x.city.toLowerCase().includes(value.toLowerCase()))
			},
		})
		// set focus to search input after rerender
		setTimeout(() => {
			searchInputRef.current.focus()
		}, 0)
	}

	const handleStateSearch = e => {
		const { value } = e.target
		setSearchStateInputValue(value)
		setFilterFn({
			fn: items => {
				if (value === '') return items
				return items.filter(x => x.stateProvince.toLowerCase().includes(value.toLowerCase()))
			},
		})
		// set focus to search input after rerender
		setTimeout(() => {
			searchInputRef.current.focus()
		}, 0)
	}

	const handleSearch = e => {
		const { value } = e.target
		setSearchInputValue(value)
		setFilterFn({
			fn: items => {
				if (value === '') return items
				return items.filter(x => x.assetName.toLowerCase().includes(value.toLowerCase()))
			},
		})
		// set focus to search input after rerender
		setTimeout(() => {
			searchInputRef.current.focus()
		}, 0)
	}

	const goBack = () => {
		history.push(`/portfolios/${portfolioId}/locations`)
	}

	const locationAdd = async (location, resetForm) => {
		const newLocation = location

		newLocation.owner = userId

		if (newLocation.locationType) {
			const locationType = newLocation.locationType.toLocaleLowerCase()
			const newAssetType = locationType.split(' ')

			if (newAssetType[1]) newLocation.assetType = newAssetType[1]
		}
		await dispatch(createLocation({ newLocation }))
		dispatch(initializeActiveLocations())
		resetForm()
		setOpenPopup(false)
	}

	return (
		<div>
			<React.Fragment>
				<Layout.PageToolbar_Filter
					buttonText="Add New Location"
					buttonOnClick={() => setOpenPopup(true)}
					searchInputRef={searchInputRef}
					searchInputValue={searchInputValue}
					searchInputOnChange={handleSearch}
					cityInputValue="City"
					cityInputOnChange={handleCitySearch}
					cityList={cityList}
					stateInputValue="State"
					stateInputOnChange={handleStateSearch}
					stateList={stateList}
				/>

				<PortfolioAddLocationsTable
					locations={recordsAfterPagingAndSorting()}
					addLocation={addLocation}
					TblContainer={TblContainer}
					TblHeader={TblHeader}
					TblPagination={TblPagination}
					recordCount={recordCount}
					userId={userId}
				/>
			</React.Fragment>
			{openPopup && (
				<Popup title="New Location" openPopup={openPopup} setOpenPopup={setOpenPopup}>
					<LocationAddNew locationAdd={locationAdd} user={userId} />
				</Popup>
			)}
		</div>
	)
}

export default PortfolioAddLocations
