import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import MDTLogo from 'images/MDT_FullLogo.png'
import swal from 'sweetalert'
import { login, registerNewUser } from 'slices/authSlice'
import Button from 'components/Button'
import Message from 'components/Message'

const validationSchema = Yup.object().shape({
	name: Yup.string().required('Full name is Required'),
	firstName: Yup.string().required('First name is Required'),
	email: Yup.string().email('Invalid email').required('Email is required'),
	password: Yup.string().required('Password is required'),
	passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

const EmailConfirmForm = () => {
	const [errorMessage, setErrorMessage] = useState('')
	const dispatch = useDispatch()

	const userLogin = useSelector((state: any) => state.userLogin)
	const { loading: userLoginLoading } = userLogin

	const url = new URL(window.location.href)
	const urlParams = new URLSearchParams(url.search) // We might need a polyfill to use this

	const initialValues = {
		name: urlParams.get('name') || '',
		firstName: urlParams.get('firstname') || '',
		email: urlParams.get('email') || '',

		password: '',
		passwordConfirm: '',
	}

	function handleFirstName(name, setFieldValue) {
		if (name?.length) {
			const first = name.replace(/ .*/, '')
			setFieldValue('firstName', first)
		}
	}

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={async (values, { setSubmitting }) => {
				const { name, firstName, email, password } = values
				if (values.password !== values.passwordConfirm) {
					setErrorMessage('Passwords do not match')
				} else {
					await dispatch(registerNewUser({ name, firstName, email, password }))

					swal({
						title: `New Account Created!`,
						text: `Congratulations! Your new account with MYDealTeams has been verified. The final step in the account creation process will be to complete your user profile.`,
						icon: 'success',
					})

					await dispatch(login(values))
				}

				setSubmitting(false)
			}}
		>
			{({ isSubmitting, setFieldValue, errors }) => {
				/*
          TODO: handle form errors in UI
        */
				return (
					<Form>
						<img
							style={{ display: 'block', margin: '0 auto 16px' }}
							src={MDTLogo}
							alt="My Deal Teams logo"
							width={360}
						/>
						<h1 className="form-title">Create a new account</h1>
						{errorMessage && <Message variant="danger">{errorMessage}</Message>}
						<div className="inputs">
							<div className="input">
								<Field
									type="text"
									name="name"
									placeholder="Full Name"
									onBlur={e => handleFirstName(e.target.value, setFieldValue)}
								/>
							</div>
							<div className="input">
								<Field type="text" name="firstName" placeholder="First Name" />
							</div>
							<div className="input">
								<Field type="email" name="email" placeholder="Email" />
							</div>
							<div className="input">
								<Field type="password" name="password" placeholder="Password" />
							</div>
							<div className="input">
								<Field type="password" name="passwordConfirm" placeholder="Confirm Password" />
							</div>
						</div>
						<div className="actions">
							<Button type="submit" isLarge disabled={isSubmitting || userLoginLoading}>
								{isSubmitting || userLoginLoading ? 'Submitting...' : 'Create Account'}
							</Button>
						</div>
					</Form>
				)
			}}
		</Formik>
	)
}

export default EmailConfirmForm
