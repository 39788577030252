import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import store from './store'
import { DetailsProvider } from 'features/Details/DetailsContext'
import { TemplateDetailsProvider } from 'features/TemplateDetails/TemplateDetailsContext'
import { TasksProvider } from 'features/Tasks/TasksContext'
import App from './components/App'
import { GlobalStyles } from './styles/globalStyles'
import * as _ from 'styles/helpers'

const container = document.getElementById('root')
const root = createRoot(container!)

export const theme = createTheme({
	typography: {
		fontFamily: _.FONTS.base_font_family,
	},
})

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<Route>
				<ThemeProvider theme={theme}>
					<GlobalStyles />
					<DetailsProvider>
						<TemplateDetailsProvider>
							<TasksProvider>
								<App />
							</TasksProvider>
						</TemplateDetailsProvider>
					</DetailsProvider>
				</ThemeProvider>
			</Route>
		</BrowserRouter>
	</Provider>,
)
