import styled from 'styled-components'
import * as _ from 'styles/helpers'
import type { Props } from './ChatInput'

const StyledChatInput = styled.div<Partial<Props>>`
	margin-top: auto;

	> form {
		display: flex;
		grid-gap: ${_.rem(8)};
	}

	.ChatInput__input {
		flex: 1;
	}

	.ChatInput__submit-button {
		margin: 0;
		padding: ${_.rem(8)};

		svg {
			margin: 0;
			font-size: ${_.rem(24)};
		}
	}
`

export default StyledChatInput
