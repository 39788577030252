import React, { forwardRef } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@mui/material/TextField'
import { Close as CloseIcon } from '@mui/icons-material'
import Button from 'components/Button'
import StyledChatAddressBar from './ChatAddressBar.style'

export interface Props {
	className?: string
	myPartners: { name: string }[]
	handleAutocomplete: any
	handleCloseNewChat: Function
}

const ChatAddressBar = forwardRef(
	({ className = '', myPartners, handleAutocomplete, handleCloseNewChat }: Props, ref) => {
		console.log(`myPartners: `, myPartners)
		if (!myPartners) return null

		return (
			<StyledChatAddressBar data-testid="ChatAddressBar" className={className}>
				<Autocomplete
					className="ChatAddressBar__Autocomplete"
					multiple
					options={myPartners}
					// @ts-ignore
					getOptionLabel={option => option.partner.name}
					renderInput={params => (
						<TextField {...params} inputRef={ref} variant="outlined" size="small" label="To" />
					)}
					onChange={handleAutocomplete}
				/>
				<div>
					<Button
						className="ChatAddressBar__submit-button"
						type="submit"
						variant="ghost"
						onClick={handleCloseNewChat}
						aria-label="Start new chat"
					>
						<CloseIcon />
					</Button>
				</div>
			</StyledChatAddressBar>
		)
	},
)

export default ChatAddressBar
