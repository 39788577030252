import React from 'react'
import { useContainerQuery } from 'react-container-query'
import classnames from 'classnames'
import getGridDims from './utils/getGridDims'
import StyledCardGrid from './CardGrid.style'

export interface Props {
	className?: string
	children: React.ReactNode
	header?: any
}

const query = {
	'grid-of-1': {
		maxWidth: getGridDims(2).minWidth - 1,
	},
	'grid-of-2': {
		minWidth: getGridDims(2).minWidth,
	},
	'grid-of-3': {
		minWidth: getGridDims(3).minWidth,
	},
	'grid-of-4': {
		minWidth: getGridDims(4).minWidth,
	},
	'grid-of-5': {
		minWidth: getGridDims(5).minWidth,
	},
	'grid-of-6': {
		minWidth: getGridDims(6).minWidth,
	},
}

export const CardGrid = ({ className = '', children, header, ...props }: Props) => {
	// @ts-ignore
	const [params, containerRef] = useContainerQuery(query)
	return (
		<StyledCardGrid
			data-testid="CardGrid"
			className={classnames(params, className)}
			ref={containerRef}
			{...props}
		>
			<div className="CardGrid__container">
				{header && <div className="CardGrid__header">{header}</div>}
				<div className="CardGrid__grid">{children}</div>
			</div>
		</StyledCardGrid>
	)
}

export default CardGrid
