import React from 'react'
import { IconContext } from 'react-icons'
import { AiOutlineFilePdf } from 'react-icons/ai'
import { ImFilePicture } from 'react-icons/im'
import { SiMicrosoftword, SiMicrosoftexcel } from 'react-icons/si'
import { GrDocumentCsv, GrDocument } from 'react-icons/gr'

const FileTypeIcon = ({ fileName }) => {
	var fileIcon
	const elements = fileName.split('.')
	const fileExt = elements
		.slice(elements.length - 1)
		.toString()
		.toLowerCase()

	switch (fileExt) {
		case 'csv':
			fileIcon = <GrDocumentCsv className="icon__fileTypeCSV" />
			break
		case 'doc':
			fileIcon = <SiMicrosoftword className="icon__fileTypeWord" />
			break
		case 'docx':
			fileIcon = <SiMicrosoftword className="icon__fileTypeWord" />
			break
		case 'jpg':
			fileIcon = <ImFilePicture className="icon__fileTypeJpeg" />
			break
		case 'jpeg':
			fileIcon = <ImFilePicture className="icon__fileTypeJpeg" />
			break
		case 'pdf':
			fileIcon = <AiOutlineFilePdf className="icon__fileTypePdf" />
			break
		case 'xls':
			fileIcon = <SiMicrosoftexcel className="icon__fileTypeExcel" />
			break
		case 'xlsx':
			fileIcon = <SiMicrosoftexcel className="icon__fileTypeExcel" />
			break
		default:
			fileIcon = <GrDocument className="icon__fileTypeUnknown" />
	}

	return (
		<div>
			<IconContext.Provider value={{ size: '2em' }}>{fileIcon}</IconContext.Provider>
		</div>
	)
}

export default FileTypeIcon
