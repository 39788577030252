import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Card from 'components/Card'
import Popup from 'components/Popup'
import styled from 'styled-components'
import {
	acceptMyPartner,
	getMyPendingInvitations,
	getMyPendingPartnerShips,
} from 'slices/myPartnersSlice'
import Layout from 'components/Layout'
import * as _ from 'styles/helpers'
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import { FaUserClock } from 'react-icons/fa'
import PartnerReminderPopup from '../popups/PartnerReminderPopup'
import swal from 'sweetalert'
import {
	emailInviteNewUser,
	emailInviteNewUsercc,
	emailPartnershipAccepted,
} from 'functions/loginRegisterEmails'
import Message from '../Message'

function SidebarMyPendingPartnerships() {
	const dispatch = useDispatch()

	const [openPopup, setOpenPopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('Invite User')
	const [recordForEdit, setRecordForEdit] = useState(null)
	const [runCount, setRunCount] = useState(1)

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo: user } = userLogin
	const userid = user._id

	// const { isInitialized: myPartnersInitialized } = useSelector(state => state.myPartners)

	const {
		entities: myPendingInvitations,
		ids: myPendingInvitationIds,
		isLoading: myPendingInvitationsLoading,
		isInitialized: myPendingInvitationsInitialized,
		error,
	} = useSelector(state => state.myPartners.myPendingInvitations)
	const myPendingInvitationsList = myPendingInvitationIds.map(id => myPendingInvitations[id])

	useEffect(() => {
		console.log(`SidebarMyPendingPartners - useEffect ran - ${runCount}`)
		if (!myPendingInvitationsInitialized) {
			dispatch(getMyPendingInvitations())
		}
	}, [dispatch, myPendingInvitationsList])

	const SERVER_BASE_URL =
		process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://app.mydealteams.com'

	const fromUser = {
		name: user.name,
		email: user.email,
		company: user.company,
	}

	const acceptPartnershipHandler = partner => {
		swal({
			title: `Partnership Invitation!`,
			text: `${partner.user.name} from ${partner.user.company} has invited you to join their partnership team.`,
			icon: 'success',
			buttons: {
				cancel: {
					text: 'Decline',
					value: null,
					visible: true,
					className: '',
					closeModal: true,
				},
				confirm: {
					text: 'Accept',
					value: true,
					visible: true,
					className: '',
					closeModal: true,
				},
			},
		}).then(willDelete => {
			if (willDelete) {
				swal({
					title: `Invitation Accepted!`,
					text: `Congratulations! You and ${partner.user.name} are now partners.`,
					icon: 'success',
				})
				dispatch(acceptMyPartner({ partnershipId: partner._id, isAccepted: true }))

				const toUser = {
					name: partner.user.name,
					firstName: partner.user.firstName,
					email: partner.user.email,
				}

				emailPartnershipAccepted(fromUser, toUser)
			} else {
				swal({
					title: 'Invitation Declined',
					text: `You have declined a partnership with ${partner.user.name}.`,
					icon: 'info',
				})
				dispatch(acceptMyPartner({ partnershipId: partner._id, isAccepted: false }))
			}
		})
		dispatch(getMyPendingInvitations())
	}

	const remindPartner = async partner => {
		const toUserId = partner.partner._id

		const toUser = {
			name: partner.partner.name,
			firstName: partner.partner.firstName,
			// email: 'info@mydealteams.com',
			email: partner.partner.email,
		}

		swal({
			title: `Partner Invited!`,
			text: `An email has been sent to ${toUser.name} remind them of your partnership invitation.`,
			icon: 'success',
			button: 'Ok',
		})

		const pushLink = `${SERVER_BASE_URL}/registerconfirm/?id=${toUserId}&name=${toUser.name}&firstname=${toUser.firstName}&email=${toUser.email}`
		// const pushLink = `http://https://tradewind-app-service.onrender.com/registerconfirm/?id=${toUserId}&name=${partner.name}&firstname=${partner.firstName}&email=${partner.email}`

		emailInviteNewUser(fromUser, toUser, pushLink)
		emailInviteNewUsercc(fromUser, toUser, pushLink)

		setOpenPopup(false)
		setRecordForEdit(null)
	}

	return (
		<Layout>
			<Layout.Sidebar>
				<StyledSidebar>
					<div className="SidebarContent">
						<Card className="SidebarContent__card SidebarContent__card--partners">
							<div className="SidebarContent__card-header">
								<h2>
									<FaUserClock /> Invitations
								</h2>
							</div>
							<div className="SidebarContent__card-pending-body">
								{/* Mike: TODO: Set initial state for partners to empty array in redux */}

								{myPendingInvitationsLoading ? (
									<Layout.SidebarItemLoading />
								) : error ? (
									<Message variant="danger">{error}</Message>
								) : (
									myPendingInvitationsList &&
									myPendingInvitationsList.length > 0 && (
										<List dense disablePadding>
											{myPendingInvitationsList.map((partner, index) => (
												<ListItem
													key={index}
													component="a"
													onClick={() => acceptPartnershipHandler(partner)}
												>
													<ListItemAvatar>
														<Avatar src={partner.user.image} />
													</ListItemAvatar>
													<ListItemText
														primary={partner.user.name}
														secondary={partner.user.company}
													/>
												</ListItem>
											))}
										</List>
									)
								)}
							</div>
						</Card>
					</div>
				</StyledSidebar>
			</Layout.Sidebar>
			{openPopup ? (
				<Popup title={popupTitle} openPopup={openPopup} setOpenPopup={setOpenPopup}>
					<PartnerReminderPopup partner={recordForEdit} remindPartner={remindPartner} />
				</Popup>
			) : null}
		</Layout>
	)
}

const StyledSidebar = styled.div`
	.SidebarHeader {
		${_.MIXINS.vc}
		margin-bottom: ${_.rem(32)};

		&__avatar {
			width: ${_.rem(68)};
			height: ${_.rem(68)};
			margin-right: ${_.rem(24)};
			border: ${_.rem(4)} solid #0000001a;
		}

		&__greeting {
			${_.MIXINS.mod}
			display: flex;
			justify-content: center;
			flex-direction: column;

			&__username {
				${_.TYPOGRAPHY.p}
			}
			&__title {
				${_.TYPOGRAPHY.h1}
			}
		}
	}

	.SidebarContent {
		display: grid;
		grid-gap: ${_.rem(24)};
		grid-template-columns: repeat(12, [col] 1fr);

		&__card {
			border-top: ${_.rem(4)} solid ${_.COLORS.tradewind_blue};
		}

		&__card-header {
			${_.MIXINS.vc};
			justify-content: space-between;
			margin-bottom: ${_.rem(16)};

			h2 {
				${_.MIXINS.vc};
				${_.TYPOGRAPHY.h2};
				font-size: ${_.rem(22)};
				margin: 0;
				grid-gap: ${_.rem(8)};
			}

			p {
				margin: 0;
				font-size: ${_.rem(11)};
				font-style: italic;
			}
		}

		&__card-active-body {
			height: 100%;
			min-height: ${_.rem(300)};
			height: ${_.rem(335)};
			overflow-y: scroll;
		}

		&__card-pending-body {
			height: 100%;
			height: ${_.rem(245)};
			overflow-y: scroll;
		}

		&__card-empty {
			${_.MIXINS.vhc};
			height: 100%;

			h3 {
				font-size: ${_.rem(16)};
				font-weight: 300;
				color: rgba(0, 0, 0, 0.54);
			}
		}

		&__card {
			min-height: ${_.rem(188)};

			&--partners {
				grid-column: col / span 12;
			}

			&-content {
				padding: 0 ${_.rem(12)};
			}
		}
	}
`

export default SidebarMyPendingPartnerships
