import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { initializeActiveLocations } from 'slices/locationSlice'
import PortfolioAreaMap from './components/PortfolioAreaMap'

const PortfolioMapsScreen = () => {
	const dispatch = useDispatch()

	const { id } = useParams()

	const { isInitialized, loading, portfolio } = useSelector(state => {
		return {
			isInitialized: state.portfolios.isInitialized,
			loading: state.portfolios.isLoading,
			portfolio: state.portfolios.entities[id],
		}
	})

	// const apiKeyDetails = useSelector(state => state.apiKeyDetails)
	// const { apiKey } = apiKeyDetails // { success }

	// // TODO: Create skeleton loader
	if (loading || !isInitialized || !portfolio) return null

	return <PortfolioAreaMap portfolio={portfolio} />
}

export default PortfolioMapsScreen
