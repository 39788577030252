import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PortfolioLocationsList from './components/PortfolioLocationsList'

const PortfolioLocationsScreen = () => {
	const { id } = useParams()
	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	const { isInitialized, loading, portfolio } = useSelector(state => {
		return {
			isInitialized: state.portfolios.isInitialized,
			loading: state.portfolios.isLoading,
			portfolio: state.portfolios.entities[id],
		}
	})

	const { entity: apiKey } = useSelector(state => state.apiKey)

	// // TODO: Create skeleton loader
	if (loading || !isInitialized || !portfolio) return null

	return <PortfolioLocationsList apiKey={apiKey.key} portfolio={portfolio} userId={userInfo._id} />
}

export default PortfolioLocationsScreen
