import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PortfolioAddNew from '../PortfolioAddNew'
import PortfoliosCards from './PortfoliosCards'
import Popup from 'components/Popup'
import Message from 'components/Message'
import Spinner from 'components/Spinner'
import Layout from 'components/Layout'
import EmptyLogo from 'images/empty_folder.png'
import useTableNoPage from 'components/controls/useTableNoPage'
import useMediaQuery from 'hooks/useMediaQuery'
import * as _ from 'styles/helpers'
import { createPortfolio, initializePortfolios } from 'slices/portfolioSlice'
import { initializeUsers } from 'slices/usersSlice'
import Placeholder from 'components/Placeholder'
import { FaPlus } from 'react-icons/fa'
import Button from 'components/Button'
import PortfoliosAdminPopup from 'components/popups/PortfoliosAdminPopup'

const headerCells = [
	{ id: 'Image', label: '', disableSorting: true },
	{ id: 'assetName', label: 'Portfolio' },
	{ id: 'description', label: 'Description' },
	{ id: 'locationCount', label: 'Locations' },
	{ id: 'owner.image', label: 'Owner', disableSorting: true },
	{ id: 'owner.name', label: '', disableSorting: true },
	{ id: 'updatedAt', label: 'Updated' },
]

const Portfolios = ({ openPopup, setOpenPopup }) => {
	const dispatch = useDispatch()

	const searchInputRef = useRef(null)
	const searchNoteInputRef = useRef(null)
	const [searchInputValue, setSearchInputValue] = useState('')
	const [searchNoteInputValue, setSearchNoteInputValue] = useState('')

	const [filterFn, setFilterFn] = useState({
		fn: items => {
			return items
		},
	})

	const { entities: portfolios, error, ids: portfolioIds, isLoading, isInitialized } = useSelector(
		state => state.portfolios,
	)
	const records = portfolioIds.map(id => portfolios[id])

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo: user } = userLogin

	const userId = user._id

	useEffect(() => {
		dispatch(initializePortfolios())
		dispatch(initializeUsers())
	}, [dispatch])

	const recordCount = records.length

	const { recordsAfterPagingAndSorting } = useTableNoPage(records, headerCells, filterFn)

	const portfolioAdd = (portfolio, resetForm) => {
		dispatch(
			createPortfolio({
				owner: userId,
				assetName: portfolio.assetName,
				description: portfolio.description,
				updateNote: 'New Portfolio',
			}),
		)
		resetForm()
		setOpenPopup(false)
	}

	const handleNoteSearch = e => {
		const { value } = e.target
		setSearchNoteInputValue(value)
		setFilterFn({
			fn: items => {
				if (value === '') return items
				return items.filter(x => x.description.toLowerCase().includes(value.toLowerCase()))
			},
		})
		// set focus to search input after rerender
		setTimeout(() => {
			searchNoteInputRef.current.focus()
		}, 0)
	}

	const handleSearch = e => {
		const { value } = e.target
		setSearchInputValue(value)
		setFilterFn({
			fn: items => {
				if (value === '') return items
				return items.filter(x => x.assetName.toLowerCase().includes(value.toLowerCase()))
			},
		})
		// set focus to search input after rerender
		setTimeout(() => {
			searchInputRef.current.focus()
		}, 0)
	}

	const handelPortfolioAdd = () => {
		setOpenPopup(true)
	}

	const isMobile = useMediaQuery(_.MQ.before_nav)

	return (
		<div>
			<React.Fragment>
				{isLoading ? (
					<Spinner containerHeight="50vh" />
				) : !records ? (
					<Message variant="danger">{error}</Message>
				) : error ? (
					<Message variant="danger">{error}</Message>
				) : records.length === 0 ? (
					<Placeholder aspectRatio="16:9" isFlexColumn isTransparent>
						<div>
							<img
								onClick={handelPortfolioAdd}
								className="details__avatar"
								src={EmptyLogo}
								alt="Avatar"
							/>
						</div>
						<div>
							<Button onClick={handelPortfolioAdd} variant="success" isLarge>
								<FaPlus /> Start Here to Add a New Portfolio
							</Button>
						</div>
					</Placeholder>
				) : (
					<>
						<Layout.PageToolbar_Filter
							buttonText="Add Portfolio"
							buttonOnClick={() => setOpenPopup(true)}
							searchInputRef={searchInputRef}
							searchInputValue={searchInputValue}
							searchInputOnChange={handleSearch}
							searchNoteInputRef={searchNoteInputRef}
							searchNoteInputValue={searchNoteInputValue}
							searchNoteInputOnChange={handleNoteSearch}
						/>
						{/* {isMobile ? ( */}
						<PortfoliosCards portfolios={recordsAfterPagingAndSorting()} userId={userId} />
						{/* ) : (
						<PortfoliosTable
							portfolios={recordsAfterPagingAndSorting()}
							TblContainer={TblContainer}
							TblHeader={TblHeader}
							TblPagination={TblPagination}
							recordCount={recordCount}
							userId={userId}
						/>
					)} */}
					</>
				)}
			</React.Fragment>
			<Popup title="Add Portfolio" openPopup={openPopup} setOpenPopup={setOpenPopup}>
				<PortfolioAddNew portfolioAdd={portfolioAdd} />
			</Popup>
		</div>
	)
}

export default Portfolios
