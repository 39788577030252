import React from 'react'
import { Grid } from '@mui/material'
import TWControls from './controls/TWControls'
import { Form, useForm } from './controls/useForm'

const initialFValues = {
	id: 0,
	name: '',
	email: '',
	mobile: '',
	contactDate: new Date(),
	isAdmin: false,
}

const UserEditForm = ({ history, match }) => {
	// const [name, setName] = useState('')
	// const [email, setEmail] = useState('')
	// const [mobile, setMobile] = useState('')
	// const [contactDate, setContactDate] = useState(new Date())
	// const [isAdmin, setIsAdmin] = useState(false)

	const validate = (fieldValues = values) => {
		let temp = { ...errors }
		if ('name' in fieldValues) temp.name = values.name ? '' : 'Required field!'
		if ('emai;' in fieldValues) temp.email = / .+@.+..+/.test(values.email) ? '' : 'Required field!'
		if ('mobile' in fieldValues)
			temp.mobile = values.mobile.length > 9 ? '' : 'Invalid mobile number!'
		setErrors({
			...temp,
		})
		if (fieldValues === values) return Object.values(temp).every(x => x === '')
	}

	// { setValues }
	const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialFValues,
		true,
		validate,
	)

	// const dispatch = useDispatch()

	// const userDetails = useSelector((state) => state.userDetails)
	// const { loading, error, user } = userDetails

	// const userId = user._id

	// const userUpdate = useSelector((state) => state.userUpdate)
	// const {
	// 	loading: loadingUpdate,
	// 	error: errorUpdate,
	// 	success: successUpdate,
	// } = userUpdate

	// useEffect(() => {
	// 	if (successUpdate) {
	// 		dispatch({ type: USER_UPDATE_RESET })
	// 		history.push('/admin/users')
	// 	} else {
	// 		// if (!user.name || user._id !== userId) {
	// 		// 	dispatch(getUserDetails(userId))
	// 		// } else {
	// 		setName(user.name)
	// 		setEmail(user.email)
	// 		setIsAdmin(user.isAdmin)
	// 		// }
	// 	}
	// }, [dispatch, history, successUpdate, user])

	const submitHandler = e => {
		e.preventDefault()
		if (validate()) window.alert('testing...')
		// dispatch(updateUser({ _id: userId, name, email, isAdmin }))
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container>
				<div>
					<h1>UserEditForm: - UserEditForm.js</h1>
				</div>

				{/* {loadingUpdate && <Spinner />}
				{errorUpdate && <Message variant="danger">{error}</Message>}
				{loading ? (
					<Spinner />
				) : error ? (
					<Message variant="danger">{error}</Message>
				) : (
					<Form onSubmit={submitHandler}> */}
				<Grid item xs={6}>
					<TWControls.Input
						name="name"
						label="Name"
						value={values.name}
						onChange={handleInputChange}
						error={errors.name}
					/>
					<TWControls.Input
						name="email"
						label="Email"
						value={values.email}
						onChange={handleInputChange}
						error={errors.email}
					/>
					{/* <TWControls.Input
						name="mobile"
						label="Phone Number"
						value={values.mobile}
						onChange={handleInputChange}
						// onChange={(e) => setMobile(e.target.value)}
						error={errors.mobile}
					/> */}
				</Grid>
				<Grid item xs={6}>
					{/* <TWControls.DatePicker
						name="contactDate"
						label="Contact Date"
						value={values.contactDate}
						onChange={handleInputChange}
						// onChange={(e) => setContactDate(e.target.value)}
					/>
					<TWControls.CheckBox
						value={values.isAdmin}
						name="isAdmin"
						onChange={handleInputChange}
						// onChange={(e) => setIsAdmin(e.target.checked)}
						label="Admin ?"
					/> */}
					<div>
						<TWControls.Button type="submit" text="Submit" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default UserEditForm
