import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Popup from 'components/Popup'
import Spinner from 'components/Spinner'
import Message from 'components/Message'
import Placeholder from 'components/Placeholder'
import ImageGallery from 'components/ImageGallery'
import EmptyLogo from 'images/empty_folder.png'
import { deleteImage, getImages, updateImage } from 'slices/locationImageSlice'
import ImagePreviewPopup from 'components/popups/ImagePreviewPopup'
import swal from 'sweetalert'

export interface Props {
	className?: string
	[x: string]: any
}

const ImageGalleryContainer = ({ className, projectid }: Props) => {
	const dispatch = useDispatch()
	const params = useParams()

	const [openPopup, setOpenPopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('Update Tenant')
	const [recordForEdit, setRecordForEdit] = useState(null)

	const userLogin = useSelector((state: any) => state.userLogin)
	const { userInfo } = userLogin
	const userid = userInfo._id
	const locationid = (params as any).id

	// const { locationImages, error, loading } = useSelector((state: any) => {
	// 	const location = state.locations.entities[locationid]
	// 	const images = state.locationImages.entities
	// 	return {
	// 		locationImages: location?.images?.map(imgId => images[imgId] || {}) || [],
	// 		error: state.locations.error,
	// 		loading: state.locationImages.isLoading,
	// 	}
	// })

	const {
		entities: imageEntities,
		ids: imageIds,
		isInitialized,
		isLoading: loading,
		error,
	} = useSelector((state: any) => state.locationImages)
	const locationImages = imageIds.map(id => imageEntities[id])

	useEffect(() => {
		// @ts-ignore
		dispatch(getImages({ locationid, projectid, userid }))
	}, [dispatch, userid, locationid, projectid, isInitialized])

	const deleteImageHandler = async image => {
		const imageId = image._id
		const userId = image.userid._id

		if (userid === userId) {
			await swal({
				title: 'Are you sure?',
				text: 'Once removed, you will not be able to recover this image!',
				icon: 'warning',
				dangerMode: true,
				buttons: {
					cancel: {
						text: 'Cancel',
						value: null,
						visible: true,
						className: '',
						closeModal: true,
					},
					confirm: {
						text: 'Remove',
						value: true,
						visible: true,
						className: '',
						closeModal: true,
					},
				},
			}).then(willDelete => {
				if (willDelete) {
					swal('The image has been removed from your library!', {
						icon: 'success',
					})
					dispatch(deleteImage({ imageid: imageId }))
					dispatch(getImages({ locationid, projectid, userid }))
					setOpenPopup(false)
				}
			})
		} else {
			swal('You do not have delete privileges for this image.', {
				icon: 'warning',
			})
			setOpenPopup(false)
		}
	}

	const updateImageHandler = async (image: string) => {
		await dispatch(updateImage(image))
		dispatch(getImages({ locationid, projectid, userid }))
		setOpenPopup(false)
	}

	const handleDeleteImage = async image => {
		setOpenPopup(true)
	}

	const handlePreviewImage = async image => {
		setRecordForEdit(image)
		setOpenPopup(true)
	}

	const hasImages = locationImages.length > 0
	if (loading) return <Spinner containerHeight="50vh" />
	if (error) return <Message variant="danger">{error}</Message>
	// if (locationImages.length === 0) return <Placeholder>No Images</Placeholder>

	return (
		<div>
			<div>
				{hasImages ? (
					<ImageGallery
						// className={className}
						locationImages={locationImages}
						handleDeleteImage={handleDeleteImage}
						handlePreviewImage={handlePreviewImage}
					/>
				) : (
					<Placeholder aspectRatio="16:9" isFlexColumn isTransparent>
						<h2 className="h2">{`Select 'Upload New Image' to add your first image.`}</h2>
						<div>
							<img className="details__avatar_noHover" src={EmptyLogo} alt="Avatar" />
						</div>
					</Placeholder>
				)}{' '}
			</div>
			<Popup title={null} openPopup={openPopup} setOpenPopup={setOpenPopup}>
				<ImagePreviewPopup
					image={recordForEdit}
					updateImageHandler={updateImageHandler}
					deleteImageHandler={deleteImageHandler}
				/>
			</Popup>
		</div>
	)
}

export default ImageGalleryContainer
