import React from 'react'
import { Box, LinearProgress } from '@mui/material'
import StyledSpinner from './Spinner.style'

export interface Props {
	className?: string
	width?: string
	containerHeight?: string
}

const Spinner = ({ className = '', width = '50', containerHeight }: Props) => {
	return (
		<Box sx={{ width: '100%' }}>
			<LinearProgress />
			<p>Loading. . .</p>
		</Box>
		// <StyledSpinner
		// 	data-testid="Spinner"
		// 	className={className}
		// 	width={width}
		// 	containerHeight={containerHeight}
		// >
		// 	<svg className="spinner" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
		// 		<circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
		// 	</svg>
		// </StyledSpinner>
	)
}

export default Spinner
