import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material'

import { IconButton, Menu, MenuItem } from '@mui/material'
import Card from '../Card'
import S from '../Card.style'

export interface LibraryCardWrapperProps {
	linkUrl?: string
	children: any
}

export interface LibraryCardProps {
	className?: string
	name: string
	image: string
	linkUrl?: string
	// menuItems?: {
	// 	title: string
	// 	link?: string
	// 	callback?: Function
	// }[]
	isHorizontal?: boolean
}

const LibraryCardWrapper = ({ linkUrl, children }: LibraryCardWrapperProps) => {
	if (linkUrl) return <Link to={linkUrl}>{children}</Link>
	return children
}

export const LibraryCard = ({
	className = '',
	name,
	image,
	// menuItems,
	linkUrl,
	isHorizontal = false,
}: LibraryCardProps) => {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)

	const handleClose = callback => {
		setAnchorEl(null)
		if (typeof callback === 'function') callback()
	}

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const actions = () => {
		// if (menuItems) {
		// 	return (
		// 		<>
		// 			<IconButton color="inherit" size="medium" onClick={handleClick}>
		// 				<MoreHorizIcon />
		// 			</IconButton>
		// 			<Menu
		// 				id="long-menu"
		// 				anchorEl={anchorEl}
		// 				keepMounted
		// 				open={open}
		// 				onClose={handleClose}
		// 				PaperProps={{
		// 					style: {
		// 						width: '15ch',
		// 						color: 'blue',
		// 					},
		// 				}}
		// 			>
		// 				<MenuItem onClick={() => handleClose(undefined)}>...</MenuItem>
		// 				{menuItems.map((menuItem, index) => (
		// 					<MenuItem key={index} onClick={() => handleClose(menuItem.callback)}>
		// 						{menuItem.link && <Link to={menuItem.link}>{menuItem.title}</Link>}
		// 						{menuItem.callback && menuItem.title}
		// 					</MenuItem>
		// 				))}
		// 			</Menu>
		// 		</>
		// 	)
		// }
	}

	return (
		<Card actions={actions()} isCenteredText>
			<StyledLibraryCard
				data-testid="LibraryCard"
				className={className}
				isHorizontal={isHorizontal}
			>
				<LibraryCardWrapper linkUrl={linkUrl}>
					<div className="LibraryCard__body">
						<div className="LibraryCard__content">
							<S.Title>{name}</S.Title>
						</div>
						<div>
							<S.Image className="LibraryCard__Image" src={image} />
						</div>
					</div>
				</LibraryCardWrapper>
			</StyledLibraryCard>
		</Card>
	)
}

const StyledLibraryCard = styled.div<Partial<LibraryCardProps>>`
	width: 100%;

	a:hover {
		text-decoration: none;
	}

	.LibraryCard__body {
		${({ isHorizontal }) =>
			isHorizontal &&
			`
      display: flex;
    `}
	}

	.LibraryCard__Image {
		${({ isHorizontal }) =>
			isHorizontal
				? `
      margin: -16px;
      margin-left: 0;
      padding-top: 25%;
      flex: 1;
    `
				: `
      margin: 1rem -1rem -1rem -1rem;
    `}
	}

	.LibraryCard__content {
		${({ isHorizontal }) =>
			isHorizontal &&
			`
      flex: 250px 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: -16px;
      margin-right: 0;
      padding: 16px;
    `}
	}
`

export default LibraryCard
