import styled from 'styled-components'
import type { Props } from './Placeholder'
import * as _ from 'styles/helpers'

const StyledPlaceholder = styled.div<Partial<Props>>`
	position: relative;
	padding-top: ${({ aspectRatio }) => aspectRatio === '1:1' && '100%'};
	padding-top: ${({ aspectRatio }) => aspectRatio === '4:3' && '75%'};
	padding-top: ${({ aspectRatio }) => aspectRatio === '16:9' && '56.25%'};
	background-color: ${({ isTransparent }) => (isTransparent ? 'transparent' : _.COLORS.gray_100)};

	.content {
		${_.MIXINS.vhc};
		${_.MIXINS.mod};
		flex-direction: ${({ isFlexColumn }) => isFlexColumn && 'column'};
		text-align: center;
		padding: ${_.rem(24)};
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
`

export default StyledPlaceholder
