import React, { useState } from 'react'
import {
	makeStyles,
	Table,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	table: {
		'& thead th': {
			fontWeight: '600',
			color: '#444',
			borderBottom: '2px solid #027fff',
		},
		'& thead th:hover span': {
			color: '#444',
		},
		'& tbody td': {
			fontWeight: '400',
		},
		'& tbody tr:hover': {
			backgroundColor: '#fffbf2',
			cursor: 'pointer',
		},
	},
}))

export default function useTable(records, headerCells, filterFn, noPages) {
	const classes = useStyles()

	const pages = [10, 15, 25, { label: 'All', value: 99999 }]
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(pages[page])
	const [order, setOrder] = useState()
	const [orderBy, setOrderBy] = useState()

	if (noPages) {
		setRowsPerPage(99999)
	}
	const TblContainer = props => <Table className={classes.table}>{props.children}</Table>

	const TblHeader = props => {
		const handleSortRequest = cellId => {
			const isAsc = orderBy === cellId && order === 'asc'
			setOrder(isAsc ? 'desc' : 'asc')
			setOrderBy(cellId)
		}

		return (
			<TableHead>
				<TableRow>
					{headerCells.map(headerCell => (
						<TableCell
							key={headerCell.id}
							sortDirection={orderBy === headerCell.id ? order : false}
						>
							{headerCell.disableSorting ? (
								headerCell.label
							) : (
								<TableSortLabel
									className={classes.headerLabel}
									active={orderBy === headerCell.id}
									direction={orderBy === headerCell.id ? order : 'asc'}
									onClick={() => {
										handleSortRequest(headerCell.id)
									}}
								>
									{headerCell.label}
								</TableSortLabel>
							)}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
		)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const TblPagination = () => (
		<TablePagination
			component="div"
			page={page}
			rowsPerPageOptions={pages}
			rowsPerPage={rowsPerPage}
			count={records.length}
			onChangePage={handleChangePage}
			onChangeRowsPerPage={handleChangeRowsPerPage}
		/>
	)

	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index])
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0])
			if (order !== 0) return order
			return a[1] - b[1]
		})
		return stabilizedThis.map(el => el[0])
	}

	function getComparator(order, orderBy) {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy)
	}

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1
		}
		if (b[orderBy] > a[orderBy]) {
			return 1
		}
		return 0
	}

	const recordsAfterPagingAndSorting = () => {
		return stableSort(filterFn.fn(records), getComparator(order, orderBy)).slice(
			page * rowsPerPage,
			(page + 1) * rowsPerPage,
		)
	}

	return {
		TblContainer,
		TblHeader,
		TblPagination,
		recordsAfterPagingAndSorting,
	}
}
