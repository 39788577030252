interface ImgObj {
	fileName: string
	fileType: string
	base64: string
}

const uid = (() => {
	let id = 0

	const getId = () => {
		id += 1
		return id
	}

	return getId
})()

const getBase64ImagesFromHtmlString = (htmlString: string, id: string = ''): ImgObj[] => {
	const filteredValues = htmlString.split('"').filter(val => val.startsWith('data:image') && val)

	if (filteredValues.length > 0) {
		return filteredValues.map(val => {
			const [fileData, base64] = val.split(',')

			const regex = /data:image\/(.*);base64/
			const fileDataMatch = fileData.match(regex)
			let fileType = ''
			if (fileDataMatch) fileType = fileDataMatch[1]

			return {
				fileName: `${id}${id && '_'}${Date.now()}_${uid()}`,
				fileType,
				base64,
			}
		})
	}

	return []
}

const getFileFromBase64 = (string64Array: ImgObj[]) => {
	return string64Array.map(imgObj => {
		const imageContent = window.atob(imgObj.base64)
		const buffer = new ArrayBuffer(imageContent.length)
		const view = new Uint8Array(buffer)

		for (let n = 0; n < imageContent.length; n++) {
			view[n] = imageContent.charCodeAt(n)
		}

		let imgType = imgObj.fileType
		if (imgType === 'jpeg') imgType = 'jpg'

		const fileName = `${imgObj.fileName}.${imgType}`
		const type = `image/${imgObj.fileType}`
		const blob = new Blob([buffer], { type })
		return new File([blob], `${fileName}`, { lastModified: new Date().getTime(), type })
	})
}

// TODO: Modify the note data (swap base64 with URLs)
export const modifyNote = (htmlString: string, uploadedImages: any[]) => {
	let index = 0
	const filteredValues = htmlString
		.split('"')
		.map(val => {
			if (val.startsWith('data:image')) {
				val = uploadedImages[index].cdnUrl
				index += 1
			}
			return val
		})
		.join('"')

	return filteredValues
}

const convertRTEImages = (input, projectid) =>
	getFileFromBase64(getBase64ImagesFromHtmlString(input, projectid))

export default convertRTEImages
