// extracts given query variable from url
export function getQueryVariable(variable) {
	var split = window.location.href.split('?', 2)
	if (split.length > 1) {
		const query = split[1]
		var vars = query.split('&')
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split('=')
			if (pair[0] === variable) {
				if (window.location.href.includes('localhost')) {
					const code = pair[1].replace('#/', '')
					return code
				}
				return pair[1]
			}
		}
	}
	return ''
}
