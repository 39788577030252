import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'components/Spinner'
import Message from 'components/Message'
import PopupRTE from 'components/PopupRTE'
import { Avatar, Grid } from '@mui/material'
import NoteAddPopup from 'components/popups/NoteAddPopup'
import convertRTEImages, { modifyNote } from 'utils/convertRTEImages'
import { uploadFile } from '@uploadcare/upload-client'
import PortfolioNote from './PortfolioNote'
import { createPortfolioNote, getPortfolioNotes } from 'slices/portfolioNoteSlice'
import { createPortfolioImage } from 'slices/portfolioImagesSlice'
import { patchPortfolio } from 'slices/portfolioSlice'

const UPLOAD_CARE_PUBLIC_KEY = 'b4b06db04515dc9f76e0'

const PortfolioNoteFeed = ({ userInfo, portfolio }) => {
	const dispatch = useDispatch()
	const portfolioId = portfolio._id
	const userId = userInfo._id

	const [openPopup, setOpenPopup] = useState(false)

	const {
		entities: portfolioNoteEntities,
		ids: portfolioNoteIds,
		isLoading: loading,
		isInitialized: isLocationNotesInitialized,
		error,
	} = useSelector(state => state.portfolioNotes)
	const portfolioNotes = portfolioNoteIds.map(id => portfolioNoteEntities[id])

	useEffect(() => {
		// if (!isLocationNotesInitialized) {
		dispatch(getPortfolioNotes({ portfolioId }))
		// }
	}, [dispatch, isLocationNotesInitialized])

	const addNote = async newNote => {
		setOpenPopup(false)

		const fileArray = convertRTEImages(newNote, portfolioId)

		const uploadedImages = []

		for (const file of fileArray) {
			const uploadedImage = await uploadFile(file, {
				publicKey: UPLOAD_CARE_PUBLIC_KEY,
				store: 'auto',
			})
			uploadedImages.push(uploadedImage)
		}

		const modifiedNote = modifyNote(newNote, uploadedImages)

		await dispatch(
			createPortfolioNote({
				userid: userId,
				portfolioid: portfolioId,
				note: modifiedNote,
			}),
		)

		for (const uploadedImage of uploadedImages) {
			await dispatch(
				createPortfolioImage({
					userid: userId,
					portfolioid: portfolioId,
					uuid: uploadedImage.uuid,
					name: uploadedImage.name,
					size: uploadedImage.size,
					url: uploadedImage.cdnUrl,
					type: uploadedImage.mimeType.split('/')[1],
				}),
			)
		}

		await dispatch(
			patchPortfolio({ portfolioId: portfolioId, updates: { updateNote: 'New Note(s) Added' } }),
		)
		await dispatch(getPortfolioNotes({ portfolioId }))
	}

	const openInPopup = () => {
		setOpenPopup(true)
	}

	return (
		<div>
			<Grid container spacing={3}>
				<Grid item xs={9}>
					<div className="feed">
						<div className="feed_inputContainer" style={{ display: `flex`, gridGap: '.5rem' }}>
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<Avatar src={userInfo.image}></Avatar>
							</div>
							<div style={{ flex: '1' }}>
								<form className="feed_newInput" onClick={openInPopup}>
									{` add note . . .`}
									{error && <Message variant="danger">{error}</Message>}
								</form>
							</div>
						</div>
						<div className="feed_Container">
							{loading ? (
								<Spinner />
							) : error ? (
								<Message variant="danger">{error}</Message>
							) : (
								<div>
									{portfolioNotes?.map(portfolioNote => (
										<PortfolioNote key={portfolioNote._id} portfolioNote={portfolioNote} />
									))}
								</div>
							)}
						</div>
					</div>
				</Grid>
			</Grid>
			<PopupRTE title="" openPopup={openPopup} setOpenPopup={setOpenPopup}>
				<NoteAddPopup addNote={addNote} />
			</PopupRTE>
		</div>
	)
}

export default PortfolioNoteFeed
