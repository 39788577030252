const mapStyles = [
	// const mapStyles = [
	// 	{
	// 		featureType: 'administrative',
	// 		elementType: 'labels.text.fill',
	// 		stylers: [
	// 			{
	// 				color: '#6195a0',
	// 			},
	// 		],
	// 	},
	// 	{
	// 		featureType: 'landscape',
	// 		elementType: 'all',
	// 		stylers: [
	// 			{
	// 				color: '#f2f2f2',
	// 			},
	// 		],
	// 	},
	// 	{
	// 		featureType: 'landscape',
	// 		elementType: 'geometry.fill',
	// 		stylers: [
	// 			{
	// 				color: '#ffffff',
	// 			},
	// 		],
	// 	},
	// 	{
	// 		featureType: 'poi',
	// 		elementType: 'all',
	// 		stylers: [
	// 			{
	// 				visibility: 'on',
	// 			},
	// 		],
	// 	},
	// 	{
	// 		featureType: 'poi.park',
	// 		elementType: 'geometry.fill',
	// 		stylers: [
	// 			{
	// 				color: '#e6f3d6',
	// 			},
	// 			{
	// 				visibility: 'on',
	// 			},
	// 		],
	// 	},
	// 	{
	// 		featureType: 'road',
	// 		elementType: 'all',
	// 		stylers: [
	// 			{
	// 				saturation: -100,
	// 			},
	// 			{
	// 				lightness: 45,
	// 			},
	// 			{
	// 				visibility: 'simplified',
	// 			},
	// 		],
	// 	},
	// 	{
	// 		featureType: 'road.highway',
	// 		elementType: 'all',
	// 		stylers: [
	// 			{
	// 				visibility: 'simplified',
	// 			},
	// 		],
	// 	},
	// 	{
	// 		featureType: 'road.highway',
	// 		elementType: 'geometry.fill',
	// 		stylers: [
	// 			{
	// 				color: '#f4d2c5',
	// 			},
	// 			{
	// 				visibility: 'simplified',
	// 			},
	// 		],
	// 	},
	// 	{
	// 		featureType: 'road.highway',
	// 		elementType: 'labels.text',
	// 		stylers: [
	// 			{
	// 				color: '#4e4e4e',
	// 			},
	// 		],
	// 	},
	// 	{
	// 		featureType: 'road.arterial',
	// 		elementType: 'geometry.fill',
	// 		stylers: [
	// 			{
	// 				color: '#f4f4f4',
	// 			},
	// 		],
	// 	},
	// 	{
	// 		featureType: 'road.arterial',
	// 		elementType: 'labels.text.fill',
	// 		stylers: [
	// 			{
	// 				color: '#787878',
	// 			},
	// 		],
	// 	},
	// 	{
	// 		featureType: 'road.arterial',
	// 		elementType: 'labels.icon',
	// 		stylers: [
	// 			{
	// 				visibility: 'off',
	// 			},
	// 		],
	// 	},
	// 	{
	// 		featureType: 'transit',
	// 		elementType: 'all',
	// 		stylers: [
	// 			{
	// 				visibility: 'off',
	// 			},
	// 		],
	// 	},
	// 	{
	// 		featureType: 'water',
	// 		elementType: 'all',
	// 		stylers: [
	// 			{
	// 				color: '#eaf6f8',
	// 			},
	// 			{
	// 				visibility: 'on',
	// 			},
	// 		],
	// 	},
	// 	{
	// 		featureType: 'water',
	// 		elementType: 'geometry.fill',
	// 		stylers: [
	// 			{
	// 				color: '#eaf6f8',
	// 			},
	// 		],
	// 	},
	// ]

	{
		featureType: 'administrative',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#444444',
			},
		],
	},
	{
		featureType: 'landscape',
		elementType: 'all',
		stylers: [
			{
				color: '#f2f2f2',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'all',
		stylers: [
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'all',
		stylers: [
			{
				saturation: -100,
			},
			{
				lightness: 45,
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'all',
		stylers: [
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'road.arterial',
		elementType: 'labels.icon',
		stylers: [
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'transit',
		elementType: 'all',
		stylers: [
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'all',
		stylers: [
			{
				color: '#46bcec',
			},
			{
				visibility: 'on',
			},
		],
	},
]
export default mapStyles
