import React from 'react'
import StyledChatWindowHeader from './ChatWindowHeader.style'

export interface Props {
	className?: string
	label?: string
}

const ChatWindowHeader = ({ className = '', label = '' }: Props) => {
	return (
		<StyledChatWindowHeader data-testid="ChatWindowHeader" className={className}>
			<h4 className="ChatWindowHeader__label">{label ? label : ''}</h4>
		</StyledChatWindowHeader>
	)
}

export default ChatWindowHeader
