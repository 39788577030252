import Tasks_Edit from './Tasks_Edit'
import routes from 'constants/routes'

const getTaskRoutes = (id: string) => [
	{
		// linkText: 'Edit',
		linkTo: `${routes.TASKS}/${id}`,
		linkPattern: `${routes.TASKS}/:id/edit`,
		component: Tasks_Edit,
	},
	/*
		Redirect routes
	*/
	{
		redirectFrom: `${routes.TASKS}/:id`,
		redirectTo: `${routes.TASKS}/${id}/edit`,
	},
]

export default getTaskRoutes
