import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import TemplateTaskFeed from './components/TemplateTask_Feed'

const TemplateTask_Edit = () => {
	const { id } = useParams()

	const { template, loading } = useSelector(state => {
		return {
			loading: state.templates.isLoading,
			template: state.templates.entities[id],
		}
	})

	// TODO: Create skeleton loader
	if (loading || !template) return null

	return <TemplateTaskFeed taskTemplate={template} />
}

export default TemplateTask_Edit
