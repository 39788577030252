const getChatHeaderSecondaryText = (message, lastMessage, userId: string) => {
	const isFirstMessage = !lastMessage
	if (isFirstMessage) return message

	const { senderId, message: lastMessageStr } = lastMessage
	const from = senderId._id === userId ? 'Me' : senderId.firstName
	return `${from}: ${lastMessageStr.substring(0, 50)}`
}

export default getChatHeaderSecondaryText
