import React, { useState, useEffect } from 'react'
import { Avatar, Badge, Box } from '@mui/material'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { Menu, MenuItem, MenuList, MenuButton, MenuLink } from '@reach/menu-button'
import {
	FaAppStore,
	FaBars,
	FaBuilding,
	FaFileAlt,
	FaFolderOpen,
	FaMapMarkerAlt,
	FaProjectDiagram,
	FaRegGrinAlt,
	FaRegSmileWink,
	FaSignOutAlt,
	FaTasks,
	FaTimes,
	FaToolbox,
	FaTools,
	FaUserCircle,
	FaUsers,
} from 'react-icons/fa'
import { BsFillChatLeftTextFill } from 'react-icons/bs'
import { FiLogOut } from 'react-icons/fi'
import AvatarLogo from 'images/MDT_FullLogo.png'
// import AvatarTW from 'images/tradewind-icon.jpg'
import AvatarTW from 'images/tradewind-logo.png'
import Container from 'components/Container'
// import Logo from 'components/Logo'
import routes from 'constants/routes'
import useMediaQuery from 'hooks/useMediaQuery'
import StyledHeader from './Header.style'
import * as _ from 'styles/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { getMyPendingInvitations } from 'slices/myPartnersSlice'

export interface Props {
	className?: string
	userName: string
	userImage: string
	firstTimeUser: Boolean
	isGuestOnly: Boolean
	handleLogout: Function
	isOpen?: Boolean
}

const Header = ({
	className = '',
	userName,
	userImage,
	firstTimeUser,
	isGuestOnly,
	handleLogout,
	isOpen: _isOpen = false,
}: Props) => {
	const dispatch = useDispatch()

	const location = useLocation()
	const isMobile = useMediaQuery(_.MQ.medium_and_below)
	const [isOpen, setIsOpen] = useState(_isOpen)
	const isVisible = (!isMobile || (isMobile && isOpen)) && !firstTimeUser && !isGuestOnly

	const { entities, ids, isInitialized } = useSelector(
		(state: any) => state.myPartners.myPendingInvitations,
	)
	const myPendingInvitations = ids.map(id => entities[id])
	const pendingInvitations = myPendingInvitations.length > 0

	useEffect(() => {
		setIsOpen(false)
		if (!isInitialized) {
			dispatch(getMyPendingInvitations())
		}
	}, [dispatch, , isInitialized, location])

	return (
		<StyledHeader data-testid="Header" className={className} isOpen={isOpen}>
			<Container className="Header__Container">
				{!isMobile && (
					<div className="Header__logo-wrapper">
						<Link to={routes.HOME}>
							<img src={AvatarLogo} alt="My Deal Teams logo" className="Header__logo" />
						</Link>
					</div>
				)}

				<nav className="nav">
					<ul>
						<li>
							<NavLink
								to={routes.LOCATIONS}
								className={`nav-link ${isVisible ? '' : 'is-disabled'}`}
								activeClassName="is-active"
							>
								<span className="nav-link-icon">
									<FaMapMarkerAlt />
								</span>
								<span className="nav-link-label">Locations</span>
							</NavLink>
						</li>
						<li>
							<NavLink
								to={routes.PROJECTS}
								className={`nav-link ${isVisible ? '' : 'is-disabled'}`}
								activeClassName="is-active"
							>
								<span className="nav-link-icon">
									<FaProjectDiagram />
								</span>
								<span className="nav-link-label">Projects</span>
							</NavLink>
						</li>
						<li>
							<NavLink
								to={routes.PORTFOLIOS}
								className={`nav-link ${isVisible ? '' : 'is-disabled'}`}
								activeClassName="is-active"
							>
								<span className="nav-link-icon">
									<FaFolderOpen />
								</span>
								<span className="nav-link-label">Portfolios</span>
							</NavLink>
						</li>
						{/* <li>
							<NavLink
								to={routes.TASKS}
								className={`nav-link ${isVisible ? '' : 'is-disabled'}`}
								activeClassName="is-active"
							>
								<span className="nav-link-icon">
									<FaTasks />
								</span>
								<span className="nav-link-label">My Tasks</span>
							</NavLink>
						</li> */}
						<li>
							<NavLink
								to={routes.CHAT}
								className={`nav-link ${isVisible ? '' : 'is-disabled'}`}
								activeClassName="is-active"
							>
								<span className="nav-link-icon">
									<BsFillChatLeftTextFill />
								</span>
								<span className="nav-link-label">My Chats</span>
							</NavLink>
						</li>
						<li>
							<NavLink
								to={routes.PARTNERAPPS}
								className={`nav-link ${isVisible ? '' : 'is-disabled'}`}
								activeClassName="is-active"
							>
								<span className="nav-link-icon">
									<FaAppStore />
								</span>
								<span className="nav-link-label">My Apps</span>
							</NavLink>
						</li>
						<li>
							{pendingInvitations ? (
								<Box sx={{ color: 'action.active' }}>
									<NavLink
										to={routes.PARTNERS}
										className={`nav-link ${isVisible ? '' : 'is-disabled'}`}
										activeClassName="is-active"
									>
										<span className="nav-link-icon">
											<Badge color="secondary" variant="dot">
												<FaUsers />
											</Badge>
										</span>
										<span className="nav-link-label">Partners</span>

										{/* <span className="nav-link-icon"></span> */}
									</NavLink>
								</Box>
							) : (
								<NavLink
									to={routes.PARTNERS}
									className={`nav-link ${isVisible ? '' : 'is-disabled'}`}
									activeClassName="is-active"
								>
									<span className="nav-link-icon">
										<FaUsers />
									</span>
									<span className="nav-link-label">Partners</span>
								</NavLink>
							)}
						</li>
						<li>
							<NavLink
								to={routes.LOCATION_ADMIN_TOOLS}
								className={`nav-link ${isVisible ? '' : 'is-disabled'}`}
								activeClassName="is-e"
							>
								<span className="nav-link-icon">
									<FaToolbox />
								</span>
								<span className="nav-link-label">Admin</span>
							</NavLink>
						</li>
						<li>
							<NavLink
								to={routes.COMMUNITY}
								className={`nav-link ${isVisible ? '' : 'is-disabled'}`}
								activeClassName="is-e"
							>
								<span className="nav-link-icon">
									<Avatar src={AvatarTW} className="Header__tw" />
									<span className="nav-link-label">TradeWind</span>
								</span>
							</NavLink>
						</li>

						{isMobile && (
							<>
								<hr />
								<li>
									<NavLink
										to={routes.USER_PROFILE}
										className={`nav-link ${isVisible ? '' : 'is-disabled'}`}
										activeClassName="is-active"
									>
										<span className="nav-link-icon">
											<FaUserCircle />
										</span>
										<span className="nav-link-label">Profile</span>
									</NavLink>
								</li>
								<li>
									<button
										className={`nav-link ${isVisible ? '' : 'is-disabled'}`}
										onClick={() => handleLogout()}
									>
										<span className="nav-link-icon">
											<FaSignOutAlt />
										</span>
										<span className="nav-link-label">Logout</span>
									</button>
								</li>
							</>
						)}
					</ul>
				</nav>

				{!isMobile && (
					<div className="user-menu">
						{isVisible ? (
							<Menu>
								<MenuButton className="dropdown-link">
									<Avatar alt={userName} src={userImage} sx={{ width: 50, height: 50 }} />
								</MenuButton>

								<MenuList portal={false}>
									<MenuLink href={routes.USER_PROFILE} className="dropdown-link">
										<span className="dropdown-link-icon">
											<FaRegSmileWink />
										</span>
										<span className="dropdown-link-label">User Profile</span>
									</MenuLink>

									<MenuLink href={routes.COMPANY_PROFILE} className="dropdown-link">
										<span className="dropdown-link-icon">
											<FaBuilding />
										</span>
										<span className="dropdown-link-label">Company Profile</span>
									</MenuLink>
									<MenuLink href={routes.TEMPLATES} className="dropdown-link">
										<span className="dropdown-link-icon">
											<FaFileAlt />
										</span>
										<span className="dropdown-link-label">Templates</span>
									</MenuLink>

									<MenuLink href={routes.PARTNERAPPS} className="dropdown-link">
										<span className="dropdown-link-icon">
											<FaAppStore />
										</span>
										<span className="dropdown-link-label">Third Party Apps</span>
									</MenuLink>

									<MenuLink href={routes.LOCATION_ADMIN_TOOLS} className="dropdown-link">
										<span className="dropdown-link-icon">
											<FaToolbox />
										</span>
										<span className="dropdown-link-label">Admin Tools</span>
									</MenuLink>

									<MenuItem onSelect={() => handleLogout()} className="dropdown-link">
										<span className="dropdown-link-icon">
											<FiLogOut />
										</span>
										<span className="dropdown-link-label">Logout</span>
									</MenuItem>
								</MenuList>
							</Menu>
						) : (
							<Menu>
								<MenuButton className="dropdown-link">
									<Avatar alt={userName} src={userImage} sx={{ width: 50, height: 50 }} />
								</MenuButton>

								<MenuList portal={false}>
									<MenuLink href={routes.USER_PROFILE} className="dropdown-link">
										<span className="dropdown-link-icon">
											<FaRegSmileWink />
										</span>
										<span className="dropdown-link-label">User Profile</span>
									</MenuLink>

									<MenuItem onSelect={() => handleLogout()} className="dropdown-link">
										<span className="dropdown-link-icon">
											<FiLogOut />
										</span>
										<span className="dropdown-link-label">Logout</span>
									</MenuItem>
								</MenuList>
							</Menu>
						)}
					</div>
				)}
				{isMobile && (
					<div className="mobile-menu">
						<div className="Header__logo-wrapper">
							<Link to={routes.HOME}>
								<img src={AvatarLogo} alt="My Deal Teams logo" className="Header__logo" />
							</Link>
						</div>
						<button className="mobile-menu-button nav-link" onClick={() => setIsOpen(!isOpen)}>
							{isOpen ? <FaTimes /> : <FaBars />}
						</button>
					</div>
				)}
			</Container>
		</StyledHeader>
	)
}

export default Header
