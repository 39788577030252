import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const SLICE_NAME = 'users'

const initialState = {
	users: {
		loading: true,
		data: [],
		error: null,
	},
	entities: {},
	ids: [],
	recents: [],
	isLoading: false,
	isInitialized: false,
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

export const initializeUsers = createAsyncThunk(
	`${SLICE_NAME}/initialize`,
	async (payload, { getState, rejectWithValue }) => {
		try {
			return axios.get('/api/users', prepConfig(getState)).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize users')
		}
	},
)

const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {},
	extraReducers: {
		[initializeUsers.fulfilled as any]: (state, action) => {
			const users = action.payload

			state.recents = []
			state.ids = users?.map((user, index) => {
				state.entities[user._id] = user
				return user._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeUsers.pending as any]: state => {
			state.isLoading = true
		},
		[initializeUsers.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},
	} as any,
})

export default usersSlice.reducer
