import React from 'react'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import type { IChatHeader } from 'features/Chat/Chat.types'
import getChatHeaderSecondaryText from 'features/Chat/utils/getChatHeaderSecondaryText'
import ChatHeader from '../ChatHeader'
import StyledChatHeaderList from './ChatHeaderList.style'

export interface Props {
	className?: string
	chatHeaders: IChatHeader[]
	userId: string
	currentEventId: string
	showSelected?: boolean
	onClick: any
}

const ChatHeaderList = ({
	className = '',
	chatHeaders,
	userId,
	currentEventId,
	showSelected = true,
	onClick,
}: Props) => {
	return (
		<StyledChatHeaderList data-testid="ChatHeaderList" className={className}>
			{chatHeaders.length > 0 && (
				<List dense>
					{chatHeaders.map((chatHeader, index) => {
						return (
							<li key={index}>
								<ChatHeader
									avatars={chatHeader.avatars}
									primaryText={chatHeader.label}
									secondaryText={getChatHeaderSecondaryText(
										chatHeader.message,
										chatHeader.lastMessage,
										userId,
									)}
									isSelected={showSelected && chatHeader._id === currentEventId}
									isUnread={!chatHeader.readByIds.includes(userId)}
									onClick={() => onClick(chatHeader)}
								/>
								<Divider variant="inset" />
							</li>
						)
					})}
				</List>
			)}
		</StyledChatHeaderList>
	)
}

export default ChatHeaderList
