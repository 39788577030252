import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'components/Spinner'
import Message from 'components/Message'
import PopupRTE from 'components/PopupRTE'
import { Avatar, Grid } from '@mui/material'
import PostAddPopup from 'components/popups/PostAddPopup'
import convertRTEImages, { modifyNote } from 'utils/convertRTEImages'
import { uploadFile } from '@uploadcare/upload-client'
import Post from 'components/Post'
import { createPost, createPostImage, initializePosts } from 'slices/postSlice'

const UPLOAD_CARE_PUBLIC_KEY = 'b4b06db04515dc9f76e0'

const ComunityPostsFeed = () => {
	const dispatch = useDispatch()

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin
	const userId = userInfo._id
	const groupId = userInfo.lastGroup

	const [openPopup, setOpenPopup] = useState(false)

	const {
		entities: postEntities,
		ids: postIds,
		isLoading: postsLoading,
		isInitialized: isCommunityPostsInitialized,
		error,
	} = useSelector(state => state.posts)
	const posts = postIds.map(id => postEntities[id])

	const { group, loading } = useSelector(state => {
		return {
			loading: state.groups.isLoading,
			success: state.groups.isInitialized,
			group: state.groups.entities[groupId],
		}
	})

	useEffect(() => {
		dispatch(initializePosts({ groupId }))
	}, [dispatch, isCommunityPostsInitialized])

	const addPost = async newNote => {
		setOpenPopup(false)

		const fileArray = convertRTEImages(newNote.content)
		const groupId = newNote.postGroup

		const uploadedImages = []

		for (const file of fileArray) {
			const uploadedImage = await uploadFile(file, {
				publicKey: UPLOAD_CARE_PUBLIC_KEY,
				store: 'auto',
			})
			uploadedImages.push(uploadedImage)
		}

		const modifiedNote = modifyNote(newNote.content, uploadedImages)

		await dispatch(
			createPost({
				user: userInfo._id,
				message: modifiedNote,
				postGroup: groupId,
			}),
		)

		for (const uploadedImage of uploadedImages) {
			await dispatch(
				createPostImage({
					userid: userId,
					uuid: uploadedImage.uuid,
					name: uploadedImage.name,
					size: uploadedImage.size,
					url: uploadedImage.cdnUrl,
					source: uploadedImage.source,
					type: uploadedImage.mimeType.split('/')[1],
				}),
			)
		}
		await dispatch(initializePosts({ groupId }))
	}

	const openInPopup = () => {
		setOpenPopup(true)
	}

	return (
		<div>
			<Grid container spacing={6}>
				<Grid item xs={1}></Grid>
				<Grid item xs={10}>
					{loading ? (
						<Spinner />
					) : error ? (
						<Message variant="danger">{error}</Message>
					) : (
						<div className="feed__header">
							<h2>{group.groupName}</h2>
						</div>
					)}

					<div className="feed">
						<div className="feed_inputContainer" style={{ display: `flex`, gridGap: '.5rem' }}>
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<Avatar src={userInfo.image}></Avatar>
							</div>
							<div style={{ flex: '1' }}>
								<form className="feed_newInput" onClick={openInPopup}>
									{` start a post . . .`}
									{error && <Message variant="danger">{error}</Message>}
								</form>
							</div>
						</div>
						<div className="feed_Container">
							{postsLoading ? (
								<Spinner />
							) : error ? (
								<Message variant="danger">{error}</Message>
							) : (
								<div>
									{posts.map(post => (
										<Post key={post._id} post={post} />
									))}
								</div>
							)}
						</div>
					</div>
				</Grid>
			</Grid>
			<PopupRTE title="" openPopup={openPopup} setOpenPopup={setOpenPopup}>
				<PostAddPopup addNote={addPost} postGroup={groupId} />
			</PopupRTE>
		</div>
	)
}

export default ComunityPostsFeed
