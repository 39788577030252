import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import routes from 'constants/routes'

const PrivateRoute = ({ component: Component, ...rest }: any) => {
	const userLogin = useSelector((state: any) => state.userLogin)
	const isLoggedIn = userLogin.userInfo !== null && userLogin.userInfo !== undefined

	return (
		<Route
			{...rest}
			render={props =>
				isLoggedIn ? <Component {...props} /> : <Redirect to={{ pathname: routes.LOGIN }} />
			}
		/>
	)
}

export default PrivateRoute
