import styled from 'styled-components'
import * as _ from 'styles/helpers'
import {
	LayoutRightbarProps,
	LayoutRightbarItemProps,
	LayoutRightbarTopProps,
	LayoutSidebarProps,
	LayoutSidebarItemProps,
	LayoutSidebarTopProps,
	LayoutSidebarTop2Props,
	LayoutLocationProps,
	LayoutPartnerProps,
	LayoutPartnerRemoveProps,
	LayoutFirstTimeContentProps,
} from './Layout'

const SIDEBAR_WIDTH = 320
const PAGE_PADDING_TOP = 24
const SCROLL_GRADIENT_WIDTH = 32
const SCROLL_CONTAINER_PADDING = 16

const Layout = styled.div`
	.Layout__Page {
		padding-top: ${_.rem(PAGE_PADDING_TOP)};

		.Layout__Page__Container {
			@media ${_.MQ.before_nav} {
				max-width: ${_.rem(768)};
			}

			@media ${_.MQ.nav} {
				display: flex;
			}
		}
	}
`

const LayoutPageHeader = styled.div`
	color: ${_.COLORS.black};
	text-align: center;

	@media ${_.MQ.nav} {
		text-align: left;
	}

	h2 {
		margin: 0 0 ${_.rem(24)};
		font-size: ${_.rem(32)};
		font-weight: 600;

		@media ${_.MQ.nav} {
			margin: 0 0 ${_.rem(32)};
			font-size: ${_.rem(40)};
		}
	}
`

const LayoutPageToolbar = styled.div`
	margin-bottom: ${_.rem(24)};

	:not(&.PageToolbar--isMobile) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
`

const LayoutPageToolbar_NoPlus = styled.div`
	margin-bottom: ${_.rem(24)};

	:not(&.PageToolbar--isMobile) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
`

const LayoutSecondaryNav = styled.div`
	color: ${_.COLORS.white};
	background-color: ${_.COLORS.blue_twd};

	.SecondaryNav {
		// .SecondaryNav__Container
		&__Container {
			position: relative;
		}

		// .SecondaryNav__backlink
		&__backlink {
			display: flex;
			align-items: center;
			position: absolute;
			top: ${_.rem(2)};
			left: ${_.rem(24)};
			height: ${_.rem(50)};
			font-size: ${_.rem(16)};

			svg {
				margin-right: ${_.rem(2)};
			}

			a {
				display: flex;
				align-items: center;
				justify-content: center;
				color: ${_.COLORS.white};
				/* text-decoration: none; */
			}
		}

		// .SecondaryNav__links
		&__links {
			position: relative;

			font-weight: ${_.FONTS.medium};

			@media ${_.MQ.nav} {
				margin-left: ${_.rem(SIDEBAR_WIDTH)};
			}

			&:after {
				content: '';
				position: absolute;
				top: ${_.rem(SCROLL_CONTAINER_PADDING)};
				bottom: ${_.rem(SCROLL_CONTAINER_PADDING)};
				right: 0;
				width: ${_.rem(SCROLL_GRADIENT_WIDTH)};
				background: linear-gradient(90deg, rgba(2, 127, 255, 0) 0%, rgba(2, 127, 255, 1) 100%);
				pointer-events: none;
			}

			// .SecondaryNav__links__scroll-container
			&__scroll-container {
				display: flex;
				align-items: center;
				min-height: ${_.rem(_.HEADER_HEIGHT_MOBILE)};
				padding-top: ${_.rem(SCROLL_CONTAINER_PADDING)};
				padding-bottom: ${_.rem(SCROLL_CONTAINER_PADDING)};
				padding-right: ${_.rem(SCROLL_GRADIENT_WIDTH)};
				overflow-x: auto;
				-ms-overflow-style: -ms-autohiding-scrollbar;
			}
		}

		// .SecondaryNav__link
		&__link {
			display: block;
			margin-right: ${_.rem(4)};
			padding: ${_.rem(8)} ${_.rem(12)};
			font-size: ${_.rem(14)};
			color: inherit;
			border-radius: ${_.rem(4)};

			&:last-of-type {
				margin-right: 0;
			}

			@media ${_.MQ.nav} {
				padding: ${_.rem(8)} ${_.rem(16)};
			}

			&:hover {
				text-decoration: none;
				background: rgba(255, 255, 255, 0.125);
			}

			&.is-active,
			&.is-active:hover {
				background-color: ${_.COLORS.blue_800};
			}
		}
	}
`

const LayoutBody = styled.div`
	flex: 1;
	/* padding-bottom: ${_.rem(80)}; */
`
const LayoutRightbar = styled.div<Partial<LayoutRightbarProps>>`
	flex: 0 0 ${_.rem(SIDEBAR_WIDTH)};
	max-width: ${_.rem(SIDEBAR_WIDTH)};
	padding-right: ${_.rem(32)};
	margin-top: ${({ isOffset }) => isOffset && _.rem(-(16 + PAGE_PADDING_TOP))};
`

const _LayoutRightbarItem = `
  margin-bottom: ${_.rem(20)};
  background-color: ${_.COLORS.white};
  box-shadow: 0 0 0 ${_.rem(1)} ${_.COLORS.white_smoke}, 0 0 0 0;
  border-radius: ${_.rem(2)};
  `
// overflow: hidden;

const LayoutRightbarItem = styled.div<Partial<LayoutRightbarItemProps>>`
	${_LayoutRightbarItem}

	.RightbarItem__section-title {
		display: flex;
		margin: 16px 16px 0px;
		line-height: 1;
		font-weight: 600;
		font-size: 14px;

		svg {
			margin-right: 4px;
		}
	}

	ul {
		margin: 0;
		padding: ${_.rem(8)} 0;
		list-style: none;
	}

	.partner {
		display: flex;
	}
`

const LayoutRightbarTop = styled.div<Partial<LayoutRightbarTopProps>>`
	${_LayoutRightbarItem}

	.img {
		display: 'flex';
		/* flex-direction: column; */
		align-items: center;
		justify-content: center;
		text-align: center;
		height: 0;
		width: 100%;
		padding-top: 75%; // 4:3 ratio
		/* padding-top: 56.25%; // 16:9 ratio */
		background-image: url(${({ img }) => img});
		background-size: cover;
		background-color: ${_.COLORS.gray_100};
	}

	.body {
		${_.MIXINS.mod}
		padding: ${_.rem(16)};
		font-size: ${_.rem(12)};

		> p {
			margin: 0;
		}
	}

	.title {
		font-size: ${_.rem(18)};
		margin-bottom: ${_.rem(4)};
		font-weight: 600;
		color: ${_.COLORS.black};
	}
`

const LayoutSidebar = styled.div<Partial<LayoutSidebarProps>>`
	flex: 0 0 ${_.rem(SIDEBAR_WIDTH)};
	max-width: ${_.rem(SIDEBAR_WIDTH)};
	padding-right: ${_.rem(32)};
	margin-top: ${({ isOffset }) => isOffset && _.rem(-(16 + PAGE_PADDING_TOP))};
`

const _LayoutSidebarItem = `
  margin-bottom: ${_.rem(20)};
  background-color: ${_.COLORS.white};
  box-shadow: 0 0 0 ${_.rem(1)} ${_.COLORS.white_smoke}, 0 0 0 0;
  border-radius: ${_.rem(2)};
  `
// overflow: hidden;

const LayoutSidebarItem = styled.div<Partial<LayoutSidebarItemProps>>`
	${_LayoutSidebarItem}

	.SidebarItem__section-title {
		display: flex;
		margin: 16px 16px 0px;
		line-height: 1;
		font-weight: 600;
		font-size: 14px;

		svg {
			margin-right: 4px;
		}
	}

	ul {
		margin: 0;
		padding: ${_.rem(8)} 0;
		list-style: none;
	}

	.partner {
		display: flex;
	}
`

const listItem = `
  a {
    display: block;
    padding: ${_.rem(8)} ${_.rem(16)};
    color: ${_.COLORS.gray_500};

    &:hover {
      text-decoration: none;
      background-color: ${_.COLORS.white_smoke};
    }
  }

  p {
    margin: 0;
    font-size: ${_.rem(13)};
  }

  .title {
    font-weight: 600;
    color: ${_.COLORS.black};
  }

  .description {
    max-width: ${_.rem(256)};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .meta {
    color: ${_.COLORS.black};
    /* color: ${_.COLORS.gray_500}; */
    font-size: ${_.rem(12)};
    font-style: italic;
  }
`

const LayoutLocationItem = styled.div<Partial<LayoutLocationProps>>`
	${listItem}

	.body ,
	.name {
		${_.MIXINS.vc}
	}
	.name {
		font-weight: 600;
		color: ${_.COLORS.black};

		svg {
			margin-left: auto;
			color: ${_.COLORS.tradewind_blue};
		}
	}
	.details {
		/* flex: 1;
		margin-bottom: 15px; */
	}
	.info {
		font-size: ${_.rem(12)};
	}
	.bottom {
		margin-bottom: 10px;
	}
`

const LayoutPartnerItem = styled.div<Partial<LayoutPartnerProps>>`
	${listItem}

	.body ,
	.name {
		${_.MIXINS.vc}
	}
	.name {
		font-weight: 600;
		color: ${_.COLORS.black};

		svg {
			margin-left: auto;
			color: ${_.COLORS.tradewind_blue};
		}
	}
	.details {
		/* flex: 1;
		margin-bottom: 15px; */
	}
	.info {
		font-size: ${_.rem(12)};
	}
	.bottom {
		margin-bottom: 10px;
	}
`

const LayoutPartnerRemoveItem = styled.div<Partial<LayoutPartnerRemoveProps>>`
	${listItem}

	.body,
	.name {
		${_.MIXINS.vc}
	}
	.name {
		font-weight: 600;
		color: ${_.COLORS.black};

		svg {
			margin-left: auto;
			color: ${_.COLORS.tradewind_blue};
		}
	}
	.details {
		flex: 1;
	}
	.info {
		font-size: ${_.rem(12)};
	}
	.wrapper {
		padding-top: 8px;
		padding-left: 16px;
		padding-right: 15px;
		padding-bottom: 8px;
		/* margin-bottom: 12px; */
	}
`

const LayoutSidebarTop = styled.div<Partial<LayoutSidebarTopProps>>`
	${_LayoutSidebarItem}

	.img {
		display: 'flex';
		/* flex-direction: column; */
		align-items: center;
		justify-content: center;
		text-align: center;
		height: 0;
		width: 100%;
		padding-top: 75%; // 4:3 ratio
		/* padding-top: 56.25%; // 16:9 ratio */
		background-image: url(${({ img }) => img});
		background-size: cover;
		background-color: ${_.COLORS.gray_100};
	}

	.body {
		${_.MIXINS.mod}
		padding: ${_.rem(16)};
		font-size: ${_.rem(12)};

		> p {
			margin: 0;
		}
	}

	.title {
		font-size: ${_.rem(18)};
		margin-bottom: ${_.rem(4)};
		font-weight: 600;
		color: ${_.COLORS.black};
	}
`

const LayoutSidebarTop2 = styled.div<Partial<LayoutSidebarTop2Props>>`
	${_LayoutSidebarItem}

	.img {
		height: 0;
		padding-top: 75%; // 4:3 ratio
		/* background-image: url(${({ img }) => img});
		width: 100%; */
		/* background-size: cover;
		background-color: ${_.COLORS.gray_100}; */
	}

	.body {
		${_.MIXINS.mod}
		padding: ${_.rem(16)};
		font-size: ${_.rem(12)};
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		/* background-color: ${_.COLORS.gray_100}; */
		> p {
			margin: 0;
		}
	}

	.title {
		font-size: ${_.rem(18)};
		margin-bottom: ${_.rem(4)};
		font-weight: 400;
		color: ${_.COLORS.black};
	}

	.project {
		font-size: ${_.rem(20)};
		font-weight: 600;
		color: ${_.COLORS.tradewind_blue};
	}
`

const LayoutSidebarNews = styled.div`
	flex: 0 0 ${_.rem(SIDEBAR_WIDTH)};
	padding-left: ${_.rem(32)};
`

const LayoutSidebarPartners = styled.div`
	flex: 0 0 ${_.rem(SIDEBAR_WIDTH)};
	padding-left: ${_.rem(32)};
`

const LayoutNewsArticle = styled.div`
	a {
		display: block;
		max-width: ${_.rem(320)};
		padding: 16px;
		color: ${_.COLORS.black};

		margin-bottom: 16px;
		background-color: #f7f7f7;

		&:hover {
			text-decoration: none;
			background-color: ${_.COLORS.white_smoke};
			/* make this a mixin */
			box-shadow: inset 0 0 0 0.0625rem #edf2f7, 0 0.125rem 0.0625rem -0.0625rem rgb(0 0 0 / 20%),
				0 0.0625rem 0.0625rem 0 rgb(0 0 0 / 14%), 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 12%);

			.title {
				text-decoration: underline;
			}
		}
	}

	.title {
		margin-bottom: 0;
		font-size: ${_.rem(14)};
		font-weight: 600;
	}
	.img {
		height: 0;
		padding-top: 56.25%;
		margin-bottom: ${_.rem(8)};
		background-size: cover;
		background-position: center;
		background-color: ${_.COLORS.gray_100};
	}
	.source {
		font-size: ${_.rem(12)};
		font-weight: 400;
		font-style: italic;
		color: ${_.COLORS.gray_500};
	}
`

const LayoutSidebarUserCard = styled.div`
	border-top: ${_.rem(4)} solid ${_.COLORS.tradewind_blue};
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: ${_.COLORS.black};
	background-color: ${_.COLORS.white};
	border-radius: ${_.rem(5)};
	overflow: hidden;

	.LayoutSidebarUserCard {
		&__avatar {
			margin-bottom: ${_.rem(10)};
			height: ${_.rem(96)};
			width: ${_.rem(96)};
			border: ${_.rem(2)} solid ${_.COLORS.blue_300};
		}

		&__user-name {
			margin-bottom: 0;
			font-size: ${_.rem(22)};
			font-weight: 600;
		}

		&__greeting {
			/* padding: 20px; */
			margin-top: 20px;
			margin-bottom: 0;
			font-size: ${_.rem(22)};
			font-weight: 400;
			color: ${_.COLORS.tradewind_blue};
		}
	}
`

const LayoutSidebarAssetTop = styled.div`
	/* border-top: ${_.rem(4)} solid ${_.COLORS.tradewind_blue}; */
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: ${_.COLORS.black};
	background-color: ${_.COLORS.white};
	border-radius: ${_.rem(5)};
	overflow: hidden;

	.LayoutSidebarAssetTop {
		&__avatar {
			margin-bottom: ${_.rem(10)};
			height: ${_.rem(126)};
			width: ${_.rem(126)};
			border: ${_.rem(2)} solid ${_.COLORS.blue_300};
		}

		&__title {
			margin-bottom: 0;
			font-size: ${_.rem(18)};
			font-weight: 600;

			&:hover {
				text-decoration: none;
				cursor: pointer;
			}
		}

		&__address {
			margin-bottom: 0;
			font-size: ${_.rem(12)};
			font-weight: 600;
		}

		&__originator {
			margin-bottom: 0;
			font-size: ${_.rem(14)};
			font-weight: 400;
		}

		&__greeting {
			/* padding: 20px; */
			margin-top: 20px;
			margin-bottom: 0;
			font-size: ${_.rem(22)};
			font-weight: 400;
			color: ${_.COLORS.tradewind_blue};
		}

		&__project {
			padding: 5px;
			/* margin-top: 10px; */
			margin-bottom: 0;
			font-size: ${_.rem(20)};
			font-weight: 600;
			color: ${_.COLORS.tradewind_blue};

			&:hover {
				text-decoration: none;
				cursor: pointer;
			}
		}
	}
`

const _LayoutFirstTimeContent = `
  margin-bottom: ${_.rem(20)};
  background-color: ${_.COLORS.white};
  box-shadow: 0 0 0 ${_.rem(1)} ${_.COLORS.white_smoke}, 0 0 0 0;
  border-radius: ${_.rem(2)};
  `
// overflow: hidden;

const LayoutFirstTimeContent = styled.div<Partial<LayoutFirstTimeContentProps>>`
	${_LayoutFirstTimeContent}

	.FirstTimeContent__section-title {
		display: flex;
		margin: 16px 16px 0px;
		line-height: 1;
		font-weight: 600;
		font-size: 14px;

		svg {
			margin-right: 4px;
		}
	}

	ul {
		margin: 0;
		padding: ${_.rem(8)} 0;
		list-style: none;
	}

	.partner {
		display: flex;
	}
`

const LayoutProfileUserCard = styled.div`
	border: ${_.rem(1)} solid ${_.COLORS.tradewind_blue};
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: ${_.COLORS.black};
	background-color: ${_.COLORS.white};
	border-radius: ${_.rem(5)};
	overflow: hidden;

	.LayoutProfileUserCard {
		&__avatar {
			margin-bottom: ${_.rem(10)};
			height: ${_.rem(96)};
			width: ${_.rem(96)};
			border: ${_.rem(2)} solid ${_.COLORS.blue_300};
		}

		&__user-name {
			margin-bottom: 0;
			font-size: ${_.rem(22)};
			font-weight: 600;
		}

		&__greeting {
			/* padding: 20px; */
			margin-top: 20px;
			margin-bottom: 0;
			font-size: ${_.rem(22)};
			font-weight: 400;
			color: ${_.COLORS.tradewind_blue};
		}
	}
`

const LayoutFirstProfileUserCard = styled.div`
	border: ${_.rem(1)} solid ${_.COLORS.tradewind_blue};
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: ${_.COLORS.black};
	background-color: ${_.COLORS.white};
	border-radius: ${_.rem(5)};
	overflow: hidden;

	.LayoutFirstProfileUserCard {
		&__avatar {
			margin-bottom: ${_.rem(10)};
			height: ${_.rem(96)};
			width: ${_.rem(96)};
			border: ${_.rem(2)} solid ${_.COLORS.blue_300};
		}

		&__user-name {
			margin-bottom: 0;
			font-size: ${_.rem(22)};
			font-weight: 600;
		}

		&__greeting {
			/* padding: 20px; */
			margin-top: 20px;
			margin-bottom: 0;
			font-size: ${_.rem(22)};
			font-weight: 400;
			color: ${_.COLORS.tradewind_blue};
		}
	}
`

const LayoutFirstLocationCard = styled.div`
	border: ${_.rem(1)} solid ${_.COLORS.tradewind_blue};
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: ${_.COLORS.black};
	background-color: ${_.COLORS.white};
	border-radius: ${_.rem(5)};
	overflow: hidden;

	.LayoutFirstLocationCard {
		&__avatar {
			margin-bottom: ${_.rem(10)};
			height: ${_.rem(96)};
			width: ${_.rem(96)};
			border: ${_.rem(2)} solid ${_.COLORS.blue_300};
		}

		&__user-name {
			margin-bottom: 0;
			font-size: ${_.rem(22)};
			font-weight: 600;
		}

		&__greeting {
			/* padding: 20px; */
			margin-top: 20px;
			margin-bottom: 0;
			font-size: ${_.rem(22)};
			font-weight: 400;
			color: ${_.COLORS.tradewind_blue};
		}
	}
`

const LayoutFirstProjectCard = styled.div`
	border: ${_.rem(1)} solid ${_.COLORS.tradewind_blue};
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: ${_.COLORS.black};
	background-color: ${_.COLORS.white};
	border-radius: ${_.rem(5)};
	overflow: hidden;

	.LayoutFirstProjectCard {
		&__avatar {
			margin-bottom: ${_.rem(10)};
			height: ${_.rem(96)};
			width: ${_.rem(96)};
			border: ${_.rem(2)} solid ${_.COLORS.blue_300};
		}

		&__user-name {
			margin-bottom: 0;
			font-size: ${_.rem(22)};
			font-weight: 600;
		}

		&__greeting {
			/* padding: 20px; */
			margin-top: 20px;
			margin-bottom: 0;
			font-size: ${_.rem(22)};
			font-weight: 400;
			color: ${_.COLORS.tradewind_blue};
		}
	}
`

const LayoutFirstPartnerCard = styled.div`
	border: ${_.rem(1)} solid ${_.COLORS.tradewind_blue};
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: ${_.COLORS.black};
	background-color: ${_.COLORS.white};
	border-radius: ${_.rem(5)};
	overflow: hidden;

	.LayoutFirstPartnerCard {
		&__avatar {
			margin-bottom: ${_.rem(10)};
			height: ${_.rem(96)};
			width: ${_.rem(96)};
			border: ${_.rem(2)} solid ${_.COLORS.blue_300};
		}

		&__user-name {
			margin-bottom: 0;
			font-size: ${_.rem(22)};
			font-weight: 600;
		}

		&__greeting {
			/* padding: 20px; */
			margin-top: 20px;
			margin-bottom: 0;
			font-size: ${_.rem(22)};
			font-weight: 400;
			color: ${_.COLORS.tradewind_blue};
		}
	}
`

const StyledLayout = {
	Layout,
	LayoutPageHeader,
	LayoutPageToolbar,
	LayoutPageToolbar_NoPlus,
	LayoutSecondaryNav,
	LayoutBody,
	LayoutRightbar,
	LayoutRightbarItem,
	LayoutRightbarTop,
	LayoutSidebar,
	LayoutSidebarItem,
	LayoutSidebarTop,
	LayoutSidebarTop2,
	LayoutSidebarNews,
	LayoutSidebarPartners,
	LayoutLocationItem,
	LayoutPartnerItem,
	LayoutPartnerRemoveItem,
	LayoutNewsArticle,
	LayoutSidebarUserCard,
	LayoutSidebarAssetTop,
	LayoutFirstTimeContent,
	LayoutProfileUserCard,
	LayoutFirstLocationCard,
	LayoutFirstProjectCard,
	LayoutFirstPartnerCard,
	LayoutFirstProfileUserCard,
}

export default StyledLayout
