// shared
import LocationDetails from '../navPages/LocationDetails'

// recently updated
import LocationTasks from '../navPages/LocationTasks'

import LocationChat from '../navPages/LocationChat'
import LocationApps from '../navPages/LocationApps'
import LocationNotes from '../navPages/LocationNotes'
import LocationReport from '../navPages/LocationReport'
import LocationAnalytics from '../navPages/LocationAnalytics'
import LocationMaps from '../navPages/LocationMaps'
import LocationImages from '../navPages/LocationImages'
import LocationFiles from '../navPages/LocationDocuments'
import LocationPartners from '../navPages/LocationPartners'
import LocationAddPartners from '../components/LocationAddPartners'
import LocationTestWrapper from '../navPages/LocationTestWrapper'
import routes from 'constants/routes'

const getLocationProjectRoutes = (id: string, projectId: string) => [
	{
		linkText: 'Notes ',
		linkTo: `${routes.LOCATIONS}/${id}/projects/${projectId}/notes`,
		linkPattern: `${routes.LOCATIONS}/:id/projects/:projectId/notes`,
		component: LocationNotes,
	},
	{
		linkText: 'Chat',
		linkTo: `${routes.LOCATIONS}/${id}/projects/${projectId}/chat`,
		linkPattern: `${routes.LOCATIONS}/:id/projects/:projectId/chat`,
		component: LocationChat,
	},
	{
		linkText: 'Map',
		linkTo: `${routes.LOCATIONS}/${id}/projects/${projectId}/maps`,
		linkPattern: `${routes.LOCATIONS}/:id/projects/:projectId/maps`,
		component: LocationMaps,
	},
	{
		linkText: 'Tasks',
		linkTo: `${routes.LOCATIONS}/${id}/projects/${projectId}/tasks`,
		linkPattern: `${routes.LOCATIONS}/:id/projects/:projectId/tasks`,
		component: LocationTasks,
	},
	{
		linkText: 'Details',
		linkTo: `${routes.LOCATIONS}/${id}/projects/${projectId}/details`,
		linkPattern: `${routes.LOCATIONS}/:id/projects/:projectId/details`,
		component: LocationDetails,
	},

	{
		linkText: 'Demographics',
		linkTo: `${routes.LOCATIONS}/${id}/projects/${projectId}/analytics`,
		linkPattern: `${routes.LOCATIONS}/:id/projects/:projectId/analytics`,
		component: LocationAnalytics,
	},
	{
		linkText: 'Images',
		linkTo: `${routes.LOCATIONS}/${id}/projects/${projectId}/images`,
		linkPattern: `${routes.LOCATIONS}/:id/projects/:projectId/images`,
		component: LocationImages,
	},
	{
		linkText: 'Documents',
		linkTo: `${routes.LOCATIONS}/${id}/projects/${projectId}/files`,
		linkPattern: `${routes.LOCATIONS}/:id/projects/:projectId/files`,
		component: LocationFiles,
	},
	{
		linkText: 'Partners',
		linkTo: `${routes.LOCATIONS}/${id}/projects/${projectId}/partners`,
		linkPattern: `${routes.LOCATIONS}/:id/projects/:projectId/partners`,
		component: LocationPartners,
	},
	// {
	// 	linkText: 'Testing',
	// 	linkTo: `${routes.LOCATIONS}/${id}/projects/${projectId}/testing`,
	// 	linkPattern: `${routes.LOCATIONS}/:id/projects/:projectId/testing`,
	// 	component: LocationTestWrapper,
	// },
	// {
	// 	linkText: 'Apps',
	// 	linkTo: `${routes.LOCATIONS}/${id}/projects/${projectId}/myapps`,
	// 	linkPattern: `${routes.LOCATIONS}/:id/projects/:projectId/myapps`,
	// 	component: LocationApps,
	// },
	// {
	// 	linkText: 'Print Report',
	// 	linkTo: `${routes.LOCATIONS}/${id}/projects/${projectId}/report`,
	// 	linkPattern: `${routes.LOCATIONS}/:id/projects/:projectId/report`,
	// 	component: LocationReport,
	// 	// component: LocationMapsReport,
	// },
	/*
		Redirect routes
	*/

	{
		linkPattern: `${routes.LOCATIONS}/:id/projects/:projectId/addpartners`,
		component: LocationAddPartners,
	},

	{
		redirectFrom: `${routes.LOCATIONS}/:id/projects/:projectId`,
		redirectTo: `${routes.LOCATIONS}/${id}/projects/${projectId}/notes`,
	},
]

export default getLocationProjectRoutes
