import type { Props } from './ChatMessageList'
import type { IChatMessage } from 'features/Chat/Chat.types'

const fixture: Props = {
	className: 'ChatMessageList',
	currentMessages: Array(100)
		.fill(0)
		.map((v, i) => ({
			senderId: { name: `Person ${(i % 2) + 1}` },
			message: `Message ${i + 1}`,
		})) as IChatMessage[],
}

export default fixture
