import React from 'react'
import { Avatar, AvatarGroup } from '@mui/material'
import type { IAvatars } from '../Chat.types'

const renderChatHeaderAvatars = (avatars: IAvatars) => {
	if (avatars.length < 3) {
		return avatars.map((avatar, index) => (
			<Avatar key={index} alt={avatar.name} src={avatar.src} sx={{ width: 40, height: 40 }} />
		))
	}

	return avatars.map((avatar, index) => {
		if (index === 0) {
			return (
				<Avatar key={index} alt={avatar.name} src={avatar.src} sx={{ width: 40, height: 40 }} />
			)
		}
		if (index === 1) {
			return (
				<Avatar key={index} sx={{ width: 40, height: 40, bgcolor: '#027fff', color: 'whitesmoke' }}>
					<span style={{ fontSize: `12px`, position: `relative`, left: `4px` }}>{`+${
						avatars.length - 1
					}`}</span>
				</Avatar>
			)
		}
		return null
	})
}

export default renderChatHeaderAvatars
