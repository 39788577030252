import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useTable from 'components/controls/useTable'
import Message from 'components/Message'
import Spinner from 'components/Spinner'
import Layout from 'components/Layout'
import EmptyLogo from 'images/empty_folder.png'
import TasksTable from '../TasksTable'
import { initializeAssignedTasks } from 'slices/tasksSlice'
import Placeholder from 'components/Placeholder'

const headerCells = [
	{ id: 'taskName', label: 'Task' },
	{ id: 'packageid.assetName', label: 'Asset' },
	{ id: 'projectid.projectName', label: 'Project' },
	{ id: 'taskStatus', label: 'Status' },
	{ id: 'daysout', label: 'Due' },
]

const Tasks_Index = () => {
	const dispatch = useDispatch()

	const searchInputRef = useRef(null)
	const [searchInputValue, setSearchInputValue] = useState('')

	const [filterFn, setFilterFn] = useState({
		fn: items => {
			return items
		},
	})

	const {
		entities: taskEntitiess,
		error,
		ids: taskIds,
		tasksLoading: isLoading,
		isInitialized: tasksInitialized,
	} = useSelector(state => state.tasks)
	const tasks = taskIds.map(id => taskEntitiess[id])
	const recordCount = tasks.length

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo: user } = userLogin

	const userId = user._id

	useEffect(() => {
		// if (!tasksInitialized) {
		dispatch(
			initializeAssignedTasks({
				userId: userId,
			}),
		)
		// }
	}, [userId, dispatch])

	const records = tasks

	const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
		records,
		headerCells,
		filterFn,
	)

	const handleSearch = e => {
		const { value } = e.target
		setSearchInputValue(value)
		setFilterFn({
			fn: items => {
				if (value === '') return items
				return items.filter(x => x.taskName.toLowerCase().includes(value.toLowerCase()))
			},
		})
		// set focus to search input after rerender
		setTimeout(() => {
			searchInputRef.current.focus()
		}, 0)
	}

	return (
		<div>
			<React.Fragment>
				{isLoading ? (
					<Spinner containerHeight="50vh" />
				) : !records ? (
					<Message variant="danger">{error}</Message>
				) : error ? (
					<Message variant="danger">{error}</Message>
				) : records.length === 0 ? (
					<Placeholder aspectRatio="16:9" isFlexColumn isTransparent>
						<h2 className="h2">We don't have any Tasks, yet.</h2>
						<div>
							<img className="details__avatar" src={EmptyLogo} alt="Avatar" />
						</div>
						<div>Tasks are created and assigned to specific location projects or portfolios.</div>
						{/* <div>To create a new taks select a Location in the Locations section.</div> */}
					</Placeholder>
				) : (
					<>
						<Layout.PageToolbar
							searchInputRef={searchInputRef}
							searchInputValue={searchInputValue}
							searchInputOnChange={handleSearch}
						/>

						<TasksTable
							tasks={recordsAfterPagingAndSorting()}
							TblContainer={TblContainer}
							TblHeader={TblHeader}
							TblPagination={TblPagination}
							recordCount={recordCount}
							user={user}
						/>
					</>
				)}
			</React.Fragment>
		</div>
	)
}

export default Tasks_Index
