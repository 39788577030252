import React, { useState } from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import TWControls from 'components/controls/TWControls'
import { Form, useForm } from 'components/controls/useForm'
import Spinner from 'components/Spinner'
import { Grid, Paper, InputAdornment } from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'
import FileUploader from 'components/FileUploader'
import { FaImage } from 'react-icons/fa'
import styled from 'styled-components'

const LocationAddNew = ({ locationAdd, popupGuide }) => {
	const [address, setAddress] = useState('')
	const [picLoading, setPicLoading] = useState(false)
	const [picLoaded, setPicLoaded] = useState(false)

	const locationTypes = [
		{ id: 1 },
		{ selectValue: 'Commercial Center' },
		{ id: 2 },
		{ selectValue: 'Commercial Location' },
		{ id: 3 },
		{ selectValue: 'Retail Center' },
		{ id: 4 },
		{ selectValue: 'Retail Location' },
	]

	const initialFValues = {
		id: 0,
		user: '',
		assetName: '',
		assetType: 'location',
		locationType: 'Retail Location',
		retailer: '',
		address: '',
		city: '',
		stateProvince: '',
		postalCode: '',
		cbsa: '',
		country: '',
		latitude: '',
		longitude: '',
		sellingSqft: '',
		image: null,
		logo: null,
		updateNote: 'New Location',
	}

	const validate = () => {
		let temp: any = { ...errors }
		temp.assetName = values.assetName ? '' : '*Required field'
		temp.address = values.address ? '' : '*Required field'
		temp.city = values.city ? '' : '*Required field'
		temp.stateProvince = values.stateProvince ? '' : '*Required field'
		temp.postalCode = values.postalCode ? '' : '*Required field'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialFValues,
		true,
		validate,
	)

	const submitHandler = e => {
		e.preventDefault()
		if (validate() && picLoaded) {
			locationAdd(values, resetForm)
		}
	}

	const handleSetAddress = async value => {
		setAddress(value)
	}
	const handleSelect = async value => {
		const results = await geocodeByAddress(value)
		const latLng = await getLatLng(results[0])
		const place = results[0]

		setAddress(value)

		var newName = value.split(',')
		values.assetName = newName[0]
		values.address = ''
		values.postalCode = ''
		values.city = ''
		values.stateProvince = ''
		values.country = ''
		values.latitude = ''
		values.longitude = ''

		for (const component of place.address_components) {
			const componentType = component.types[0]

			switch (componentType) {
				case 'street_number': {
					values.address = `${component.long_name} ${values.address}`
					break
				}
				case 'route': {
					values.address += component.short_name
					break
				}
				case 'postal_code': {
					values.postalCode = `${component.long_name}${values.postalCode}`
					break
				}
				case 'postal_code_suffix': {
					values.postalCode = `${values.postalCode}-${component.long_name}`
					break
				}
				case 'locality':
					values.city = component.long_name
					break
				case 'administrative_area_level_1': {
					values.stateProvince = component.short_name
					break
				}
				case 'country':
					values.country = component.short_name
					break
				default:
					break
			}
		}
		values.latitude = latLng.lat
		values.longitude = latLng.lng
		setAddress('')
	}

	const uploadImage = async info => {
		setPicLoading(true)
		values.image = info.originalUrl
		setPicLoading(false)
		setPicLoaded(true)
	}

	const closeForm = () => {
		setPicLoaded(true)
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container spacing={1}>
				<Grid item xs={8}>
					<StyledAutocomplete>
						<PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
							{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
								<div>
									<TWControls.Input
										// size="small"
										type="search"
										label="Location Search"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<SearchIcon />
												</InputAdornment>
											),
										}}
										{...getInputProps()}
									/>

									<StyledDropdown>
										<Paper>
											{loading ? <div> ...loading</div> : null}

											{suggestions.map(suggestion => {
												const style = suggestion.active
													? { backgroundColor: '#41b6e6', padding: '4px 14px' }
													: { backgroundColor: `#B9EDF8`, padding: '4px 14px' }

												return (
													<div {...getSuggestionItemProps(suggestion, { style })}>
														{suggestion.description}
													</div>
												)
											})}
										</Paper>
									</StyledDropdown>
								</div>
							)}
						</PlacesAutocomplete>
					</StyledAutocomplete>
				</Grid>
				<Grid item xs={4}>
					<TWControls.SelectType
						size="small"
						name="locationType"
						label="Location Type"
						value={values.locationType}
						onChange={handleInputChange}
						options={locationTypes}
					/>
				</Grid>

				<Grid item xs={6}>
					<TWControls.Input
						size="small"
						name="assetName"
						label="Location Name"
						value={values.assetName}
						onChange={handleInputChange}
						// error={errors.assetName}
					/>
				</Grid>
				<Grid item xs={6}>
					<TWControls.Input
						size="small"
						name="address"
						label="Address"
						value={values.address}
						onChange={handleInputChange}
						// error={errors.address}
					/>
				</Grid>
				<Grid item xs={4}>
					<TWControls.Input
						size="small"
						name="city"
						label="City"
						value={values.city}
						onChange={handleInputChange}
						// error={errors.city}
					/>
				</Grid>
				<Grid item xs={4}>
					<TWControls.Input
						size="small"
						name="stateProvince"
						label="State/Prov"
						value={values.stateProvince}
						onChange={handleInputChange}
						// error={errors.stateProvince}
					/>
				</Grid>
				<Grid item xs={4}>
					<TWControls.Input
						size="small"
						name="postalCode"
						label="Postal Code"
						value={values.postalCode}
						onChange={handleInputChange}
						// error={errors.postalCode}
					/>
				</Grid>
				<Grid item xs={4}>
					<TWControls.Input
						size="small"
						name="latitude"
						label="Latitude"
						value={values.latitude}
						onChange={handleInputChange}
						// error={errors.latitude}
					/>
				</Grid>
				<Grid item xs={4}>
					<TWControls.Input
						size="small"
						name="longitude"
						label="Longitude"
						value={values.longitude}
						onChange={handleInputChange}
						// error={errors.longitude}
					/>
				</Grid>
				<Grid item xs={4}>
					<TWControls.Input
						size="small"
						name="country"
						label="Country"
						value={values.country}
						onChange={handleInputChange}
						// error={errors.country}
					/>
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						{picLoading ? (
							<Spinner />
						) : values.image ? (
							<img className="newLocation__image" src={values.image} alt="" />
						) : null}
					</div>
					{/* <div className="newLocation__imageHolder">
						<FileUploader onChange={info => uploadImage(info)}>
							<FaImage />
							Add Image
						</FileUploader>
					</div> */}
				</Grid>
				<Grid item xs={12}>
					{values.image ? (
						<div>
							<div className="newLocation__imageHolder">
								<FileUploader onChange={info => uploadImage(info)}>
									<FaImage />
									Change Image
								</FileUploader>
							</div>
							<div className="newLocation__imageHolder">
								<TWControls.Button type="submit" text="Submit" onClick={closeForm} />
							</div>
						</div>
					) : (
						<div>
							<div className="newLocation__imageHolder">
								<FileUploader onChange={info => uploadImage(info)}>
									<FaImage />
									Add Image
								</FileUploader>
							</div>

							<div className="newLocation__instructions">
								<h3>{`Now select the 'Add Image' button above to import an image that represents the location you've selected.`}</h3>
							</div>
						</div>
					)}
				</Grid>
			</Grid>
		</Form>
	)
}

const StyledAutocomplete = styled.div`
	position: relative;
`
const StyledDropdown = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	z-index: 9999;
`
// const StyledDropdownInner = styled.div`
// 	padding: 0 8px 8px 8px;
// `
export default LocationAddNew
