import axios from 'axios'

const geocodeAddress = async (address, apiKey) => {
	const encodedAddress = encodeURIComponent(address)

	const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${apiKey}`

	try {
		const response = await axios.get(url)
		console.log(`response: `, response)
		if (response.data.status === 'OK') {
			const location = response.data.results[0].geometry.location

			return {
				lat: location.lat,
				lng: location.lng,
			}
		} else {
			throw new Error('Geocoding failed')
		}
	} catch (error) {
		console.error('Error geocoding address:', error)
		throw error
	}
}

export default geocodeAddress
