import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import LocationNoteFeed from '../contentPages/LocationNoteFeed'

const LocationNotesScreen = () => {
	const { id } = useParams()
	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	const { location, loading } = useSelector(state => {
		return {
			loading: state.locations.isLoading,
			success: state.locations.isInitialized,
			location: state.locations.entities[id],
		}
	})

	// TODO: Create skeleton loader
	if (loading || !location) return null

	return <LocationNoteFeed locationId={id} userInfo={userInfo} />
}

export default LocationNotesScreen
