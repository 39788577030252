import React, { useEffect } from 'react'
import { Avatar, Grid, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import TWControls from './controls/TWControls'
import { Form, useForm } from './controls/useForm'
import useTableNoPage from 'components/controls/useTableNoPage'

const headerCells = [
	{ id: 'image', label: '' },
	{ id: 'name', label: 'Partner' },
	{ id: 'company', label: 'Company' },
	{ id: 'trustedPartner', label: 'Trusted?' },
	{ id: 'trustedShare', label: 'Sharing?' },
]

const FilePartners = ({ partners, updateFileSharing, recordForEdit }) => {
	const defaultValues = {
		_id: 0,
		assignedTo: '',
		taskName: '',
		description: '',
		completedBy: '',
		completed: false,
		// secured: true,
	}

	const initialValues = {
		// ...defaultValues,
		...recordForEdit,
	}

	const validate = () => {
		let temp = { ...errors }
		// temp.assignedTo = values.assignedTo ? '' : 'This field is required.'
		// temp.taskName = values.taskName ? '' : 'This field is required.'
		// temp.description = values.description ? '' : 'This field is required.'
		// temp.completedBy = values.completedBy ? '' : 'Email is not valid.'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialValues,
		true,
		validate,
	)

	const records = partners
	const { TblContainer, TblHeader, TblPagination } = useTableNoPage(
		records,
		headerCells,
		// filterFn,
	)

	const submitHandler = e => {
		e.preventDefault()

		if (validate()) {
			updateFileSharing(values, resetForm)
		}
	}

	useEffect(() => {
		if (recordForEdit != null) setValues({ ...recordForEdit })
	}, [recordForEdit, setValues])

	const handleStatusChange = (name, value, id) => {
		const newValues = { ...values }

		// setValues(newValues)
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container>
				<>
					<Grid item>
						<div>
							<TWControls.CheckBox
								name="secured"
								label="File Locked?"
								value={values.secured}
								onChange={handleInputChange}
							/>
						</div>
						<div>
							<TblContainer>
								<TblHeader />
								<TableBody>
									{partners.map(partner => (
										<TableRow key={partner.id}>
											<TableCell>
												<Avatar src={partner.image} />
											</TableCell>
											<TableCell>{partner.name}</TableCell>
											<TableCell>{partner.company}</TableCell>
											<TableCell>{partner.trustedPartner ? `Yes` : `No`}</TableCell>
											<TableCell>
												{values.secured && partner.trustedPartner
													? `Yes`
													: values.secured && partner.trustedPartner === false
													? `No`
													: !values.secured && partner.trusted
													? `Yes`
													: !values.secured && partner.trustedPartner === false
													? `Yes`
													: `Yes`}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</TblContainer>
						</div>
						<div className="newLocation__imageHolder">
							<TWControls.Button size="small" type="submit" text="Update" />
							{/* <TWControls.Button size="small" text="Reset" color="default" onClick={resetForm} /> */}
						</div>
					</Grid>
				</>
			</Grid>
		</Form>
	)
}

export default FilePartners
