import React from 'react'

const Locations_New = () => {
	return (
		<div>
			<h1>New Location</h1>
			<p>TODO: Add new location form here</p>
		</div>
	)
}

export default Locations_New
