import React from 'react'
import { useSelector } from 'react-redux'
import ArchivedPortfolios from '../components/ArchivedPortfolios'

const ArchivedPortfoliosLibrary = () => {
	const userLogin = useSelector(state => state.userLogin)
	const { userInfo: user } = userLogin
	const userId = user._id

	return <ArchivedPortfolios userId={userId} />
}

export default ArchivedPortfoliosLibrary
