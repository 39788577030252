import React from 'react'
import { FormControl, InputLabel, MenuItem, Select as MuiSelect } from '@material-ui/core'

export default function SelectType({ name, label, value, onChange, options, ...other }) {
	return (
		<FormControl variant="outlined">
			<InputLabel>{label}</InputLabel>
			<MuiSelect label={label} name={name} value={value} onChange={onChange} {...other}>
				{/* <MenuItem value="none">None</MenuItem> */}
				{options.map((item, index) => (
					<MenuItem key={index} value={item.selectValue}>
						{item.selectValue}
					</MenuItem>
				))}
			</MuiSelect>
		</FormControl>
	)
}
