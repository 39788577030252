import React from 'react'
import { useSelector } from 'react-redux'
import PartnerCards from 'components/PartnerCards'
import { useParams } from 'react-router-dom'

const PortfolioPartnersScreen = () => {
	const { id } = useParams()

	const { isInitialized, portfolio, loading } = useSelector(state => {
		return {
			isInitialized: state.portfolios.isInitialized,
			loading: state.portfolios.isLoading,
			portfolio: state.portfolios.entities[id],
		}
	})

	// // TODO: Create skeleton loader
	if (loading || !isInitialized || !portfolio) return null

	return <PartnerCards partnerType="portfolios" source={portfolio} />
}

export default PortfolioPartnersScreen
