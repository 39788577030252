import styled from 'styled-components'
import type { Props } from './Container'
import * as _ from 'styles/helpers'

const StyledContainer = styled.div<Partial<Props>>`
	width: 100%;
	max-width: ${_.rem(_.MAX_WIDTH)};
	margin-left: auto;
	margin-right: auto;
	padding-left: ${_.rem(_.CONTAINER_PADDING)};
	padding-right: ${_.rem(_.CONTAINER_PADDING)};
`

export default StyledContainer
