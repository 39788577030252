import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
const PORTFOLIO_FILES = 'portfolioFiles'

const initialState = {
	entities: {},
	isLoading: false,
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

export const getPortfolioFiles = createAsyncThunk(
	`${PORTFOLIO_FILES}/getFiles`,
	async (portfolioId, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.get(
				`/api/portfolioFiles/portfolio/${portfolioId}`,
				prepConfig(getState),
			)
			return data
		} catch (error) {
			console.error(error)
			return rejectWithValue('Failed to get images')
		}
	},
)

export const createPortfolioFile = createAsyncThunk(
	`${PORTFOLIO_FILES}/create`,
	async (portfolioFile, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/api/portfolioFiles`, portfolioFile, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to get notes')
		}
	},
)

export const updatePortfolioFile = createAsyncThunk(
	`${PORTFOLIO_FILES}/update`,
	async (file: any, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.put(
				`/api/portfoliofiles/${file._id}`,
				file,
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to get notes')
		}
	},
)

const portfolioFileSlice = createSlice({
	name: 'portfolioFiles',
	initialState,
	reducers: {},
	extraReducers: {
		[getPortfolioFiles.fulfilled as any]: (state, action) => {
			const files = action.payload
			const fileMap = files.reduce((lookup, item) => {
				lookup[item._id] = item
				return lookup
			}, {})
			state.entities = { ...state.entities, ...fileMap }
			state.isLoading = false
		},
		[getPortfolioFiles.pending as any]: state => {
			state.isLoading = true
		},
		[createPortfolioFile.fulfilled as any]: (state, action) => {
			const newFile = action.payload
			state.entities[newFile._id] = newFile
		},
		[updatePortfolioFile.fulfilled as any]: (state, action) => {
			const file = action.payload
			state.entities[file._id] = file
		},
	} as any,
})

export default portfolioFileSlice
