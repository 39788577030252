import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MapLocationRGM from 'components/MapLocationRGM'
import { Grid } from '@mui/material'
import { FaRegCircle } from 'react-icons/fa'
import Layout from 'components/Layout/Layout'
import ListAction from 'features/Actions/components/ListAction'
import { useParams } from 'react-router-dom'
import { initializeApiKey } from 'slices/apiKeysSlice'
import { getCenterTenants } from 'slices/locationSlice'

const LocationMapsScreen = () => {
	const dispatch = useDispatch()
	const { id } = useParams()
	const [ringRadius, setRingRadius] = useState(0)
	const [allRings, setAllRings] = useState(false)

	const { entity: apiKey } = useSelector(state => state.apiKey)

	const { location, loading, isInitialized } = useSelector(state => {
		return {
			loading: state.locations.isLoading,
			success: state.locations.isInitialized,
			location: state.locations.entities[id],
			isInitialized: state.locations.isInitialized,
		}
	})

	const isCenter = location.assetType === 'center'

	const {
		centerTenantEntities,
		centerTenantIds,
		centerTenantsLoading,
		centerTenantsInitialized,
	} = useSelector(state => state.locations)
	const centerTenants = centerTenantIds.map(id => centerTenantEntities[id])

	console.log(`centerTenants: `, centerTenants)

	useEffect(() => {
		if (!centerTenantsInitialized && isCenter) {
			dispatch(getCenterTenants(id))
		}
	}, [dispatch, centerTenantsInitialized, isCenter])

	const handleRing1 = () => {
		setRingRadius(1)
		setAllRings(false)
	}

	const handleRing2 = () => {
		setRingRadius(3)
		setAllRings(false)
	}

	const handleRing3 = () => {
		setRingRadius(5)
		setAllRings(false)
	}
	const handleAllRingsRing = () => {
		setRingRadius(0)
		setAllRings(true)
	}

	var mapLat = 0
	var mapLong = 0
	var zoomLevel = 10

	if (!isInitialized) return null
	const locationPins = centerTenants.map(centerTenant => {
		// const item = locationEntities[centerTenant._id]

		return {
			id: centerTenant._id,
			name: centerTenant.assetName,
			address: centerTenant.address,
			city: centerTenant.city,
			state: centerTenant.stateProvince,
			lat: centerTenant.latitude,
			lng: centerTenant.longitude,
			image: centerTenant.image,
		}
	})
	if (locationPins.length > 1) {
		const arrayLat = locationPins.map(function (pin) {
			return pin.lat
		})
		const arrayLong = locationPins.map(function (pin) {
			return pin.lng
		})
		mapLat = (Math.max.apply(null, arrayLat) + Math.min.apply(null, arrayLat)) / 2
		mapLong = (Math.min.apply(null, arrayLong) + Math.max.apply(null, arrayLong)) / 2
	} else {
		mapLat = location.latitude || 39.5
		mapLong = location.longitude || -98.35
		zoomLevel = 10
		// if (mapLat === 39.5) zoomLevel = 14
	}

	// TODO: Create skeleton loader
	if (loading || !location || !location.latitude || !location.longitude) return null

	return (
		<Grid container spacing={3}>
			<Grid item xs={9}>
				<MapLocationRGM
					apiKey={apiKey.key}
					asset={location}
					centerPin={[mapLat, mapLong]}
					useRings={true}
					ringRadius={ringRadius}
					allRings={allRings}
					markers={locationPins}
					useCenterPin={true}
					zoom={zoomLevel}
				/>
			</Grid>
			<Grid item xs={3}>
				<Layout.SidebarItem title="Actions" hasNoMaxHeight>
					<ul>
						<li className="actionList__item">
							<ListAction
								handleAction={handleRing1}
								actionLable={'1 Mile Ring'}
								actionIcon={<FaRegCircle />}
							/>
						</li>
						<li className="actionList__item">
							<ListAction
								handleAction={handleRing2}
								actionLable={'3 Mile Ring'}
								actionIcon={<FaRegCircle />}
							/>
						</li>
						<li className="actionList__item">
							<ListAction
								handleAction={handleRing3}
								actionLable={'5 Mile Ring'}
								actionIcon={<FaRegCircle />}
							/>
						</li>
						<li className="actionList__item">
							<ListAction
								handleAction={handleAllRingsRing}
								actionLable={'1/3/5 Mile Rings'}
								actionIcon={<FaRegCircle />}
							/>
						</li>
					</ul>
				</Layout.SidebarItem>
			</Grid>
		</Grid>
	)
}

export default LocationMapsScreen
