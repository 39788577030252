import React from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

export default function DatePicker({ name, label, value, onChange, disabled = false }) {
	const convertToDefEventPara = (name, value) => ({
		target: {
			name,
			value,
		},
	})
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardDatePicker
				// disableToolbar
				// margin="normal"
				id="date-picker-dialog"
				variant="dialog"
				inputVariant="outlined"
				label={label}
				format="MM/dd/yy"
				name={name}
				value={value}
				disabled={disabled}
				onChange={date => onChange(convertToDefEventPara(name, date))}
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
			/>
		</MuiPickersUtilsProvider>
	)
}
