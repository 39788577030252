import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Waypoint } from 'react-waypoint'
import { ChatProvider } from 'features/Chat'
import Header from 'components/Header/Header.container'
import AppRoutes from './AppRoutes'
import StyledApp from './App.style'
import NewUserPopup from 'components/popups/NewUserPopup/NewUserPopup'

const Layout = ({ children, isLoggedIn }: any) => {
	const [isScrolled, setIsScrolled] = useState(false)

	if (isLoggedIn) {
		return (
			<ChatProvider>
				<StyledApp className="App" isScrolled={isScrolled}>
					<Header className="App__header" />
					{/* <NewUserPopup /> */}
					<Waypoint onEnter={() => setIsScrolled(false)} onLeave={() => setIsScrolled(true)} />
					<main className="App__main">{children}</main>
				</StyledApp>
			</ChatProvider>
		)
	}
	return <main>{children}</main>
}

const App = () => {
	const userLogin = useSelector((state: any) => state.userLogin)
	const isLoggedIn = userLogin.userInfo !== null && userLogin.userInfo !== undefined

	return (
		<div>
			<Layout isLoggedIn={isLoggedIn}>
				<AppRoutes />
			</Layout>
		</div>
	)
}

export default App
