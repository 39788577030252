import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from 'components/Message'
import Spinner from 'components/Spinner'
import { initializeUsers } from 'slices/usersSlice'
import UserEditForm from 'components/UserEditForm'
import Popup from 'components/Popup'
import {
	Avatar,
	Paper,
	TableBody,
	TableCell,
	TableRow,
	Toolbar,
	InputAdornment,
	IconButton,
} from '@mui/material'
import useTable from 'components/controls/useTable'
import TWControls from 'components/controls/TWControls'
import { Add as AddIcon, Edit as EditIcon, Search as SearchIcon } from '@mui/icons-material'
import { addMyPartner } from 'slices/myPartnersSlice'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
	pageContent: {
		margin: theme.spacing(3),
		padding: theme.spacing(3),
		// // display: 'flex',
		// width: '900px',
	},
	searchInput: {
		width: '85%',
	},
	newButton: {
		position: 'absolute',
		right: '10px',
	},
}))

const headerCells = [
	{ id: 'image', label: 'Image' },
	{ id: 'name', label: 'Partner' },
	{ id: 'firstName', label: 'First Name' },
	{ id: 'title', label: 'Title' },
	{ id: 'company', label: 'Company' },
	{ id: 'email', label: 'Email' },
	{ id: 'isAdmin', label: 'Admin ?' },
	{ id: 'update', label: 'Edit', disableSorting: true },
	{ id: 'connect', label: 'Add as Partner', disableSorting: true },
	// { id: 'update', label: 'Edit/Delete', disableSorting: true },
]

const Users_Index = ({ history }) => {
	const classes = useStyles()
	const [filterFn, setFilterFn] = useState({
		fn: items => {
			return items
		},
	})
	const [openPopup, setOpenPopup] = useState(false)

	const dispatch = useDispatch()

	const userList = useSelector(state => state.userList.users)
	const { data: users, loading, error } = userList

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	const userDelete = useSelector(state => state.userDelete)
	const { success: successDelete } = userDelete

	useEffect(() => {
		if (userInfo && userInfo.isAdmin) {
			dispatch(initializeUsers())
		} else {
			history.push('/')
		}
	}, [dispatch, history, successDelete, userInfo])

	const records = users
	const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
		records,
		headerCells,
		filterFn,
	)

	const handleSearch = e => {
		let target = e.target
		setFilterFn({
			fn: items => {
				if (target.value === '') return items
				else return items.filter(x => x.name.toLowerCase().includes(target.value))
			},
		})
	}

	const addPartnerHandler = id => {
		dispatch(addMyPartner(id))
		history.push(`/portfolios/${id}/partners`)
	}

	// const handleClearSearch = () => {
	//      // setFilterFn()
	// }

	return (
		<>
			<Paper className={classes.pageContent}>
				{/* </Paper>
			<Paper className={classes.pageContent}> */}
				<br />
				<Toolbar>
					<TWControls.Input
						// label="Search"
						type="search"
						className={classes.searchInput}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
						onChange={handleSearch}
					/>
					{/* <IconButton color="primary" size="small" onClick={handleClearSearch}>
						<ClearIcon />
					</IconButton> */}
					<TWControls.Button
						className={classes.newButton}
						text="Add New"
						variant="outlined"
						size="large"
						startIcon={<AddIcon />}
						onClick={() => setOpenPopup(true)}
					/>
				</Toolbar>
				<TblContainer>
					<TblHeader />
					{loading ? (
						<Spinner />
					) : error ? (
						<Message variant="danger">{error}</Message>
					) : (
						<TableBody>
							{recordsAfterPagingAndSorting().map(user => (
								<TableRow key={user._id}>
									<TableCell>
										<Avatar src={user.image} />
									</TableCell>
									<TableCell>
										<Link to={`/admin/users/${user._id}/edit`}>{user.name}</Link>
									</TableCell>
									<TableCell>{user.firstName}</TableCell>
									<TableCell>{user.title}</TableCell>
									<TableCell>{user.company}</TableCell>
									<TableCell>
										<a href={`mailto:${user.email}`}>{user.email}</a>
									</TableCell>
									<TableCell>
										{user.isAdmin ? (
											<i
												className="fas fa-check"
												style={{
													color: 'green',
												}}
											></i>
										) : (
											<i
												className="fas fa-times"
												style={{
													color: 'red',
												}}
											></i>
										)}
									</TableCell>
									<TableCell>
										<Link to={`/admin/users/${user._id}/edit`}>
											<IconButton color="primary" size="small">
												<EditIcon />
											</IconButton>
										</Link>

										{/* <IconButton
											color="secondary"
											size="small"
											onClick={() => deleteHandler(user._id)}
										>
											<DeleteIcon />
										</IconButton> */}
									</TableCell>
									<TableCell>
										<IconButton
											color="primary"
											size="small"
											onClick={() => addPartnerHandler(user._id)}
										>
											<AddIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					)}
				</TblContainer>
				{loading ? (
					<Spinner />
				) : error ? (
					<Message variant="danger">{error}</Message>
				) : (
					<TblPagination />
				)}
			</Paper>
			<Popup title="New Partner" openPopup={openPopup} setOpenPopup={setOpenPopup}>
				<UserEditForm />
			</Popup>
		</>
	)
}

export default Users_Index
