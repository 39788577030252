import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import swal from 'sweetalert'
import Message from 'components/Message'
import Spinner from 'components/Spinner'
import { Avatar, Grid } from '@mui/material'
import { Create as CreateIcon } from '@mui/icons-material'
import TWControls from 'components/controls/TWControls'
import { Form } from 'components/controls/useForm'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		height: 140,
		width: 100,
	},
	control: {
		padding: theme.spacing(2),
	},
	newButton: {
		position: 'absolute',
		right: '10px',
	},
}))

const Tasks_Edit = ({ history, match }) => {
	const dispatch = useDispatch()
	const taskId = match.params.id
	const classes = useStyles()
	const params = useParams()
	const { id } = params

	debugger

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	const taskDetails = useSelector(state => state.taskDetails)
	const { task, loading, error, success } = taskDetails

	const taskUpdate = useSelector(state => state.taskUpdate)
	const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = taskUpdate

	const userId = userInfo._id

	const statusTypes = [
		{ id: 1 },
		{ selectValue: 'Open' },
		{ id: 2 },
		{ selectValue: 'In Progress' },
		{ id: 3 },
		{ selectValue: 'On Hold' },
		{ id: 4 },
		{ selectValue: 'Completed' },
	]

	const [input, setInput] = useState('')
	const [createdBy, setCreatedBy] = useState('')
	const [parentId, setParentId] = useState('')
	const [seq, setSeq] = useState('')
	const [assignedTo, setAssignedTo] = useState('')
	const [packageid, setPackageid] = useState('')
	const [packageType, setPackageType] = useState('')
	const [portfolioid, setPortfolioid] = useState('')
	const [templateId, setTemplateId] = useState('')
	const [taskName, setTaskName] = useState('')
	const [description, setDescription] = useState('')
	const [taskDays, setTaskDays] = useState('')
	const [completedBy, setCompletedBy] = useState('')
	const [taskStatus, setTaskStatus] = useState('')
	const [dateStarted, setDateStarted] = useState('')
	const [dateCompleted, setDateCompleted] = useState('')
	const [completed, setCompleted] = useState('')
	const [comments, setComments] = useState([])

	// const partners = location.partners.map(item => {
	// 	return {
	// 		assignedTo: item.partner.partner,
	// 		assignee: item.partner.name,
	// 	}
	// })

	// const currentUser = [
	// 	{
	// 		assignedTo: user._id,
	// 		assignee: user.name,
	// 	},
	// ]

	// const allPartners = [...currentUser, ...partners]

	useEffect(() => {
		if (successUpdate) {
			// dispatch({ type: TASK_UPDATE_RESET })
			// history.push(1)
		} else {
			if (task._id !== taskId) {
				// dispatch(getTaskDetails(taskId))
			} else {
				setCreatedBy(task.createdBy.name)
				setParentId(task.parentId)
				setSeq(task.seq)
				setAssignedTo(task.assignedTo.name)
				setPackageid(task.packageid)
				setPackageType(task.packageType)
				setPortfolioid(task.portfolioid)
				setTemplateId(task.templateId)
				setTaskName(task.taskName)
				setDescription(task.description)
				setTaskDays(task.taskDays)
				setCompletedBy(task.completedBy)
				setTaskStatus(task.taskStatus)
				setDateStarted(task.dateStarted)
				setDateCompleted(task.dateCompleted)
				setCompleted(task.completed)
				setComments(task.comments)
			}
		}
	}, [dispatch, history, task, taskId, successUpdate, userInfo])

	const submitHandler = async e => {
		e.preventDefault()

		const updatedTask = {
			_id: taskId,
			createdBy: task.createdBy._id,
			parentId,
			seq,
			assignedTo: task.assignedTo._id,
			packageid,
			packageType,
			portfolioid,
			templateId,
			taskName,
			description,
			taskDays,
			completedBy,
			taskStatus,
			dateCompleted,
			dateStarted,
			completed,
			comments,
		}
		// dispatch(updateTask(updatedTask))
		history.push(`/tasks/${taskId}/edit`)
	}

	const taskNoteCommentHandler = () => {
		const newComments = [...task.comments, { commenter: userId, comment: input }]

		const updatedTask = {
			_id: taskId,
			createdBy: task.createdBy._id,
			parentId: parentId,
			seq: seq,
			assignedTo: task.assignedTo._id,
			packageid: packageid,
			packageType: packageType,
			portfolioid: portfolioid,
			templateId: templateId,
			taskName: taskName,
			description: description,
			taskDays: taskDays,
			completedBy: completedBy,
			taskStatus: taskStatus,
			dateCompleted: dateCompleted,
			dateStarted: dateStarted,
			completed: completed,
			comments: newComments,
		}

		// dispatch(updateTask(updatedTask))
	}

	const statusChangeHandler = e => {
		switch (task.taskStatus) {
			case 'Open': {
				switch (e) {
					case 'In Progress':
						setTaskStatus(e)
						setDateStarted(Date())
						setDateCompleted(null)
						setCompleted(false)
						break
					case 'On Hold':
						setTaskStatus(e)
						if (!dateStarted) {
							setDateStarted(Date())
						}
						setDateCompleted(null)
						setCompleted(false)
						break
					case 'Completed':
						swal({
							title: 'Task Completed',
							text:
								'This action will set this task as Completed. No further updates to the task will be allowed. Are you sure of this?',
							type: 'warning',
							buttons: [true, 'Yes, set as Complete!'],
							// cancelButtonText: 'No, keep working',
						}).then(willDelete => {
							if (willDelete) {
								swal(
									`Completed!`,
									`Notifications have been sent to ${createdBy} and ${assignedTo} acknowledging that this task has been set as complete.`,
									'success',
								)
								setTaskStatus(e)
								if (!dateStarted) {
									setDateStarted(Date())
								}
								setDateCompleted(Date())
								setCompleted(true)
							}
						})
						break
					default:
						break
				}
				break
			}
			case 'In Progress': {
				switch (e) {
					case 'Open':
						setTaskStatus(e)
						setDateStarted(null)
						setDateCompleted(null)
						setCompleted(false)
						break
					case 'On Hold':
						setTaskStatus(e)
						if (!dateStarted) {
							setDateStarted(Date())
						}
						setDateCompleted(null)
						setCompleted(false)
						break
					case 'Completed':
						setTaskStatus(e)
						if (!dateStarted) {
							setDateStarted(Date())
						}
						setDateCompleted(Date())
						setCompleted(true)
						break

					default:
						break
				}
				break
			}
			case 'On Hold': {
				switch (e) {
					case 'Open':
						setTaskStatus(e)
						setDateStarted(null)
						setDateCompleted(null)
						setCompleted(false)
						break
					case 'In Progress':
						setTaskStatus(e)
						if (!dateStarted) {
							setDateStarted(Date())
						}
						setDateCompleted(null)
						setCompleted(false)
						break
					case 'Completed':
						setTaskStatus(e)
						if (!dateStarted) {
							setDateStarted(Date())
						}
						setDateCompleted(Date())
						setCompleted(true)
						break

					default:
						break
				}
				break
			}
			default:
				break
		}
	}

	const CommentDateTime = ({ date }) => {
		const dateToday = moment(Date()).format('MM/DD/YYYY')
		const dateComment = moment(date).format('MM/DD/YYYY')

		if (dateToday > dateComment) {
			return moment(date).format('MMMM Do YYYY')
		} else {
			return `Today @ ${moment(date).format('h:mm a')}`
		}
	}

	return (
		// <div>
		// 	{success ? <h2>{`Got here! - ${taskId} - ${task.taskName}`}</h2> : <h2>Didn't work</h2>}

		// </div>
		<div>
			<div>
				<Form onSubmit={submitHandler}>
					<Grid container className="taskFeed_inputContainer" spacing={2}>
						<Grid className="taskFeed_headerText" item xs={9}>
							Edit: {taskName}
						</Grid>
						<Grid className="taskFeed_button" item xs={3}>
							<TWControls.Button
								className="text-right"
								text="Update"
								variant="outlined"
								size="medium"
								type="submit"
							/>
						</Grid>
					</Grid>
				</Form>
			</div>
			<div>
				<br></br>
				<br></br>
			</div>

			{loadingUpdate && <Spinner />}
			{errorUpdate && <Message variant="danger">{errorUpdate}</Message>}

			{loading ? (
				<Spinner />
			) : error ? (
				<Message variant="danger">{error}</Message>
			) : (
				<div>
					<Form>
						<Grid container className="taskFeed_inputContainer" spacing={2}>
							<Grid item xs={12}>
								<TWControls.Input
									size="small"
									name="taskName"
									label="Task Name"
									value={taskName}
									onChange={e => setTaskName(e.target.value)}
								/>
							</Grid>
							<Grid item xs={6}>
								<TWControls.Input
									size="small"
									name="description"
									label="Description"
									value={description}
									onChange={e => setDescription(e.target.value)}
								/>
								<TWControls.DatePicker
									size="small"
									name="completedBy"
									label="Due Date"
									value={completedBy ? completedBy : null}
									onChange={e => setCompletedBy(e.target.value)}
								/>
								<TWControls.Input
									size="small"
									name="dateStarted"
									label="Date Started"
									value={dateStarted ? moment(dateStarted).format('MM/DD/YYYY') : null}
								/>
							</Grid>
							<Grid item xs={6}>
								<TWControls.Input
									size="small"
									name="assignedTo"
									label="Assigned To"
									value={assignedTo}
									onChange={e => setAssignedTo(e.target.value)}
								/>
								<TWControls.SelectType
									className="feed_select"
									// size="small"
									name="taskStatus"
									label="Task Status"
									value={taskStatus}
									onChange={e => statusChangeHandler(e.target.value)}
									options={statusTypes}
								/>
								<TWControls.Input
									size="small"
									name="dateCompleted"
									label="Date Completed"
									value={dateCompleted ? moment(dateStarted).format('MM/DD/YYYY') : null}
								/>
							</Grid>
						</Grid>
					</Form>
				</div>
			)}

			<div>
				<br></br>
			</div>

			{loading ? (
				<Spinner />
			) : error ? (
				<Message variant="danger">{error}</Message>
			) : (
				<div>
					<Form>
						<Grid container className="taskFeed_inputContainer" spacing={2}>
							<Grid item xs={12}>
								{task ? (
									<div>
										<div className="feed_input">
											<Form>
												<CreateIcon />
												<input
													value={input}
													onChange={e => setInput(e.target.value)}
													type="text"
													placeholder="comment. . ."
												/>
												<button onClick={taskNoteCommentHandler} type="submit"></button>
											</Form>
										</div>
										<div>
											{task.comments ? (
												<Form>
													{task.comments.map(comment => (
														<div>
															<div container key={comment._id} className="feed__inputOptions">
																<div className="feed_avatar">
																	<Avatar src={comment.commenter.image}></Avatar>
																</div>
																<div className="feed_comment">
																	<>{comment.comment}</>
																</div>
															</div>
															<div>
																<p className="taskFeed__date">
																	<CommentDateTime date={comment.commentDateTime} />
																	{/* {moment(comment.commentDateTime).format(
																		'MMMM Do YYYY, h:mm:ss a',
																	)} */}
																</p>
															</div>
														</div>
													))}
												</Form>
											) : (
												<h2>derp</h2>
											)}
										</div>
									</div>
								) : null}
							</Grid>
						</Grid>
					</Form>
				</div>
			)}
		</div>
	)
}

export default Tasks_Edit
