import LocationNotes from '../navPages/LocationNotes'
import LocationProjects from '../navPages/LocationProjects'
import LocationAnalytics from '../navPages/LocationAnalytics'
import LocationMaps from '../navPages/LocationMaps'
import routes from 'constants/routes'
import Locations_Edit from '../Locations_Edit'
import LocationPerformanceScreen from '../contentPages/LocationPerformancScreen'

const getLocationRoutes = (id: string) => [
	{
		linkText: 'Projects ',
		linkTo: `${routes.LOCATIONS}/${id}/projects`,
		linkPattern: `${routes.LOCATIONS}/:id/projects`,
		component: LocationProjects,
	},

	{
		linkText: 'Notes ',
		linkTo: `${routes.LOCATIONS}/${id}/notes`,
		linkPattern: `${routes.LOCATIONS}/:id/notes`,
		component: LocationNotes,
	},

	{
		linkText: 'Map',
		linkTo: `${routes.LOCATIONS}/${id}/maps`,
		linkPattern: `${routes.LOCATIONS}/:id/maps`,
		component: LocationMaps,
	},

	{
		linkText: 'Performance',
		linkTo: `${routes.LOCATIONS}/${id}/performance`,
		linkPattern: `${routes.LOCATIONS}/:id/performance`,
		component: LocationPerformanceScreen,
	},

	{
		linkText: 'Demographics',
		linkTo: `${routes.LOCATIONS}/${id}/analytics`,
		linkPattern: `${routes.LOCATIONS}/:id/analytics`,
		component: LocationAnalytics,
	},

	/*
		Redirect routes
	*/
	{
		linkPattern: `${routes.LOCATIONS}/:id/edit`,
		component: Locations_Edit,
	},
	{
		redirectFrom: `${routes.LOCATIONS}/:id`,
		redirectTo: `${routes.LOCATIONS}/${id}/projects`,
	},
]

export default getLocationRoutes
