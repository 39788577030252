import React from 'react'
import { Avatar } from '@mui/material'
import StyledPartnerAvatar from './PartnerAvatar.style'

export interface Props {
	className?: string
	name: string
	src?: string
	isOnline?: boolean
	size?: string
}

const PartnerAvatar = ({ className = '', name, src, size = '40', isOnline }: Props) => {
	return (
		<StyledPartnerAvatar
			data-testid="PartnerAvatar"
			className={className}
			isOnline={isOnline}
			size={size}
		>
			<Avatar alt={name} src={src} sx={{ width: 40, height: 40 }} />
		</StyledPartnerAvatar>
	)
}

export default PartnerAvatar
