import React from 'react'
import { Grid } from '@mui/material'
import TWControls from '../controls/TWControls'
import { Form } from '../controls/useForm'

const PortfolioLocationRemovePopup = ({ locationRemove, location }) => {
	const submitHandler = e => {
		locationRemove(location)
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<h2 className="newLocation__imageHolder">NOTICE! </h2>
				</Grid>
				<Grid item xs={12}>
					<p>This will remove the location '{location.assetName}' from this portfolio. </p>
					<p>
						All of {location.assetName}'s notes, partners, tasks, files and images are preserved and
						remain available through {location.assetName}'s individual record in Locations.{' '}
					</p>
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<TWControls.Button size="small" type="submit" text="Delete" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default PortfolioLocationRemovePopup
