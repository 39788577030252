import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import LocationAdminMap from '../components/LocationAdminMap'

const LocationArchiveMap = () => {
	const { entities, ids, isLoading, isInitialized } = useSelector(state => state.locations)

	const locations = ids.map(id => entities[id])

	// // TODO: Create skeleton loader
	if (isLoading || !isInitialized || !locations) return null

	return <LocationAdminMap locations={locations} />
}

export default LocationArchiveMap
