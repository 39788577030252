import ChainXY_Catalogue from './thirdPartyAppComponents/ChainXY_Catalogue'
import ChainXY_Fleet from './thirdPartyAppComponents/ChainXY_Fleet'
import ChainXY_Improve from './thirdPartyAppComponents/ChainXY_Improve'
import Carto_Video from './thirdPartyAppComponents/Carto_Video'
import routes from 'constants/routes'

const getPartnerAppRoutes = (id: string) => [
	{
		linkText: 'Locations ',
		linkTo: `${routes.PARTNERAPPS}/${id}/catalog`,
		linkPattern: `${routes.PARTNERAPPS}/:id/catalog`,
		component: ChainXY_Catalogue,
	},
	{
		linkText: 'Projects ',
		linkTo: `${routes.PARTNERAPPS}/${id}/fleet`,
		linkPattern: `${routes.PARTNERAPPS}/:id/fleet`,
		component: ChainXY_Fleet,
	},
	{
		linkText: 'Integrations ',
		linkTo: `${routes.PARTNERAPPS}/${id}/improve`,
		linkPattern: `${routes.PARTNERAPPS}/:id/improve`,
		component: ChainXY_Improve,
	},
	{
		linkText: 'Demonstration ',
		linkTo: `${routes.PARTNERAPPS}/${id}/carto`,
		linkPattern: `${routes.PARTNERAPPS}/:id/carto`,
		component: Carto_Video,
	},

	{
		redirectFrom: `${routes.PARTNERAPPS}/:id`,
		redirectTo: `${routes.PARTNERAPPS}/${id}/catalog`,
	},
]

export default getPartnerAppRoutes
