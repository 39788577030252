import React, { ReactNode } from 'react'
import StyledContainer from './Container.style'

export interface Props {
	className?: string
	children?: ReactNode
}

const Container = ({ className = '', children }: Props) => {
	return (
		<StyledContainer data-testid="Container" className={className}>
			{children}
		</StyledContainer>
	)
}

export default Container
