import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'components/Spinner'
import Message from 'components/Message'
import Placeholder from 'components/Placeholder'
import ImageGallery from 'components/ImageGallery'
import EmptyLogo from 'images/empty_folder.png'
import { deletePortfolioImage, getPortfolioImages } from 'slices/portfolioImagesSlice'

export interface Props {
	className?: string
	[x: string]: any
}

const PortfolioImageGalleryContainer = ({ className }: Props) => {
	const dispatch = useDispatch()
	const params = useParams()
	const portfolioid = (params as any).id

	const userLogin = useSelector((state: any) => state.userLogin)
	const { userInfo } = userLogin

	const { portfolioImages, error, loading } = useSelector((state: any) => {
		const portfolio = state.portfolios.entities[portfolioid]
		const images = state.portfolioImages.entities
		return {
			portfolioImages: portfolio?.images?.map(imgId => images[imgId] || {}) || [],
			error: state.portfolioImages.error,
			loading: state.portfolioImages.isLoading,
		}
	})

	const userid = userInfo._id

	useEffect(() => {
		// @ts-ignore
		dispatch(getPortfolioImages(portfolioid))
	}, [dispatch, userid, portfolioid])

	const handleDeleteImage = (id: string) => {
		if (window.confirm('Are you sure?')) dispatch(deletePortfolioImage(id))
	}

	if (loading) return <Spinner containerHeight="50vh" />
	if (error) return <Message variant="danger">{error}</Message>
	// if (portfolioImages.length === 0) return <Placeholder>1 No Images</Placeholder>

	return (
		<div>
			{portfolioImages.length === 0 ? (
				// <Placeholder>1 No Images</Placeholder>
				<Placeholder aspectRatio="16:9" isFlexColumn isTransparent>
					<h2 className="h2">We don't have any images, yet.</h2>
					<div>
						<img className="details__avatar" src={EmptyLogo} alt="Avatar" />
					</div>
				</Placeholder>
			) : (
				<ImageGallery
					className={className}
					locationImages={portfolioImages}
					handleDeleteImage={handleDeleteImage}
					handlePreviewImage={handleDeleteImage}
				/>
			)}
		</div>
	)
}

export default PortfolioImageGalleryContainer
