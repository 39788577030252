// Login/Register
import LoginScreen from './login/LoginScreen'
import EmailConfirm from './login/LoginScreen/EmailConfirm'
import RegisterConfirm from './login/LoginScreen/RegisterConfirm'

import Home from './Home'
import Profile from './Profile'
import Company_Profile from './admin/Company_Profile'
import UserProfile from './admin/UserProfile'

// Admin
import Users_Index from './admin/Users_Index'
// import Users_Edit from './admin/Users_Edit'

// ArchiveLibrary
import ArchiveLibrary_Index from './archiveLibrary/ArchiveLibrary_Index'
import ArchiveLibrary_Show from './archiveLibrary/ArchiveLibrary_Show'

// LocationAdminTools
import LocationAdminTools_Index from './locationAdminTools/LocationAdminTools_Index'
import LocationAdminTools_Show from './locationAdminTools/LocationAdminTools_Show'

// Chat
import ChatPage from './chat/ChatPage'

// Community
import Community_Page from './community/Community_Page'

// Locations
import Locations_Index from './locations/Locations_Index'
import Locations_New from './locations/components/Locations_New'
import Locations_Show from './locations/Locations_Show' // _OLD
import Locations_Project_Show from './locations/Locations_Project_Show' // Projects

// Partners
import Partners_Index from './partners/Partners_Index'

// Partner Apps
import PartnerApps_Index from './partnerApps/PartnerApps_Index'
import PartnerApps_Show from './partnerApps/PartnerApps_Show'

// Portfolios
import Portfolios_Index from './portfolios/Portfolios_Index'
import Portfolios_Show from './portfolios/Portfolios_Show'

// Projects
import LocationProjects from './locations/navPages/LocationProjects'
import Location_Project_Show from './locations/Locations_Project_Show'

// Projects Index
import Projects_Index from './projects/Projects_Index'

// Tasks
import Tasks_Index from './tasks/Tasks_Index'
import Tasks_Edit from './tasks/Tasks_Edit'
import Tasks_Show from './tasks/Tasks_Show'

// Templates
import Templates_Index from './templates/Templates_Index'
import TemplateTask_Show from './templates/TemplateTask_Show'
import TemplateSiteDetail_Show from './templates/TemplateSiteDetail_Show'

// 404 Not Found
import FourOhFour from './FourOhFour'

const SCREENS = {
	// Login/Register
	LoginScreen,
	EmailConfirm,
	RegisterConfirm,

	Home,
	// Profile,
	Company_Profile,
	UserProfile,

	// Locations
	Locations_Index,
	Locations_New,
	Locations_Show,
	Locations_Project_Show, // Projects

	LocationProjects,
	Location_Project_Show,

	// Chats
	ChatPage,

	// Commons
	Community_Page,

	// Partner Apps
	PartnerApps_Index,
	PartnerApps_Show,

	// Tasks
	Tasks_Index,
	Tasks_Edit,
	Tasks_Show,

	// Templates
	Templates_Index,
	TemplateSiteDetail_Show,
	TemplateTask_Show,

	// Partners
	Partners_Index,

	// Portfolios
	Portfolios_Index,
	// Portfolios_New,
	Portfolios_Show,

	// Projects
	Projects_Index,

	// Admin
	Users_Index,
	// Users_Edit,

	// ArchiveLibrary
	ArchiveLibrary_Index,
	ArchiveLibrary_Show,

	// LocationsAdminTools
	LocationAdminTools_Index,
	LocationAdminTools_Show,

	// 404 Not Found
	FourOhFour,
}

export default SCREENS
