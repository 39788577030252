import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
const PROJECT_IMAGES = 'projectImages'

const initialState = {
	entities: {},
	isLoading: false,
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

export const getProjectImages = createAsyncThunk(
	`${PROJECT_IMAGES}/getNotes`,
	async (projectId, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.get(`/api/projectImages/${projectId}`, prepConfig(getState))
			return data
		} catch (error) {
			console.error(error)
			return rejectWithValue('Failed to get images')
		}
	},
)

export const createProjectImage = createAsyncThunk(
	`${PROJECT_IMAGES}/create`,
	async (projectImage, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/api/projectImages`, projectImage, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to get notes')
		}
	},
)

export const deleteProjectImage = createAsyncThunk(
	`${PROJECT_IMAGES}/delete`,
	async (imageid: any, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.delete(`/api/projectimages/${imageid}`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to create center')
		}
	},
)

// Extract the key out of the entitiy object
// eslint-disable-next-line no-unused-vars
const removeKey = (key, { [key]: _, ...rest }) => rest

const projectImageSlice = createSlice({
	name: 'projectImages',
	initialState,
	reducers: {},
	extraReducers: {
		[getProjectImages.fulfilled as any]: (state, action) => {
			const images = action.payload
			const imageMap = images.reduce((lookup, item) => {
				lookup[item._id] = item
				return lookup
			}, {})
			state.entities = { ...state.entities, ...imageMap }
			state.isLoading = false
		},
		[getProjectImages.pending as any]: state => {
			state.isLoading = true
		},
		[createProjectImage.fulfilled as any]: (state, action) => {
			const newNote = action.payload
			state.entities[newNote._id] = newNote
		},
		[deleteProjectImage.fulfilled as any]: (state, action) => {
			const imageId = action.meta.arg
			state.entities = removeKey(imageId, state.entities)
		},
	} as any,
})

export default projectImageSlice

// export const { } = projectsSlice.actions;
