import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { read, utils } from 'xlsx'
import { Box, LinearProgress } from '@mui/material'
import UploadImage from '../../images/uploadfile.png'
import styled from 'styled-components'
import * as _ from 'styles/helpers'
import Layout from 'components/Layout'
import Spinner from 'components/Spinner'
import { createBulkLocations } from 'slices/bulkLocationSlice'
import geocodeAddress from 'functions/geocode'

const MyDropzone = ({ apiKey }) => {
	const dispatch = useDispatch()
	const [dropped, setDropped] = useState(false)
	const [working, setWorking] = useState(false)
	const [bulkLocations, setBulkLocations] = useState([])

	const onDrop = useCallback(acceptedFiles => {
		setWorking(true)
		acceptedFiles.forEach(async file => {
			if (file) {
				setDropped(true)
				const data = await file.arrayBuffer()
				const workbook = read(data)
				const workSheet = workbook.Sheets[workbook.SheetNames[0]]
				const sheetData = utils.sheet_to_json(workSheet)

				setTimeout(() => {
					setBulkLocations(sheetData as any)
				}, 500)
			}
		})
	}, [])
	const { getRootProps, getInputProps } = useDropzone({ onDrop })

	useEffect(() => {
		const fetchNewLocations = async () => {
			return Promise.all(
				bulkLocations.map(async (location: any) => {
					const address = `${location.assetName} ${location.city}, ${location.stateProvince} ${location.postalCode}`

					return await geocodeAddress(address, apiKey).then(data => {
						return {
							...location,
							latitude: data.lat,
							longitude: data.lng,
						}
					})
				}),
			)
		}

		const getNewLocations = async () => {
			if (bulkLocations.length > 0) {
				const newBulkLocations = await fetchNewLocations()

				if (newBulkLocations.length > 0) {
					dispatch(createBulkLocations(newBulkLocations))
				}
			}
		}

		getNewLocations()
	}, [apiKey, dispatch, bulkLocations])

	console.log(`working: `, working)
	return (
		<Layout>
			<Layout.Body>
				<div>
					{working ? (
						<Box sx={{ width: '100%' }}>
							<LinearProgress />
							<p>File loading. . .</p>
						</Box>
					) : (
						// <Spinner containerHeight="50vh" />
						<StyledDropzone {...getRootProps()}>
							<div className="InputContent">
								<input {...getInputProps()} />
								<img className="InputContent" src={UploadImage} alt="Avatar" />

								<h2>Drag 'n' drop a file here, or click to select a file</h2>
							</div>
						</StyledDropzone>
					)}
				</div>
			</Layout.Body>
		</Layout>
	)
}

const StyledDropzone = styled.div`
	.InputContent {
		background-color: #b9edf8;
		width: 100%;
		height: 60vh;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		border: 2px dashed ${_.COLORS.tradewind_blue};
		border-radius: 15px;

		h2 {
			${_.MIXINS.vc};
			${_.TYPOGRAPHY.h2};
			font-size: ${_.rem(28)};
			font-weight: 400;
			color: ${_.COLORS.tradewind_blue};
			margin: 5;
			padding: ${_.rem(20)};
			grid-gap: ${_.rem(8)};
		}
		img {
			width: 200px;
			height: 200px;
			border: none;
		}
	}
`

export default MyDropzone
