import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

const RenderSubRoutes = ({ routes, state }: any) => {
	// console.log(`RenderSubRoute: `)

	return (
		<Switch>
			{routes.map((link: any, index: number) => {
				if (link.redirectFrom && link.redirectTo) {
					return (
						<Route key={index} path={link.redirectFrom} exact>
							<Redirect to={{ pathname: link.redirectTo, state }} />
						</Route>
					)
				}
				return <Route key={index} path={link.linkPattern} exact component={link.component} />
			})}
		</Switch>
	)
}

export default RenderSubRoutes
