export const MAX_WIDTH = 1400 // 1280
export const CONTAINER_PADDING = 24
export const HEADER_HEIGHT = 80
export const HEADER_HEIGHT_MOBILE = 60
export const BASE_BORDER_RADIUS = 3

// BREAKPOINTS

export const BP = {
	small: 480,
	medium: 768,
	large: 1024,
	xlarge: 1200,
}

// COLORS

const colors = {
	white: '#fff',
	white_smoke: '#eee',

	black: '#444',
	rich_black: '#000',

	gray_100: '#edf2f7',
	gray_200: '#e2e8f0',
	gray_300: '#cbd5df',
	gray_400: '#a0aec0',
	gray_500: '#708096',
	gray_600: '#4a5568',
	gray_700: '#2d3748',
	gray_800: '#1a202c',
	gray_900: '#171923',

	blue_100: '#ceedff',
	blue_200: '#8fcdf4',
	blue_300: '#63b3ed',
	blue_400: '#4299e1',
	blue_500: '#3082ce',
	blue_600: '#2a69ac',
	blue_700: '#1f4e8b',
	blue_800: '#153e75',
	blue_900: '#1a365d',
	blue_twd: 'rgb(0, 128, 255)',

	red_100: '#fed7d7',
	red_200: '#feb2b2',
	red_300: '#fb8181',
	red_400: '#f56565',
	red_500: '#e53e3e',
	red_600: '#c53030',
	red_700: '#9b2d2c',
	red_800: '#822827',
	red_900: '#63171b',

	orange_100: '#feebc8',
	orange_200: '#fbd38d',
	orange_300: '#f6ad54',
	orange_400: '#ed8936',
	orange_500: '#dd6b1f',
	orange_600: '#c05621',
	orange_700: '#9c4221',
	orange_800: '#7a341e',
	orange_900: '#652b19',

	green_100: '#c5f6d5',
	green_200: '#9be6b4',
	green_300: '#67d390',
	green_400: '#48bb78',
	green_500: '#38a169',
	green_600: '#2f8559',
	green_700: '#286749',
	green_800: '#21543d',
	green_900: '#1c4532',

	// Brand
	tradewind_blue: '#0080fe',
	tradewind_blue_a11y: '#0073e6',
	tradewind_border_original: '#95c4d4',
}

export const COLORS = {
	...colors,
	gray: colors.gray_500,
	blue: colors.blue_500,
	red: colors.red_500,
	orange: colors.orange_500,
	green: colors.green_500,

	primary: colors.blue_500,
	success: colors.green_500,
	danger: colors.red_500,
	warning: colors.orange_300,
	info: colors.gray_500,

	link: colors.tradewind_blue,

	foreground: colors.black,
	background: colors.white,
}

// FONTS

export const FONTS = {
	base_font_size: 16,
	base_font_family: "'Inter', sans-serif",
	regular: '400;',
	medium: '500;',
	bold: '700;',
	extra_bold: '800',
}
