import React, { useState } from 'react'
import SidebarProjectsRecent from 'components/Sidebars/SidebarProjectsRecent'
import Layout from 'components/Layout'
import Projects from './components/Projects/Projects'

const Projects_Index = () => {
	const [openPopup, setOpenPopup] = useState(false)

	return (
		<Layout>
			<Layout.Page>
				<Layout.Sidebar>
					<SidebarProjectsRecent />
				</Layout.Sidebar>
				<Layout.Body>
					<Layout.PageHeader>
						<h2>Projects</h2>
					</Layout.PageHeader>
					<Projects openPopup={openPopup} setOpenPopup={setOpenPopup} />
				</Layout.Body>
			</Layout.Page>
		</Layout>
	)
}

export default Projects_Index
