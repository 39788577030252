import React, { useState } from 'react'
import SidebarPortfoliosRecent from 'components/Sidebars/SidebarPortfoliosRecent'
import Layout from 'components/Layout'
import Portfolios from './components/Portfolios/Portfolios'

const Portfolios_Index = ({ history }) => {
	const [openPopup, setOpenPopup] = useState(false)

	return (
		<Layout>
			<Layout.Page>
				<Layout.Sidebar>
					<SidebarPortfoliosRecent />
				</Layout.Sidebar>
				<Layout.Body>
					<Layout.PageHeader>
						<h2>Portfolios</h2>
					</Layout.PageHeader>
					<Portfolios openPopup={openPopup} setOpenPopup={setOpenPopup} />
				</Layout.Body>
			</Layout.Page>
		</Layout>
	)
}

export default Portfolios_Index
