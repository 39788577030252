import styled from 'styled-components'
import * as _ from 'styles/helpers'
import type { Props } from './ChatHeader'

const StyledChatHeader = styled.div<Partial<Props>>`
	> div {
		padding: ${_.rem(8)} ${_.rem(16)};
		min-height: ${_.rem(68)};
	}

	.ChatHeader__secondary-text {
		display: block;
		overflow: hidden;
		textoverflow: ellipsis;
		whitespace: nowrap;
	}

	svg {
		color: ${_.COLORS.tradewind_blue};
	}
`

export default StyledChatHeader
