import styled from 'styled-components'
import type { Props } from './DemographicsCharts'
import * as _ from 'styles/helpers'

const StyledDemographicsCharts = styled.div<Partial<Props>>`
	> div {
		margin-bottom: ${_.rem(24)};
	}

	.key-value {
		.key,
		.value {
			display: block;
		}
		.key {
			font-size: ${_.rem(12)};
			font-weight: 600;
		}
		.value {
		}
	}

	.chart-title {
		text-align: center;
	}

	.DemographicsCharts {
		// .DemographicsCharts__header
		&__header {
			margin-bottom: ${_.rem(48)};

			.h2 {
				display: flex;
				grid-gap: ${_.rem(24)};
			}
		}

		// .DemographicsCharts__grid
		&__grid {
			display: flex;
			grid-gap: ${_.rem(24)};

			> div {
				flex: 1;
			}
		}

		// .DemographicsCharts__chart
		&__chart {
			display: flex;
			justify-content: center;
			align-items: center;
			/* height: ${_.rem(320)}; */
		}

		// .DemographicsCharts__toggle-button
		&__toggle-button {
			${_.MIXINS.vhc}
			margin-top: ${_.rem(48)};
		}
	}
`

export default StyledDemographicsCharts
