import React, { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Layout from 'components/Layout'
import RenderSubRoutes from 'components/RenderSubRoutes'
import getPartnerAppRoutes from './getPartnerAppRoutes'
import SidebarPartnerAppTop from '../../components/Sidebars/SidebarPartnerAppTop'
import SidebarMyPartnerApps from '../../components/Sidebars/SidebarMyPartnerApps'
import { getLocationByID } from 'slices/locationSlice'

const PartnerApp_Show = () => {
	const dispatch = useDispatch()
	const params = useParams()

	const currentRoute = useLocation()

	const pathArray = currentRoute.pathname.split('/')
	const isPartnerPage = pathArray[pathArray.length - 1] === 'partners'

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	// TODO - little confused by this file. But I'll update location redux anyway
	const { id } = params

	const {
		entities: appEntities,
		ids: partnerAppIds,
		isLoading: partnerAppsLoading,
		isInitialized: partnerAppsInitialized,
	} = useSelector(state => state.partnerApps)
	const partnerApp = appEntities[id]

	// const partnerAppDetails = useSelector(state => state.partnerAppDetails)
	// const { partnerApp, success: appSuccess } = partnerAppDetails

	const subRoutes = getPartnerAppRoutes(id)

	// useEffect(() => {
	// 	dispatch(getLocationByID(id))
	// 	// dispatch(getLocationDetails(id))
	// 	// dispatch(getPartnerAppDetails(id))
	// }, [dispatch, id])

	// const partners = location.partners
	// const assetOwner = location.owner

	return (
		<Layout>
			<Layout.SecondaryNav links={subRoutes} />
			<Layout.Page>
				<Layout.Sidebar>
					{partnerAppsInitialized ? (
						<>
							<SidebarPartnerAppTop partnerApp={partnerApp} />
							<SidebarMyPartnerApps />

							{/* {isPartnerPage ? (
								<SidebarAddLocationPartner />
							) : (
								<SidebarPartnerAppPartners
									assetOwner={assetOwner}
									assetPartners={partners}
									assetName={location.assetName}
									userId={userInfo._id}
								/>
							)} */}
						</>
					) : null}
				</Layout.Sidebar>
				<Layout.Body>
					<RenderSubRoutes routes={subRoutes} />
				</Layout.Body>
			</Layout.Page>
		</Layout>
	)
}

export default PartnerApp_Show
