import { combineReducers } from 'redux'
import apiKeysSlice from 'slices/apiKeysSlice'
import appResourceSlice from 'slices/appResourceSlice'
import authReducer from 'slices/authSlice'
import bulkLocationSlice from 'slices/bulkLocationSlice'
import chatSlice from 'slices/chatSlice'
import companySlice from 'slices/companySlice'
import demographicsSlice from 'slices/demographicsSlice'
import myPartnerAppSlice from 'slices/myPartnerAppSlice'
import partnerAppSlice from 'slices/partnerAppSlice'
import portfolioSlice from 'slices/portfolioSlice'
import fleetSlice from 'slices/fleetSlice'
import groupSlice from 'slices/groupSlice'
import portfolioNoteSlice from 'slices/portfolioNoteSlice'
import portfolioImageSlice from 'slices/portfolioImagesSlice'
import portfolioFileSlice from 'slices/portfolioFileSlice'
import projectSlice from 'slices/projectSlice'
import projectNoteSlice from 'slices/projectNoteSlice'
import projectImageSlice from 'slices/projectImagesSlice'
import projectFileSlice from 'slices/projectFileSlice'
import usersReducer from 'slices/usersSlice'
import invitedUserReducer from 'slices/invitedUserSlice'
import siteDetailsReducer from 'slices/siteDetailsSlice'
import tasksReducer from 'slices/tasksSlice'
import myPartnersSlice from 'slices/myPartnersSlice'
import postSlice from 'slices/postSlice'
import locationsSlice from 'slices/locationSlice'
import locationNoteSlice from 'slices/locationNoteSlice'
import locationImageSlice from 'slices/locationImageSlice'
import locationFileSlice from 'slices/locationFileSlice'
import templateSlice from 'slices/templateSlice'

// import {
// 	// TODO - check this one last
// 	centerTenantsUploadReducer,
// } from '../redux/reducers/centerTenantReducers'

const appReducer = combineReducers({
	apiKey: apiKeysSlice.reducer,
	appResource: appResourceSlice.reducer,
	bulkLocations: bulkLocationSlice.reducer,
	chatHeaders: chatSlice.reducer,
	company: companySlice.reducer,
	userLogin: authReducer,
	demographics: demographicsSlice.reducer,
	fleets: fleetSlice.reducer,
	groups: groupSlice.reducer,
	invitedUser: invitedUserReducer,
	locations: locationsSlice.reducer,
	locationFiles: locationFileSlice.reducer,
	locationImages: locationImageSlice.reducer,
	locationNotes: locationNoteSlice.reducer,
	myPartnerApps: myPartnerAppSlice.reducer,
	myPartners: myPartnersSlice,
	partnerApps: partnerAppSlice.reducer,
	portfolios: portfolioSlice.reducer,
	portfolioFiles: portfolioFileSlice.reducer,
	portfolioImages: portfolioImageSlice.reducer,
	portfolioNotes: portfolioNoteSlice.reducer,
	posts: postSlice.reducer,
	projects: projectSlice.reducer,
	projectFiles: projectFileSlice.reducer,
	projectImages: projectImageSlice.reducer,
	projectNotes: projectNoteSlice.reducer,
	siteDetails: siteDetailsReducer,
	tasks: tasksReducer,
	templates: templateSlice.reducer,
	userList: usersReducer,

	// // TODO - REMOVE EVERYTHING
	// centerTenantsUpload: centerTenantsUploadReducer,
	// // TODO - REMOVE EVERYTHING
})

const rootReducer = (state, action) => {
	if (action.type === 'auth/logout') {
		return appReducer(undefined, action)
	}

	return appReducer(state, action)
}

export default rootReducer
