import React, { useState, useEffect } from 'react'
import { CardGrid, LibraryCard } from 'components/Card'
import { useDispatch, useSelector } from 'react-redux'
import Popup from 'components/Popup'
import LocationArchivePopup from 'components/popups/LocationArchivePopup'
import LocationDeletePopup from 'components/popups/LocationDeletePopup'
import { deleteLocation, initializeActiveLocations, patchLocation } from 'slices/locationSlice'

const ArchiveLibraryCards = ({ categories, TblContainer, userId }) => {
	const dispatch = useDispatch()

	return (
		<div>
			<TblContainer>
				<CardGrid>
					{categories.map((category, index) => (
						<LibraryCard
							key={index}
							image={category.image}
							name={category.name}
							linkUrl={`/locations/${category._id}`}
						/>
					))}
				</CardGrid>
			</TblContainer>
		</div>
	)
}

export default ArchiveLibraryCards
