import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import Button from 'components/Button'
import Message from 'components/Message'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { getQueryVariable } from 'utils/query'
import routes from 'constants/routes'

interface ChangePassword {
	password?: string
	passwordCopy?: string
}

const ChangePassword = () => {
	const [hasSubmitted, setHasSubmitted] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const initialValues = {
		password: '',
		passwordCopy: '',
		token: getQueryVariable('token'),
	}

	return (
		<>
			{hasSubmitted ? (
				<div>
					<p>Your email has been updated</p>
					<Link to={routes.LOGIN} className="form__footer-link">
						Click here to login
					</Link>
				</div>
			) : (
				<Formik
					initialValues={initialValues}
					onSubmit={async (values, { setSubmitting }) => {
						const { password, passwordCopy, token } = values
						if (!password) setErrorMessage('Password required')
						else if (password !== passwordCopy) setErrorMessage('Passwords do not match')
						else if (!token) setErrorMessage('invalid token')
						else {
							try {
								const config = {
									headers: {
										'Content-Type': 'application/json',
									},
								}
								const { data } = await axios.post(
									`/api/changePasswords`,
									{ newPassword: password, token },
									config,
								)
								if (data.success) {
									setSubmitting(false)
									setHasSubmitted(true)
								} else {
									setErrorMessage('Something went wrong')
								}
							} catch (e: any) {
								setErrorMessage(
									e.response && e.response.data.message
										? e.response.data.message
										: 'Something went wrong',
								)
							}
						}
					}}
				>
					{({ isSubmitting, errors }) => {
						/*
          TODO: handle form errors in UI
        */
						return (
							<Form>
								<h1 className="form-title">Choose a new password:</h1>
								{errorMessage && <Message variant="danger">{errorMessage}</Message>}
								<div className="inputs">
									<div className="input">
										<Field type="password" name="password" placeholder="Password" />
									</div>
									<div className="input">
										<Field type="password" name="passwordCopy" placeholder="Confirm Password" />
									</div>
								</div>

								<div className="actions">
									<Button type="submit" isLarge disabled={isSubmitting}>
										Reset My Password
									</Button>
								</div>
							</Form>
						)
					}}
				</Formik>
			)}
		</>
	)
}

export default ChangePassword
