import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import swal from 'sweetalert'
import { Grid, InputAdornment } from '@mui/material'
import useTableNoPage from 'components/controls/useTableNoPage'
import Message from 'components/Message'
import Spinner from 'components/Spinner'
import Layout from 'components/Layout'
import Popup from 'components/Popup'
import { CardGrid, PartnerCard } from 'components/Card'
import Button from 'components/Button'
import UserAddNew from 'components/UserAddNew'

import {
	emailInviteExistingPartnerCC,
	emailInviteExistingPartner,
} from 'functions/loginRegisterEmails'

import { initializeUsers } from 'slices/usersSlice'
import { inviteNewUser } from 'slices/invitedUserSlice'
import {
	addMyPartner,
	getMyPendingPartnerShips,
	initializeMyPartners,
} from 'slices/myPartnersSlice'

const Partners_Index = ({ history }) => {
	const dispatch = useDispatch()

	const searchInputRef = useRef(null)
	const [searchInputValue, setSearchInputValue] = useState('')

	const SERVER_BASE_URL =
		process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://app.mydealteams.com'

	const [openPopup, setOpenPopup] = useState(false)

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin
	const userid = userInfo._id

	const {
		entities: userList,
		ids: userListIds,
		isLoading: userListLoading,
		isInitialized: userListInitialized,
		error,
	} = useSelector(state => state.userList)
	const users = userListIds.map(id => userList[id])

	const {
		entities: myPartners,
		ids: myPartnerIds,
		isLoading: myPartnersLoading,
		isInitialized: myPartnersInitialized,
	} = useSelector(state => state.myPartners)
	const myPartnersList = myPartnerIds.map(id => myPartners[id])

	const [filterFn, setFilterFn] = useState({ fn: items => items })

	const headerCells = [{ id: 'name', label: 'Partner' }]

	const fromUser = {
		id: userInfo._id,
		name: userInfo.name,
		email: userInfo.email,
		company: userInfo.company,
	}

	useEffect(() => {
		dispatch(initializeUsers())
		if (!myPartnersInitialized) {
			dispatch(initializeMyPartners())
		}
	}, [dispatch, myPartnersInitialized])

	const handleSearch = e => {
		const { value } = e.target
		setSearchInputValue(value)
		setFilterFn({
			fn: items => {
				if (value === '') return items
				return items.filter(x => x.name.toLowerCase().includes(value.toLowerCase()))
			},
		})

		setTimeout(() => {
			searchInputRef.current.focus()
		}, 0)
	}

	const handleConnect = partner => {
		swal({
			title: `Partnership Invitation!`,
			text: `Add ${partner.name} as a partner to your teams?`,
			icon: 'success',
			buttons: {
				cancel: {
					text: 'Cancel',
					value: null,
					visible: true,
					className: '',
					closeModal: true,
				},
				confirm: {
					text: 'Connect',
					value: true,
					visible: true,
					className: '',
					closeModal: true,
				},
			},
		}).then(willDelete => {
			if (willDelete) {
				dispatch(addMyPartner({ partner: partner._id }))
				swal({
					title: `Invitation Sent!`,
					text: `Congratulations! ${partner.name} has been informed of your partnership invitation. Once they accept you two will both be partners.`,
					icon: 'success',
				})

				const toUser = {
					name: partner.name,
					firstName: partner.firstName,
					email: partner.email,
				}
				const pushLink = `${SERVER_BASE_URL}/partners`

				emailInviteExistingPartner(fromUser, toUser, pushLink)
				emailInviteExistingPartnerCC(fromUser, toUser)
			} else {
				swal({
					title: `Invitation Canceled.`,
					text: `No invitation will be sent.`,
					icon: 'info',
				})
			}
		})
	}

	const userAdd = async (newUser, resetForm) => {
		const emailUser = await dispatch(inviteNewUser({ newUser, inviterId: userid }))

		debugger

		if (emailUser._id) {
			const toUser = {
				name: newUser.name,
				firstName: newUser.firstName,
				email: newUser.email,
			}
			swal({
				title: `Partner Invited!`,
				text: `Good job. You've invited ${toUser.name} to partner with you. An invitation has been emailed to ${toUser.firstName} to join TradeWind as well as a cc to you.`,
				icon: 'success',
				button: 'Ok',
			})

			const pushLink = `${SERVER_BASE_URL}/partners`

			emailInviteExistingPartner(fromUser, toUser, pushLink)
			emailInviteExistingPartnerCC(fromUser, toUser)
		}
		resetForm()
		setOpenPopup(false)
		dispatch(initializeUsers())
		dispatch(initializeMyPartners())
		dispatch(getMyPendingPartnerShips())
	}

	const addPartnersHandler = () => {
		setOpenPopup(true)
	}

	let newPartnerList = []

	if (users?.length > 0 && myPartnersList?.length > 0 && newPartnerList?.length === 0) {
		newPartnerList = users.filter(
			user => !myPartnersList.find(partner => partner.partner._id === user._id) && user,
		)
	} else {
		newPartnerList = users
	}

	const noPagination = true

	const { recordsAfterPagingAndSorting } = useTableNoPage(newPartnerList, headerCells, filterFn)

	if (userListLoading) return <Spinner containerHeight="50vh" />
	if (myPartnersLoading) return <Spinner containerHeight="50vh" />
	if (error) return <Message variant="danger">{error}</Message>

	return (
		<div>
			<React.Fragment>
				<Grid container spacing={3}>
					<Layout.Body>
						<Layout.PageToolbar
							buttonText="Invite New Partner"
							buttonOnClick={() => addPartnersHandler()}
							searchInputRef={searchInputRef}
							searchInputValue={searchInputValue}
							searchInputOnChange={handleSearch}
						/>

						<CardGrid>
							{recordsAfterPagingAndSorting().map(partner => (
								<PartnerCard
									key={partner._id}
									name={partner.name}
									title={partner.title}
									companyName={partner.company}
									avatarUrl={partner.image}
									actions={
										<Button isOutline onClick={() => handleConnect(partner)}>
											Connect
										</Button>
									}
								/>
							))}
						</CardGrid>
					</Layout.Body>
				</Grid>
				<Popup title="Invite New Partner" openPopup={openPopup} setOpenPopup={setOpenPopup}>
					<UserAddNew fromUser={fromUser} userAdd={userAdd} />
				</Popup>
			</React.Fragment>
		</div>
	)
}

export default Partners_Index
