import React from 'react'
import { Link } from 'react-router-dom'
import { FaChartLine } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import {
	Avatar,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
} from '@mui/material'
import { ChevronRight as ChevronRightIcon, LocationOn as LocationOnIcon } from '@mui/icons-material'
import PortfolioBlank from 'images/Portfolio_Blank.png'
import Message from '../Message'
import Layout from 'components/Layout'
import styled from 'styled-components'
import * as _ from 'styles/helpers'

function SidebarPortfoliosRecent() {
	const { portfolios, error, loading } = useSelector(state => {
		const portfolioIds = state.portfolios.recents
		const myRecentPortfolios = portfolioIds.map(c => state.portfolios.entities[c])
		return {
			portfolios: myRecentPortfolios,
			portfolioSuccess: state.portfolios.isInitialized,
			portfoliosLoading: state.portfolios.isLoading,
			error: state.portfolios.error,
		}
	})

	const isEmpty = portfolios?.length === 0

	return (
		<Layout>
			<StyledSidebar>
				<div className="SidebarContent">
					<Layout.SidebarItem
						title="Recent Activity"
						icon={<FaChartLine />}
						isEmpty={isEmpty}
						noPadding
					>
						{loading ? (
							<Layout.SidebarItemLoading />
						) : error ? (
							<Message variant="danger">{error}</Message>
						) : isEmpty ? (
							<span>No Recent Activity</span>
						) : (
							<List dense>
								{portfolios.map((portfolio, index) => (
									<ListItem
										className="SidebarContent__card-body__ListItem"
										key={index}
										component={Link}
										to={`/portfolios/${portfolio._id}`}
									>
										<ListItemAvatar>
											{portfolio.image ? (
												<Avatar src={portfolio.image} />
											) : (
												<Avatar src={PortfolioBlank} />
											)}
										</ListItemAvatar>
										<ListItemText primary={portfolio.assetName} secondary={portfolio.updateNote} />
										<ListItemSecondaryAction className="secondary-action">
											<IconButton edge="end" aria-label="delete">
												<ChevronRightIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								))}
							</List>
						)}
					</Layout.SidebarItem>
				</div>
			</StyledSidebar>
		</Layout>
	)
}
const StyledSidebar = styled.div`
	.SidebarHeader {
		${_.MIXINS.vc}
		margin-bottom: ${_.rem(32)};

		&__avatar {
			width: ${_.rem(68)};
			height: ${_.rem(68)};
			margin-right: ${_.rem(24)};
			border: ${_.rem(4)} solid #0000001a;
		}

		&__greeting {
			${_.MIXINS.mod}
			display: flex;
			justify-content: center;
			flex-direction: column;

			&__username {
				${_.TYPOGRAPHY.p}
			}
			&__title {
				${_.TYPOGRAPHY.h1}
			}
		}
	}

	.SidebarContent {
		/* display: grid;
		grid-gap: ${_.rem(24)};
		grid-template-columns: repeat(12, [col] 1fr); */

		&__card {
			border-top: ${_.rem(4)} solid ${_.COLORS.tradewind_blue};
		}

		&__card-header {
			${_.MIXINS.vc};
			justify-content: space-between;
			margin-bottom: ${_.rem(16)};

			h2 {
				${_.MIXINS.vc};
				${_.TYPOGRAPHY.h2};
				font-size: ${_.rem(22)};
				margin: 0;
				grid-gap: ${_.rem(8)};
			}

			p {
				margin: 0;
				font-size: ${_.rem(11)};
				font-style: italic;
			}
		}

		&__card-body {
			height: 100%;
			max-height: ${_.rem(660)};
			overflow-y: scroll;

			&__ListItem {
				/* font-size: ${_.rem(6)}; */
				text-decoration: none;
				&:hover {
					background-color: ${_.COLORS.gray_100};
					cursor: pointer;
				}
			}
		}

		&__card-empty {
			${_.MIXINS.vhc};
			height: 100%;

			h3 {
				font-size: ${_.rem(16)};
				font-weight: 300;
				color: rgba(0, 0, 0, 0.54);
			}
		}

		&__card {
			min-height: ${_.rem(388)};

			&--partners {
				grid-column: col / span 12;
			}

			&-content {
				padding: 0 ${_.rem(12)};
			}
		}
	}
`

export default SidebarPortfoliosRecent
