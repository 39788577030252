import React from 'react'
import { Link } from 'react-router-dom'
import { RiCloseCircleLine } from 'react-icons/ri'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import type { TableCellProps } from '@mui/material'
import Button from 'components/Button'
import { StyledDetailsTable } from '../Details.style'
import moment from 'moment'

type Details = {
	id: string
	key: string
	value: string
	textValue: string
	numericValue: string
	currencyValue: string
	dateValue: string
	description: string
	detailType: string
	projectid: string
}

export interface Props {
	details?: Details[]
	handleEditDetail: Function
	handleDeleteDetail: Function
	title?: string
}

export type TableHeaders = { label: string; width?: string; props?: TableCellProps }[]

const tableHeaders: TableHeaders = [
	{ label: 'Label', width: '90px' },
	{ label: 'Value', width: '100px', props: { align: 'center' } },
	{ label: 'Note', width: '180px' },
	{ label: '', width: '30px', props: { align: 'right' } },
]

const DetailsTable = ({ details = [], handleEditDetail, handleDeleteDetail, title }: Props) => {
	return (
		<div>
			<StyledDetailsTable data-testid="DetailsTable">
				<TableContainer className="table-container">
					{title && (
						<div className="table__header">
							<div className="table__title">
								<h2>{title}</h2>
							</div>
						</div>
					)}
					<Table>
						{tableHeaders.length > 0 && (
							<TableHead>
								<TableRow>
									{tableHeaders.map((th, i) => (
										<TableCell
											className="DetailsTable__table-cell"
											key={i}
											{...th.props}
											style={{ width: th.width }}
										>
											{th.label}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
						)}
						<TableBody>
							{details.map(detail => (
								<TableRow key={detail.id} className="table-row">
									<TableCell
										className="DetailsTable__table-cell"
										scope="row"
										onClick={() => handleEditDetail(detail.id)}
									>
										<Link to="#">{detail.key}</Link>
									</TableCell>
									<TableCell
										style={{ textAlign: 'center' }}
										onClick={() => handleEditDetail(detail.id)}
									>
										{detail.detailType === 'Date'
											? moment(detail.value).format('MM/DD/YY')
											: detail.value}
									</TableCell>
									<TableCell component="th" scope="row" onClick={() => handleEditDetail(detail.id)}>
										{detail.description}
									</TableCell>
									<TableCell align="right">
										<div className="edit-button">
											<Button
												onClick={() => handleDeleteDetail(detail)}
												variant="ghost-red"
												isOutline
												aria-label="Delete"
											>
												<RiCloseCircleLine />
											</Button>
										</div>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</StyledDetailsTable>
		</div>
	)
}

export default DetailsTable
