import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FaUpload, FaBuilding } from 'react-icons/fa'
import { Avatar } from '@mui/material'
import Logo from 'images/logo-shield.png'
import useMediaQuery from 'hooks/useMediaQuery'
import styled from 'styled-components'
import swal from 'sweetalert'
import { Form, useForm } from 'components/controls/useForm'
import TWControls from 'components/controls/TWControls'
import FileUploader from 'components/FileUploader'
import Layout from 'components/Layout'
import Popup from 'components/Popup'
import Card from 'components/Card'
import Button from 'components/Button'
import * as _ from 'styles/helpers'
import { createCompany, updateCompany } from 'slices/companySlice'
import SidebarCompanyTeam from 'components/Sidebars/SidebarCompanyTeam'
import CompanyAddNewPopup from 'components/popups/CompanyAddNewPopup'

const Company_Profile_Edit = ({ company, userId }) => {
	const dispatch = useDispatch()

	const companyId = company._id

	const isMobile = useMediaQuery(_.MQ.small_and_below)

	const [logo, setLogo] = useState(company.logo)
	const [openPopup, setOpenPopup] = useState(false)
	const [companyUpdated, setCompanyUpdated] = useState(false)

	const { values, setValues, handleInputChange } = useForm(company)

	const submitForm = async values => {
		if (companyUpdated) {
			await dispatch(
				updateCompany({
					_id: companyId,
					companyName: values.companyName,
					reportingName: values.reportingName,
					administrator: values.administrator,
					emailDomain: values.emailDomain,
					address: values.address,
					address2: values.address2,
					city: values.city,
					stateProvince: values.stateProvince,
					postalCode: values.postalCode,
					country: values.country,
					phoneNumber: values.phoneNumber,
					website: values.website,
					logo: values.logo,
					companyType: values.companyType,
					linkedin: values.linkedin,
					instagram: values.instagram,
					facebook: values.facebook,
					twitter: values.twitter,
				}),
			)

			swal({
				text: 'Company Profile Updated',
				icon: 'success',
				button: 'Ok',
			})
			setCompanyUpdated(false)
		}
	}

	const handleSubmit = e => {
		e.preventDefault()
		setCompanyUpdated(true)
		submitForm(values)
	}

	const companyAdd = (location, resetForm) => {
		dispatch(
			createCompany({
				administrator: userId,
			}),
		)
		// dispatch(initializeActiveLocations())
		resetForm()
		setOpenPopup(false)
		setCompanyUpdated(false)
	}

	const uploadImage = async info => {
		setLogo(info.originalUrl)
		const newValues = { ...values, logo: info.originalUrl }
		setValues(newValues)
		submitForm(newValues)
	}

	return (
		<Layout>
			<Layout.Page>
				<Layout.Sidebar>
					<Layout.SidebarUserCard />
					{/* <SidebarCompanyTeam company={company} /> */}
				</Layout.Sidebar>
				<Layout.Body>
					<StyledCompanyProfile isMobile={isMobile}>
						<Form onSubmit={handleSubmit}>
							<Card
								className="UserProfile__Card"
								header={{
									title: values.companyName,
									icon: <FaBuilding />,
									secondary: !isMobile && (
										<Button isOutline onClick={() => setOpenPopup(true)}>
											Build Company Team
										</Button>
									),
								}}
							>
								<div className="UserProfile__User">
									<Avatar src={logo ? logo : Logo} className="UserProfile__Avatar" />
									<FileUploader onChange={info => uploadImage(info)}>
										<FaUpload /> Upload Logo
									</FileUploader>
								</div>

								<h3 className="input-group-title">Company Details</h3>
								<div className="input-group">
									<TWControls.Input
										placeholder="Company Name"
										size="small"
										name="companyName"
										label="Company Name"
										value={values.companyName}
										onChange={handleInputChange}
									/>
									<TWControls.Input
										placeholder="Reporting Name"
										size="small"
										name="reportingName"
										label="Reporting Name"
										value={values.reportingName}
										onChange={handleInputChange}
									/>
								</div>

								<div className="input-group">
									<TWControls.Input
										placeholder="Address"
										size="small"
										name="address"
										label="Address"
										value={values.address}
										onChange={handleInputChange}
									/>
									<TWControls.Input
										placeholder="Address 2"
										size="small"
										name="address2"
										label="Address 2"
										value={values.address2}
										onChange={handleInputChange}
									/>
									<TWControls.Input
										placeholder="Phone Number"
										size="small"
										name="phoneNumber"
										label="Phone Number"
										value={values.phoneNumber}
										onChange={handleInputChange}
									/>
								</div>

								<div className="input-group">
									<TWControls.Input
										placeholder="City"
										size="small"
										name="city"
										label="City"
										value={values.city}
										onChange={handleInputChange}
									/>

									<TWControls.Input
										placeholder="State"
										size="small"
										name="stateProvince"
										label="State"
										value={values.stateProvince}
										onChange={handleInputChange}
									/>
									<TWControls.Input
										placeholder="Postal Code"
										size="small"
										name="postalCode"
										label="Postal Code"
										value={values.postalCode}
										onChange={handleInputChange}
									/>
								</div>

								<div className="input-group">
									<TWControls.Input
										placeholder="Website"
										size="small"
										name="website"
										label="Website"
										value={values.website}
										onChange={handleInputChange}
									/>
									<TWControls.Input
										placeholder="Email Domain"
										size="small"
										name="emailDomain"
										label="Email Domain"
										value={values.emailDomain}
										onChange={handleInputChange}
									/>
									<TWControls.Input
										placeholder="Company Type"
										size="small"
										name="companyType"
										label="Company Type"
										value={values.companyType}
										onChange={handleInputChange}
									/>
								</div>

								<h3 className="input-group-title">Social Media</h3>
								<div className="input-group">
									<TWControls.Input
										placeholder="LinkedIn"
										size="small"
										name="linkedin"
										label="LinkedIn"
										value={values.linkedin}
										onChange={handleInputChange}
									/>
									<TWControls.Input
										placeholder="Instagram"
										size="small"
										name="instagram"
										label="Instagram"
										value={values.instagram}
										onChange={handleInputChange}
									/>
									<TWControls.Input
										placeholder="Facebook"
										size="small"
										name="facebook"
										label="Facebook"
										value={values.facebook}
										onChange={handleInputChange}
									/>
									<TWControls.Input
										placeholder="Twitter"
										size="small"
										name="twitter"
										label="Twitter"
										value={values.twitter}
										onChange={handleInputChange}
									/>
								</div>

								<div className="UserProfile__Actions">
									<Button size="small" type="submit">
										Update
									</Button>
								</div>
							</Card>
						</Form>
					</StyledCompanyProfile>
				</Layout.Body>
			</Layout.Page>
			<Popup title="Create a Company" openPopup={openPopup} setOpenPopup={setOpenPopup}>
				<CompanyAddNewPopup companyAdd={companyAdd} />
			</Popup>
		</Layout>
	)
}

const StyledCompanyProfile = styled.div`
	max-width: ${_.rem(960)};
	margin: 0 auto;

	.UserProfile {
		&__Card {
			h2 {
				overflow: hidden;
				white-space: nowrap;
			}
		}

		&__password-buttton {
			${_.MIXINS.vhc}
			margin: ${_.rem(16)} 0 ${_.rem(24)};
		}

		&__User,
		&__Actions {
			${_.MIXINS.mod}
			${_.MIXINS.vhc}
			flex-direction: column;
			padding: ${_.rem(16)};
		}

		&__Avatar {
			width: ${_.rem(160)};
			height: ${_.rem(160)};
			margin-bottom: ${_.rem(16)};
		}
	}

	input {
		font-size: ${_.rem(18)};
	}

	.input-group {
		${({ isMobile }) =>
			!isMobile &&
			`
      display: flex;
      gap: ${_.rem(16)};
    `}
	}

	.input-group-title {
		margin-top: ${_.rem(16)};
		margin-bottom: ${_.rem(16)};
		font-weight: 400;
		font-size: ${_.rem(14)};
		color: ${_.COLORS.gray_500};
	}
`

export default Company_Profile_Edit
