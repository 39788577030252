import React from 'react'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import renderChatHeaderAvatars from 'features/Chat/utils/renderChatHeaderAvatars'
import StyledChatHeader from './ChatHeader.style'
import type { IAvatars } from 'features/Chat/Chat.types'

export interface Props {
	className?: string
	avatars: IAvatars
	primaryText: string
	secondaryText: string
	isSelected: boolean
	isUnread: boolean
	onClick: any
}

const ChatHeader = ({
	className = '',
	avatars,
	onClick,
	isSelected = false,
	primaryText,
	secondaryText,
	isUnread = false,
}: Props) => {
	return (
		<StyledChatHeader data-testid="ChatHeader" className={className}>
			<ListItem onClick={onClick} selected={isSelected} button disableRipple>
				<AvatarGroup>{renderChatHeaderAvatars(avatars)}</AvatarGroup>
				<ListItemText
					primary={primaryText}
					secondary={<span className="ChatHeader__secondary-text">{secondaryText}</span>}
				/>
				{isUnread && <FiberManualRecordIcon />}
			</ListItem>
		</StyledChatHeader>
	)
}

export default ChatHeader
