import React from 'react'
import { Grid } from '@mui/material'
import TWControls from '../controls/TWControls'
import { Form } from '../controls/useForm'

const PartnerReminderPopup = ({ partner, remindPartner }) => {
	const submitHandler = e => {
		remindPartner(partner)
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container spacing={2}>
				<Grid item className="newLocation__imageHolder" xs={12}>
					<p>
						{`Resend a partner invitation to ${partner.partnerUserId.name} at ${partner.partnerUserId.email}?`}{' '}
					</p>
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<TWControls.Button size="small" type="submit" text="Resend" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default PartnerReminderPopup
