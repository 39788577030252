import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from 'components/Message'
import Spinner from 'components/Spinner'
import { Form } from 'components/controls/useForm'
import AddImage from 'images/add_image.png'
import FileUploader from 'components/FileUploader'
import { FaImage } from 'react-icons/fa'
import styled from 'styled-components'
import TWControls from 'components/controls/TWControls'
import { useParams, useHistory } from 'react-router-dom'
import { initializePortfolios, updatePortfolio } from 'slices/portfolioSlice'
import Layout from 'components/Layout/Layout'
import * as _ from 'styles/helpers'
import Card from 'components/Card'
import Button from 'components/Button'
import { Grid } from '@mui/material'

const Portfolios_Edit = () => {
	const { id: portfolioId } = useParams()
	const history = useHistory()

	const [owner, setOwner] = useState({})
	const [assetName, setPortfolioName] = useState('')
	const [description, setDescription] = useState('')
	const [latitude, setLatitude] = useState('')
	const [longitude, setLongitude] = useState('')
	const [locations, setLocations] = useState([])
	const [partners, setPartners] = useState([])
	const [locationCount, setLocationCount] = useState('')
	const [totalSqFt, setTotalSqFt] = useState('')
	const [activePortfolio, setActivePortfolio] = useState('')
	const [activeUsers, setActiveUsers] = useState([])
	const [image, setImage] = useState('')
	const [picLoading, setPicLoading] = useState(false)

	const dispatch = useDispatch()

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	// const userId = userInfo._id

	const { isLoading: loading, portfolio, error, isInitialized } = useSelector(state => {
		return {
			isLoading: state.portfolios.isLoading,
			portfolio: state.portfolios.entities[portfolioId] || {},
			error: state.portfolios.error,
			isInitialized: state.portfolios.isInitialized,
		}
	})

	useEffect(() => {
		if (!isInitialized) {
			dispatch(initializePortfolios())
		} else {
			setOwner(portfolio.owner)
			setPortfolioName(portfolio.assetName)
			setDescription(portfolio.description)
			setLatitude(portfolio.latitude)
			setLongitude(portfolio.longitude)
			setLocations(portfolio.locations)
			setPartners(portfolio.partners)
			setLocationCount(portfolio.locationCount)
			setTotalSqFt(portfolio.totalSqFt)
			setActivePortfolio(portfolio.activePortfolio)
			setActiveUsers(portfolio.activeUsers)
			setImage(portfolio.image)
		}
	}, [dispatch, portfolioId, isInitialized, portfolio])

	const submitHandler = e => {
		e.preventDefault()
		const updatedPortfolio = {
			_id: portfolioId,
			owner,
			assetName: assetName,
			description: description,
			latitude: latitude,
			longitude: longitude,
			locations: locations,
			partners: partners,
			locationCount: locationCount,
			totalSqFt: totalSqFt,
			activePortfolio: activePortfolio,
			activeUsers: activeUsers,
			image: image,
			updateNote: 'Edited Portfolio.',
		}
		dispatch(updatePortfolio(updatedPortfolio))
		history.push(`/portfolios/${portfolioId}/edit`)
	}

	const uploadImage = async info => {
		setPicLoading(true)
		setImage(info.originalUrl)
		setPicLoading(false)
	}

	return (
		<div>
			<Layout>
				<Layout.Page>
					<Layout.Body>
						<StyledAssetEdit>
							<Form onSubmit={submitHandler}>
								<Card
									className="AssetEdit__Card"
									header={{
										title: assetName,
										// icon: <FaRegSmileWink />,
									}}
								>
									<div className="AssetEdit__Asset">
										{picLoading ? (
											<Spinner />
										) : image ? (
											<img className="AssetEdit__Image" src={image} alt="" />
										) : (
											<img className="newLocation__altimage" src={AddImage} alt="" />
										)}
										<FileUploader onChange={info => uploadImage(info)}>
											<FaImage /> {image ? `Update Image` : `Add Image`}
										</FileUploader>
									</div>
									{loading && <Spinner />}
									{error && <Message variant="danger">{error}</Message>}
									{loading ? (
										<Spinner />
									) : error ? (
										<Message variant="danger">{error}</Message>
									) : (
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<TWControls.Input
													size="small"
													name="assetName"
													label="Portfolio Name"
													value={assetName}
													onChange={e => setPortfolioName(e.target.value)}
												/>
											</Grid>
											<Grid item xs={12}>
												<TWControls.Input
													size="small"
													name="description"
													label="Description"
													value={description}
													onChange={e => setDescription(e.target.value)}
												/>
											</Grid>
										</Grid>
									)}
									<div className="AssetEdit__Actions">
										<Button variant="success" size="small" type="submit" isLarge>
											Update
										</Button>
									</div>
								</Card>
							</Form>
						</StyledAssetEdit>
					</Layout.Body>
				</Layout.Page>
			</Layout>
		</div>
	)
}

const StyledAssetEdit = styled.div`
	max-width: ${_.rem(960)};
	margin: 0 auto;

	.AssetEdit {
		&__Card {
			h2 {
				overflow: hidden;
				white-space: nowrap;
			}
		}

		&__password-buttton {
			${_.MIXINS.vhc}
			margin: ${_.rem(16)} 0 ${_.rem(24)};
		}

		&__Asset,
		&__Actions {
			${_.MIXINS.mod}
			${_.MIXINS.vhc}
			flex-direction: column;
			padding: ${_.rem(16)};
		}

		&__Image {
			width: ${_.rem(250)};
			margin-bottom: ${_.rem(10)};
			border-radius: ${_.rem(5)};
			border: ${_.rem(2)} solid #0073e6;
			margin-bottom: ${_.rem(16)};
		}
	}

	input {
		font-size: ${_.rem(18)};
	}

	.input-group {
		${({ isMobile }) =>
			!isMobile &&
			`
      display: flex;
      gap: ${_.rem(16)};
    `}
	}

	.input-group-title {
		margin-top: ${_.rem(16)};
		margin-bottom: ${_.rem(16)};
		font-weight: 400;
		font-size: ${_.rem(14)};
		color: ${_.COLORS.gray_500};
	}
`

export default Portfolios_Edit
