import React from 'react'
import { FaPlus } from 'react-icons/fa'
import Button from 'components/Button'
import Popup from 'components/Popup'
import Placeholder from 'components/Placeholder'
import DetailEditPopup from 'components/popups/DetailEditPopup'
import { useDetails } from './DetailsContext'
import DetailsTable from './components/DetailsTable'
import EmptyLogo from 'images/empty_folder.png'
import DetailDeletePopup from 'components/popups/DetailDeletePopup'
import swal from 'sweetalert'
import { deleteSiteDetail, getSiteDetails } from 'slices/siteDetailsSlice'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

interface Details {
	_id: string
	detailLabel: string
	description: string
	detail: string
	detailTextValue: string
	detailNumericValue: string
	detailCurrencyValue: string
	detailDateValue: string

	detailType: string
	projectid: string
	createdAt?: string
	packageType?: string
	packageid?: string
	updatedAt?: string
	userid?: string
}

export interface Props {
	className?: string
	details: Details[]
	deleteDetail: boolean
	handleSubmit: Function
	handelDeleteDetail: Function
}

const Details = ({ details = [], handleSubmit }: Props) => {
	const dispatch = useDispatch()

	const {
		state: { openPopup, openDeletePopup, popupTitle, recordForEdit, deleteDetail },
		actions: { setOpenPopup, setOpenDeletePopup, setPopupTitle, setRecordForEdit, setDeleteDetail },
	} = useDetails()

	const hasDetails = details.length > 0

	const getFormattedDetails = () =>
		details.map(v => ({
			id: v._id,
			key: v.detailLabel,
			description: v.description,
			value: v.detailType === 'Currency' ? `$${v.detail}` : v.detail,
			textValue: v.detailTextValue,
			numericValue: v.detailNumericValue,
			currencyValue: v.detailCurrencyValue,
			dateValue: v.detailDateValue,
			detailType: v.detailType,
			projectid: v.projectid,
		}))

	const getDetailById = (id: string) => details.find((detail: any) => detail._id === id)

	const handleAddDetail = () => {
		setPopupTitle('New Detail')
		setRecordForEdit({})
		setOpenPopup(true)
	}

	const handleEditDetail = (id: string) => {
		setPopupTitle('Update Detail')
		setRecordForEdit(getDetailById(id))
		setDeleteDetail(false)
		setOpenPopup(true)
	}

	const handleDeleteDetail = async detail => {
		console.log(`Detail - detail:`, detail)
		const projectId = detail.projectid

		await swal({
			title: `Delete ${detail.key}?`,
			text: `This will delete the project detail '${detail.key}' from this project.`,
			icon: 'warning',
			buttons: {
				cancel: {
					text: 'Cancel',
					value: null,
					visible: true,
					className: '',
					closeModal: true,
				},
				confirm: {
					text: 'Delete',
					value: true,
					visible: true,
					className: '',
					closeModal: true,
				},
			},
		}).then(willDelete => {
			if (willDelete) {
				dispatch(deleteSiteDetail(detail.id))

				swal({
					title: `Success!`,
					text: ` ${detail.key} has been deleted.`,
					icon: 'success',
				})
			}
		})
	}

	const filteredDetails = {
		currency: {
			title: 'Currency',
			details: getFormattedDetails().filter(detail => detail.detailType === 'Currency'),
		},
		date: {
			title: 'Date',
			details: getFormattedDetails().filter(detail => detail.detailType === 'Date'),
		},
		number: {
			title: 'Number',
			details: getFormattedDetails().filter(detail => detail.detailType === 'Number'),
		},
		text: {
			title: 'Text',
			details: getFormattedDetails().filter(detail => detail.detailType === 'Text'),
		},
	}

	return (
		<div>
			{hasDetails &&
				Object.keys(filteredDetails).map((detailType, index) => {
					if (filteredDetails[detailType].details.length > 0) {
						return (
							<DetailsTable
								key={index}
								title={filteredDetails[detailType].title}
								details={filteredDetails[detailType].details}
								handleDeleteDetail={handleDeleteDetail}
								handleEditDetail={handleEditDetail}
							/>
						)
					}
					return false
				})}

			{!hasDetails && (
				<Placeholder aspectRatio="16:9" isFlexColumn isTransparent>
					<h2 className="h2">We don't have any details, yet.</h2>
					<div>
						<img className="details__avatar" src={EmptyLogo} alt="" />
					</div>
					{/* <div>
						<Button onClick={handleAddDetail} variant="success" isLarge>
							<FaPlus /> Start Here to Add a New Detail
						</Button>
					</div> */}
				</Placeholder>
			)}

			{openPopup && (
				<Popup
					title={popupTitle}
					openPopup={openPopup}
					setOpenPopup={(payload: boolean) => setOpenPopup(payload)}
					isFullWidth={false}
				>
					<DetailEditPopup
						recordForEdit={recordForEdit}
						handleSubmit={handleSubmit}
						// deleteDetail={deleteDetail}
					/>
				</Popup>
			)}
		</div>
	)
}

export default Details
