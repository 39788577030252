import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
	entity: {},
	id: [],
	isLoading: false,
	isInitialized: false,
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

export const createCompany = createAsyncThunk(
	'company/create',
	async (payload: any, { getState, rejectWithValue }) => {
		try {
			return axios.post(`/api/company`, payload, prepConfig(getState)).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to create the company')
		}
	},
)

export const initializeCompany = createAsyncThunk(
	'company/initialize',
	async (payload: any, { getState, rejectWithValue }) => {
		const companyid = payload

		try {
			return axios.get(`/api/company/${companyid}`, prepConfig(getState)).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to load the company')
		}
	},
)

export const patchCompany = createAsyncThunk(
	'company/patch',
	async (payload: any, { getState, rejectWithValue }) => {
		const { companyid, updates } = payload

		try {
			const { data } = await axios.patch(
				`/api/company/${companyid}/patch`,
				updates,
				prepConfig(getState),
			)
			return data
		} catch (error) {
			console.error(error)
			return rejectWithValue('Unable to update the company record')
		}
	},
)

export const updateCompany = createAsyncThunk(
	'company/update',
	async (payload: any, { getState, rejectWithValue }) => {
		const { company } = payload
		try {
			return axios
				.put(`/api/company/${company._id}`, company, prepConfig(getState))
				.then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to update the company record')
		}
	},
)

export const deleteCompany = createAsyncThunk(
	'company/delete',
	async (companyid, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.delete(`/api/company/${companyid}`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to delete the company record')
		}
	},
)

export const addTeamMember = createAsyncThunk(
	'company/addTeamMember',
	async (payload: any, { getState, rejectWithValue }) => {
		const { companyid, member } = payload

		try {
			const { data } = await axios.post(
				`/api/company/${companyid}/addteammember`,
				{ memberid: member._id },
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to add partner')
		}
	},
)

export const removeTeamMember = createAsyncThunk(
	'company/removeTeamMember',
	async (payload: any, { getState, rejectWithValue }) => {
		const { companyid, partnerId } = payload

		try {
			const { data } = await axios.post(
				`/api/company/${companyid}/removePartner`,
				{ partnerId },
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to add partner')
		}
	},
)

// Extract the key out of the entitiy object
// eslint-disable-next-line no-unused-vars
const removeKey = (key, { [key]: _, ...rest }) => rest

const companySlice = createSlice({
	name: 'company',
	initialState,
	reducers: {},
	extraReducers: {
		[createCompany.fulfilled as any]: (state, action) => {
			const company = action.payload
			state.entity[company._id] = company
			state.id.push(company._id)
		},
		[initializeCompany.fulfilled as any]: (state, action) => {
			const company = action.payload
			state.entity = company
			state.id = company._id
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeCompany.pending as any]: state => {
			state.isLoading = true
		},
		[initializeCompany.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},
		[updateCompany.fulfilled as any]: (state, action) => {
			const company = action.payload
			const companyid = company._id
			state.entity[companyid] = company
		},
		[deleteCompany.fulfilled as any]: (state, action) => {
			const id = action.meta.arg
			state.entity = removeKey(id, state.entity)
			state.ids = state.ids.filter(stateId => stateId !== id)
		},
		[patchCompany.fulfilled as any]: (state, action) => {
			const company = action.payload
			state.entity[company._id] = { ...action.payload, ...state.entity[company._id] }
			state.isInitialized = false
		},

		[addTeamMember.fulfilled as any]: (state, action) => {
			const company = action.payload
			state.entity[company._id] = company
			state.isInitialized = false
		},
		[addTeamMember.rejected as any]: (state, action) => {
			console.log(action)
		},
		[removeTeamMember.fulfilled as any]: (state, action) => {
			const teamMembers = action.payload
			state.entity[action.meta.arg.entityid].teamMembers = teamMembers
		},
	} as any,
})

export default companySlice
