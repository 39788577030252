import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaTelegramPlane, FaUserPlus } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { Grid } from '@mui/material'
import swal from 'sweetalert'
import { addMyPartner, initializeMyPartners, updateMyPartner } from 'slices/myPartnersSlice'
import { CardGrid, PartnerCard } from 'components/Card'
import Button from 'components/Button'
import Popup from 'components/Popup'
import UserReviewPopup from 'components/popups/PartnerSettingsPopup'
import Layout from 'components/Layout/Layout'
import ListAction from 'features/Actions/components/ListAction'
import Placeholder from 'components/Placeholder'
// import EmptyLogo from 'images/no-partners.png'
import EmptyLogo from 'images/empty_folder.png'
import { h2 } from 'styles/helpers/_typography'

const PartnerCards = ({ partnerType, source }) => {
	const dispatch = useDispatch()

	const history = useHistory()

	const [isOwner, setIsOwner] = useState(false)
	const [openPopup, setOpenPopup] = useState(false)
	const [recordForEdit, setRecordForEdit] = useState(null)

	const { _id: sourceId, partners, owner } = source
	const ownerId = owner._id

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin
	const userId = userInfo._id

	const userEntities = useSelector(state => state.userList.entities)

	const assetPartners = partners

	useEffect(() => {
		dispatch(initializeMyPartners())
	}, [])

	const openInPopup = partner => {
		if (ownerId === userId) {
			setIsOwner(true)
		}
		setRecordForEdit(partner)
		setOpenPopup(true)
	}

	const updatePartner = async (partner, resetForm) => {
		dispatch(updateMyPartner({ partner }))
		resetForm()
		setOpenPopup(false)
		setRecordForEdit(null)
	}

	const addDropPartnersHandler = () => {
		if (userId === ownerId) {
			history.push(`/${partnerType}/${sourceId}/addpartners`)
		} else {
			swal({
				title: `Add Partner Error!`,
				text: `You must be the originator of '${source.assetName}' to add a new partner to the team.`,
				icon: 'error',
				button: 'Ok',
			})
		}
	}

	const handleConnect = partner => {
		swal({
			title: `Partnership Invitation!`,
			text: `Add ${partner.name} as a partner to your teams?`,
			icon: 'success',
			buttons: {
				cancel: {
					text: 'Cancel',
					value: null,
					visible: true,
					className: '',
					closeModal: true,
				},
				confirm: {
					text: 'Connect',
					value: true,
					visible: true,
					className: '',
					closeModal: true,
				},
			},
		}).then(willDelete => {
			if (willDelete) {
				swal({
					title: `Invitation Sent!`,
					text: `Congratulations! ${partner.name} has been informed of your partnership invitation. Once they accept you two will both be partners.`,
					icon: 'success',
				})
				dispatch(addMyPartner({ partner: partner.partner }))
			}
		})
	}

	return (
		<div>
			<Grid container spacing={3}>
				<Grid item xs={9}>
					{partners.length === 0 ? (
						<Placeholder aspectRatio="16:9" isFlexColumn isTransparent>
							<h2 className="h2">We don't have any partners, yet.</h2>
							<div>
								<img className="details__avatar" src={EmptyLogo} alt="Avatar" />
							</div>

							{/* <br></br>
							<div>
								<Button onClick={addDropPartnersHandler} variant="success" isLarge>
									<FaUserPlus /> Start Here to Add a New Partner
								</Button>
							</div> */}
						</Placeholder>
					) : (
						<CardGrid>
							{assetPartners.map(assetPartner => {
								const partner = userEntities[assetPartner.partnerUserId._id]
								if (!partner) return <h2> You have no partners assigned to this project.</h2>
								return (
									<PartnerCard
										key={partner._id}
										name={partner.name}
										title={partner.title}
										companyName={partner.company}
										avatarUrl={partner.image}
										actions={
											userId === ownerId || userId === partner.partner ? (
												<Button isOutline onClick={() => openInPopup(partner)}>
													Review
												</Button>
											) : (
												<Button isOutline onClick={() => handleConnect(partner)}>
													Connect
												</Button>
											)
										}
									/>
								)
							})}
						</CardGrid>
					)}
				</Grid>
				<Grid item xs={3}>
					<Layout.SidebarItem title="Actions" hasNoMaxHeight>
						<ul>
							<li className="actionList__item">
								<ListAction
									handleAction={addDropPartnersHandler}
									actionLable={'Add Partner'}
									actionIcon={<FaUserPlus />}
								/>
							</li>
						</ul>
					</Layout.SidebarItem>
				</Grid>
			</Grid>
			<Popup title="User Review" openPopup={openPopup} setOpenPopup={setOpenPopup}>
				<UserReviewPopup
					recordForEdit={recordForEdit}
					updatePartner={updatePartner}
					isOwner={isOwner}
				/>
			</Popup>
		</div>
	)
}

export default PartnerCards
