import type { Props } from './ChatAddressBar'

const fixture: Props = {
	className: 'ChatAddressBar',
	myPartners: [{ name: '1' }, { name: '2' }, { name: '3' }],
	handleAutocomplete: () => console.log('handleAutocomplete'),
	handleCloseNewChat: () => console.log('handleCloseNewChat'),
}

export default fixture
