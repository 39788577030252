import React from 'react'
import { useSelector } from 'react-redux'
import DemographicsCharts from 'features/DemographicsCharts'
import { useParams } from 'react-router-dom'

const LocationAnalyticsScreen = () => {
	const { id } = useParams()
	const { location, loading } = useSelector(state => {
		return {
			loading: state.locations.isLoading,
			success: state.locations.isInitialized,
			location: state.locations.entities[id],
		}
	})

	// TODO - should put a warning that they need lat/long to have this page populate
	if (loading || !location || !location.longitude || !location.latitude) return null
	return <DemographicsCharts asset={location} />
}

export default LocationAnalyticsScreen
