import React, { useState } from 'react'
import {
	FormControlLabel,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from '@mui/material'
import TWControls from 'components/controls/TWControls'
import { Form, useForm } from 'components/controls/useForm'
import Message from 'components/Message'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const UserUpdatePassword = ({ updatePassword, externalError, setExternalError }) => {
	const [showOldPassword, setShowOldPassword] = useState(false)
	const [showNewPassword, setShowNewPassword] = useState(false)
	const [showNewPasswordCopy, setShowNewPasswordCopy] = useState(false)

	const initialFValues = {
		id: 0,
		oldPassword: '',
		newPassword: '',
		newPasswordCopy: '',
	}

	const validate = () => {
		let temp = { ...errors }
		temp.oldPassword = values.oldPassword ? '' : 'Old password required.'
		temp.newPassword = values.newPassword ? '' : 'New password is required.'

		temp.newPasswordCopy =
			values.newPassword === values.newPasswordCopy ? '' : 'Passwords must match.'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialFValues,
		false,
		validate,
	)

	function handleChange(e) {
		setExternalError('')
		handleInputChange(e)
	}

	const submitHandler = e => {
		e.preventDefault()
		if (validate()) {
			updatePassword(values, resetForm)
		}
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container>
				<Grid item>
					<TWControls.Input
						name="oldPassword"
						label="Old Password"
						value={values.oldPassword}
						onChange={handleChange}
						error={errors.oldPassword}
						type={showOldPassword ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={() => setShowOldPassword(!showOldPassword)}
										onMouseDown={() => setShowOldPassword(!showOldPassword)}
										edge="end"
									>
										{showOldPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					{externalError && <Message variant="danger">{externalError}</Message>}
					<TWControls.Input
						name="newPassword"
						label="New Password"
						value={values.newPassword}
						onChange={handleChange}
						error={errors.newPassword}
						type={showNewPassword ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={() => setShowNewPassword(!showNewPassword)}
										onMouseDown={() => setShowNewPassword(!showNewPassword)}
										edge="end"
									>
										{showNewPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<TWControls.Input
						name="newPasswordCopy"
						label="Confirm New Password"
						value={values.newPasswordCopy}
						onChange={handleChange}
						error={errors.newPasswordCopy}
						type={showNewPasswordCopy ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={() => setShowNewPasswordCopy(!showNewPasswordCopy)}
										onMouseDown={() => setShowNewPasswordCopy(!showNewPasswordCopy)}
										edge="end"
									>
										{showNewPasswordCopy ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<div>
						<TWControls.Button size="small" type="submit" text="Update" />
						<TWControls.Button size="small" text="Clear" color="default" onClick={resetForm} />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default UserUpdatePassword
