import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
const LOCATION_IMAGES = 'locationImages'

const initialState = {
	entities: {},
	ids: [],
	isLoading: false,
	isInitialized: false,
	error: null,
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

export const createImage = createAsyncThunk(
	`${LOCATION_IMAGES}/create`,
	async (locationImage, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/api/locationImages`, locationImage, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to get notes')
		}
	},
)

export const getImages = createAsyncThunk(
	`${LOCATION_IMAGES}/getImages`,
	async (payload: any, { getState, rejectWithValue }) => {
		const { locationid, projectid, userid } = payload

		try {
			const { data } = await axios.get(`/api/locationimages/images`, {
				params: {
					locationid: locationid,
					projectid: projectid,
					userid: userid,
				},
			})
			return data
		} catch (error) {
			console.error(error)
			return rejectWithValue('Failed to get images')
		}
	},
)

export const updateImage = createAsyncThunk(
	`${LOCATION_IMAGES}/update`,
	async (image: any, { getState, rejectWithValue }) => {
		if (image && image._id) {
			try {
				const { data } = await axios.put(
					`/api/locationimages/${image._id}`,
					image,
					prepConfig(getState),
				)
				return data
			} catch (error) {
				console.error(error)
				return rejectWithValue('Unable to update image')
			}
		}
		return rejectWithValue('Malformed image.')
	},
)

export const deleteImage = createAsyncThunk(
	`${LOCATION_IMAGES}/delete`,
	async (payload: any, { getState, rejectWithValue }) => {
		const { imageid } = payload

		try {
			const { data } = await axios.delete(`/api/locationimages/${imageid}`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to remove image')
		}
	},
)

// Extract the key out of the entitiy object
// eslint-disable-next-line no-unused-vars
const removeKey = (key, { [key]: _, ...rest }) => rest

const locationImageSlice = createSlice({
	name: 'locationImages',
	initialState,
	reducers: {},
	extraReducers: {
		[getImages.fulfilled as any]: (state, action) => {
			const images = action.payload

			state.ids = images?.map((image, index) => {
				state.entities[image._id] = image
				return image._id
			})
			state.isLoading = false
			state.isInitialized = true

			// const imageMap = images.reduce((lookup, item) => {
			// 	lookup[item._id] = item
			// 	return lookup
			// }, {})
			// state.entities = { ...state.entities, ...imageMap }
			// state.isLoading = false
		},
		[getImages.pending as any]: state => {
			state.isLoading = true
		},
		[createImage.fulfilled as any]: (state, action) => {
			const newNote = action.payload
			state.entities[newNote._id] = newNote
		},
		[updateImage.fulfilled as any]: (state, action) => {
			const image = action.payload
			state.entities[image._id] = { ...action.payload, ...state.entities[image._id] }
		},
		[deleteImage.fulfilled as any]: (state, action) => {
			const imageId = action.meta.arg
			state.entities = removeKey(imageId, state.entities)
			state.isInitialized = false
		},
	} as any,
})

export default locationImageSlice

// export const { } = locationsSlice.actions;
