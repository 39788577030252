import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PortfolioImageGalleryContainer from 'containers/PortfolioImageGalleryContainer'
import FileUploader from 'components/FileUploader'
import ImageGalleryContainer from 'containers/ImageGalleryContainer'
import { Grid } from '@mui/material'
import Layout from 'components/Layout/Layout'
import { useParams } from 'react-router-dom'
import { createPortfolioImage } from 'slices/portfolioImagesSlice'

const PortfolioImagesScreen = () => {
	const { id } = useParams()
	const dispatch = useDispatch()

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	const { isLoading: loading, portfolio, error, isInitialized } = useSelector(state => {
		return {
			isLoading: state.portfolios.isLoading,
			portfolio: state.portfolios.entities[id] || {},
			error: state.portfolios.error,
			isInitialized: state.portfolios.isInitialized,
		}
	})

	const sendPortfolioImage = async info => {
		const elements = info.name.split('.')
		const imageExt = elements.slice(elements.length - 1).toString()

		dispatch(
			createPortfolioImage({
				userid: userInfo._id,
				portfolioid: portfolio._id,
				uuid: info.uuid,
				name: info.name,
				size: info.size,
				url: info.originalUrl,
				source: info.source,
				type: imageExt,
			}),
		)
	}

	// // TODO: Create skeleton loader
	if (loading) return null

	return (
		<div>
			<Grid container spacing={3}>
				<Grid item xs={9}>
					<PortfolioImageGalleryContainer />{' '}
				</Grid>
				<Grid item xs={3}>
					<Layout.SidebarItem title="Actions" hasNoMaxHeight>
						<ul>
							<li className="actionList__item">
								<FileUploader fileType="image" onChange={info => sendPortfolioImage(info)} />
							</li>
						</ul>
					</Layout.SidebarItem>
				</Grid>
			</Grid>
		</div>
	)
}

export default PortfolioImagesScreen
