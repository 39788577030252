import React from 'react'
import Popup from 'components/Popup'
import TemplateSiteDetailPopup from 'components/popups/TemplateSiteDetailPopup'
import { useDetails } from './TemplateDetailsContext'
import TemplateDetailsTable from './components/TemplateDetailsTable'
import type { TableHeaders } from './components/TemplateDetailsTable'

interface TemplateDetails {
	_id: string
	templateId: string
	detailLabel: string
	description: string
	detailType: string
	sampleTextValue: string
	sampleNumericValue: string
	sampleCurrencyValue: string
	sampleDateValue: string

	createdAt?: string
	updatedAt?: string
}

export interface Props {
	className?: string
	details: TemplateDetails[]
	deleteDetail: boolean
	handleSubmit: Function
	handelDeleteDetail: Function
	tableHeaders?: TableHeaders
}

const TemplateDetails = ({ className = '', details = [], handleSubmit, tableHeaders }: Props) => {
	const {
		state: { openPopup, popupTitle, recordForEdit, deleteDetail },
		actions: { setOpenPopup, setPopupTitle, setRecordForEdit, setDeleteDetail },
	} = useDetails()

	// const hasDetails = details.length > 0
	const getFormattedDetails = () =>
		details.map(v => ({
			id: v._id,
			key: v.detailLabel,
			templateId: v.templateId,
			description: v.description,
			textValue: v.sampleTextValue,
			numericValue: v.sampleNumericValue,
			currencyValue: v.sampleCurrencyValue,
			dateValue: v.sampleDateValue,
			detailType: v.detailType,
		}))
	const getDetailById = (id: string) => details.find((detail: any) => detail._id === id)

	// const handleAddDetail = () => {
	// 	setPopupTitle('New Detail')
	// 	setRecordForEdit({})
	// 	setOpenPopup(true)
	// }

	const handleEditDetail = (id: string) => {
		setPopupTitle('Update Detail')
		setRecordForEdit(getDetailById(id))
		setDeleteDetail(false)
		setOpenPopup(true)
	}

	const handleDeleteDetail = (id: string) => {
		setPopupTitle('Delete Detail')
		setRecordForEdit(getDetailById(id))
		setDeleteDetail(true)
		setOpenPopup(true)
	}

	return (
		<div className="taskFeed_Container">
			<div className="task">
				<TemplateDetailsTable
					details={getFormattedDetails()}
					handleDeleteDetail={handleDeleteDetail}
					handleEditDetail={handleEditDetail}
					tableHeaders={tableHeaders}
				/>
			</div>
			<Popup
				title={popupTitle}
				openPopup={openPopup}
				setOpenPopup={(payload: boolean) => setOpenPopup(payload)}
				isFullWidth={false}
			>
				<TemplateSiteDetailPopup
					recordForEdit={recordForEdit}
					handleSubmit={handleSubmit}
					deleteDetail={deleteDetail}
				/>
			</Popup>
		</div>
	)
}

export default TemplateDetails
