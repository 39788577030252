import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Popup from 'components/Popup'
import FileUploader from 'components/FileUploader'
import FileList from 'components/FileList'
import { Grid } from '@mui/material'
import Layout from 'components/Layout/Layout'
import { createFile } from 'slices/locationFileSlice'
import { patchLocation } from 'slices/locationSlice'
import DocumentPreviewPopup from 'components/popups/DocumentPreviewPopup'
import { patchProject } from 'slices/projectSlice'

const LocationDocumentsLibrary = ({ user, location, project }) => {
	const dispatch = useDispatch()

	const [openPreviewPopup, setOpenPreviewPopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('')
	const [recordForEdit, setRecordForEdit] = useState(null)

	const locationId = location._id
	const projectId = project._id
	const userId = user._id

	const sendLocationFile = async info => {
		const elements = info.name.split('.')
		const fileExt = elements.slice(elements.length - 1).toString()

		const dateDate = info.sourceInfo.file.lastModifiedDate.getDate()
		const dateMonth = info.sourceInfo.file.lastModifiedDate.getMonth() + 1
		const dateYear = info.sourceInfo.file.lastModifiedDate.getFullYear()
		const docDate = `${dateMonth}/${dateDate}/${dateYear}`

		dispatch(
			createFile({
				userid: userId,
				locationid: locationId,
				projectid: projectId,
				uuid: info.uuid,
				name: info.name,
				size: info.size,
				url: info.originalUrl,
				source: info.source,
				type: fileExt,
				secured: true,
				docDate: docDate,
				partners: project.partners,
			}),
		)
		dispatch(
			patchLocation({ locationId: locationId, updates: { updateNote: 'Document(s) Updated' } }),
		)
		dispatch(patchProject({ projectId: projectId, updates: { updateNote: 'Document(s) Updated' } }))
	}

	const handlePreviewDocument = async document => {
		const doc = [
			{
				uri: document.url,
				fileType: document.fileType,
				fileName: document.fileName,
			},
		]
		setRecordForEdit(doc)
		setPopupTitle(null)
		setOpenPreviewPopup(true)
	}

	return (
		<div>
			<Grid container spacing={3}>
				<Grid item xs={9}>
					<FileList
						userId={userId}
						project={project}
						location={location}
						handlePreviewDocument={handlePreviewDocument}
					/>
				</Grid>
				<Grid item xs={3}>
					<Layout.SidebarItem title="Actions" hasNoMaxHeight>
						<ul>
							<li className="actionList__item">
								<FileUploader fileType="document" onChange={info => sendLocationFile(info)} />
							</li>
						</ul>
					</Layout.SidebarItem>
				</Grid>
			</Grid>
			{openPreviewPopup ? (
				<Popup
					title={popupTitle}
					openPopup={openPreviewPopup}
					setOpenPopup={setOpenPreviewPopup}
					popupWidth="lg"
					isFullWidth={true}
				>
					<DocumentPreviewPopup document={recordForEdit} />
				</Popup>
			) : null}
		</div>
	)
}

export default LocationDocumentsLibrary
