import React, { useState } from 'react'
import { CardGrid, ProjectCard } from 'components/Card'
import { useDispatch, useSelector } from 'react-redux'
import Popup from 'components/Popup'
import ProjectArchivePopup from 'components/popups/ProjectArchivePopup'
import ProjectDeletePopup from 'components/popups/ProjectDeletePopup'
import { deleteProject, initializeProjects, patchProject } from 'slices/projectSlice'

const ProjectCards = ({ projects, userId }) => {
	const dispatch = useDispatch()
	// const userLogin = useSelector(state => state.userLogin)
	// const { userInfo } = userLogin

	const [openArchivePopup, setOpenArchivePopup] = useState(false)
	const [openDeletePopup, setOpenDeletePopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('')
	const [recordForEdit, setRecordForEdit] = useState(null)

	const menuItems = project => [
		{
			title: 'Edit Project',
			link: project.owner === userId && `/projects/${project._id}/edit`,
		},
		{
			title: 'Archive Project',
			callback: () => {
				setPopupTitle(`Archive Project: ${project.assetName}`)
				setRecordForEdit(project)
				setOpenArchivePopup(true)
			},
		},
		{
			title: 'Delete Project',
			callback: () => {
				setPopupTitle(`Delete Project: ${project.assetName}`)
				setRecordForEdit(project)
				setOpenDeletePopup(true)
			},
		},
	]

	const handleArchiveProject = async newProject => {
		const projectId = newProject._id

		await dispatch(
			patchProject({
				projectId: projectId,
				updates: { isActive: false, updateNote: 'Project Archived' },
			}),
		)

		dispatch(
			initializeProjects({
				projectId: projectId,
			}),
		)

		setOpenArchivePopup(false)
	}

	const handleDeleteProject = async newProject => {
		const projectId = newProject._id

		await dispatch(deleteProject(projectId))

		dispatch(
			initializeProjects({
				projectId: projectId,
			}),
		)

		setOpenDeletePopup(false)
	}

	return (
		<div>
			<>
				<CardGrid>
					{projects.map((project, index) => (
						<ProjectCard
							key={index}
							image={project.projectAsset.image}
							name={project.projectName}
							locationName={project.projectAsset.assetName}
							locationCityProv={`${project.projectAsset.city}, ${project.projectAsset.stateProvince}`}
							projectType={project.projectType}
							linkUrl={`/locations/${project.projectAsset._id}/projects/${project._id}`}
							isOwner={project.owner._id === userId ? true : false}
							menuItems={menuItems(project)}
							ownerImage={project.owner.image}
						/>
					))}
				</CardGrid>
			</>

			{openArchivePopup ? (
				<Popup title={popupTitle} openPopup={openArchivePopup} setOpenPopup={setOpenArchivePopup}>
					<ProjectArchivePopup archiveProject={handleArchiveProject} project={recordForEdit} />
				</Popup>
			) : null}

			{openDeletePopup ? (
				<Popup title={popupTitle} openPopup={openDeletePopup} setOpenPopup={setOpenDeletePopup}>
					<ProjectDeletePopup deleteProject={handleDeleteProject} project={recordForEdit} />
				</Popup>
			) : null}
		</div>
	)
}

export default ProjectCards
