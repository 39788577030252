import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FaPlus, FaFileAlt } from 'react-icons/fa'
import Button from 'components/Button'
import Popup from 'components/Popup'
import Placeholder from 'components/Placeholder'
import Spinner from 'components/Spinner'
import LocationTenantEdit from '../components/LocationTenantEdit'
import NumberFormat from 'react-number-format'
import useTableNoPage from 'components/controls/useTableNoPage'
import AltRetailerLogo from 'images/Old_Navy_Logo.png'
import { Avatar, TableBody, TableCell, TableRow } from '@mui/material'

import { uploadCenterTenants } from 'redux/actions/centerTenantActions'
import { Grid } from '@mui/material'
import Layout from 'components/Layout/Layout'
import ListAction from 'features/Actions/components/ListAction'
import EmptyLogo from 'images/empty_folder.png'
import {
	createLocation,
	deleteLocation,
	getCenterTenants,
	updateLocation,
} from 'slices/locationSlice'
import { useParams } from 'react-router-dom'
import swal from 'sweetalert'
import { makeStyles } from '@mui/styles'

const headerCells = [
	{ id: 'Image', label: '', disableSorting: true },
	{ id: 'tenant', label: 'Tenant' },
	{ id: 'tenantSize', label: 'Sq. Ft.' },
	{ id: 'estimatedSales', label: 'Estimated Sales' },
]

const useStyles = makeStyles(theme => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
	input: {
		display: 'none',
	},
	newButton: {
		position: 'absolute',
		right: '10px',
	},
	pageContent: {
		margin: theme.spacing(1),
		padding: theme.spacing(1),
		// display: 'flex',
		width: '100%',
	},
}))

const LocationTenants = ({ userId, location }) => {
	const dispatch = useDispatch()

	const history = useHistory()

	const [filterFn, setFilterFn] = useState({
		fn: items => {
			return items
		},
	})

	const { id: locationId } = useParams()
	// const locationId = location._id
	const ownerId = location.owner._id

	const initialTenant = {
		_id: 0,
		isTenantOnly: true,
	}

	const [openPopup, setOpenPopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('Update Tenant')
	const [recordForEdit, setRecordForEdit] = useState(null)

	// const { centerTenants } = useSelector(state => {
	// 	const centerTenantIds = state.locations.entities[locationId].tenants
	// 	const tenants = centerTenantIds?.map(tId => state.locations.centerEntities[tId]) || []
	// 	return {
	// 		centerTenants: tenants,
	// 	}
	// })

	const {
		centerTenantEntities,
		centerTenantIds,
		centerTenantsLoading,
		centerTenantsInitialized,
	} = useSelector(state => state.locations)
	const centerTenants = centerTenantIds.map(id => centerTenantEntities[id])

	const classes = useStyles()

	useEffect(() => {
		// if (!centerTenantsInitialized) {
		dispatch(getCenterTenants(locationId))
		// }
	}, [centerTenantsInitialized, dispatch])

	const addDropTenantsHandler = () => {
		if (userId === ownerId) {
			history.push(`/locations/${locationId}/addtenants`)
		} else {
			swal({
				title: `Add Tenant Error!`,
				text: `You must be the center originator of ${location.assetName} to add a new location.`,
				icon: 'error',
				button: 'Ok',
			})
		}
	}

	const uploadCenterTenantsHandler = result => {
		const newTenants = [...result.data]
		let tenants = []

		for (let i = 0, len = newTenants.length; i < len; i++) {
			tenants.push({
				tenant: newTenants[i].tenant,
				userid: userId,
				parentid: locationId,
			})
		}

		dispatch(uploadCenterTenants(locationId, tenants))
	}

	const addEditTenant = async (newTenant, resetForm) => {
		const assetName = newTenant.assetName
		const centerLocation = newTenant.centerLocation
		const sellingSqft = newTenant.totalSqft
		const totalSqft = newTenant.totalSqft
		const estimatedSales = newTenant.estimatedSales
		const retailer = newTenant.retailer
		const address = newTenant.address
		const city = newTenant.city
		const stateProvince = newTenant.stateProvince
		const postalCode = newTenant.postalCode
		const country = newTenant.country
		const latitude = newTenant.latitude
		const longitude = newTenant.longitude
		const partners = newTenant.partners
		const image = newTenant.image
		const isTenantOnly = newTenant.isTenantOnly

		if (newTenant._id === 0) {
			dispatch(
				createLocation({
					owner: userId,
					parentid: locationId,
					assetType: 'location',
					locationType: 'Retail Location',
					...newTenant,
				}),
			)
		} else {
			dispatch(
				updateLocation({
					_id: newTenant._id,
					owner: userId,
					parentid: locationId,
					assetName,
					assetType: 'location',
					locationType: 'Retail Location',
					retailer,
					centerLocation,
					sellingSqft,
					totalSqft,
					estimatedSales,
					address,
					city,
					stateProvince,
					postalCode,
					country,
					latitude,
					longitude,
					image,
					partners,
					isTenantOnly,
				}),
			)
		}

		resetForm()
		setOpenPopup(false)
		setRecordForEdit(null)
	}

	const editTenantHandler = id => {
		if (id.isTenantOnly) {
			setPopupTitle('Update Tenant')
			openInPopup(id)
		} else {
			history.push(`/locations/${id._id}/edit`)
		}
	}

	const records = centerTenants
	const { TblContainer, TblHeader, recordsAfterPagingAndSorting } = useTableNoPage(
		records,
		headerCells,
		filterFn,
	)

	const openInPopup = currentTenent => {
		setRecordForEdit(currentTenent)
		setOpenPopup(true)
	}

	const newTenatHandler = () => {
		setPopupTitle('New Tenant')
		setRecordForEdit(initialTenant)
		setOpenPopup(true)
	}

	// if (tenantLoading) return <Spinner containerHeight="50vh" />

	const hasTenants = centerTenants.length > 0

	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				{hasTenants ? (
					<Grid item xs={9}>
						<TblContainer>
							<TblHeader />
							<TableBody>
								{recordsAfterPagingAndSorting().map(tenant => (
									<TableRow key={tenant._id} onClick={() => editTenantHandler(tenant)}>
										<TableCell>
											<Avatar alt={AltRetailerLogo} src={tenant.image} />
										</TableCell>
										<TableCell>
											{/* <Link to={location => `${location.pathname}/${tenant._id}`}>
											{tenant.tenant}
										</Link> */}
											{tenant.assetName}
										</TableCell>
										<TableCell>
											<NumberFormat
												className="feed__fileSize"
												value={tenant.sellingSqft}
												decimalScale={0}
												displayType={'text'}
												thousandSeparator={true}
											/>
										</TableCell>
										<TableCell>
											{tenant.estimatedSales}
											{/* <NumberFormat
												className="feed__fileSize"
												value={tenant.estimatedSales}
												decimalScale={2}
												displayType={'text'}
												thousandSeparator={true}
											/> */}
										</TableCell>
										{/* <TableCell>
											<IconButton
												color="secondary"
												size="small"
												onClick={() => editTenantHandler(tenant._id)}
											>
												<DeleteIcon />
											</IconButton>
										</TableCell> */}
									</TableRow>
								))}
							</TableBody>
						</TblContainer>
					</Grid>
				) : (
					<Grid item xs={9}>
						<Placeholder aspectRatio="16:9" isFlexColumn isTransparent>
							<h1 className="h1">We don't have any tenants, yet.</h1>
							<div>
								<img className="details__avatar" src={EmptyLogo} alt="Avatar" />
							</div>
							<div>
								<Button onClick={newTenatHandler} variant="success" isLarge>
									<FaPlus /> Start Here to Add a New Tenant
								</Button>
							</div>
						</Placeholder>
					</Grid>
				)}
				<Grid item xs={3}>
					<Layout.SidebarItem title="Actions" hasNoMaxHeight>
						<ul>
							<li className="actionList__item">
								<ListAction
									handleAction={addDropTenantsHandler}
									actionLable={'Add/Remove Tenants'}
									actionIcon={<FaPlus />}
								/>
							</li>
							<li className="actionList__item">
								<ListAction
									handleAction={uploadCenterTenantsHandler}
									actionLable={'Upload Tenant List'}
									actionIcon={<FaPlus />}
								/>
							</li>
						</ul>
					</Layout.SidebarItem>
				</Grid>
			</Grid>

			<Popup title={popupTitle} openPopup={openPopup} setOpenPopup={setOpenPopup}>
				<LocationTenantEdit recordForEdit={recordForEdit} addEditTenant={addEditTenant} />
			</Popup>
		</div>
	)
}

export default LocationTenants
