import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from 'components/Layout'
import Partners from './components/Partners/Partners'
import SidebarMyPartners from 'components/Sidebars/SidebarMyActivePartners'
import SidebarMyPendingPartnerships from 'components/Sidebars/SidebarMyPendingPartnerships'
import { getMyPendingInvitations } from 'slices/myPartnersSlice'

const Partners_Index = ({ history }) => {
	const dispatch = useDispatch()

	const { entities, ids, isInitialized } = useSelector(
		state => state.myPartners.myPendingInvitations,
	)
	const myPendingInvitations = ids.map(id => entities[id])
	const pendingInvitations = myPendingInvitations.length > 0

	useEffect(() => {
		if (!isInitialized) {
			dispatch(getMyPendingInvitations())
		}
	}, [dispatch, isInitialized])

	return (
		<Layout>
			<Layout.Page>
				<Layout.Sidebar>
					<SidebarMyPartners />
					{pendingInvitations && <SidebarMyPendingPartnerships />}
				</Layout.Sidebar>
				<Layout.Body>
					<Layout.PageHeader>
						<h2>Partners</h2>
					</Layout.PageHeader>
					<Partners />
				</Layout.Body>
			</Layout.Page>
		</Layout>
	)
}

export default Partners_Index
