import { axiosInterceptor, apiCall } from 'services'

const API_BASE_URL =
	process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://app.mydealteams.com'

const api = axiosInterceptor(API_BASE_URL)

interface AddChatEventProps {
	projectId?: string
	eventId?: string
	receiverIds: string[]
	message: string
}

const chatService = () => {
	const addChatEvent = async ({ projectId, eventId, receiverIds, message }: AddChatEventProps) => {
		const chatData = {
			projectid: projectId,
			eventId,
			receiverIds: receiverIds.length > 0 ? receiverIds.map(id => ({ receiverId: id })) : undefined,
			message,
		}

		return await apiCall(api.post('/api/chats', chatData))
	}

	const getChatsByEventId = async eventId => await apiCall(api.get(`/api/chats/event/${eventId}`))

	const getChatsByRoomId = async projectId => await apiCall(api.get(`/api/chats/room/${projectId}`))

	const getChatHeadersByUserId = async userId =>
		await apiCall(api.get(`/api/chats/headers/user/${userId}`))

	return {
		addChatEvent,
		getChatsByEventId,
		getChatsByRoomId,
		getChatHeadersByUserId,
	}
}

export default chatService
