import styled from 'styled-components'
import type { Props } from './ImageGallery'
import * as _ from 'styles/helpers'

const GRID_GAP = 3
const GRID_GAP_MOBILE = 32

const StyledImageGallery = styled.div<Partial<Props>>`
	ul {
		@media ${_.MQ.before_nav} {
			grid-gap: ${_.rem(GRID_GAP_MOBILE)};
		}

		${_.MIXINS.ul_reset};
		display: grid;
		grid-gap: ${_.rem(GRID_GAP)};
		grid-template-columns: repeat(auto-fill, minmax(${_.rem(240)}, 1fr));
	}

	.LocationImage {
		position: relative;
		background-color: #e2e8f0;

		// .LocationImage--video
		&--video {
			height: 0;
			padding-top: 75%;
		}

		// .LocationImage__video
		&__video {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			/* object-fit: cover; */
		}

		// .LocationImage__img
		&__img {
			height: 0;
			padding-top: 75%;
			background-size: cover;
			background-position: center center;
			border-radius: ${_.rem(_.BASE_BORDER_RADIUS)};
		}

		&:hover .LocationImage__img {
			cursor: pointer;
			color: cornflowerblue;
		}

		// .LocationImage__delete-button
		&__delete-button {
			position: absolute;
			top: ${_.rem(GRID_GAP)};
			right: ${_.rem(GRID_GAP)};
			width: ${_.rem(44)};
			height: ${_.rem(44)};
			font-size: ${_.rem(20)};
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			color: ${_.COLORS.white};
			background-color: ${_.COLORS.danger};
			border: none;
			opacity: 0;
			border-radius: ${_.rem(_.BASE_BORDER_RADIUS)};

			&:focus {
				opacity: 1;
			}
		}
	}
`

export default StyledImageGallery
