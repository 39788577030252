import React, { ReactNode, useRef } from 'react'
import { Widget } from '@uploadcare/react-widget'
import { FaImage, FaVideo, FaFileAlt } from 'react-icons/fa'
import Button from 'components/Button'
import StyledFileUploader from './FileUploader.style'

const UPLOAD_CARE_PUBLIC_KEY = 'b4b06db04515dc9f76e0'

export interface Props {
	className?: string
	fileType?: 'image' | 'video' | 'document'
	children?: ReactNode
	onChange: Function
}

const FileUploader = ({ className = '', fileType, children, onChange }: Props) => {
	const widgetApi = useRef() as React.MutableRefObject<any>

	const handleClick = e => {
		e.preventDefault()
		widgetApi.current.openDialog()
	}

	return (
		<StyledFileUploader data-testid="FileUploader" className={className}>
			<Button onClick={handleClick} variant="ghost">
				{fileType && fileType === 'image' && (
					<>
						<FaImage /> Upload New Image
					</>
				)}
				{fileType && fileType === 'video' && (
					<>
						<FaVideo /> Upload New Video
					</>
				)}
				{fileType && fileType === 'document' && (
					<>
						<FaFileAlt /> Upload New Document
					</>
				)}
				{!fileType && children}
			</Button>
			<Widget
				publicKey={UPLOAD_CARE_PUBLIC_KEY}
				ref={widgetApi}
				onChange={info => onChange(info)}
				onDialogOpen={e => e.hideTab('preview')}
			/>
		</StyledFileUploader>
	)
}

export default FileUploader
