import React, { useEffect, useState } from 'react'
import { Avatar, Stack, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { FaImage, FaRegSmileWink } from 'react-icons/fa'
// import { Avatar } from '@mui/material'
import useMediaQuery from 'hooks/useMediaQuery'
import styled from 'styled-components'
import axios from 'axios'
import swal from 'sweetalert'
import { getUserDetails, updateUser } from 'slices/authSlice'
import { Form, useForm } from 'components/controls/useForm'
import UserUpdatePassword from 'components/UserUpdatePassword'
import TWControls from 'components/controls/TWControls'
import FileUploader from 'components/FileUploader'
import Layout from 'components/Layout'
import Popup from 'components/Popup'
import Card from 'components/Card'
import Button from 'components/Button'
import * as _ from 'styles/helpers'

const UserProfile = () => {
	const dispatch = useDispatch()
	const isMobile = useMediaQuery(_.MQ.small_and_below)

	const { userInfo, isInitialized } = useSelector(state => state.userLogin)
	const userId = userInfo._id

	useEffect(() => {
		if (!isInitialized) {
			dispatch(getUserDetails(userInfo._id))
		}
	}, [dispatch, userInfo])

	const {
		values,
		setValues,
		// errors,
		// setErrors,
		handleInputChange,
	} = useForm(userInfo)

	const [image, setImage] = useState(userInfo.image)
	const [openPopup, setOpenPopup] = useState(false)
	const [passwordError, setPasswordError] = useState('')

	const submitForm = async values => {
		await dispatch(
			updateUser({
				_id: userId,
				isAdmin: values.isAdmin,
				image: values.image,
				name: values.name,
				firstName: values.firstName,
				email: values.email,
				company: values.company,
				title: values.title,
				role: values.role,
				firstTime: values.firstTime,
				isCompleted: values.isCompleted,
				isGuestOnly: values.isGuestOnly,
				linkedin: values.linkedin,
				instagram: values.instagram,
				facebook: values.facebook,
				twitter: values.twitter,
			}),
		)

		swal({
			text: 'Profile updated',
			icon: 'success',
			button: 'Ok',
		})
	}

	const handleChangePasswordButton = values => {
		setOpenPopup(true)
	}

	const handleSubmit = e => {
		e.preventDefault()
		if (!openPopup) {
			submitForm(values)
		}
	}

	const uploadImage = async info => {
		setImage(info.originalUrl)
		const newValues = { ...values, image: info.originalUrl }
		setValues(newValues)
		submitForm(newValues)
	}

	const updatePassword = async (passwordData, resetForm) => {
		const config = { headers: { 'Content-Type': 'application/json' } }
		const { data } = await axios
			.post(
				'/api/users/updatePassword',
				{
					email: userInfo.email,
					oldPassword: passwordData.oldPassword,
					newPassword: passwordData.newPassword,
				},
				config,
			)
			.catch(e => {
				return e.toJSON().message
			})

		if (data?.success) {
			swal({
				title: `Password Updated!`,
				text: `Your password was successfully updated`,
				icon: 'success',
				button: 'Ok',
			})

			resetForm()
			setOpenPopup(false)
		} else {
			setPasswordError('Incorrect password')
		}
	}

	return (
		<Layout>
			<Layout.Page>
				<Layout.Sidebar>
					<Layout.SidebarUserCard />
					{/* <SidebarCompanyTeam company={company} /> */}
				</Layout.Sidebar>
				<Layout.Body>
					<StyledUserProfile isMobile={isMobile}>
						<Form onSubmit={handleSubmit}>
							<Card
								className="UserProfile__Card"
								header={{
									title: values.name,
									icon: <FaRegSmileWink />,
									secondary: !isMobile && (
										<Button isOutline onClick={() => handleChangePasswordButton(values)}>
											Change Password
										</Button>
									),
								}}
							>
								<div className="UserProfile__User">
									<Avatar src={image} className="UserProfile__Avatar" />
									<FileUploader onChange={info => uploadImage(info)}>
										<FaImage /> {image ? `Update Photo` : `Add Photo`}
									</FileUploader>
								</div>

								{isMobile && (
									<div className="UserProfile__password-buttton">
										<Button isOutline onClick={() => setOpenPopup(true)}>
											Change Password
										</Button>
									</div>
								)}

								<h3 className="input-group-title">Personal Details</h3>
								<div className="input-group">
									<TWControls.Input
										placeholder="Full Name"
										size="small"
										name="name"
										label="Full Name"
										value={values.name}
										onChange={handleInputChange}
										fullWidth
									/>
									<TWControls.Input
										placeholder="First Name"
										size="small"
										name="firstName"
										label="First Name"
										value={values.firstName}
										onChange={handleInputChange}
										fullWidth
									/>
								</div>

								<TWControls.Input
									placeholder="Email"
									size="small"
									name="email"
									label="Email"
									value={values.email}
									onChange={handleInputChange}
									fullWidth
								/>

								<h3 className="input-group-title">Employment Details</h3>

								<div className="input-group">
									<TWControls.Input
										placeholder="Company"
										size="small"
										name="company"
										label="Company"
										value={values.company}
										onChange={handleInputChange}
										fullWidth
									/>

									<TWControls.Input
										placeholder="Title"
										size="small"
										name="title"
										label="Title"
										value={values.title}
										onChange={handleInputChange}
										fullWidth
									/>
									<TWControls.Input
										placeholder="Role"
										size="small"
										name="role"
										label="Role"
										value={values.role}
										onChange={handleInputChange}
										fullWidth
									/>
								</div>

								<h3 className="input-group-title">Social Media</h3>
								<div className="input-group">
									<TWControls.Input
										placeholder="LinkedIn"
										size="small"
										name="linkedin"
										label="LinkedIn"
										value={values.linkedin}
										onChange={handleInputChange}
										fullWidth
									/>
									<TWControls.Input
										placeholder="Instagram"
										size="small"
										name="instagram"
										label="Instagram"
										value={values.instagram}
										onChange={handleInputChange}
										fullWidth
									/>
									<TWControls.Input
										placeholder="Facebook"
										size="small"
										name="facebook"
										label="Facebook"
										value={values.facebook}
										onChange={handleInputChange}
										fullWidth
									/>
									<TWControls.Input
										placeholder="Twitter"
										size="small"
										name="twitter"
										label="Twitter"
										value={values.twitter}
										onChange={handleInputChange}
										fullWidth
									/>
								</div>

								<div className="UserProfile__Actions">
									<Button size="small" type="submit">
										Update
									</Button>
								</div>
							</Card>
						</Form>
					</StyledUserProfile>
				</Layout.Body>
			</Layout.Page>
			<Popup title="Change Password" openPopup={openPopup} setOpenPopup={setOpenPopup}>
				<UserUpdatePassword
					updatePassword={updatePassword}
					externalError={passwordError}
					setExternalError={setPasswordError}
				/>
			</Popup>
		</Layout>
	)
}

const StyledUserProfile = styled.div`
	max-width: ${_.rem(960)};
	margin: 0 auto;

	.UserProfile {
		&__Card {
			h2 {
				overflow: hidden;
				white-space: nowrap;
			}
		}

		&__password-buttton {
			${_.MIXINS.vhc}
			margin: ${_.rem(16)} 0 ${_.rem(24)};
		}

		&__User,
		&__Actions {
			${_.MIXINS.mod}
			${_.MIXINS.vhc}
			flex-direction: column;
			padding: ${_.rem(16)};
		}

		&__Avatar {
			width: ${_.rem(160)};
			height: ${_.rem(160)};
			margin-bottom: ${_.rem(16)};
		}
	}

	input {
		font-size: ${_.rem(18)};
	}

	.input-group {
		display: flex;
		gap: ${_.rem(16)};
		margin-bottom: ${_.rem(16)};
	}

	.input-group-title {
		margin-top: ${_.rem(16)};
		margin-bottom: ${_.rem(16)};
		font-weight: 400;
		font-size: ${_.rem(14)};
		color: ${_.COLORS.gray_500};
	}
`

export default UserProfile
