import React, { useState } from 'react'
import TWControls from 'components/controls/TWControls'
import { Form } from 'components/controls/useForm'
import { Grid } from '@mui/material'

const centerOptions = [
	{ selectId: '1', selectValue: 'Center 1' },
	{ selectId: '2', selectValue: 'Center 2' },
	{ selectId: '3', selectValue: 'Center 3' },
	{ selectId: '4', selectValue: 'Center 4' },
]

const BulkFleetAddPopup = ({ addLocations, location, _retailCenters }) => {
	const [retailCenter, setRetailCenter] = useState('')

	const submitHandler = e => {
		addLocations(retailCenter)
	}

	const handleInputChange = e => {
		console.log(`e.target.value: `, e.target.value)

		setRetailCenter(e.target.value)
	}

	return (
		<Form sx={{ m: 1, minWidth: 420 }} onSubmit={submitHandler}>
			<Grid container spacing={2}>
				<Grid item className="newLocation__imageHolder" xs={12}>
					<h3 xs={12}>{`location.assetName: location.city, location.stateProvince`}</h3>
					{/* <h3 xs={12}>{`${location.assetName}: ${location.city}, ${location.stateProvince}`}</h3> */}
				</Grid>
				<Grid item xs={12}>
					<p>
						{`Archived locations are removed from the active location list but can be recoverd. All of location.assetName associated notes, partners, tasks, files and images are preserved and will be
						restored when the archived location is recovered.`}{' '}
					</p>
					{/* <p>
						{`Archived locations are removed from the active location list but can be recoverd. All of ${location.assetName} associated notes, partners, tasks, files and images are preserved and will be
						restored when the archived location is recovered.`}{' '}
					</p> */}
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<TWControls.SelectId
							name="retailCenter"
							label="Retail Center"
							value={retailCenter}
							onChange={handleInputChange}
							options={centerOptions}
						/>
					</div>
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<TWControls.Button size="small" type="submit" text="Post" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default BulkFleetAddPopup
