import React, { ReactNode } from 'react'
import StyledPlaceholder from './Placeholder.style'

export interface Props {
	className?: string
	children?: ReactNode
	aspectRatio?: '1:1' | '4:3' | '16:9'
	isFlexColumn?: boolean
	isTransparent?: boolean
}

const Placeholder = ({
	className = '',
	children,
	aspectRatio = '4:3',
	isFlexColumn = false,
	isTransparent = false,
}: Props) => {
	return (
		<StyledPlaceholder
			data-testid="Placeholder"
			className={className}
			aspectRatio={aspectRatio}
			isFlexColumn={isFlexColumn}
			isTransparent={isTransparent}
		>
			{children && <div className="content">{children}</div>}
		</StyledPlaceholder>
	)
}

export default Placeholder
