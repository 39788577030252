import React, { forwardRef } from 'react'
import { List as FixedSizeList, AutoSizer } from 'react-virtualized'
import ChatMessage from 'features/Chat/components/ChatMessage'
import { getFormattedDateTime } from 'features/Chat/utils'
import StyledChatMessageList from './ChatMessageList.style'
import type { IChatMessage } from 'features/Chat/Chat.types'

export interface Props {
	className?: string
	currentMessages: IChatMessage[]
}

const ChatMessageList = forwardRef(({ className = '', currentMessages }: Props, ref) => {
	const ROW_HEIGHT = 70
	const renderRow = ({ index, style }) => (
		<ChatMessage
			key={index}
			style={style}
			avatar={currentMessages[index].senderId.image}
			message={{
				body: currentMessages[index].message,
				username: currentMessages[index].senderId.name,
				time: getFormattedDateTime(currentMessages[index].createdAt),
			}}
		/>
	)

	return (
		<StyledChatMessageList data-testid="ChatMessageList" className={className}>
			<div className="ScrollToBottom">
				<div className="ChatMessageList__inner">
					<AutoSizer>
						{({ height, width }) => (
							<>
								<FixedSizeList
									width={width}
									height={height}
									rowHeight={ROW_HEIGHT}
									rowRenderer={renderRow}
									scrollToIndex={currentMessages.length - 1}
									rowCount={currentMessages.length}
									overscanRowCount={3}
								>
									{/* @ts-ignore */}
									<div ref={ref}>Bottom</div>
								</FixedSizeList>
							</>
						)}
					</AutoSizer>
				</div>
			</div>
		</StyledChatMessageList>
	)
})

export default ChatMessageList
