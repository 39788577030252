import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
	entities: {},
	ids: [],
	recents: [],
	isLoading: false,
	isInitialized: false,
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

export const createGroup = createAsyncThunk(
	'groups/create',
	async (group: any, { getState, rejectWithValue }) => {
		try {
			return axios.post(`/api/groups`, group, prepConfig(getState)).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize groups')
		}
	},
)

export const initializeAllGroups = createAsyncThunk(
	'groups/initialize',
	async (_, { getState, rejectWithValue }) => {
		try {
			return axios.get(`/api/groups`, prepConfig(getState)).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize groups')
		}
	},
)

export const initializeMyGroups = createAsyncThunk(
	'groups/initialize',
	async (_, { getState, rejectWithValue }) => {
		try {
			return axios.get(`/api/groups/mine`, prepConfig(getState)).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize groups')
		}
	},
)

export const deleteGroup = createAsyncThunk(
	'groups/delete',
	async (groupId, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.delete(`/api/groups/${groupId}`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to delete group')
		}
	},
)

export const addMemberToGroup = createAsyncThunk(
	'groups/addMember',
	async (payload: any, { getState, rejectWithValue }) => {
		const { groupId, memberId } = payload

		try {
			const { data } = await axios.post(
				`/api/groups/addmember`,
				{ groupId: groupId, memberId: memberId },
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to add member')
		}
	},
)

export const removeMemberFromGroup = createAsyncThunk(
	'groups/removeMember',
	async (payload: any, { getState, rejectWithValue }) => {
		const { groupId, memberId } = payload

		try {
			const { data } = await axios.post(
				`/api/groups/removemember`,
				{ groupId: groupId, memberId: memberId },
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to remove member')
		}
	},
)

export const getUpdatedGroup = createAsyncThunk(
	'groups/getUpdate',
	async (groupId, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.get(`/api/groups/${groupId}`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to update group')
		}
	},
)

// Extract the key out of the entitiy object
// eslint-disable-next-line no-unused-vars
const removeKey = (key, { [key]: _, ...rest }) => rest

const groupSlice = createSlice({
	name: 'groups',
	initialState,
	reducers: {},
	extraReducers: {
		[initializeAllGroups.fulfilled as any]: (state, action) => {
			const groups = action.payload

			state.recents = []
			state.ids = groups?.map((loc, index) => {
				state.entities[loc._id] = loc
				return loc._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeAllGroups.pending as any]: state => {
			state.isLoading = true
		},
		[initializeAllGroups.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},

		[initializeMyGroups.fulfilled as any]: (state, action) => {
			const groups = action.payload

			state.recents = []
			state.ids = groups?.map((loc, index) => {
				state.entities[loc._id] = loc
				return loc._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeMyGroups.pending as any]: state => {
			state.isLoading = true
		},
		[initializeMyGroups.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},

		[createGroup.fulfilled as any]: (state, action) => {
			const group = action.payload
			state.entities[group._id] = group
			state.ids.push(group._id)
			state.recents.unshift(group._id)

			if (state.recents.length > 4) {
				state.recents.pop()
			}
		},

		[deleteGroup.fulfilled as any]: (state, action) => {
			const id = action.meta.arg
			state.entities = removeKey(id, state.entities)
			state.ids = state.ids.filter(stateId => stateId !== id)
		},

		[getUpdatedGroup.fulfilled as any]: (state, action) => {
			// works for any asset type since all types are stored under entities
			state.entities[action.meta.arg] = { ...action.payload, ...state.entities[action.meta.arg] }
			state.isLoading = false
		},
		[getUpdatedGroup.rejected as any]: (state, action) => {
			state.error = action.error
		},
		[addMemberToGroup.fulfilled as any]: (state, action) => {
			const group = action.payload
			state.entities[group._id] = group
			state.isInitialized = false
		},
		[addMemberToGroup.rejected as any]: (state, action) => {
			console.log(action)
		},
		[removeMemberFromGroup.fulfilled as any]: (state, action) => {
			const members = action.payload
			state.entities[action.meta.arg.groupId].members = members
		},
	} as any,
})

export default groupSlice
