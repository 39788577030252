import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PortfolioNoteFeed from './components/PortfolioNoteFeed'

const PortfolioNotesScreen = () => {
	const { id } = useParams()
	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	const { isInitialized, loading, portfolio } = useSelector(state => {
		return {
			isInitialized: state.portfolios.isInitialized,
			loading: state.portfolios.isLoading,
			portfolio: state.portfolios.entities[id],
		}
	})

	// // TODO: Create skeleton loader
	if (loading || !portfolio) return null

	return <PortfolioNoteFeed userInfo={userInfo} portfolio={portfolio} />
}

export default PortfolioNotesScreen
