import React, { useState } from 'react'
import Layout from 'components/Layout'
import LocationAdminTools from './components/AdminTools/LocationAdminTools'
import SidebarLocationsRecent from 'components/Sidebars/SidebarLocationsRecent'

const LocationAdminTools_Index = () => {
	console.log(`LocationAdminTools_Index`)
	return (
		<Layout>
			<Layout.Page>
				<Layout.Sidebar>
					<Layout.SidebarUserCard />
				</Layout.Sidebar>
				<Layout.Body>
					<Layout.PageHeader>
						<h2>Admin Tools</h2>
					</Layout.PageHeader>
					<LocationAdminTools />
				</Layout.Body>
			</Layout.Page>
		</Layout>
	)
}

export default LocationAdminTools_Index
