import styled from 'styled-components'
import * as _ from 'styles/helpers'
import type { Props } from './ChatMessageList'

const StyledChatMessageList = styled.div<Partial<Props>>`
	padding-top: ${_.rem(8)};
	padding-bottom: ${_.rem(8)};

	.ChatMessageList__inner {
		height: 65vh;
		overflow-y: auto;
	}
`

export default StyledChatMessageList
