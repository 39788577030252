import ActionButton from './ActionButton'
import Button from './Button'
import CheckBox from './CheckBox'
import RadioGroup from './RadioGroup'
import DatePicker from './DatePicker'
import Input from './Input'
import InputMulti from './InputMulti'
import Select from './Select'
import SelectId from './SelectId'
import SelectType from './SelectType'
import Switch from './Switch'

const TWControls = {
	ActionButton,
	Button,
	DatePicker,
	CheckBox,
	RadioGroup,
	Input,
	InputMulti,
	Select,
	SelectId,
	SelectType,
	Switch,
}

export default TWControls
