import React from 'react'
import { TextField } from '@material-ui/core'

export default function InputMulti(props) {
	const { name, label, value, defaultValue, error = null, onChange, ...other } = props

	return (
		<TextField
			variant="outlined"
			name={name}
			label={label}
			defaultValue={defaultValue}
			multiline
			minRows={2}
			// maxRows={4}
			value={value}
			onChange={onChange}
			{...other}
			{...(error && { error: true, helperText: error })}
		/>
	)
}
