import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
const APP_RESOURCE = 'appResources'

const initialState = {
	entities: {},
	ids: [],
	isLoading: false,
	isInitialized: false,
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

export const createPost = createAsyncThunk(
	`${APP_RESOURCE}/create`,
	async (appResource, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.post(`/api/appResources`, appResource, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to create appResource')
		}
	},
)

export const initializeAppResources = createAsyncThunk(
	`${APP_RESOURCE}/initializeAppResources`,
	async (_, { getState, rejectWithValue }) => {
		try {
			return axios.get('/api/appResources', prepConfig(getState)).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to get appResources')
		}
	},
)

const appResourceSlice = createSlice({
	name: APP_RESOURCE,
	initialState,
	reducers: {},
	extraReducers: {
		[createPost.fulfilled as any]: (state, action) => {
			const newAppResource = action.payload
			state.entities[newAppResource._id] = newAppResource
		},

		[initializeAppResources.pending as any]: state => {
			state.isLoading = true
		},
		[initializeAppResources.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},
		[initializeAppResources.fulfilled as any]: (state, action) => {
			const appResources = action.payload

			state.ids = appResources?.map((appResource, index) => {
				state.entities[appResource._id] = appResource
				return appResource._id
			})

			state.isLoading = false
			state.isInitialized = true
		},
	} as any,
})

export default appResourceSlice
