import React from 'react'
import { useChat, ChatInput } from 'features/Chat'

const ChatInputContainer = ({ shouldAlwaysDisplay = false }: { shouldAlwaysDisplay?: boolean }) => {
	const {
		state: {
			chatInput,
			isSendingMessage,
			startNewChat,
			currentReceiverIds,
			shouldDisplayChatInput,
		},
		actions: { handleChatInput, handleSendChatMessage },
	} = useChat()

	const isDisabled = isSendingMessage || (startNewChat && currentReceiverIds.length === 0)

	if (!shouldAlwaysDisplay && !shouldDisplayChatInput) return null

	return (
		<ChatInput
			isDisabled={isDisabled}
			value={chatInput}
			handleChatInput={handleChatInput}
			handleSendChatMessage={handleSendChatMessage}
		/>
	)
}

export default ChatInputContainer
