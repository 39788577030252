import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, IconButton, Tooltip } from '@mui/material'
import { MdAddTask } from 'react-icons/md'
import Popup from 'components/Popup'
import Spinner from 'components/Spinner'
import Message from 'components/Message'
import TemplateTaskPopup from 'components/popups/TemplateTaskPopup'
import TemplateAddEdit from 'components/TemplateAddEdit'
import TemplateTask from './TemplateTask'
import {
	createTemplateTask,
	initializeTemplateTasks,
	initializeTemplates,
	updateTemplate,
	updateTemplateTask,
} from 'slices/templateSlice'

const TaskTemplateFeed = ({ taskTemplate }) => {
	const dispatch = useDispatch()

	const templateId = taskTemplate._id

	const [openPopup, setOpenPopup] = useState(false)
	const [openEditTemplatePopup, setOpenEditTemplatePopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('Update Task')
	const [recordForEdit, setRecordForEdit] = useState(null)

	const {
		entities: taskEntities,
		ids: taskIds,
		isInitialized: tasksInitialized,
		isLoading: tasksLoading,
		error,
	} = useSelector(state => state.templates.tasks)
	const newTasks = taskIds.map(id => taskEntities[id])

	const tasks = newTasks.filter(item => item.parentId === null)
	const taskSeq = tasks.length + 1

	const subtasks = newTasks.filter(item => item.parentId !== null)

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo: user } = userLogin

	const userId = user._id

	useEffect(() => {
		dispatch(initializeTemplateTasks(templateId))
	}, [dispatch, templateId])

	const openInPopup = task => {
		setRecordForEdit(task)
		setOpenEditTemplatePopup(true)
	}

	const addEditTask = async (task, resetForm) => {
		const taskName = task.taskName
		const seq = task.seq
		const taskDays = task.taskDays
		const description = task.description

		if (task._id === 0) {
			dispatch(
				createTemplateTask({
					templateId: templateId,
					parentId: null,
					taskName: taskName,
					seq: seq,
					taskDays: taskDays,
					description: description,
				}),
			)
			dispatch(initializeTemplateTasks(templateId))
		} else {
			dispatch(
				updateTemplateTask({
					_id: task._id,
					templateId: templateId,
					parentId: null,
					taskName: taskName,
					seq: seq,
					description: description,
				}),
			)
		}

		resetForm()
		setOpenPopup(false)
		setRecordForEdit(null)
		await dispatch(initializeTemplateTasks(templateId))
	}

	const handleUpdateTemplate = async (template, resetForm) => {
		const templateName = template.templateName
		const description = template.description
		const templateType = template.templateType

		await dispatch(
			updateTemplate({
				_id: templateId,
				createdBy: userId,
				templateName: templateName,
				description: description,
				templateType: templateType,
			}),
		)

		resetForm()
		setRecordForEdit(null)
		setOpenEditTemplatePopup(false)
		await dispatch(initializeTemplates())
	}

	return (
		<Grid item>
			<div className="task">
				<div className="task__header">
					<div className="task__info">
						<Tooltip title={taskTemplate.description}>
							<h2
								onClick={() => {
									setPopupTitle('Update')
									openInPopup(taskTemplate)
								}}
							>
								{taskTemplate.templateName}
							</h2>
						</Tooltip>
					</div>
					<div className="task__button">
						<Tooltip title={`Add new Task.`}>
							<IconButton
								className="feed__icon"
								color="primary"
								size="small"
								onClick={() => {
									setPopupTitle(`New Task`)
									setRecordForEdit(null)
									setOpenPopup(true)
								}}
							>
								<MdAddTask />
							</IconButton>
						</Tooltip>
					</div>
				</div>
			</div>
			<div className="taskFeed_Container">
				{tasksLoading ? (
					<Spinner />
				) : error ? (
					<Message variant="danger">{error}</Message>
				) : tasks.length > 0 ? (
					<div>
						{tasks.map(task => (
							<TemplateTask key={task._id} templateTask={task} subtasks={subtasks} />
						))}
					</div>
				) : null}

				{openPopup && (
					<Popup title={popupTitle} openPopup={openPopup} setOpenPopup={setOpenPopup}>
						<TemplateTaskPopup
							recordForEdit={recordForEdit}
							addEditTask={addEditTask}
							taskSeq={taskSeq}
						/>
					</Popup>
				)}
				{openEditTemplatePopup && (
					<Popup
						title={popupTitle}
						openPopup={openEditTemplatePopup}
						setOpenPopup={setOpenEditTemplatePopup}
					>
						<TemplateAddEdit
							recordForEdit={recordForEdit}
							handleSubmit={handleUpdateTemplate}
							// taskSeq={taskSeq}
						/>
					</Popup>
				)}
			</div>
		</Grid>
	)
}

export default TaskTemplateFeed
