import React, { useReducer, useRef, useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { io } from 'socket.io-client'
import curry from 'utils/curry'
import { chatService, formatChatHeadersByUserId, getEventIdForReceiverIds } from 'features/Chat'
import type { IChatState, IChatInternalActions, IChatActions } from './Chat.types'

export const socket = io(
	process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : 'https://chat.mydealteams.com',
)

socket.on('connect', () => {
	console.log(`ChatContext|connect|socket.id=${socket.id}`)
})

const STATE: IChatState = {
	sample: 'Sample',
	socket,
	userId: '',
	chatHeaders: [],
	chatMessages: {},
	currentEventId: '',
	currentProjectId: '',
	currentReceiverIds: [],
	chatInput: '',
	startNewChat: false,
	isLoading: false,
	isSendingMessage: false,
	onlineUserIds: [],

	// computed state
	currentMessages: [],
	hasCurrentMessages: undefined,
	shouldDisplayChatInput: undefined,
	currentChatHeader: undefined,
}

const ACTIONS = {
	setSample: 'setSample',
	setChatHeaders: 'setChatHeaders',
	setChatMessages: 'setChatMessages',
	setCurrentEventId: 'setCurrentEventId',
	setCurrentProjectId: 'setCurrentProjectId',
	setCurrentReceiverIds: 'setCurrentReceiverIds',
	setChatInput: 'setChatInput',
	setStartNewChat: 'setStartNewChat',
	setIsLoading: 'setIsLoading',
	setIsSendingMessage: 'setIsSendingMessage',
	setOnlineUserIds: 'setOnlineUserIds',
}

/*
  REDUCER
*/
const reducer = (state: IChatState, action: { type: string; payload: any }) => {
	const { type, payload } = action

	if (type === ACTIONS.setSample) return { ...state, sample: payload }
	if (type === ACTIONS.setChatHeaders) return { ...state, chatHeaders: payload }
	if (type === ACTIONS.setChatMessages) return { ...state, chatMessages: payload }
	if (type === ACTIONS.setCurrentEventId) return { ...state, currentEventId: payload }
	if (type === ACTIONS.setCurrentProjectId) return { ...state, currentProjectId: payload }
	if (type === ACTIONS.setCurrentReceiverIds) return { ...state, currentReceiverIds: payload }
	if (type === ACTIONS.setChatInput) return { ...state, chatInput: payload }
	if (type === ACTIONS.setStartNewChat) return { ...state, startNewChat: payload }
	if (type === ACTIONS.setIsLoading) return { ...state, isLoading: payload }
	if (type === ACTIONS.setIsSendingMessage) return { ...state, isSendingMessage: payload }
	if (type === ACTIONS.setOnlineUserIds) return { ...state, onlineUserIds: payload }

	return state
}

type Context = { state: IChatState; actions: any } | undefined
const ChatContext = React.createContext<Context>(undefined)

const ChatProvider = ({ children }: { children: React.ReactNode }) => {
	const chatInputRef = useRef<HTMLInputElement>(null)
	const autocompleteInputRef = useRef<HTMLInputElement>(null)
	const messagesEndRef = useRef<HTMLDivElement>(null)
	const joinedChatRooms = useRef(false)

	const location = useLocation()

	useEffect(() => {
		if (location.pathname.includes('/chats')) getChatHeaders()
		// eslint-disable-next-line
	}, [location])

	useEffect(() => {
		const locationArr = location.pathname.split('/')
		const projectId = locationArr[locationArr.indexOf('projects') + 1]
		// console.log('setting projectId to: ', projectId)
		if (projectId) setCurrentProjectId(projectId)
		else setCurrentProjectId('')
		// eslint-disable-next-line
	}, [location])

	// @ts-ignore
	const userLogin = useSelector(state => state.userLogin)
	const userId = userLogin?.userInfo?._id

	// @ts-ignore
	const params = new URL(document.location).searchParams
	const idParam = params.get('id')

	const [state, dispatch] = useReducer(reducer, STATE)
	const setState = (type: string, payload: any = undefined) => dispatch({ type, payload })
	const _setState = curry(setState) // curried setState
	// create actions
	const _actions = Object.entries(ACTIONS).reduce(
		(acc, curr) => ({ ...acc, [curr[1]]: _setState(curr[1]) }),
		{},
	) as IChatInternalActions

	const {
		setChatHeaders,
		setChatMessages,
		setCurrentEventId,
		setCurrentProjectId,
		setCurrentReceiverIds,
		setChatInput,
		setStartNewChat,
		setIsLoading,
		setIsSendingMessage,
		setOnlineUserIds,
	} = _actions

	const isChatRoom = !!state.currentProjectId

	const getChatMessages = async (
		eventId: string,
		receiverIds: { receiverId: { _id: string } }[] = [],
		shouldUpdateCurrentEventId = true,
	) => {
		if (!state.chatMessages[eventId]) {
			let chatMessages = []
			if (isChatRoom) {
				chatMessages = await chatService().getChatsByRoomId(state.currentProjectId)
			} else chatMessages = await chatService().getChatsByEventId(eventId)

			setChatMessages({ ...state.chatMessages, [eventId]: chatMessages })
		}
		if (shouldUpdateCurrentEventId) {
			setCurrentEventId(eventId)
		}
		if (receiverIds.length > 0) {
			setCurrentReceiverIds(receiverIds.map(receiver => receiver.receiverId._id))
		}

		setStartNewChat(false)

		setTimeout(() => {
			chatInputRef.current?.focus()
		}, 0)
	}

	const getChatHeaders = async (isReceiveMessageCallback = false) => {
		const chatHeadersByUserId = await chatService().getChatHeadersByUserId(userId)
		const username = userLogin?.userInfo?.name

		// join the global chat room to get messages sent to you from far off places
		if (!joinedChatRooms.current) {
			handleJoinChatRoom(username, 'GLOBAL_CHAT_ROOM')
		}

		if (chatHeadersByUserId.length > 0) {
			// determine the currently selected chat header to get chat messages to display
			// select the first chatHeader initially
			let chatHeaderIndex = 0

			// if currentEventId exists, find the index of that one
			// use window because react state is not updating
			// @ts-ignore
			if (window.currentEventId) {
				const currentEventIdIndex = chatHeadersByUserId.findIndex(
					// @ts-ignore
					chatHeader => chatHeader._id === window.currentEventId,
				)
				if (currentEventIdIndex > 0) chatHeaderIndex = currentEventIdIndex
			}

			// if it's a param, find the index of that one
			if (idParam) {
				const idParamIndex = chatHeadersByUserId.findIndex(chatHeader => chatHeader._id === idParam)
				if (idParamIndex > 0) chatHeaderIndex = idParamIndex
			}

			const initChatHeader = chatHeadersByUserId[chatHeaderIndex]

			initChatHeader.readByIds = Array.from(new Set([...initChatHeader.readByIds, userId]))

			const formattedChatHeadersByUserId = formatChatHeadersByUserId(chatHeadersByUserId, userId)

			if (!joinedChatRooms.current) {
				formattedChatHeadersByUserId.forEach(header => {
					handleJoinChatRoom(username, header._id)
				})

				joinedChatRooms.current = true
			}

			setChatHeaders(formattedChatHeadersByUserId)
			getChatMessages(initChatHeader._id, initChatHeader.receiverIds, !isReceiveMessageCallback)
		} else {
			setStartNewChat(true)
		}
	}

	const handleStartNewChat = () => {
		setStartNewChat(true)
		setCurrentEventId('')
		setCurrentReceiverIds([])
		setChatInput('')

		setTimeout(() => {
			autocompleteInputRef.current?.focus()
		}, 0)
	}

	const handleCloseNewChat = () => {
		setStartNewChat(false)
		setCurrentReceiverIds([])
		setChatInput('')

		if (state.chatHeaders.length > 0) {
			getChatMessages(state.chatHeaders[0]._id, state.chatHeaders[0].receiverIds)
		}
	}

	const handleChatInput = e => {
		setChatInput(e.target.value)
	}

	const sendChatMessage = async () => {
		if (!state.chatInput) return

		if (isChatRoom) {
			const chatMessageData = {
				projectId: state.currentProjectId,
				eventId: undefined,
				receiverIds: [],
				message: state.chatInput,
			}

			const chatMessage = await chatService().addChatEvent(chatMessageData)
			socket.emit('send_message', chatMessage)

			// const messages = state.chatMessages

			// console.log(`messages: `, messages)

			// console.log(`chatMessage.projectId: `, chatMessage.projectid)

			// debugger

			if (state.chatMessages[chatMessage.projectid]) {
				// handle subsequent messages
				setChatMessages({
					...state.chatMessages,
					[chatMessage.projectid]: [
						...state.chatMessages[chatMessage.projectid],
						{ ...chatMessage },
					],
				})
			} else {
				setChatMessages({
					...state.chatMessages,
					[chatMessage.projectId]: [{ ...chatMessage }],
				})
			}

			setChatInput('')
			setIsLoading(false)
			setIsSendingMessage(false)
		} else {
			setIsSendingMessage(true)
			let isFirstMessage = !state.currentEventId

			if (isFirstMessage) {
				setStartNewChat(false)
				setIsLoading(true)
			}

			const existingEventIdForChatMessage = getEventIdForReceiverIds(
				state.chatHeaders,
				state.currentReceiverIds,
			)

			const chatMessageData = {
				eventId: isFirstMessage
					? getEventIdForReceiverIds(state.chatHeaders, state.currentReceiverIds)
					: state.currentEventId,
				receiverIds: state.currentReceiverIds,
				message: state.chatInput,
			}

			const chatMessage = await chatService().addChatEvent(chatMessageData)

			const eventId = !existingEventIdForChatMessage ? chatMessage._id : chatMessage.eventId

			if (!existingEventIdForChatMessage) {
				setChatMessages({
					...state.chatMessages,
					[eventId]: [{ ...chatMessage }],
				})
			} else {
				// handle subsequent messages
				setChatMessages({
					...state.chatMessages,
					[eventId]: [...state.chatMessages[eventId], { ...chatMessage }],
				})
			}

			socket.emit('send_message', chatMessage)

			await getChatHeaders()
			// create skeleton loader if doing the next line (instead of Spinner component)
			// setCurrentEventId(eventId)
			setChatInput('')
			setIsLoading(false)
			setIsSendingMessage(false)
		}
	}

	const handleSendChatMessage = e => {
		e.preventDefault()
		sendChatMessage()
	}

	const handleJoinChatRoom = (
		username: string,
		roomId: string,
		userId: undefined | string = undefined,
	) => {
		if (username && roomId) {
			// console.log('handleJoinChatRoom|roomId=', roomId)
			socket.emit('join_room', { username, roomId, userId })
		}
	}

	const joinChatRoom = useCallback(() => {
		const username = userLogin?.userInfo?.name
		const roomId = state.currentEventId
		handleJoinChatRoom(username, roomId, userLogin.userInfo._id)
	}, [state.currentEventId, userLogin])

	const handleUserJoinedRoom = useCallback(
		userId => {
			// console.log('handleUserJoinedRoom|userId=', userId)
			setOnlineUserIds(Array.from(new Set([...state.onlineUserIds, userId])))
		},
		// eslint-disable-next-line
		[state.onlineUserIds],
	)

	const handleUserLeftRoom = useCallback(
		userId => {
			// console.log('handleUserLeftRoom|userId=', userId)
			setOnlineUserIds(state.onlineUserIds.filter(id => id !== userId))
		},
		// eslint-disable-next-line
		[state.onlineUserIds],
	)

	const handleReceiveMessage = useCallback(
		message => {
			const eventId = message.roomId || message.eventId

			// incoming messages frmo the GLOBAL_CHAT_ROOM
			const receiverIds = message.receiverIds.map(receiver => receiver.receiverId)

			if (state.chatMessages[eventId]) {
				setChatMessages({
					...state.chatMessages,
					[eventId]: [...state.chatMessages[eventId], { ...message }],
				})
			} else if (receiverIds.includes(userId)) {
				getChatHeaders(true)
			} else {
				setChatMessages({
					...state.chatMessages,
					[eventId]: [{ ...message }],
				})
			}

			if (message.eventId) getChatHeaders(true)
		},
		// eslint-disable-next-line
		[state.chatMessages],
	)

	useEffect(() => {
		socket.off('receive_message').on('receive_message', message => {
			handleReceiveMessage(message)
		})
	}, [handleReceiveMessage])

	useEffect(() => {
		socket.off('user_is_online').on('user_is_online', userId => {
			handleUserJoinedRoom(userId)
		})
	})

	useEffect(() => {
		socket.off('user_is_offline').on('user_is_offline', userId => {
			handleUserLeftRoom(userId)
		})
	})

	useEffect(() => {
		scrollToBottom()
	}, [state.chatMessages])

	useEffect(() => {
		joinChatRoom()
		setChatInput('')
		scrollToBottom('instant')

		if (isChatRoom) {
			getChatMessages(state.currentProjectId)
		}
		// eslint-disable-next-line
	}, [state.currentEventId])

	const handleAutocomplete = (event, value) => {
		const newReceiverIds = value.map(receiver => receiver.partner)
		setCurrentReceiverIds(newReceiverIds)
	}

	const scrollToBottom = (behavior = 'smooth') => {
		messagesEndRef.current?.scrollIntoView({
			// @ts-ignore
			behavior,
			block: 'nearest',
			inline: 'start',
		})
	}

	const currentMessages = state.chatMessages[`${state.currentEventId}`]
	const hasCurrentMessages = state.currentEventId && currentMessages && currentMessages.length > 0
	const shouldDisplayChatInput = state.startNewChat || hasCurrentMessages
	const currentChatHeader = state.chatHeaders.find(
		chatHeader => chatHeader._id === state.currentEventId,
	)

	// this is a hack because latest react state is not available
	// when getChatHeaders is called by handleReceiveMessage
	// @ts-ignore
	window.currentEventId = state.currentEventId

	const chatActions: IChatActions = {
		..._actions,
		getChatHeaders,
		getChatMessages,
		handleStartNewChat,
		handleCloseNewChat,
		handleChatInput,
		handleSendChatMessage,
		handleAutocomplete,
	}

	const chatState: IChatState = {
		...state,
		userId,
		chatInputRef,
		autocompleteInputRef,
		messagesEndRef,

		// computed state
		currentMessages,
		hasCurrentMessages,
		shouldDisplayChatInput,
		currentChatHeader,
	}

	return (
		<ChatContext.Provider value={{ state: chatState, actions: chatActions }}>
			{children}
		</ChatContext.Provider>
	)
}

const useChat = () => {
	const context = React.useContext(ChatContext)
	if (context === undefined) throw new Error('useChat must be used within a ChatProvider')
	return context
}

export { ChatProvider, useChat }

/*
  # Usage
  import { useChat } from 'features/Chat'
  const { state, actions } = useChat()
*/
