import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import TemplateSiteDetailFeed from './components/TemplateSiteDetail_Feed'

const TemplateSiteDetail_Edit = () => {
	const { id } = useParams()

	const { template, loading } = useSelector(state => {
		return {
			loading: state.templates.isLoading,
			template: state.templates.entities[id],
		}
	})

	// TODO: Create skeleton loader
	if (loading || !template) return null

	return <TemplateSiteDetailFeed template={template} />
}

export default TemplateSiteDetail_Edit
