import ArchiveLibrary_Locations_Archived from '../navPages/ArchiveLibrary_Locations_Archived'
import ArchiveLibrary_Locations_Active from '../navPages/ArchiveLibrary_Locations_Active'
import routes from 'constants/routes'

const getArchiveLibraryRoutes = (id: string) => [
	{
		linkText: 'Archived ',
		linkTo: `${routes.ARCHIVE_LIBRARY}/${id}/projects`,
		linkPattern: `${routes.ARCHIVE_LIBRARY}/:id/projects`,
		component: ArchiveLibrary_Locations_Archived,
	},

	{
		linkText: 'Active ',
		linkTo: `${routes.ARCHIVE_LIBRARY}/${id}/notes`,
		linkPattern: `${routes.ARCHIVE_LIBRARY}/:id/notes`,
		component: ArchiveLibrary_Locations_Active,
	},

	/*
		Redirect routes
	*/
	{
		redirectFrom: `${routes.ARCHIVE_LIBRARY}/:id`,
		redirectTo: `${routes.ARCHIVE_LIBRARY}/${id}/projects`,
	},
]

export default getArchiveLibraryRoutes
