import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { myPartnersService } from 'services'

const SLICE_NAME = 'myPartners'

const initialState = {
	entities: {},
	ids: [],
	isLoading: false,
	isInitialized: false,
	myPartnersList: {
		loading: true,
		data: [],
		error: null,
	},
	myPendingPartner: {
		loading: true,
		data: {},
		error: null,
	},
	myPendingInvitations: {
		entities: {},
		ids: [],
		isLoading: false,
		isInitialized: false,
	},
	myPendingPartnerShips: {
		entities: {},
		ids: [],
		isLoading: false,
		isInitialized: false,
	},
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

//CREATE
export const addMyPartner = createAsyncThunk(
	`${SLICE_NAME}/addMyPartner`,
	async (partner: any) => (await myPartnersService().addMyPartner(partner)) || {},
)

//READ
export const initializeMyPartners = createAsyncThunk(
	`${SLICE_NAME}/initialize`,
	async (payload, { getState, rejectWithValue }) => {
		try {
			return axios.get('/api/mypartners', prepConfig(getState)).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize my partners')
		}
	},
)

export const getMyPendingInvitations = createAsyncThunk(
	`${SLICE_NAME}/getMyPendingInvitations`,
	async (payload, { getState, rejectWithValue }) => {
		try {
			return axios.get('/api/mypartners/invitations', prepConfig(getState)).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize my partnership invitiations')
		}
	},
)

export const getMyPendingPartnerShips = createAsyncThunk(
	`${SLICE_NAME}/getMyPendingPartnerships`,
	async (payload, { getState, rejectWithValue }) => {
		try {
			return axios.get('/api/mypartners/partnerships', prepConfig(getState)).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize my pending partnerships')
		}
	},
)

//UPDATE
export const updateMyPartner = createAsyncThunk(
	`${SLICE_NAME}/updateMyPartner`,
	async (payload: any, { getState, rejectWithValue }) => {
		try {
			return axios.put(`/api/mypartners`, payload, prepConfig(getState)).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize projects')
		}
	},
)

//DELETE

export const deleteMyPartner = createAsyncThunk(
	`${SLICE_NAME}/deleteMyPartner`,
	async (mypartnerId, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.delete(`/api/myPartners/${mypartnerId}`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to delete partner')
		}
	},
)

//OTHER
export const acceptMyPartner = createAsyncThunk(
	`${SLICE_NAME}/acceptMyPartner`,
	async ({ partnershipId, isAccepted }: { partnershipId: string; isAccepted: boolean }) =>
		(await myPartnersService().acceptMyPartner({ partnershipId, isAccepted })) || {},
)

// Extract the key out of the entitiy object
// eslint-disable-next-line no-unused-vars
const removeKey = (key, { [key]: _, ...rest }) => rest

const myPartnersSlice = createSlice({
	name: SLICE_NAME,
	initialState,
	reducers: {},
	extraReducers: {
		[addMyPartner.fulfilled as any]: state => {
			state.isInitialized = false
		},
		[initializeMyPartners.pending as any]: state => {
			state.isLoading = true
		},

		[initializeMyPartners.fulfilled as any]: (state, action) => {
			const mypartners = action.payload

			state.ids = mypartners?.map((item, index) => {
				state.entities[item._id] = item

				return item._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeMyPartners.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},

		[getMyPendingPartnerShips.pending as any]: state => {
			state.myPendingPartnerShips.isLoading = true
			state.myPendingPartnerShips.isInitialized = false
		},

		[getMyPendingPartnerShips.fulfilled as any]: (state, action) => {
			const mypartners = action.payload

			state.myPendingPartnerShips.ids = mypartners?.map((item, index) => {
				state.myPendingPartnerShips.entities[item._id] = item

				return item._id
			})
			state.myPendingPartnerShips.isLoading = false
			state.myPendingPartnerShips.isInitialized = true
		},
		[getMyPendingPartnerShips.rejected as any]: (state, action) => {
			state.myPendingPartnerShips.isLoading = false
			state.myPendingPartnerShips.isInitialized = true
			state.myPendingPartnerShips.error = action.error.message
		},

		[getMyPendingInvitations.pending as any]: state => {
			state.myPendingInvitations.isLoading = true
			state.myPendingInvitations.isInitialized = false
		},

		[getMyPendingInvitations.fulfilled as any]: (state, action) => {
			const mypartners = action.payload

			state.myPendingInvitations.ids = mypartners?.map((item, index) => {
				state.myPendingInvitations.entities[item._id] = item

				return item._id
			})
			state.myPendingInvitations.isLoading = false
			state.myPendingInvitations.isInitialized = true
		},
		[getMyPendingInvitations.rejected as any]: (state, action) => {
			state.myPendingInvitations.isLoading = false
			state.myPendingInvitations.isInitialized = true
			state.isInitialized = false
			state.myPendingInvitations.error = action.error.message
		},

		[acceptMyPartner.pending as any]: () => ({ ...initialState }),

		[acceptMyPartner.fulfilled as any]: (state, action) => ({
			...state,
			acceptMyPartner: action.payload,
			loading: false,
		}),

		[acceptMyPartner.rejected as any]: (state, action) => ({
			...state,
			loading: false,
			error: action.error,
		}),
		[deleteMyPartner.fulfilled as any]: (state, action) => {
			const id = action.meta.arg
			state.entities = removeKey(id, state.entities)
			state.ids = state.ids.filter(stateId => stateId !== id)
		},
	} as any,
})

export default myPartnersSlice.reducer
