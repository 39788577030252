import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { createProjectFile, getProjectFiles, updateProjectFile } from './projectFileSlice'
import { createProjectImage, deleteProjectImage, getProjectImages } from './projectImagesSlice'
import { createProjectNote, getProjectNotes } from './projectNoteSlice'

const initialState = {
	entities: {},
	ids: [],
	recents: [],
	isLoading: false,
	isInitialized: false,
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

export const initializeProjects = createAsyncThunk(
	'projects/initialize',
	async (payload: any, { getState, rejectWithValue }) => {
		const { locationId } = payload

		try {
			return axios.get(`/api/projects/${locationId}`, prepConfig(getState)).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize projects')
		}
	},
)

export const initializeMyProjects = createAsyncThunk(
	'projects/initialize',
	async (_, { getState, rejectWithValue }) => {
		try {
			return axios.get(`/api/projects/myProjects`, prepConfig(getState)).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize projects')
		}
	},
)

export const createProject = createAsyncThunk(
	'projects/create',
	async (project: any, { getState, rejectWithValue }) => {
		try {
			return axios.post(`/api/projects`, project, prepConfig(getState)).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize projects')
		}
	},
)

export const deleteProject = createAsyncThunk(
	'projects/delete',
	async (projectId, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.delete(`/api/projects/${projectId}`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to delete project')
		}
	},
)

export const patchProject = createAsyncThunk(
	'projects/patch',
	async (payload: any, { getState, rejectWithValue }) => {
		const { projectId, updates } = payload

		try {
			const { data } = await axios.patch(
				`/api/projects/${projectId}/patch`,
				updates,
				prepConfig(getState),
			)
			return data
		} catch (error) {
			console.error(error)
			return rejectWithValue('Unable to patch project')
		}
	},
)

export const updateProject = createAsyncThunk(
	'projects/update',
	async (payload: any, { getState, rejectWithValue }) => {
		try {
			return axios
				.put(`/api/projects/${payload._id}`, payload, prepConfig(getState))
				.then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize projects')
		}
	},
)

export const addPartnerToProject = createAsyncThunk(
	'projects/addPartner',
	async (payload: any, { getState, rejectWithValue }) => {
		const { projectId, partner } = payload

		console.log(`payload: `, payload)

		debugger

		try {
			const { data } = await axios.post(
				`/api/projects/${projectId}/addpartners`,
				{ partnerId: partner._id, partnerUserId: partner.partner._id, projectId },
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to add partner')
		}
	},
)

export const removePartnerFromProject = createAsyncThunk(
	'projects/removePartner',
	async (payload: any, { getState, rejectWithValue }) => {
		const { projectId, partnerId } = payload

		try {
			const { data } = await axios.post(
				`/api/projects/${projectId}/removePartner`,
				{ projectId: projectId, partnerId: partnerId },
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to remove partner')
		}
	},
)

export const getUpdatedProject = createAsyncThunk(
	'projects/getUpdate',
	async (projectId, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.get(`/api/projects/${projectId}`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to update project')
		}
	},
)

// Extract the key out of the entitiy object
// eslint-disable-next-line no-unused-vars
const removeKey = (key, { [key]: _, ...rest }) => rest

const projectSlice = createSlice({
	name: 'projects',
	initialState,
	reducers: {},
	extraReducers: {
		[initializeProjects.fulfilled as any]: (state, action) => {
			const projects = action.payload

			state.recents = []
			state.ids = projects?.map((loc, index) => {
				state.entities[loc._id] = loc

				if (index < 4) {
					state.recents.push(loc._id)
				}
				return loc._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeProjects.pending as any]: state => {
			state.isLoading = true
		},
		[initializeProjects.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},
		[initializeMyProjects.fulfilled as any]: (state, action) => {
			const projects = action.payload

			state.recents = []
			state.ids = projects?.map((loc, index) => {
				state.entities[loc._id] = loc

				if (index < 4) {
					state.recents.push(loc._id)
				}
				return loc._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeMyProjects.pending as any]: state => {
			state.isLoading = true
		},
		[initializeMyProjects.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},
		[createProject.fulfilled as any]: (state, action) => {
			const project = action.payload
			state.entities[project._id] = project
			state.ids.push(project._id)
			state.recents.unshift(project._id)

			if (state.recents.length > 4) {
				state.recents.pop()
			}
		},
		[updateProject.fulfilled as any]: (state, action) => {
			const project = action.payload
			const projectId = project._id
			state.entities[projectId] = project
		},
		[deleteProject.fulfilled as any]: (state, action) => {
			const id = action.meta.arg
			state.entities = removeKey(id, state.entities)
			state.ids = state.ids.filter(stateId => stateId !== id)
		},
		[patchProject.fulfilled as any]: (state, action) => {
			const project = action.payload
			state.entities[project._id] = { ...action.payload, ...state.entities[project._id] }
			state.isInitialized = false
		},

		[getUpdatedProject.fulfilled as any]: (state, action) => {
			// works for any asset type since all types are stored under entities
			state.entities[action.meta.arg] = { ...action.payload, ...state.entities[action.meta.arg] }
			state.isLoading = false
		},
		[getUpdatedProject.rejected as any]: (state, action) => {
			state.error = action.error
		},
		[addPartnerToProject.fulfilled as any]: (state, action) => {
			const project = action.payload
			state.entities[project._id] = project
			state.isInitialized = false
		},
		[addPartnerToProject.rejected as any]: (state, action) => {
			console.log(action)
		},
		[getProjectNotes.fulfilled as any]: (state, action) => {
			const notes = action.payload
			const projectId = action.meta.arg
			const projectRef = state.entities[projectId]
			if (projectRef && notes?.length) {
				const projectNoteIds = notes.map(n => n._id)
				projectRef.notes = projectNoteIds
			}
		},
		[createProjectNote.fulfilled as any]: (state, action) => {
			const newNote = action.payload
			const existingNotes = state.entities[newNote.projectid].notes
			if (existingNotes) {
				existingNotes.unshift(newNote._id)
			} else {
				state.entities[newNote.projectid].notes = [newNote._id]
			}
		},
		[getProjectImages.fulfilled as any]: (state, action) => {
			const images = action.payload
			const locationId = action.meta.arg
			const locationRef = state.entities[locationId]
			if (locationRef && images?.length) {
				const locationImageIds = images.map(n => n._id)
				locationRef.images = locationImageIds
			}
		},
		[createProjectImage.fulfilled as any]: (state, action) => {
			const image = action.payload
			const locationId = image.projectid
			const locationRef = state.entities[locationId]
			if (locationRef && image) {
				if (locationRef.images) {
					locationRef.images.push(image._id)
				} else {
					locationRef.images = [image._id]
				}
			}
		},
		[deleteProjectImage.fulfilled as any]: (state, action) => {
			const locationId = action.payload.projectid
			const imageId = action.meta.arg
			const updatedImageIds = state.entities[locationId]?.images?.filter((imgId: any) => {
				return imgId !== imageId
			})
			state.entities[locationId].images = updatedImageIds
		},
		[getProjectFiles.fulfilled as any]: (state, action) => {
			const files = action.payload
			const locationId = action.meta.arg
			const locationRef = state.entities[locationId]
			if (locationRef && files?.length) {
				const locationFileIds = files.map(n => n._id)
				locationRef.files = locationFileIds
			}
		},
		[createProjectFile.fulfilled as any]: (state, action) => {
			const file = action.payload
			const projectId = file.projectid
			const locationRef = state.entities[projectId]
			if (locationRef && file) {
				if (locationRef.files) {
					locationRef.files.push(file._id)
				} else {
					locationRef.files = [file._id]
				}
			}
		},
		[removePartnerFromProject.fulfilled as any]: (state, action) => {
			const partners = action.payload
			state.entities[action.meta.arg.projectId].partners = partners
		},
	} as any,
})

export default projectSlice
