import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { createPortfolioFile, getPortfolioFiles, updatePortfolioFile } from './portfolioFileSlice'
import {
	createPortfolioImage,
	deletePortfolioImage,
	getPortfolioImages,
} from './portfolioImagesSlice'
import { createPortfolioNote, getPortfolioNotes } from './portfolioNoteSlice'

const initialState = {
	entities: {},
	ids: [],
	recents: [],
	isLoading: false,
	isInitialized: false,
}

function prepConfig(getState) {
	const {
		userLogin: { userInfo },
	} = getState()

	return {
		headers: {
			Authorization: `Bearer ${userInfo.token}`,
		},
	}
}

export const initializePortfolios = createAsyncThunk(
	'portfolios/initialize',
	async (_, { getState, rejectWithValue }) => {
		try {
			return axios
				.get(`/api/portfolios/sharedportfolios`, prepConfig(getState))
				.then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize portfolios')
		}
	},
)

export const initializeArchivedPortfolios = createAsyncThunk(
	'portfolios/initializeArchived',
	async (_, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.get('/api/portfolios/archived', prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to initialize portfolios')
		}
	},
)

export const createPortfolio = createAsyncThunk(
	'portfolios/create',
	async (portfolio, { getState, rejectWithValue }) => {
		try {
			return axios.post(`/api/portfolios`, portfolio, prepConfig(getState)).then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize portfolios')
		}
	},
)

export const deletePortfolio = createAsyncThunk(
	'portfolios/delete',
	async (portfolioId, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.delete(`/api/portfolios/${portfolioId}`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to delete portfolio')
		}
	},
)

export const patchPortfolio = createAsyncThunk(
	'portfolios/patch',
	async (payload: any, { getState, rejectWithValue }) => {
		const { portfolioId, updates } = payload

		try {
			const { data } = await axios.patch(
				`/api/portfolios/${portfolioId}/patch`,
				updates,
				prepConfig(getState),
			)
			return data
		} catch (error) {
			console.error(error)
			return rejectWithValue('Unable to patch portfolio')
		}
	},
)

export const updatePortfolio = createAsyncThunk(
	'portfolios/update',
	async (payload: any, { getState, rejectWithValue }) => {
		console.log(`payload: `, payload)
		try {
			return axios
				.put(`/api/portfolios/${payload._id}`, payload, prepConfig(getState))
				.then(res => res.data)
		} catch (error) {
			return rejectWithValue('Failed to initialize portfolios')
		}
	},
)

export const addLocationToPortfolio = createAsyncThunk(
	'portfolios/addLocation',
	async (payload: any, { getState, rejectWithValue }) => {
		const { portfolioId, locationId } = payload

		try {
			const { data } = await axios.post(
				`/api/portfolios/${portfolioId}/locations`,
				{ locationId },
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to add location')
		}
	},
)

export const removeLocationFromPortfolio = createAsyncThunk(
	'portfolios/removeLocationFromPortfolio',
	async (payload: any, { getState, rejectWithValue }) => {
		const { locationId, portfolioId } = payload

		try {
			const { data } = await axios.delete(
				`/api/portfolios/${portfolioId}/locations/${locationId}`,
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to add partner')
		}
	},
)

export const addPartnerToPortfolio = createAsyncThunk(
	'portfolios/addPartner',
	async (payload: any, { getState, rejectWithValue }) => {
		const { portfolioId, partner } = payload

		try {
			const { data } = await axios.post(
				`/api/portfolios/${portfolioId}/addpartners`,
				{ partnerId: partner._id, partnerUserId: partner.partner, portfolioId },
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to add partner')
		}
	},
)

export const removePartnerFromPortfolio = createAsyncThunk(
	'portfolios/removePartner',
	async (payload: any, { getState, rejectWithValue }) => {
		const { portfolioId, partnerId } = payload

		try {
			const { data } = await axios.post(
				`/api/portfolios/${portfolioId}/removePartner`,
				{ partnerId },
				prepConfig(getState),
			)
			return data
		} catch (error) {
			return rejectWithValue('Failed to remove partner')
		}
	},
)

export const getUpdatedPortfolio = createAsyncThunk(
	'portfolios/getUpdate',
	async (portfolioId, { getState, rejectWithValue }) => {
		try {
			const { data } = await axios.get(`/api/portfolios/${portfolioId}`, prepConfig(getState))
			return data
		} catch (error) {
			return rejectWithValue('Failed to update portfolio')
		}
	},
)

// Extract the key out of the entitiy object
// eslint-disable-next-line no-unused-vars
const removeKey = (key, { [key]: _, ...rest }) => rest

const portfolioSlice = createSlice({
	name: 'portfolios',
	initialState,
	reducers: {},
	extraReducers: {
		[initializePortfolios.fulfilled as any]: (state, action) => {
			const portfolios = action.payload

			state.recents = []
			state.ids = portfolios?.map((loc, index) => {
				state.entities[loc._id] = loc

				if (index < 4) {
					state.recents.push(loc._id)
				}
				return loc._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializePortfolios.pending as any]: state => {
			state.isLoading = true
		},
		[initializePortfolios.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},

		[initializeArchivedPortfolios.fulfilled as any]: (state, action) => {
			const portfolios = action.payload

			state.ids = portfolios?.map((loc, index) => {
				state.entities[loc._id] = loc
				return loc._id
			})
			state.isLoading = false
			state.isInitialized = true
		},
		[initializeArchivedPortfolios.pending as any]: state => {
			state.isLoading = true
		},
		[initializeArchivedPortfolios.rejected as any]: (state, action) => {
			state.isLoading = false
			state.isInitialized = true
			state.error = action.error.message
		},

		[createPortfolio.fulfilled as any]: (state, action) => {
			const portfolio = action.payload
			state.entities[portfolio._id] = portfolio
			state.ids.push(portfolio._id)
			state.recents.unshift(portfolio._id)

			if (state.recents.length > 4) {
				state.recents.pop()
			}
		},
		[deletePortfolio.fulfilled as any]: (state, action) => {
			const id = action.meta.arg
			state.entities = removeKey(id, state.entities)
			state.ids = state.ids.filter(stateId => stateId !== id)
		},
		[patchPortfolio.fulfilled as any]: (state, action) => {
			const portfolio = action.payload
			state.entities[portfolio._id] = { ...action.payload, ...state.entities[portfolio._id] }
			state.isInitialized = false
		},

		[updatePortfolio.fulfilled as any]: (state, action) => {
			const portfolio = action.payload
			const portfolioId = portfolio._id
			state.entities[portfolioId] = portfolio
		},
		[getUpdatedPortfolio.fulfilled as any]: (state, action) => {
			// works for any asset type since all types are stored under entities
			state.entities[action.meta.arg] = { ...action.payload, ...state.entities[action.meta.arg] }
			state.isLoading = false
		},
		[getUpdatedPortfolio.rejected as any]: (state, action) => {
			state.error = action.error
		},
		[addLocationToPortfolio.fulfilled as any]: (state, action) => {
			const locations = action.payload
			state.entities[action.meta.arg.portfolioId].locations = locations
			state.isInitialized = false
		},
		[addLocationToPortfolio.rejected as any]: (state, action) => {
			state.error = 'Something went wrong'
		},
		[addPartnerToPortfolio.fulfilled as any]: (state, action) => {
			const portfolio = action.payload
			state.entities[portfolio._id] = portfolio
		},
		[addPartnerToPortfolio.rejected as any]: (state, action) => {
			console.log(action)
		},
		[removeLocationFromPortfolio.fulfilled as any]: (state, action) => {
			const locations = action.payload
			state.entities[action.meta.arg.portfolioId].locations = locations
			state.isInitialized = false
		},
		[getPortfolioNotes.fulfilled as any]: (state, action) => {
			const notes = action.payload
			const portfolioId = action.meta.arg
			const portfolioRef = state.entities[portfolioId]
			if (portfolioRef && notes?.length) {
				const portfolioNoteIds = notes.map(n => n._id)
				portfolioRef.notes = portfolioNoteIds
			}
		},
		[createPortfolioNote.fulfilled as any]: (state, action) => {
			const newNote = action.payload
			const existingNotes = state.entities[newNote.portfolioid].notes
			if (existingNotes) {
				existingNotes.unshift(newNote._id)
			} else {
				state.entities[newNote.portfolioid].notes = [newNote._id]
			}
		},
		[getPortfolioImages.fulfilled as any]: (state, action) => {
			const images = action.payload
			const locationId = action.meta.arg
			const locationRef = state.entities[locationId]
			if (locationRef && images?.length) {
				const locationImageIds = images.map(n => n._id)
				locationRef.images = locationImageIds
			}
		},
		[createPortfolioImage.fulfilled as any]: (state, action) => {
			const image = action.payload
			const locationId = image.portfolioid
			const locationRef = state.entities[locationId]
			if (locationRef && image) {
				if (locationRef.images) {
					locationRef.images.push(image._id)
				} else {
					locationRef.images = [image._id]
				}
			}
		},
		[deletePortfolioImage.fulfilled as any]: (state, action) => {
			const locationId = action.payload.portfolioid
			const imageId = action.meta.arg
			const updatedImageIds = state.entities[locationId]?.images?.filter((imgId: any) => {
				return imgId !== imageId
			})
			state.entities[locationId].images = updatedImageIds
		},
		[getPortfolioFiles.fulfilled as any]: (state, action) => {
			const files = action.payload
			const locationId = action.meta.arg
			const locationRef = state.entities[locationId]
			if (locationRef && files?.length) {
				const locationFileIds = files.map(n => n._id)
				locationRef.files = locationFileIds
			}
		},
		[createPortfolioFile.fulfilled as any]: (state, action) => {
			const file = action.payload
			const portfolioId = file.portfolioid
			const locationRef = state.entities[portfolioId]
			if (locationRef && file) {
				if (locationRef.files) {
					locationRef.files.push(file._id)
				} else {
					locationRef.files = [file._id]
				}
			}
		},
		[removePartnerFromPortfolio.fulfilled as any]: (state, action) => {
			const partners = action.payload
			state.entities[action.meta.arg.portfolioId].partners = partners
		},
	} as any,
})

export default portfolioSlice
