import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import TWControls from '../controls/TWControls'
import { Form, useForm } from '../controls/useForm'

const DetailEditPopup = ({ recordForEdit = {}, handleSubmit }) => {
	const deleteDetail = false
	const [touchedInputs, setTouchedInputs] = useState([])

	const detailTypes = [
		{ id: 1 },
		{ selectValue: 'Text' },
		{ id: 2 },
		{ selectValue: 'Number' },
		{ id: 3 },
		{ selectValue: 'Currency' },
		{ id: 4 },
		{ selectValue: 'Date' },
	]

	const isValid = (_errors = errors) => {
		const _isValid = Object.values(_errors).every(x => x === '')

		if (values._id === 0) {
			return (
				touchedInputs.includes('detailLabel') &&
				touchedInputs.includes('description') &&
				touchedInputs.includes('detailType') &&
				_isValid
			)
		}
		return _isValid
	}

	const validate = () => {
		const temp = { ...errors }

		temp.detailLabel = values.detailLabel ? '' : 'This field is required.'
		temp.detailType = values.detailType ? '' : 'This field is required.'

		setErrors({ ...temp })

		return isValid(temp)
	}

	const defaultValues = {
		_id: 0,
		detailLabel: '',
		description: '',
		detailType: '',
		detail: '',
	}

	const initialValues = {
		...defaultValues,
		...recordForEdit,
	}

	const {
		values,
		setValues,
		errors,
		setErrors,
		handleInputChange,
		_handleInputChange,
		resetForm,
	} = useForm(initialValues, true, validate)

	const submitHandler = e => {
		e.preventDefault()
		if (validate() || deleteDetail) handleSubmit(values, resetForm)
	}
	let curr = {}

	const handleOnInput = (e, id) => {
		if (!touchedInputs.includes(id)) setTouchedInputs([...touchedInputs, id])

		const { name, value } = e.target
		curr = { name, value }

		setTimeout(() => {
			validate()
		}, 0)
	}

	useEffect(() => {
		_handleInputChange(curr)

		setTimeout(() => {
			validate()
		}, 0)
		// eslint-disable-next-line
	}, [touchedInputs])

	return (
		<Form onSubmit={submitHandler}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TWControls.Input
						name="detailLabel"
						label="Detail Label"
						value={values.detailLabel}
						onChange={handleInputChange}
						onInput={e => {
							handleOnInput(e, 'detailLabel')
						}}
						onBlur={e => {
							handleOnInput(e, 'detailLabel')
						}}
						error={touchedInputs.includes('detailLabel') && errors.detailLabel}
					/>
					<TWControls.SelectType
						name="detailType"
						label="Detail Type"
						value={values.detailType}
						onChange={handleInputChange}
						onInput={e => {
							handleOnInput(e, 'detailType')
						}}
						onBlur={e => {
							handleOnInput(e, 'detailType')
						}}
						error={touchedInputs.includes('detailType') && errors.detailType}
						options={detailTypes}
					/>

					{values.detailType === 'Date' ? (
						<TWControls.DatePicker
							name="detail"
							label="Initial Date"
							value={values.detail}
							onChange={handleInputChange}
							onInput={e => {
								handleOnInput(e, 'detail')
							}}
							onBlur={e => {
								handleOnInput(e, 'detail')
							}}
							error={touchedInputs.includes('detail') && errors.detail}
						/>
					) : (
						<TWControls.Input
							name="detail"
							label="Initial Value"
							value={values.detail}
							onChange={handleInputChange}
							onInput={e => {
								handleOnInput(e, 'detail')
							}}
							onBlur={e => {
								handleOnInput(e, 'detail')
							}}
							error={touchedInputs.includes('detail') && errors.detail}
						/>
					)}
					<TWControls.Input
						name="description"
						label="Note"
						value={values.description}
						onChange={handleInputChange}
						onInput={e => {
							handleOnInput(e, 'description')
						}}
						onBlur={e => {
							handleOnInput(e, 'description')
						}}
						error={touchedInputs.includes('description') && errors.description}
					/>
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<TWControls.Button size="small" type="submit" text="Update" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default DetailEditPopup
