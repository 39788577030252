import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MapLocationRGM from 'components/MapLocationRGM'
import { Grid } from '@mui/material'
import { FaRegCircle } from 'react-icons/fa'
import Layout from 'components/Layout/Layout'
import ListAction from 'features/Actions/components/ListAction'
import { useParams } from 'react-router-dom'
import { initializeActiveLocations } from 'slices/locationSlice'
import { initializeApiKey } from 'slices/apiKeysSlice'

const PortfolioMapsScreen = ({ portfolio }) => {
	const dispatch = useDispatch()

	const { id } = useParams()
	const [ringRadius, setRingRadius] = useState(0)
	const [allRings, setAllRings] = useState(false)

	const { entities: locationEntities, isInitialized, loading } = useSelector(state => {
		return {
			isInitialized: state.locations.isInitialized,
			loading: state.locations.isLoading,
			entities: state.locations.entities,
		}
	})

	const { entity: apiKey } = useSelector(state => state.apiKey)

	const portfolioLocations = portfolio.locations

	useEffect(() => {
		dispatch(initializeApiKey('REACT_APP_GOOGLE_API_KEY'))
		dispatch(initializeActiveLocations())
	}, [dispatch])

	const handleRing1 = () => {
		setRingRadius(1)
		setAllRings(false)
	}

	const handleRing2 = () => {
		setRingRadius(2)
		setAllRings(false)
	}

	const handleRing3 = () => {
		setRingRadius(3)
		setAllRings(false)
	}
	const handleAllRingsRing = () => {
		setRingRadius(0)
		setAllRings(true)
	}

	// TODO: Create skeleton loader
	// if (loading || !apiKey) return null

	var mapLat = 0
	var mapLong = 0
	var zoomLevel = 10

	if (!isInitialized) return null
	const locationPins = portfolioLocations.map(portfolioLocation => {
		const item = locationEntities[portfolioLocation.location._id]

		return {
			id: item._id,
			name: item.assetName,
			address: item.address,
			city: item.city,
			state: item.stateProvince,
			lat: item.latitude,
			lng: item.longitude,
			image: item.image,
		}
	})
	if (locationPins.length > 1) {
		console.log(`Pins!`)
		const arrayLat = locationPins.map(function (pin) {
			return pin.lat
		})
		const arrayLong = locationPins.map(function (pin) {
			return pin.lng
		})
		mapLat = (Math.max.apply(null, arrayLat) + Math.min.apply(null, arrayLat)) / 2
		mapLong = (Math.min.apply(null, arrayLong) + Math.max.apply(null, arrayLong)) / 2
	} else {
		console.log(`No Pins!`)
		mapLat = locationPins[0]?.lat || 39.5
		mapLong = locationPins[0]?.lng || -98.35
		if (mapLat === 39.5) zoomLevel = 10
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={9}>
				<MapLocationRGM
					apiKey={apiKey}
					asset={portfolio}
					centerPin={[mapLat, mapLong]}
					useRings={true}
					ringRadius={ringRadius}
					allRings={allRings}
					markers={locationPins}
					useCenterPin={false}
					zoom={zoomLevel}
				/>
			</Grid>
			<Grid item xs={3}>
				<Layout.SidebarItem title="Actions" hasNoMaxHeight>
					<ul>
						<li className="actionList__item">
							<ListAction
								handleAction={handleRing1}
								actionLable={'1 Mile Ring'}
								actionIcon={<FaRegCircle />}
							/>
						</li>
						<li className="actionList__item">
							<ListAction
								handleAction={handleRing2}
								actionLable={'2 Mile Ring'}
								actionIcon={<FaRegCircle />}
							/>
						</li>
						<li className="actionList__item">
							<ListAction
								handleAction={handleRing3}
								actionLable={'3 Mile Ring'}
								actionIcon={<FaRegCircle />}
							/>
						</li>
						<li className="actionList__item">
							<ListAction
								handleAction={handleAllRingsRing}
								actionLable={'1/2/3 Mile Rings'}
								actionIcon={<FaRegCircle />}
							/>
						</li>
					</ul>
				</Layout.SidebarItem>
			</Grid>
		</Grid>
	)
}

export default PortfolioMapsScreen
