import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import TWControls from '../controls/TWControls'
import { Form, useForm } from '../controls/useForm'

const TemplateTaskPopup = ({ addEditTask, recordForEdit = {}, isSubtask, taskSeq }) => {
	console.log(`recordForEdit: `, recordForEdit)
	const defaultValues = {
		_id: 0,
		taskName: '',
		seq: taskSeq,
		taskDays: 7,
		description: '',
	}
	const initialValues = {
		...defaultValues,
		...recordForEdit,
	}

	const validate = () => {
		let temp = { ...errors }
		temp.taskName = values.taskName ? '' : 'This field is required.'
		// temp.seq = values.seq ? '' : 'This field is required.'
		// temp.taskDays = values.taskDays ? '' : 'This field is required.'
		// temp.description = values.description ? '' : 'This field is required.'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialValues,
		true,
		validate,
	)

	const submitHandler = e => {
		e.preventDefault()
		if (validate()) {
			addEditTask(values, resetForm)
		}
	}

	useEffect(() => {
		if (recordForEdit != null) setValues({ ...recordForEdit })
	}, [recordForEdit, setValues])

	return (
		<Form onSubmit={submitHandler}>
			<Grid container>
				<Grid item>
					<TWControls.Input
						name="taskName"
						label={isSubtask ? 'Subtask Name' : 'Task Name'}
						value={values.taskName}
						onChange={handleInputChange}
						error={errors.taskName}
					/>
					{values._id === 0 ? null : (
						<div>
							<TWControls.Input
								name="seq"
								label="Seq (Order of operation.)"
								value={values.seq}
								onChange={handleInputChange}
								error={errors.seq}
							/>
							<TWControls.Input
								name="taskDays"
								label="Task Days (Estimated no. of days to complete)"
								value={values.taskDays}
								onChange={handleInputChange}
								error={errors.taskDays}
							/>
							<TWControls.InputMulti
								name="description"
								label="Notes"
								value={values.description}
								onChange={handleInputChange}
								error={errors.description}
							/>
						</div>
					)}
					{values._id === 0 ? (
						<div className="newLocation__imageHolder">
							<TWControls.Button
								size="small"
								type="submit"
								text={isSubtask ? 'Add Subtask' : 'Add Task'}
							/>
						</div>
					) : (
						<div className="newLocation__imageHolder">
							<TWControls.Button size="small" type="submit" text="Update" />
						</div>
					)}
				</Grid>
			</Grid>
		</Form>
	)
}

export default TemplateTaskPopup
