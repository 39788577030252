import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ArchiveLibraryCards from './ArchiveLibraryCards'
import Spinner from 'components/Spinner'
import useTableNoPage from 'components/controls/useTableNoPage'
import { initializeAppResources } from 'slices/appResourceSlice'

const headerCells = [
	{ id: 'Image', label: '', disableSorting: true },
	{ id: 'assetName', label: 'Location' },
	{ id: 'owner.name', label: 'Originator' },
	{ id: 'address', label: 'Address' },
	{ id: 'city', label: 'City' },
	{ id: 'stateProvince', label: 'State/Prov' },
	{ id: 'update', label: 'Edit' },
]

const ArchiveLibrary = () => {
	const dispatch = useDispatch()

	const [filterFn, setFilterFn] = useState({
		fn: items => {
			return items
		},
	})

	const { entities, ids, isLoading, isInitialized } = useSelector(state => state.appResource)
	const records = ids.map(id => entities[id])

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo: user } = userLogin

	const userId = user._id

	useEffect(() => {
		if (!isInitialized) {
			dispatch(initializeAppResources())
		}
	}, [dispatch, user, isInitialized])

	const { TblContainer, TblHeader, recordsAfterPagingAndSorting } = useTableNoPage(
		records,
		headerCells,
		filterFn,
	)

	return (
		<React.Fragment>
			{!isLoading ? (
				<div>
					<ArchiveLibraryCards
						categories={recordsAfterPagingAndSorting()}
						TblContainer={TblContainer}
						userId={userId}
					/>
				</div>
			) : (
				<Spinner containerHeight="50vh" />
			)}
		</React.Fragment>
	)
}

export default ArchiveLibrary
