import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { initializeMyPartners } from 'slices/myPartnersSlice'
import { useChat } from 'features/Chat'
import { ChatAddressBar } from 'features/Chat'

const ChatAddressBarContainer = () => {
	const {
		state: { hasCurrentMessages, startNewChat, autocompleteInputRef },
		actions: { handleCloseNewChat, handleAutocomplete },
	} = useChat()

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(initializeMyPartners())
		// eslint-disable-next-line
	}, [])

	// @ts-ignore
	// const myPartnersSelector = useSelector(state => state.myPartners.myPartnersList)
	// const { data: myPartnersList, success } = myPartnersSelector

	const {
		entities: myPartners,
		ids: myPartnerIds,
		isLoading: myPartnersLoading,
		isInitialized: myPartnersInitialized,
	} = useSelector((state: any) => state.myPartners)
	const myPartnersList = myPartnerIds.map(id => myPartners[id])

	const shouldRender = !hasCurrentMessages && startNewChat && myPartnersList
	if (!shouldRender) return null

	return (
		<ChatAddressBar
			ref={autocompleteInputRef}
			myPartners={myPartnersList}
			handleAutocomplete={handleAutocomplete}
			handleCloseNewChat={handleCloseNewChat}
		/>
	)
}

export default ChatAddressBarContainer
