import React, { useState } from 'react'
import { MdOutlineEmail } from 'react-icons/md'
import { FaUsers } from 'react-icons/fa'
import Button from 'components/Button'
import Layout from 'components/Layout'
import Popup from 'components/Popup'
import CommunicationPartners from 'components/popups/CommunicationPartners'
import { useChat } from 'features/Chat'

const SidebarLocationProjectPartners = ({ assetOwner, assetName, projectPartners, userId }) => {
	const { state } = useChat()
	const [openPopup, setOpenPopup] = useState(false)
	const [recordForEdit, setRecordForEdit] = useState(null)

	const owner = [
		{
			id: assetOwner._id,
			image: assetOwner.image,
			name: assetOwner.name,
			email: assetOwner.email,
			title: assetOwner.title,
			company: assetOwner.company,
			isAccepted: true,
			owner: true,
		},
	]

	// console.log(`projectPartners: `, projectPartners)

	// debugger

	const partners = projectPartners.map(item => {
		if (item) {
			return {
				id: item.partnerUserId._id,
				image: item.partnerUserId.image,
				name: item.partnerUserId.name,
				email: item.partnerUserId.email,
				title: item.partnerUserId.title,
				company: item.partnerUserId.company,
				isAccepted: item.partner.isAccepted,
				owner: false,
				emailOption: '',
			}
		}
		return {}
	})

	const newPartners = [...owner, ...partners]
	const allPartners = newPartners.filter(item => item.id !== userId)
	const isEmpty = allPartners?.length === 0

	const getIsOnline = id => state.onlineUserIds.some(onlineUserId => onlineUserId === id)

	const handlePartnerComms = async resetForm => {
		const primary = partners.filter(partner => partner.emailOption === 'primary')
		const cc = partners.filter(partner => partner.emailOption === 'cc')
		const bcc = partners.filter(partner => partner.emailOption === 'bcc')

		const newPrimary = primary.reduce((acc, curr) => `${acc}${curr.email};`, '')
		const newCC = cc.reduce((acc, curr) => `${acc}${curr.email};`, '')
		const newBcc = bcc.reduce((acc, curr) => `${acc}${curr.email};`, '')

		if (newPrimary) {
			var mailTo = `mailto:${newPrimary}`

			if (newCC) {
				mailTo = mailTo + `?cc=${newCC}`
				if (newBcc) {
					mailTo = mailTo + `&bcc=${newBcc}&Subject=${assetName}`
				}
			} else if (newBcc) {
				mailTo = mailTo + `?bcc=${newBcc}&Subject=${assetName}`
			} else {
				mailTo = mailTo + `?Subject=${assetName}`
			}

			window.location.href = `${mailTo}`
		}

		resetForm()
		setOpenPopup(false)
		setRecordForEdit(null)
	}

	const openInPopup = () => {
		setOpenPopup(true)
	}

	return (
		<div>
			<Layout.SidebarItem
				title="Project Partners"
				icon={<FaUsers />}
				isEmpty={isEmpty}
				noPadding
				contentRight={
					<Button
						type="submit"
						variant="ghost"
						onClick={openInPopup}
						aria-label="Partner Communications"
						style={{
							margin: '0',
							padding: '0',
							backgroundColor: 'transparent',
						}}
					>
						<MdOutlineEmail style={{ margin: '0', fontSize: '24px' }} />
					</Button>
				}
			>
				{isEmpty ? (
					<span>No Partners</span>
				) : (
					<ul>
						{allPartners.map(partner => (
							<li key={partner.id}>
								<Layout.PartnerItem
									avatar={partner.image}
									name={partner.name}
									title={partner.isAccepted ? partner.title : '(Pending)'}
									company={partner.isAccepted ? partner.company : null}
									link={`mailto: ${partner.name} (${partner.email})?subject=${assetName}`}
									isOwner={partner.owner}
									isOnline={getIsOnline(partner.id)}
								/>
							</li>
						))}
					</ul>
				)}
			</Layout.SidebarItem>
			<Popup title="Partner Communication" openPopup={openPopup} setOpenPopup={setOpenPopup}>
				<CommunicationPartners
					partners={partners}
					recordForEdit={recordForEdit}
					handlePartnerComms={handlePartnerComms}
				/>
			</Popup>
		</div>
	)
}

export default SidebarLocationProjectPartners
