import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ImageGalleryContainer from 'containers/ImageGalleryContainer'
import FileUploader from 'components/FileUploader'
import { Grid } from '@mui/material'
import Layout from 'components/Layout/Layout'
import { createImage, getImages } from 'slices/locationImageSlice'
import { patchLocation } from 'slices/locationSlice'

const LocationImageGallery = ({ user, location, project }) => {
	const locationId = location._id
	const projectId = project._id
	const userId = user._id

	const dispatch = useDispatch()

	const sendLocationImage = async info => {
		const elements = info.name.split('.')
		const imageExt = elements.slice(elements.length - 1).toString()

		await dispatch(
			createImage({
				userid: userId,
				locationid: locationId,
				projectid: projectId,
				uuid: info.uuid,
				name: info.name,
				size: info.size,
				url: info.originalUrl,
				source: info.source,
				type: imageExt,
			}),
		)
		dispatch(patchLocation({ locationId: locationId, updates: { updateNote: 'Image(s) Updated' } }))
		await dispatch(
			getImages({
				locationid: locationId,
				projectid: projectId,
				userid: userId,
			}),
		)
	}

	return (
		<div>
			<Grid container spacing={3}>
				<Grid item xs={9}>
					<ImageGalleryContainer projectid={projectId} />
				</Grid>
				<Grid item xs={3}>
					<Layout.SidebarItem title="Actions" hasNoMaxHeight>
						<ul>
							<li className="actionList__item">
								<FileUploader fileType="image" onChange={info => sendLocationImage(info)} />
							</li>
						</ul>
					</Layout.SidebarItem>
				</Grid>
			</Grid>
		</div>
	)
}

export default LocationImageGallery
