import React, { useCallback, useRef, useState } from 'react'
import { Circle, GoogleMap, InfoWindow, Marker } from '@react-google-maps/api'
import { Avatar } from '@mui/material'

import mapStyles from './mapStyles'

const libraries = ['places']

const mapContainerStyle = {
	// maxWidth: '760px',
	height: '680px',
}

const options = {
	styles: mapStyles,
	// disableDefaultUI: true,
	// clickableIcons: false,
	zoomControl: true,
}

const MapLocationRGM = ({
	apiKey,
	asset,
	centerPin,
	useCenterPin,
	useRings,
	ringRadius,
	allRings,
	markers,
	zoom,
}) => {
	const [markrs, setMarkers] = useState([])
	const [infoClick, setInfoClick] = useState(false)
	const [markerInfoClick, setMarkerInfoClick] = useState(false)
	const [currentMarker, setCurrentMarker] = useState(null)

	const onMapClick = useCallback(e => {
		setMarkers(current => [
			...current,
			{
				lat: e.latLng.lat(),
				lng: e.latLng.lng(),
				time: new Date(),
			},
		])
	}, [])

	const mapRef = useRef()
	const onMapLoad = useCallback(
		map => {
			mapRef.current = map
			if (mapRef.current && markers.length > 0) {
				const bounds = new window.google.maps.LatLngBounds()

				// Extend the bounds to include each marker
				markers.forEach(({ lat, lng }) => {
					bounds.extend(new window.google.maps.LatLng(lat, lng))
				})

				// Fit the map to the new bounds
				mapRef.current.fitBounds(bounds)
			}
		},
		[markers],
	)

	const openMarkerInfo = marker => {
		setMarkerInfoClick(true)
		setCurrentMarker(marker)
	}

	return (
		<div className="map__container">
			<GoogleMap
				className="map"
				mapContainerStyle={mapContainerStyle}
				zoom={zoom}
				center={{ lat: centerPin[0], lng: centerPin[1] }}
				options={options}
				googleMapsApiKey={apiKey}
				libraries={libraries}
				onClick={onMapClick}
				onLoad={onMapLoad}
			>
				<>
					{useCenterPin ? (
						<Marker
							position={{ lat: centerPin[0], lng: centerPin[1] }}
							onClick={() => {
								setInfoClick(true)
							}}
						/>
					) : null}

					{markers
						? markers.map(marker => (
								<Marker
									key={marker.id}
									position={marker}
									onClick={() => {
										openMarkerInfo(marker)
									}}
								/>
						  ))
						: null}
					{useRings ? (
						<Circle
							center={{ lat: centerPin[0], lng: centerPin[1] }}
							radius={1.609344 * 1000 * ringRadius}
							options={closeOptions}
						/>
					) : null}
					{allRings ? (
						<Circle
							center={{ lat: centerPin[0], lng: centerPin[1] }}
							radius={1.609344 * 1000}
							options={closeOptions}
						/>
					) : null}
					{allRings ? (
						<Circle
							center={{ lat: centerPin[0], lng: centerPin[1] }}
							radius={1.609344 * 3000}
							options={middleOptions}
						/>
					) : null}
					{allRings ? (
						<Circle
							center={{ lat: centerPin[0], lng: centerPin[1] }}
							radius={1.609344 * 5000}
							options={farOptions}
						/>
					) : null}
				</>
				{infoClick && (
					<InfoWindow
						position={{
							lat: asset.latitude,
							lng: asset.longitude,
						}}
						onCloseClick={() => {
							setInfoClick(false)
						}}
					>
						<div>
							<h3>{asset.assetName}</h3>
							<Avatar src={asset.image} sx={{ width: 72, height: 72 }} />
							<p>{asset.address}</p>
							<p>
								{asset.city}, {asset.stateProvince}
							</p>
						</div>
					</InfoWindow>
				)}
				{markerInfoClick && (
					<InfoWindow
						position={{
							lat: currentMarker.lat,
							lng: currentMarker.lng,
						}}
						onCloseClick={() => {
							setMarkerInfoClick(false)
						}}
					>
						<div>
							<h3>{currentMarker.name}</h3>
							<Avatar src={currentMarker.image} sx={{ width: 72, height: 72 }} />
							<p>{currentMarker.address}</p>
							<p>
								{currentMarker.city}, {currentMarker.state}
							</p>
						</div>
					</InfoWindow>
				)}
			</GoogleMap>
		</div>
	)
}

const defaultOptions = {
	strokeOpacity: 0.5,
	strokeWeight: 2,
	clickable: false,
	draggable: false,
	editable: false,
	visible: true,
}
const closeOptions = {
	...defaultOptions,
	zIndex: 3,
	fillOpacity: 0.05,
	strokeColor: '#8BC34A',
	fillColor: '#8BC34A',
}
const middleOptions = {
	...defaultOptions,
	zIndex: 2,
	fillOpacity: 0.05,
	strokeColor: '#FBC02D',
	fillColor: '#FBC02D',
}
const farOptions = {
	...defaultOptions,
	zIndex: 1,
	fillOpacity: 0.05,
	strokeColor: '#FF5252',
	fillColor: '#FF5252',
}
export default MapLocationRGM
