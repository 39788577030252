import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { login } from '../../../slices/authSlice'
import MDTLogo from 'images/MDT_FullLogo.png'
import Button from 'components/Button'
import Message from 'components/Message'
import routes from 'constants/routes'
import { Link } from 'react-router-dom'

const LoginForm = () => {
	const dispatch = useDispatch()

	const userLogin = useSelector((state: any) => state.userLogin)
	const { loading: userLoginLoading, error: userLoginError } = userLogin

	return (
		<Formik
			initialValues={{ email: '', password: '' }}
			onSubmit={(values, { setSubmitting }) => {
				dispatch(login(values))

				setSubmitting(false)
			}}
		>
			{({ isSubmitting }) => (
				<Form>
					<div>
						<img
							style={{ display: 'block', margin: '0 auto 16px' }}
							src={MDTLogo}
							alt="My Deal Teams logo"
							width={360}
						/>
					</div>
					<h1 className="form-title">Member Login</h1>

					{userLoginError && (
						<Message variant="danger">
							{userLoginError.message == `Request failed with status code 401`
								? 'Invalid email or password!'
								: userLoginError.message}
						</Message>
					)}
					<div className="inputs">
						<div className="input">
							<Field type="email" name="email" placeholder="Email" />
						</div>
						<div className="input">
							<Field type="password" name="password" placeholder="Password" />
						</div>
					</div>
					<div className="actions">
						<Button type="submit" isLarge disabled={isSubmitting || userLoginLoading}>
							{isSubmitting || userLoginLoading ? 'Submitting...' : 'Login'}
						</Button>
						<Link to={`${routes.FORGOT_PASSWORD}`} className="form__footer-link">
							Forgot Password
						</Link>
					</div>
				</Form>
			)}
		</Formik>
	)
}

export default LoginForm
