import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material'
import Layout from 'components/Layout'
import { initializeActiveLocations } from 'slices/locationSlice'
import { useParams } from 'react-router-dom'
import { removeLocationFromPortfolio } from 'slices/portfolioSlice'

const SidebarPortfolioAddLocation = () => {
	const { id: portfolioId } = useParams()
	const dispatch = useDispatch()

	const { isLoading: loading, portfolio, error, isInitialized: success } = useSelector(state => {
		return {
			isLoading: state.portfolios.isLoading,
			portfolio: state.portfolios.entities[portfolioId] || {},
			error: state.portfolios.error,
			isInitialized: state.portfolios.isInitialized,
		}
	})

	const locationEntities = useSelector(state => state.locations.entities)

	const dropLocationFromPortfolio = async id => {
		await dispatch(removeLocationFromPortfolio({ portfolioId, locationId: id }))
		if (success) {
			await dispatch(initializeActiveLocations())
		}
	}

	const portfolioLocations = portfolio.locations

	console.log(`portfolioLocations: `, portfolioLocations)

	const isEmpty = portfolioLocations?.length === 0

	return (
		<Layout.SidebarItem title="Portfolio Locations" noPadding isEmpty={isEmpty}>
			{success ? (
				<div>
					<List dense>
						{portfolioLocations.map(portfolioLocation => {
							const location = locationEntities[portfolioLocation.location._id]
							if (!location) return <></>
							return (
								<ListItem className="listGroup__task" key={location._id}>
									<ListItemAvatar>
										<Avatar src={location.image} alt={location.image} />
									</ListItemAvatar>
									<ListItemText
										primary={location.assetName}
										secondary={
											<React.Fragment>
												<Typography component="span" variant="body2" color="textPrimary">
													{location.city}
													{' - '}
												</Typography>
												{location.stateProvince}
											</React.Fragment>
										}
									/>
									<ListItemAvatar
										color="secondary"
										size="small"
										onClick={() => dropLocationFromPortfolio(location._id)}
									>
										<DeleteIcon />
									</ListItemAvatar>
								</ListItem>
							)
						})}
					</List>
				</div>
			) : null}
		</Layout.SidebarItem>
	)
}

export default SidebarPortfolioAddLocation
