import React from 'react'
import { Link } from 'react-router-dom'
import Button from 'components/Button'
import { StyledDetailsTable } from 'features/TemplateDetails/TemplateDetails.style'
import { RiCloseCircleLine } from 'react-icons/ri'
import { TableBody, TableCell, TableRow } from '@mui/material'
import moment from 'moment'

const TemplatesTable = ({ templates, TblContainer, TblHeader, handleDeleteDetail }) => {
	return (
		<StyledDetailsTable>
			<TblContainer className="topPage__tableContainer">
				<TblHeader />
				<TableBody>
					{templates.map(template => (
						<TableRow key={template._id} className="table-row">
							<TableCell>
								<Link to={`/templates/${template._id}/${template.templateType}`}>
									{template.templateName}
								</Link>
							</TableCell>
							<TableCell>
								<div>{template.createdBy.name}</div>
							</TableCell>
							<TableCell>
								<div>
									{template.templateType === `task` ? 'Task Template' : 'Site Detail Template'}
								</div>
							</TableCell>
							<TableCell>
								<div>{moment(template.createdAt).format('MM/DD/YYYY')}</div>
							</TableCell>
							<TableCell>
								<div>{moment(template.updatedAt).format('MM/DD/YYYY')}</div>
							</TableCell>
							<TableCell align="right">
								<div className="edit-button">
									<Button
										onClick={() => handleDeleteDetail(template)}
										variant="ghost-red"
										isOutline
										aria-label="Delete"
									>
										<RiCloseCircleLine />
									</Button>
								</div>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</TblContainer>
		</StyledDetailsTable>
	)
}

export default TemplatesTable
