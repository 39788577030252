import React, { useState } from 'react'
import { Grid } from '@mui/material'
import TWControls from '../controls/TWControls'
import { Form, useForm } from '../controls/useForm'

const SendEmailPopup = ({ sendEmailHandler, emailKit }) => {
	const initialValues = {
		id: 0,
		emailSubject: emailKit.emailSubject,
		emailBody: '',
	}

	const validate = () => {
		let temp = { ...errors }
		temp.emailBody = values.emailBody ? '' : 'This field is required.'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialValues,
		true,
		validate,
	)

	const submitHandler = e => {
		e.preventDefault()

		if (validate()) {
			emailKit.emailBody = values.emailBody
			sendEmailHandler(emailKit, resetForm)
		}
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container spacing={2}>
				<Grid item xs={9}>
					<p>{`From: ${emailKit.fromName}`}</p>
					<p>{`To: ${emailKit.toName}`}</p>
				</Grid>
				<Grid item xs={12}>
					<TWControls.Input
						name="emailSubject"
						label="Subject:"
						value={values.emailSubject}
						error={errors.emailSubject}
					/>
					<TWControls.InputMulti
						name="emailBody"
						label="Content:"
						value={values.emailBody}
						onChange={handleInputChange}
						error={errors.emailBody}
					/>
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<TWControls.Button type="submit" text="Send" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default SendEmailPopup
