import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Chart as ChartJS,
	ArcElement,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import PieChart from './components/PieChart'
import BarChart from './components/BarChart'
import { getPerc } from './utils'
import StyledDemographicsCharts from './DemographicsCharts.style'
import * as _ from 'styles/helpers'
import {
	initializeDemosByCBSA,
	initializeDemosByCity,
	initializeDemosByPostalCode,
} from 'slices/demographicsSlice'

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title)

export interface Props {
	className?: string
	asset: any
}

const DemographicsCharts = ({ asset, className = '' }: Props) => {
	const dispatch = useDispatch()

	const postalCode = asset.postalCode
	const uCaseCity = asset.city.toUpperCase()
	const uCaseState = asset.stateProvince.toUpperCase()

	const city = `${asset.city}`

	const { cbsa } = asset

	// @ts-ignore
	const { entity: demographic } = useSelector(state => state.demographics.byPostalCode)

	// @ts-ignore
	const { entity: demographicCity } = useSelector(state => state.demographics.byCity)

	// @ts-ignore
	const { entity: demographicCBSA } = useSelector(state => state.demographics.byCBSA)

	useEffect(() => {
		dispatch(initializeDemosByPostalCode(postalCode))
		dispatch(initializeDemosByCity({ uCaseCity, uCaseState }))
		dispatch(initializeDemosByCBSA(cbsa))
	}, [postalCode, dispatch, uCaseCity, uCaseState, cbsa])

	const [isGender, setIsGender] = useState(true)

	const CHART_DATA = {
		population: {
			name: 'Population',
			postalCode: demographic.population,
			city: demographicCity.population,
			msa: demographicCBSA.population,
		},
		households: {
			name: 'Households',
			postalCode: demographic.householdsPerZipCode,
			city: demographicCity.householdsPerZipCode,
			msa: demographicCBSA.householdsPerZipCode,
		},
		income: {
			name: 'Median Household Income',
			postalCode: demographic.incomePerHousehold,
			city: demographicCity.incomePerHousehold,
			msa: demographicCBSA.incomePerHousehold,
		},
		home_value: {
			name: 'Average Home Value',
			postalCode: demographic.averageHouseValue,
			city: demographicCity.averageHouseValue,
			msa: demographicCBSA.averageHouseValue,
		},
		gender: {
			female: {
				name: 'Female',
				postalCode: demographic.femalePopulation,
				postalCodePrcnt: getPerc(demographic.femalePopulation, demographic.population),
				city: demographicCity.femalePopulation,
				cityPrcnt: getPerc(demographicCity.femalePopulation, demographicCity.population),
				msa: demographicCBSA.femalePopulation,
				msaPrcnt: getPerc(demographicCBSA.femalePopulation, demographicCBSA.population),
			},
			male: {
				postalCode: demographic.malePopulation,
				postalCodePrcnt: getPerc(demographic.malePopulation, demographic.population),
				city: demographicCity.malePopulation,
				cityPrcnt: getPerc(demographicCity.malePopulation, demographicCity.population),
				msa: demographicCBSA.malePopulation,
				msaPrcnt: getPerc(demographicCBSA.malePopulation, demographicCBSA.population),
			},
		},
		race: {
			asian: {
				name: 'Asian',
				postalCode: demographic.asianPopulation,
				postalCodePrcnt: getPerc(demographic.asianPopulation, demographic.population),
				city: demographicCity.asianPopulation,
				cityPrcnt: getPerc(demographicCity.asianPopulation, demographicCity.population),
				msa: demographicCBSA.asianPopulation,
				msaPrcnt: getPerc(demographicCBSA.asianPopulation, demographicCBSA.population),
			},
			black: {
				name: 'Black',
				postalCode: demographic.blackPopulation,
				postalCodePrcnt: getPerc(demographic.blackPopulation, demographic.population),
				city: demographicCity.blackPopulation,
				cityPrcnt: getPerc(demographicCity.blackPopulation, demographicCity.population),
				msa: demographicCBSA.blackPopulation,
				msaPrcnt: getPerc(demographicCBSA.blackPopulation, demographicCBSA.population),
			},
			hispanic: {
				name: 'Hispanic',
				postalCode: demographic.hispanicPopulation,
				postalCodePrcnt: getPerc(demographic.hispanicPopulation, demographic.population),
				city: demographicCity.hispanicPopulation,
				cityPrcnt: getPerc(demographicCity.hispanicPopulation, demographicCity.population),
				msa: demographicCBSA.hispanicPopulation,
				msaPrcnt: getPerc(demographicCBSA.hispanicPopulation, demographicCBSA.population),
			},
			indian: {
				name: 'Indian',
				postalCode: demographic.indianPopulation,
				postalCodePrcnt: getPerc(demographic.indianPopulation, demographic.population),
				city: demographicCity.indianPopulation,
				cityPrcnt: getPerc(demographicCity.indianPopulation, demographicCity.population),
				msa: demographicCBSA.indianPopulation,
				msaPrcnt: getPerc(demographicCBSA.indianPopulation, demographicCBSA.population),
			},
			other: {
				name: 'Other',
				postalCode: demographic.otherPopulation,
				postalCodePrcnt: getPerc(demographic.otherPopulation, demographic.population),
				city: demographic.otherPopulation,
				cityPrcnt: getPerc(demographic.otherPopulation, demographic.population),
				msa: demographicCBSA.otherPopulation,
				msaPrcnt: getPerc(demographicCBSA.otherPopulation, demographicCBSA.population),
			},
			white: {
				name: 'White',
				postalCode: demographic.whitePopulation,
				postalCodePrcnt: getPerc(demographic.whitePopulation, demographic.population),
				city: demographicCity.whitePopulation,
				cityPrcnt: getPerc(demographicCity.whitePopulation, demographicCity.population),
				msa: demographicCBSA.whitePopulation,
				msaPrcnt: getPerc(demographicCBSA.whitePopulation, demographicCBSA.population),
			},
		},
	}

	const populationChartData = {
		labels: [postalCode, city, 'CBSA'],
		datasets: [
			{
				label: 'Dataset 1',
				data: [
					CHART_DATA.population.postalCode,
					CHART_DATA.population.city,
					CHART_DATA.population.msa,
				],
				backgroundColor: _.COLORS.tradewind_blue,
			},
		],
	}

	const householdsChartData = {
		labels: [postalCode, city, 'CBSA'],
		datasets: [
			{
				label: 'Dataset 1',
				data: [
					CHART_DATA.households.postalCode,
					CHART_DATA.households.city,
					CHART_DATA.households.msa,
				],
				backgroundColor: _.COLORS.tradewind_blue,
			},
		],
	}

	const incomeVsPriceChartData = {
		labels: [postalCode, city, 'CBSA'],
		datasets: [
			{
				label: 'Income', // Median Income
				data: [CHART_DATA.income.postalCode, CHART_DATA.income.city, CHART_DATA.income.msa],
				backgroundColor: _.COLORS.tradewind_blue,
			},
			{
				label: 'Home Price', // Median Home Price
				data: [
					CHART_DATA.home_value.postalCode,
					CHART_DATA.home_value.city,
					CHART_DATA.home_value.msa,
				],
				backgroundColor: _.COLORS.orange_400,
			},
		],
	}

	const genderPostalCodeChartData = {
		labels: ['Male', 'Female'],
		datasets: [
			{
				data: [CHART_DATA.gender.male.postalCode, CHART_DATA.gender.female.postalCode],
				backgroundColor: [_.COLORS.blue_500, _.COLORS.orange_400],
				borderWidth: 1,
			},
		],
	}

	const genderCityCodeChartData = {
		labels: ['Male', 'Female'],
		datasets: [
			{
				data: [CHART_DATA.gender.male.city, CHART_DATA.gender.female.city],
				backgroundColor: [_.COLORS.blue_500, _.COLORS.orange_400],
				borderWidth: 1,
			},
		],
	}

	const genderCbsaCodeChartData = {
		labels: ['Male', 'Female'],
		datasets: [
			{
				data: [CHART_DATA.gender.male.msa, CHART_DATA.gender.female.msa],
				backgroundColor: [_.COLORS.blue_500, _.COLORS.orange_400],
				borderWidth: 1,
			},
		],
	}

	const raceChartLabels = ['Asian', 'Black', 'Hispanic', 'Indian', 'Other', 'White']
	const raceChartBgColors = [
		_.COLORS.orange_200,
		_.COLORS.orange_300,
		_.COLORS.orange_400,
		_.COLORS.blue_300,
		_.COLORS.blue_400,
		_.COLORS.blue_500,
	]

	const racePostalCodeChartData = {
		labels: raceChartLabels,
		datasets: [
			{
				data: [
					CHART_DATA.race.asian.postalCode,
					CHART_DATA.race.black.postalCode,
					CHART_DATA.race.hispanic.postalCode,
					CHART_DATA.race.indian.postalCode,
					CHART_DATA.race.other.postalCode,
					CHART_DATA.race.white.postalCode,
				],
				backgroundColor: raceChartBgColors,
				borderWidth: 1,
			},
		],
	}

	const raceCityCodeChartData = {
		labels: raceChartLabels,
		datasets: [
			{
				data: [
					CHART_DATA.race.asian.city,
					CHART_DATA.race.black.city,
					CHART_DATA.race.hispanic.city,
					CHART_DATA.race.indian.city,
					CHART_DATA.race.other.city,
					CHART_DATA.race.white.city,
				],
				backgroundColor: raceChartBgColors,
				borderWidth: 1,
			},
		],
	}

	const raceCbsaCodeChartData = {
		labels: raceChartLabels,
		datasets: [
			{
				data: [
					CHART_DATA.race.asian.msa,
					CHART_DATA.race.black.msa,
					CHART_DATA.race.hispanic.msa,
					CHART_DATA.race.indian.msa,
					CHART_DATA.race.other.msa,
					CHART_DATA.race.white.msa,
				],
				backgroundColor: raceChartBgColors,
				borderWidth: 1,
			},
		],
	}

	const ChartsHeader = () => (
		<div className="DemographicsCharts__header">
			<h1 className="h2">
				<div className="key-value">
					<span className="key">Postal Code </span>
					<span className="value">{postalCode}</span>
				</div>
				<div className="key-value">
					<span className="key">CBSA </span>
					<span className="value">{demographic.cBSA_Name}</span>
				</div>
			</h1>
		</div>
	)

	const GenderRaceToggle = () => {
		return (
			<div className="DemographicsCharts__toggle-button">
				<ButtonGroup disableElevation size="small" color="primary">
					<Button
						color="primary"
						variant={isGender ? 'contained' : 'outlined'}
						onClick={() => {
							setIsGender(true)
						}}
					>
						Gender
					</Button>
					<Button
						color="primary"
						variant={!isGender ? 'contained' : 'outlined'}
						onClick={() => {
							setIsGender(false)
						}}
					>
						Ethnicity
					</Button>
				</ButtonGroup>
			</div>
		)
	}

	const ChartItem = ({ title, children }) => (
		<div>
			<h3 className="h3 chart-title">{title}</h3>
			<div className="DemographicsCharts__chart">{children}</div>
		</div>
	)

	const PieChartItem = ({ title, data }) => (
		<ChartItem title={title}>
			<PieChart data={data} />
		</ChartItem>
	)

	const BarChartItem = ({ title, data, options = {} }) => (
		<ChartItem title={title}>
			<BarChart options={options} data={data} />
		</ChartItem>
	)

	return (
		<StyledDemographicsCharts data-testid="DemographicsCharts" className={className}>
			<ChartsHeader />
			<div className="DemographicsCharts__grid">
				<BarChartItem title={CHART_DATA.population.name} data={populationChartData} />
				<BarChartItem title="Households" data={householdsChartData} />
				<BarChartItem
					title="Income vs Home Price"
					data={incomeVsPriceChartData}
					options={{ showLegend: true }}
				/>
			</div>
			<GenderRaceToggle />
			<div className="DemographicsCharts__grid">
				{isGender ? (
					<>
						<PieChartItem title={postalCode} data={genderPostalCodeChartData} />
						<PieChartItem title={city} data={genderCityCodeChartData} />
						<PieChartItem title="CBSA" data={genderCbsaCodeChartData} />
					</>
				) : (
					<>
						<PieChartItem title={postalCode} data={racePostalCodeChartData} />
						<PieChartItem title={city} data={raceCityCodeChartData} />
						<PieChartItem title="CBSA" data={raceCbsaCodeChartData} />
					</>
				)}
			</div>
		</StyledDemographicsCharts>
	)
}

export default DemographicsCharts
