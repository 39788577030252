import React, { useEffect } from 'react'
import Avatar from 'react-avatar'
import { Grid } from '@mui/material'
import TWControls from '../controls/TWControls'
import { Form, useForm } from '../controls/useForm'
import Card from 'components/Card'
import styled from 'styled-components'
import * as _ from 'styles/helpers'

const PartnerSettings = ({ recordForEdit }) => {
	// const editPartner = {
	// 	_id: recordForEdit.partner._id,
	// 	user: recordForEdit.partner.user,
	// 	partner: recordForEdit.partner.partner,
	// 	trustedPartner: recordForEdit.partner.trustedPartner,
	// 	isAccepted: recordForEdit.partner.isAccepted,
	// 	isSuspended: recordForEdit.partner.isSuspended,
	// 	image: recordForEdit.partnerUserId.image,
	// 	name: recordForEdit.partnerUserId.name,
	// 	company: recordForEdit.partnerUserId.company,
	// 	title: recordForEdit.partnerUserId.title,
	// 	email: recordForEdit.partnerUserId.email,
	// }
	const initialValues = {
		...recordForEdit,
	}

	console.log(`recordForEdit: `, recordForEdit)
	const validate = () => {
		let temp = { ...errors }
		temp.name = values.name ? '' : 'This field is required.'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialValues,
		true,
		validate,
	)

	return (
		<StyledUserProfile>
			<Form>
				<Grid container spacing={2}>
					<div></div>
					<Grid item xs={12} className="newLocation__imageHolder">
						<Avatar src={values.image} className="UserProfile__Avatar" />
					</Grid>
					<Grid item xs={12}>
						<div className="display-text">
							<h2 className="display-text-name">{values.name}</h2>
						</div>
						<div className="display-text">
							<p className="display-text-title">{values.title}</p>
						</div>
						{/* {values.isAccepted ? (
							<div className="display-text">
								<p className="display-text-title">{values.title}</p>
							</div>
						) : (
							<div className="display-text">
								<p className="display-text-title">Partnership Pending</p>
							</div>
						)} */}
						<div className="display-text">
							<p className="display-text-contact ">
								{values.company}
								<br />
								{values.email}
							</p>
						</div>
					</Grid>
				</Grid>
			</Form>
		</StyledUserProfile>
	)
}

const StyledUserProfile = styled.div`
	max-width: ${_.rem(260)};
	margin: 0 auto;

	.UserProfile {
		&__Card {
			h2 {
				overflow: hidden;
				white-space: nowrap;
			}
		}

		&__User,
		&__Actions {
			${_.MIXINS.mod}
			${_.MIXINS.vhc}
			flex-direction: column;
			padding: ${_.rem(16)};
		}

		&__Avatar {
			border-radius: ${_.rem(6)};
			max-width: ${_.rem(160)};
			height: ${_.rem(160)};
			margin-bottom: ${_.rem(16)};
		}
	}

	.display-text {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		text-align: center;
	}

	/* .display-group {
		display: flex;
		gap: ${_.rem(16)};
		margin-bottom: ${_.rem(16)};
	} */

	.display-text-name {
		/* margin-top: ${_.rem(16)}; */
		margin-bottom: ${_.rem(6)};
		font-weight: 600;
		font-size: ${_.rem(24)};
		color: ${_.COLORS.tradewind_blue};
	}

	.display-text-title {
		margin-top: ${_.rem(6)};
		margin-bottom: ${_.rem(6)};
		font-weight: 600;
		font-size: ${_.rem(20)};
		color: ${_.COLORS.gray_600};
	}

	.display-text-company {
		/* margin-top: ${_.rem(16)}; */
		/* margin-bottom: ${_.rem(16)}; */
		font-weight: 600;
		font-size: ${_.rem(20)};
		color: ${_.COLORS.gray_500};
	}

	.display-text-contact {
		margin-top: ${_.rem(5)};
		/* margin-bottom: ${_.rem(16)}; */
		font-weight: 400;
		font-size: ${_.rem(12)};
		color: ${_.COLORS.gray_500};
	}
`
export default PartnerSettings
