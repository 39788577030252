import React from 'react'
import Layout from 'components/Layout'
import RenderSubRoutes from 'components/RenderSubRoutes'
import getLocationAdminToolRoutes from './getRoutes/getLocationAdminToolRoutes'
import routes from 'constants/routes'
import { useParams } from 'react-router-dom'

const LocationAdminTools_Show = tool => {
	const { toolType } = useParams()

	const subRoutes = getLocationAdminToolRoutes(toolType)

	console.log(`LocationAdminTools_Show:`, toolType)

	return (
		<Layout>
			<Layout.SecondaryNav
				links={subRoutes}
				backLink={{ title: 'Admin Tools', link: routes.LOCATION_ADMIN_TOOLS }}
			/>

			<Layout.Page>
				<Layout.Sidebar isOffset>
					<Layout.SidebarUserCard />
				</Layout.Sidebar>
				<Layout.Body>
					<RenderSubRoutes routes={subRoutes} />
				</Layout.Body>
			</Layout.Page>
		</Layout>
	)
}

export default LocationAdminTools_Show
