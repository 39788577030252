import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from 'components/Layout'
import { initializeCompany } from 'slices/companySlice'

function SidebarCompanyTeam(company) {
	const companyTeam = company.members
	const isEmpty = companyTeam?.length === 0

	return (
		<Layout.SidebarItem title="Company Team" isEmpty={isEmpty} hasNoMaxHeight>
			{<h2>Got Here</h2>}
			{/* {!isEmpty ? (
				<ul>
					{companyTeam.map(member => (
						<li className="listGroup__task" key={member._id}>
							<Layout.PartnerItem
								avatar={member.image}
								name={member.name}
								// title={member.title}
								title={member.title}
								link={`mailto: ${member.name} (${member.email})`}
							/>
						</li>
					))}
				</ul>
			) : null} */}
		</Layout.SidebarItem>
	)
}

export default SidebarCompanyTeam
