import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FaBuilding, FaChartLine, FaMapMarkerAlt, FaFolderOpen } from 'react-icons/fa'
import Layout from 'components/Layout'
import { initializeActiveLocations, listRecentLocations } from 'slices/locationSlice'
import { initializePortfolios } from 'slices/portfolioSlice'
import {
	Avatar,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
} from '@mui/material'
import { ChevronRight as ChevronRightIcon, LocationOn as LocationOnIcon } from '@mui/icons-material'
import styled from 'styled-components'
import * as _ from 'styles/helpers'
import Message from 'components/Message'

function SidebarCommunityAccordian() {
	const dispatch = useDispatch()

	const { portfolios, portfolioSuccess, myRecentPortfolios, portfoliosLoading } = useSelector(
		state => {
			const portfolioIds = state.portfolios.recents
			const myRecentPortfolios = portfolioIds.map(c => state.portfolios.entities[c])
			return {
				portfolios: myRecentPortfolios,
				portfolioSuccess: state.portfolios.isInitialized,
				portfoliosLoading: state.portfolios.isLoading,
			}
		},
	)

	const { locations, isInitialized, recentLocations, isLoading, error } = useSelector(state => {
		// format recent locations based on recent IDs
		const locations = state.locations.entities
		const recentIds = state.locations.recents || []
		const recentLocations = recentIds.map(rId => locations[rId] || {})
		return {
			locations: recentLocations,
			isLoading: state.locations.isLoading,
			isInitialized: state.locations.isInitialized,
			error: state.locations.error,
		}
	})

	useEffect(() => {
		dispatch(initializeActiveLocations())
		// if (!portfolioSuccess) {
		dispatch(initializePortfolios())
		// }
	}, [dispatch, isInitialized, portfolioSuccess])

	const isEmpty = locations.length === 0

	return (
		<Layout>
			<StyledSidebar>
				<div className="SidebarContent">
					<Layout.SidebarItem
						title="Recent Activity"
						icon={<FaChartLine />}
						hasNoMaxHeight
						noPadding
					>
						{locations && locations.length > 0 && (
							<React.Fragment>
								<h5 className="SidebarItem__section-title">
									<FaMapMarkerAlt />
									Locations
								</h5>
								<List dense disablePadding>
									{locations.map((location, index) => (
										<ListItem
											className="SidebarContent__card-body__ListItem"
											key={index}
											component={Link}
											to={`/locations/${location._id}`}
										>
											<ListItemAvatar>
												<Avatar src={location.image} />
											</ListItemAvatar>
											<ListItemText primary={location.assetName} secondary={location.updateNote} />
											<ListItemSecondaryAction className="secondary-action">
												<IconButton edge="end" aria-label="delete">
													<ChevronRightIcon />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									))}
								</List>
							</React.Fragment>
						)}
						{portfolioSuccess && (
							<React.Fragment>
								<h5 className="SidebarItem__section-title">
									<FaFolderOpen /> Portfolios
								</h5>
								<List dense disablePadding>
									{portfolios.map((portfolio, index) => (
										<ListItem
											className="SidebarContent__card-body__ListItem"
											key={index}
											component={Link}
											to={`/portfolios/${portfolio._id}`}
										>
											<ListItemAvatar>
												<Avatar src={portfolio.image} />
											</ListItemAvatar>
											<ListItemText
												primary={portfolio.assetName}
												secondary={portfolio.updateNote}
											/>
											<ListItemSecondaryAction className="secondary-action">
												<IconButton edge="end" aria-label="delete">
													<ChevronRightIcon />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									))}
								</List>
							</React.Fragment>
						)}
					</Layout.SidebarItem>

					{/* <Layout.SidebarItem
						title="Recent Activity"
						icon={<FaChartLine />}
						isEmpty={isEmpty}
						noPadding
					>
						{isLoading ? (
							<Layout.SidebarItemLoading />
						) : error ? (
							<Message variant="danger">{error}</Message>
						) : isEmpty ? (
							<span>No Recent Activity</span>
						) : (
							<List dense className="SidebarContent__card-body">
								{locations.map((location, index) => (
									<ListItem
										className="SidebarContent__card-body__ListItem"
										key={index}
										component={Link}
										to={`/locations/${location._id}`}
									>
										<ListItemAvatar>
											{location.image ? (
												<Avatar src={location.image} />
											) : (
												<Avatar>
													<LocationOnIcon />
												</Avatar>
											)}
										</ListItemAvatar>
										<ListItemText
											primary={`${
												location.retailer ? location.retailer : location.assetName
											} - ${`${location.city}, ${location.stateProvince}`}`}
											secondary={location.updateNote}
										/>
										<ListItemSecondaryAction className="secondary-action">
											<IconButton edge="end" aria-label="delete">
												<ChevronRightIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								))}
							</List>
						)}
					</Layout.SidebarItem> */}
				</div>
			</StyledSidebar>
		</Layout>
	)
}

const StyledSidebar = styled.div`
	.SidebarHeader {
		${_.MIXINS.vc}
		margin-bottom: ${_.rem(32)};

		&__avatar {
			width: ${_.rem(68)};
			height: ${_.rem(68)};
			margin-right: ${_.rem(24)};
			border: ${_.rem(4)} solid #0000001a;
		}

		&__greeting {
			${_.MIXINS.mod}
			display: flex;
			justify-content: center;
			flex-direction: column;

			&__username {
				${_.TYPOGRAPHY.p}
			}
			&__title {
				${_.TYPOGRAPHY.h1}
			}
		}
	}

	.SidebarContent {
		/* display: grid;
		grid-gap: ${_.rem(24)};
		grid-template-columns: repeat(12, [col] 1fr); */

		&__card {
			border-top: ${_.rem(4)} solid ${_.COLORS.tradewind_blue};
		}

		&__card-header {
			${_.MIXINS.vc};
			justify-content: space-between;
			margin-bottom: ${_.rem(16)};

			h2 {
				${_.MIXINS.vc};
				${_.TYPOGRAPHY.h2};
				font-size: ${_.rem(22)};
				margin: 0;
				grid-gap: ${_.rem(8)};
			}

			p {
				margin: 0;
				font-size: ${_.rem(11)};
				font-style: italic;
			}
		}

		&__card-body {
			height: 100%;
			max-height: ${_.rem(660)};
			overflow-y: scroll;

			&__ListItem {
				text-decoration: none;
				&:hover {
					background-color: ${_.COLORS.gray_100};
					cursor: pointer;
				}
			}
		}

		&__card-empty {
			${_.MIXINS.vhc};
			height: 100%;

			h3 {
				font-size: ${_.rem(16)};
				font-weight: 300;
				color: rgba(0, 0, 0, 0.54);
			}
		}

		&__card {
			min-height: ${_.rem(388)};

			&--partners {
				grid-column: col / span 12;
			}

			&-content {
				padding: 0 ${_.rem(12)};
			}
		}
	}
`

export default SidebarCommunityAccordian
