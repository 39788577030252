import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'
import Layout from 'components/Layout'
import Popup from 'components/Popup'
import PortfolioEditPopup from 'components/popups/PortfolioEditPopup'
import { initializeApiKey } from 'slices/apiKeysSlice'
import { initializePortfolios, updatePortfolio } from 'slices/portfolioSlice'

const SidebarPortfolioTop = ({ portfolio }) => {
	const dispatch = useDispatch()

	const [openPopup, setOpenPopup] = useState(false)
	const [openMap, setOpenMap] = useState(false)
	const [openModifyPopup, setOpenModifyPopup] = useState(false)
	const [recordForEdit, setRecordForEdit] = useState(null)
	const [center, setCenter] = useState([])
	const [mapTitle, setMapTitle] = useState(null)
	const [popupTitle, setPopupTitle] = useState(null)

	const { entity: apiKey } = useSelector(state => state.apiKey)

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	const portfolioId = portfolio._id

	useEffect(() => {
		dispatch(initializeApiKey('REACT_APP_GOOGLE_API_KEY'))
	}, [portfolio, portfolioId, dispatch])

	const editPortfolio = async values => {
		const newPortfolio = values

		newPortfolio.updateNote = 'Edited Portfolio.'

		await dispatch(updatePortfolio(newPortfolio))

		await dispatch(initializePortfolios())

		setOpenPopup(false)
	}

	const openEdit = portfolio => {
		setRecordForEdit(portfolio)
		setPopupTitle('Edit Portfolio')
		setOpenPopup(true)
	}

	return (
		<div>
			<Layout.SidebarAssetTop
				image={portfolio.image}
				assetName={
					portfolio.owner._id === userInfo._id ? (
						<span className="title" onClick={() => openEdit(portfolio)}>
							{portfolio.assetName}{' '}
							<IconButton color="primary" size="small">
								<EditIcon fontSize="inherit" />
							</IconButton>
						</span>
					) : (
						<span className="title">{portfolio.assetName} </span>
					)
				}
				address={portfolio.description}
				originator={`portfolio originator: ${portfolio.owner.name}`}
			/>
			{openPopup ? (
				<Popup title={popupTitle} openPopup={openPopup} setOpenPopup={setOpenPopup}>
					<PortfolioEditPopup editPortfolio={editPortfolio} recordForEdit={recordForEdit} />
				</Popup>
			) : null}
		</div>
	)
}

export default SidebarPortfolioTop
