import React from 'react'
import { Grid } from '@mui/material'
import TWControls from '../controls/TWControls'
import { Form } from '../controls/useForm'

const PortfolioDeletePopup = ({ deletePortfolio, portfolio }) => {
	const submitHandler = e => {
		deletePortfolio(portfolio)
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<h2 style={{ color: 'red' }} className="newLocation__imageHolder">
						WARNING!{' '}
					</h2>
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<p>
							This will delete this portfolio and all of the portfolio's notes, partner
							relationships, tasks, files and images.
						</p>
						{/* </div>
					</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder"> */}
						<p style={{ color: 'red' }}>Deleted portfolios are not recoverable.</p>
					</div>
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<TWControls.Button color="error" size="small" type="submit" text="Delete" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default PortfolioDeletePopup
