import React from 'react'
import Avatar from 'react-avatar'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material'
import { IconButton, Menu, MenuItem } from '@mui/material'
import Button from 'components/Button'
import * as _ from 'styles/helpers'
import Card from '../Card'
import S from '../Card.style'

export interface PartnerProjectCardProps {
	name: string
	title: string
	companyName: string
	isAccepted: boolean
	className?: string
	editUrl?: string
	avatarUrl?: string
	menuItems?: {
		title: string
		link?: string
		callback?: Function
	}[]
}

export const PartnerProjectCard = ({
	className = '',
	name,
	title,
	companyName,
	isAccepted,
	editUrl,
	avatarUrl,
	menuItems,
}: PartnerProjectCardProps) => {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)

	const handleClose = callback => {
		setAnchorEl(null)
		if (typeof callback === 'function') callback()
	}

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const actions = () => {
		if (editUrl)
			return (
				<Button as={Link} isOutline to={editUrl}>
					Edit
				</Button>
			)
		if (menuItems) {
			return (
				<>
					<IconButton color="inherit" size="medium" onClick={handleClick}>
						<MoreHorizIcon />
					</IconButton>
					<Menu
						id="long-menu"
						anchorEl={anchorEl}
						keepMounted
						open={open}
						onClose={handleClose}
						PaperProps={{
							style: {
								width: '15ch',
								color: 'blue',
							},
						}}
					>
						<MenuItem onClick={() => handleClose(undefined)}>...</MenuItem>
						{menuItems.map((menuItem, index) => (
							<MenuItem key={index} onClick={() => handleClose(menuItem.callback)}>
								{menuItem.link && <Link to={menuItem.link}>{menuItem.title}</Link>}
								{menuItem.callback && menuItem.title}
							</MenuItem>
						))}
					</Menu>
				</>
			)
		}
	}

	return (
		<Card actions={actions()} isSquare isCentered isCenteredText>
			<StyledPartnerProjectCard data-testid="PartnerProjectCard" className={className}>
				<Avatar className="avatar" name={name} src={avatarUrl} round={true} size="100" />
				<S.Title>{name}</S.Title>
				{isAccepted ? (
					<S.Description>
						{title} at
						<br />
						{companyName}
					</S.Description>
				) : (
					<S.Description>Pending Partnership</S.Description>
				)}
			</StyledPartnerProjectCard>
		</Card>
	)
}

const StyledPartnerProjectCard = styled.div`
	width: 100%;

	.avatar {
		margin-bottom: ${_.rem(16)};
	}
`

export default PartnerProjectCard
