import { FONTS } from './_variables'
import { MQ } from './_mediaQueries'
import { rem } from './_functions'

export const heading = `
  margin-top: 0;
	font-weight: ${FONTS.extra_bold};
	letter-spacing: -0.5px;
`

export const h1 = `
	${heading};
	font-size: ${rem(32)};
	line-height: 1.25;

	@media ${MQ.small} {
		font-size: ${rem(40)};
	}
`

export const h2 = `
	${heading};
	font-size: ${rem(24)};
	line-height: 1.125;
`

export const h3 = `
	${heading};
	font-size: ${rem(16)};
	line-height: 1.5;
`

export const p = `
	font-size: ${rem(16)};
	font-weight: ${FONTS.regular};
	line-height: 1.5;
`
