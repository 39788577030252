import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Form, useForm } from '../controls/useForm'
import { GoogleMap, InfoWindow, Marker, useLoadScript } from '@react-google-maps/api'
import { Avatar } from '@mui/material'

import mapStyles from '../mapStyles'

const libraries = ['places']

const mapContainerStyle = {
	width: '860px',
	height: '640px',
}

const options = {
	// styles: mapStyles,
	// disableDefaultUI: true,
	zoomControl: true,
}

const MapLocationPopup = ({ apiKey, location, center }) => {
	const [markers, setMarkers] = useState([])
	const [infoClick, setInfoClick] = useState(false)
	const zoom = 11

	// const initialFValues = {
	// 	_id: 0,
	// 	taskName: '',
	// 	description: '',
	// 	completedBy: '',
	// 	completed: false,
	// }

	// const validate = () => {
	// 	let temp = { ...errors }
	// 	temp.taskName = values.taskName ? '' : 'This field is required.'
	// 	temp.description = values.description ? '' : 'This field is required.'
	// 	temp.completedBy = values.completedBy ? '' : 'Email is not valid.'

	// 	setErrors({
	// 		...temp,
	// 	})

	// 	return Object.values(temp).every(x => x === '')
	// }

	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: apiKey,
		libraries: libraries,
	})
	const { values, errors, setErrors } = useForm()

	const onMapClick = useCallback(e => {
		setMarkers(current => [
			...current,
			{
				lat: e.latLng.lat(),
				lng: e.latLng.lng(),
				time: new Date(),
			},
		])
	}, [])

	const mapRef = useRef()
	const onMapLoad = useCallback(map => {
		mapRef.current = map
	}, [])

	if (loadError) return 'Error loading maps'
	if (!isLoaded) return 'Loading'

	return (
		<Form>
			{/* <h1>
				{location.city}, {location.stateProvince}
			</h1> */}
			<GoogleMap
				className="map"
				mapContainerStyle={mapContainerStyle}
				zoom={zoom}
				center={{ lat: center[0], lng: center[1] }}
				options={options}
				googleMapsApiKey={apiKey}
				libraries={libraries}
				onClick={onMapClick}
				onLoad={onMapLoad}
			>
				<Marker
					position={{ lat: center[0], lng: center[1] }}
					onClick={() => {
						setInfoClick(true)
					}}
				/>
				{infoClick && (
					<InfoWindow
						position={{
							lat: location.latitude,
							lng: location.longitude,
						}}
						onCloseClick={() => {
							setInfoClick(false)
						}}
					>
						<div>
							<h3>{location.assetName}</h3>
							<Avatar src={location.image} sx={{ width: 72, height: 72 }} />
							<p>{location.address}</p>
							<p>
								{location.city}, {location.stateProvince}
							</p>
						</div>
					</InfoWindow>
				)}
			</GoogleMap>
		</Form>
	)
}

export default MapLocationPopup
