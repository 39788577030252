import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	Avatar,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
} from '@mui/material'
import Layout from 'components/Layout'
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material/'
import { initializeMyPartnerApps } from 'slices/myPartnerAppSlice'

function SidebareMyPartnerApps() {
	const dispatch = useDispatch()

	const {
		entities: appEntities,
		ids: myPartnerAppIds,
		isLoading: myPartnerAppsLoading,
		isInitialized: myPartnerAppsInitialized,
	} = useSelector(state => state.myPartnerApps)
	const partnerApps = myPartnerAppIds.map(id => appEntities[id])

	useEffect(() => {
		dispatch(initializeMyPartnerApps())
	}, [dispatch])

	return (
		<Layout.SidebarItem title="My Apps">
			{myPartnerAppsInitialized ? (
				// <ul>
				// 	{partnerApps.map(partnerApp => (
				// 		<li key={partnerApp._id}>
				// 			<Layout.PartnerItem
				// 				avatar={partnerApp.partnerApp.image}
				// 				name={partnerApp.partnerApp.appName}
				// 				title={` by ${partnerApp.partnerApp.vendor}`}
				// 				link={`/partnerapps/${partnerApp.partnerApp._id}`}
				// 			/>
				// 		</li>
				// 	))}
				// </ul>

				<List dense disablePadding>
					{partnerApps.map((app, index) => (
						<ListItem key={index} component="a" href={`/partnerapps/${app.partnerApp._id}`}>
							<ListItemAvatar>
								<Avatar src={app.partnerApp.image} />
							</ListItemAvatar>
							<ListItemText
								primary={app.partnerApp.appName}
								// secondary={truncate(app.partnerApp.description, 80)}
							/>
							<IconButton edge="end" aria-label="delete">
								<ChevronRightIcon />
							</IconButton>
						</ListItem>
					))}
				</List>
			) : null}
		</Layout.SidebarItem>
	)
}

export default SidebareMyPartnerApps
