import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useTable from 'components/controls/useTable'
import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material'
import { FaRegThumbsUp } from 'react-icons/fa6'
import * as _ from 'styles/helpers'
import Popup from 'components/Popup'
import Layout from 'components/Layout'
import MyDropzone from 'components/MyDropzone/MyDropzone'
import ListAction from 'features/Actions/components/ListAction'
import {
	createBulkLocations,
	initializeLocations,
	initializeMyCenters,
	patchLocation,
	updateLocation,
} from 'slices/locationSlice'
import {
	deleteAllLocations,
	initializeBulkLocations,
	updateBulkLocation,
} from 'slices/bulkLocationSlice'
import swal from 'sweetalert'
import { initializeApiKey } from 'slices/apiKeysSlice'
import Spinner from 'components/Spinner'
import BulkLocationEditPopup from 'components/popups/BulkLocationEditPopup'
import BulkFleetAddPopup from 'components/popups/BulkFleetAddPopup'
import BulkLocationsAddPopup from 'components/popups/BulkLocationsAddPopup'
import BulkTenantsAddPopup from 'components/popups/BulkTenantsAddPopup'

const headerCells = [
	{ id: 'assetName', label: 'Location' },
	{ id: 'address', label: 'Address' },
	{ id: 'city', label: 'City' },
	{ id: 'stateProvince', label: 'State' },
	{ id: 'postalCode', label: 'Zip' },
]

const BulkLoadLocations = ({ userId }) => {
	const [filterFn, setFilterFn] = useState({
		fn: items => {
			return items
		},
	})
	const dispatch = useDispatch()

	const [openModifyPopup, setOpenModifyPopup] = useState(false)
	const searchInputRef = useRef(null)
	const [openPostFleetPopup, setOpenPostFleetPopup] = useState(false)
	const [openPostLocationsPopup, setOpenPostLocationsPopup] = useState(false)
	const [openPostTenantsPopup, setOpenPostTenantsPopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('')
	const [searchInputValue, setSearchInputValue] = useState('')
	const [fileName, setFileName] = useState(null)
	const [recordForEdit, setRecordForEdit] = useState(null)

	const { entity: apiKeyState } = useSelector(state => state.apiKey)
	const apiKey = apiKeyState.key

	const { entities: locations, ids: locationIds, isLoading, isInitialized } = useSelector(
		state => state.bulkLocations,
	)
	const records = locationIds.map(id => locations[id])

	const { entities, ids: centerIds } = useSelector(state => state.locations)
	const centers = centerIds.map(id => entities[id])

	const myCenters = centers.map(center => {
		return {
			selectId: center._id,
			selectValue: center.assetName,
		}
	})

	useEffect(() => {
		dispatch(initializeMyCenters())
		if (!isInitialized) {
			dispatch(initializeBulkLocations())
		}
		dispatch(initializeApiKey('REACT_APP_GOOGLE_API_KEY'))
	}, [dispatch, isInitialized])

	const hasRows = records ? records.length : null

	const postSummary = {
		recordCount: records.length,
		isActive: false,
		isPOIOnly: false,
		isTenantOnly: false,
		fleetId: '',
	}

	const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTable(
		records,
		headerCells,
		filterFn,
	)

	const handleSearch = e => {
		const { value } = e.target
		setSearchInputValue(value)
		setFilterFn({
			fn: items => {
				if (value === '') return items
				return items.filter(x => x.assetName.toLowerCase().includes(value.toLowerCase()))
			},
		})
		// set focus to search input after rerender
		setTimeout(() => {
			searchInputRef.current.focus()
		}, 0)
	}

	const actionPostLocations = () => {
		setOpenPostLocationsPopup(true)
	}
	const actionPostFleet = () => {
		setOpenPostFleetPopup(true)
	}

	const actionPostTenants = () => {
		setOpenPostTenantsPopup(true)
		// swal({
		// 	title: `Post Retail Center Locations!`,
		// 	text: `This will post the current list of locations as retail tenant locations.`,
		// 	icon: 'success',
		// 	button: 'Ok',
		// })
	}

	const handleOpenEditPopup = location => {
		setPopupTitle('Edit Location')
		setRecordForEdit(location)
		setOpenModifyPopup(true)
	}

	const handleEditLocation = async newLocation => {
		await dispatch(updateBulkLocation(newLocation))

		setOpenModifyPopup(false)
	}

	const handlePostBulkLocations = async activeStatus => {
		const locations = records
		await dispatch(createBulkLocations({ locations, activeStatus }))

		setOpenModifyPopup(false)
	}

	const handlePostFleetLocations = async postSummary => {
		await dispatch(updateBulkLocation(records))

		setOpenModifyPopup(false)
	}

	const handlePostCenterTenants = async retailCenter => {
		await dispatch(updateBulkLocation(retailCenter))

		setOpenModifyPopup(false)
	}

	const handleClearList = () => {
		swal({
			title: `Clear List!`,
			text: `This will clear the current import list without posting the new locations.`,
			icon: 'success',
			buttons: {
				confirm: {
					text: 'Confirm',
					value: true,
					visible: true,
					className: '',
					closeModal: true,
				},
				cancel: {
					text: 'Cancel',
					value: null,
					visible: true,
					className: '',
					closeModal: true,
				},
			},
		}).then(willDelete => {
			if (willDelete) {
				swal({
					title: `List Cleared!`,
					text: `The current list has been cleard.`,
					icon: 'success',
				})
				dispatch(deleteAllLocations())
			}
		})
		dispatch(initializeBulkLocations())

		// dispatch(uploadCenterTenants(locationId, tenants))
	}

	return (
		<div>
			<Layout>
				<Layout.Body>
					<Grid container spacing={3}>
						<Grid item xs={9}>
							<div>
								<Layout.PageToolbar_NoIcon
									buttonText="Clear List"
									buttonOnClick={handleClearList}
									searchInputRef={searchInputRef}
									searchInputValue={searchInputValue}
									searchInputOnChange={handleSearch}
								/>
							</div>
							{!hasRows ? (
								<MyDropzone apiKey={apiKey} />
							) : isLoading ? (
								<Spinner containerHeight="50vh" />
							) : (
								<div className="TableContent">
									<TblContainer sx={{ minWidth: 725 }}>
										<Table size="small" arial-label="simple table">
											<TblHeader />
											<TableBody>
												{recordsAfterPagingAndSorting().map(location => (
													<TableRow
														key={location._id}
														sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
														onClick={() => handleOpenEditPopup(location)}
													>
														<TableCell>{location.assetName}</TableCell>
														<TableCell>{location.address}</TableCell>
														<TableCell>{location.city} </TableCell>
														<TableCell>{location.stateProvince}</TableCell>
														<TableCell>{location.postalCode}</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
										<TblPagination />
									</TblContainer>
								</div>
							)}
						</Grid>
						<Grid item xs={3}>
							<Layout.SidebarItem title="Actions" hasNoMaxHeight>
								<ul>
									<li className="actionList__item">
										<ListAction
											handleAction={actionPostLocations}
											actionLable={'Post as Locations'}
											actionIcon={<FaRegThumbsUp />}
										/>
									</li>
									<li className="actionList__item">
										<ListAction
											handleAction={actionPostFleet}
											actionLable={'Post as Fleet'}
											actionIcon={<FaRegThumbsUp />}
										/>
									</li>
									<li className="actionList__item">
										<ListAction
											handleAction={actionPostTenants}
											actionLable={'Post as Tenants'}
											actionIcon={<FaRegThumbsUp />}
										/>
									</li>
								</ul>
							</Layout.SidebarItem>
						</Grid>
					</Grid>
				</Layout.Body>
			</Layout>

			{openModifyPopup ? (
				<Popup title={popupTitle} openPopup={openModifyPopup} setOpenPopup={setOpenModifyPopup}>
					<BulkLocationEditPopup editLocation={handleEditLocation} recordForEdit={recordForEdit} />
				</Popup>
			) : null}

			{openPostFleetPopup ? (
				<Popup
					title={popupTitle}
					openPopup={openPostFleetPopup}
					setOpenPopup={setOpenPostFleetPopup}
				>
					<BulkFleetAddPopup editLocation={handleEditLocation} recordForEdit={recordForEdit} />
				</Popup>
			) : null}

			{openPostLocationsPopup ? (
				<Popup
					title={popupTitle}
					openPopup={openPostLocationsPopup}
					setOpenPopup={setOpenPostLocationsPopup}
				>
					<BulkLocationsAddPopup
						handlePostBulkLocations={handlePostBulkLocations}
						locationCount={records.length}
					/>
				</Popup>
			) : null}

			{openPostTenantsPopup ? (
				<Popup
					title={popupTitle}
					openPopup={openPostTenantsPopup}
					setOpenPopup={setOpenPostTenantsPopup}
				>
					<BulkTenantsAddPopup
						myCenters={myCenters}
						handlePostFleetLocations={handlePostFleetLocations}
					/>
				</Popup>
			) : null}
		</div>
	)
}

export default BulkLoadLocations
