import React from 'react'
import S from './Card.style'

export interface CardProps {
	children: any
	className?: string
	header?: {
		title: string
		icon?: React.ReactNode
		secondary?: React.ReactNode
	}
	actions?: any
	isCentered?: boolean
	isCenteredText?: boolean
	isSquare?: boolean
	isLandscape?: boolean
	noPadding?: boolean
	noElevation?: boolean
}

const Card = ({
	className = '',
	children,
	header = undefined,
	actions,
	isCentered,
	isCenteredText,
	isSquare,
	isLandscape,
	noPadding,
	noElevation,
}: CardProps) => {
	return (
		<S.Card data-testid="Card" className={className} header={header} noElevation={noElevation}>
			{header && (
				<S.Header>
					<h2>
						{header.icon && header.icon} {header.title}
					</h2>
					{header?.secondary && typeof header.secondary === 'string' ? (
						<p>{header.secondary}</p>
					) : (
						header.secondary
					)}
				</S.Header>
			)}
			<S.Content
				data-testid="Card.Content"
				isCentered={isCentered}
				isCenteredText={isCenteredText}
				isSquare={isSquare}
				isLandscape={isLandscape}
				header={header}
				noPadding={noPadding}
			>
				{children}
			</S.Content>
			{actions && <S.Actions data-testid="Card.Actions">{actions}</S.Actions>}
		</S.Card>
	)
}

export default Card
