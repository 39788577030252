import emailjs from 'emailjs-com'
import moment from 'moment'

const userId = process.env.REACT_APP_EMAILJS_USER_ID
const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID

const emailTaskPopup = (emailKit, pushLink) => {
	const templateId = 'template_w1e01ac'

	console.log(`emailKit: `, emailKit)

	const templateParams = {
		fromName: emailKit.fromName,
		fromFirstName: emailKit.fromFirstName,
		fromEmail: emailKit.fromEmail,
		toName: emailKit.toName,
		toFirstName: emailKit.toFirstName,
		toEmail: emailKit.toEmail,
		emailSubject: emailKit.emailSubject,
		emailBody: emailKit.emailBody,
		taskName: emailKit.taskName,
		taskLocation: emailKit.taskLocation,
		taskProject: emailKit.taskProject,
		taskDue: emailKit.taskDue,
		taskStatus: emailKit.taskStatus,
		taskNotes: emailKit.taskNotes,
		taskLink: pushLink,
	}

	emailjs
		.send(serviceId, templateId, templateParams, userId)
		.then(res => {
			console.log(`Task Assignment Successful: `, res)
		})
		.catch(err => console.log(`Send error: `, err))
}

const emailTaskUpdate = ({ originalTask, newTask, location, user }) => {
	const templateId = 'template_tiluo32'
	const owner = location.owner

	var taskChanged = false

	var taskName = ''
	var description = ''
	var assignedTo = ''
	var completedBy = ''
	var taskStatus = ''

	const originalAssignee = originalTask.partners.filter(
		partner => partner.assignedTo === originalTask.assignedTo,
	)
	const assignedPartner = newTask.partners.filter(
		partner => partner.assignedTo === newTask.assignedTo,
	)

	if (originalTask.taskName === newTask.taskName) {
		taskName = `Task Name: <b>${originalTask.taskName}</b>`
	} else {
		taskChanged = true
		taskName = `Task Name: Has been changed from <b>${originalTask.taskName}</b> to <b>${newTask.taskName}</b>.`
	}

	if (originalTask.description === newTask.description) {
		description = `Description: <b>${originalTask.description}</b>`
	} else {
		taskChanged = true
		description = `Description: Has been changed from <b>${originalTask.description}</b> to <b>${newTask.description}</b>.`
	}

	if (originalTask.assignedTo === newTask.assignedTo) {
		assignedTo = `Assigned To: <b>${originalTask.assignedToName}</b>`
	} else {
		taskChanged = true
		assignedTo = `Assigned To: Has been changed from <b>${originalTask.assignedToName}</b> to <b>${assignedPartner[0].assignee}</b>.`
	}

	if (originalTask.completedBy === newTask.completedBy) {
		completedBy = `Due Date: <b>${moment(originalTask.completedBy).format('MM-DD-YYYY')}</b>`
	} else {
		taskChanged = true
		moment(originalTask.completedBy).format('MM-DD-YYYY')
		completedBy = `Due Date: Has been changed from <b>${moment(originalTask.completedBy).format(
			'MM-DD-YYYY',
		)}</b> to <b>${moment(newTask.completedBy).format('MM-DD-YYYY')}</b>.`
	}

	if (originalTask.taskStatus === newTask.taskStatus) {
		taskStatus = `Task Status: <b>${originalTask.taskStatus}</b>`
	} else {
		taskChanged = true
		taskStatus = `Task Status: Has been changed from <b>${originalTask.taskStatus}</b> to <b>${newTask.taskStatus}</b>.`
	}

	const templateParams = {
		ownerEmail: owner.email,
		ownerName: owner.name,
		originalAssigneeEmail: originalAssignee[0].email,
		assignedPartnerEmail: assignedPartner[0].email,
		taskName: taskName,
		description: description,
		assignedTo: assignedTo,
		completedBy: completedBy,
		taskStatus: taskStatus,
		locationName: location.assetName,
		locationCity: location.city,
		locationState: location.stateProvince,

		// locationLink: pushLink,
	}

	if (taskChanged) emailjs.send(serviceId, templateId, templateParams, userId)
	// .then(res => {
	// 	console.log(res)
	// })
	// .catch(err => console.log(err))
}

const emailTaskChanged = (toUser, pushLink) => {
	const templateId = 'template_vdnospb'

	const templateParams = {
		toName: toUser.name,
		toEmail: toUser.email,
		confirmLink: pushLink,
	}

	emailjs
		.send(serviceId, templateId, templateParams, userId)
		.then(res => {
			console.log(`Email Confirm Successful: `, res)
		})
		.catch(err => console.log(`Send error: `, err))
}

export { emailTaskPopup, emailTaskUpdate, emailTaskChanged }
