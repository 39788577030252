import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaPlus } from 'react-icons/fa'
import Popup from 'components/Popup'
import FilePartners from 'components/FilePartners'
import NumberFormat from 'react-number-format'
import Message from 'components/Message'
import Spinner from 'components/Spinner'
import useTableNoPage from 'components/controls/useTableNoPage'
import { FormGroup, TableBody, TableCell, TableRow, IconButton } from '@mui/material'
import {
	Delete as DeleteIcon,
	EnhancedEncryptionOutlined as EnhancedEncryptionOutlinedIcon,
	LockOpenOutlined as LockOpenOutlinedIcon,
	LockTwoTone as LockTwoToneIcon,
} from '@mui/icons-material'
import FileTypeIcon from 'components/FileTypeIcon'
import { updatePortfolioFile } from 'slices/portfolioFileSlice'
import Placeholder from 'components/Placeholder/Placeholder'
import EmptyLogo from 'images/empty_folder.png'
import Button from 'components/controls/Button'

const headerCells = [
	{ id: 'type', label: 'Type' },
	{ id: 'name', label: 'File Name' },
	{ id: 'owner', label: 'Owner' },
	{ id: 'trustedShare', label: 'File Sharing' },
	// { id: 'delete', label: 'Remove' },
]

const PortfolioFileList = ({ userId, portfolioId, portfolio }) => {
	const dispatch = useDispatch()

	const [openPopup, setOpenPopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('Sharing')
	const [recordForEdit, setRecordForEdit] = useState(null)
	const [partners, setPartners] = useState([])

	const { portfolioFiles, error, loading } = useSelector(state => {
		const files = state.portfolioFiles.entities
		return {
			portfolioFiles: portfolio?.files?.map(fileId => files[fileId] || {}) || [],
			error: state.portfolioNotes.error,
			loading: state.portfolioNotes.isLoading,
		}
	})

	const [filterFn, setFilterFn] = useState({
		fn: items => {
			return items
		},
	})

	useEffect(() => {
		if (portfolio.partners) {
			const sharePartners = portfolio.partners.map(item => {
				return {
					id: item.partner._id,
					name: item.partner.name,
					company: item.partner.company,
					trustedPartner: item.partner.trustedPartner,
				}
			})
			setPartners(sharePartners)
		}
	}, [userId, portfolioId, portfolio, dispatch])

	const updateFileSharing = async (file, resetForm) => {
		dispatch(updatePortfolioFile(file))
		resetForm()
		setOpenPopup(false)
		setRecordForEdit(null)
	}

	const records = portfolioFiles
	const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTableNoPage(
		records,
		headerCells,
		filterFn,
	)

	const deleteHandler = id => {
		if (window.confirm('Are you sure?')) {
			// dispatch(deletePortfolioFile(id))
		}
	}

	const openInPopup = file => {
		setRecordForEdit(file)
		setOpenPopup(true)
	}

	return (
		<div>
			{loading ? (
				<Spinner />
			) : !records ? (
				<Message variant="danger">{error}</Message>
			) : error ? (
				<Message variant="danger">{error}</Message>
			) : portfolioFiles.length === 0 ? (
				<Placeholder aspectRatio="16:9" isFlexColumn isTransparent>
					<h2 className="h2">We don't have any documents, yet.</h2>
					<div>
						<img className="details__avatar" src={EmptyLogo} alt="Avatar" />
					</div>
					{/* <div>
						<Button onClick={addDropLocationsHandler} variant="success" isLarge>
							<FaPlus /> Start Here to Add a Location
						</Button>
					</div> */}
				</Placeholder>
			) : (
				<FormGroup className="topPage__tableContainer">
					<TblContainer>
						<TblHeader />
						<TableBody>
							{portfolioFiles.map(file => (
								<TableRow key={file._id}>
									<TableCell>
										<FileTypeIcon fileName={file.name} />
									</TableCell>
									<TableCell>
										<a
											target="_blank"
											rel="noopener noreferrer"
											className="feed__fileName"
											href={file.url}
										>
											{file.name}
										</a>
									</TableCell>
									<TableCell>
										<a
											href={`mailto: ${file.userid.name} (${file.userid.email})?subject=${portfolio.assetName}&body=Hi ${file.userid.firstName}, Blah, blah, blah!`}
										>
											<div className="feed__fileName">
												{file.userid.name}
												{/* <FaTelegramPlane /> */}
											</div>
										</a>
									</TableCell>
									<TableCell>
										{file.userid._id === userId ? (
											<IconButton
												className="feed__icon"
												color="primary"
												size="small"
												onClick={() => {
													setPopupTitle('Update')
													openInPopup(file)
												}}
											>
												{file.secured === true ? (
													<EnhancedEncryptionOutlinedIcon />
												) : (
													<LockOpenOutlinedIcon />
												)}
											</IconButton>
										) : (
											<LockTwoToneIcon />
										)}
									</TableCell>
									{/* <TableCell>
										{file.userid._id === userId ? (
											<IconButton
												className="feed__icon"
												color="secondary"
												size="small"
												onClick={() => deleteHandler(file._id)}
											>
												<DeleteIcon />
											</IconButton>
										) : null}{' '}
									</TableCell> */}
								</TableRow>
							))}
						</TableBody>
					</TblContainer>
				</FormGroup>
			)}
			<Popup title={popupTitle} openPopup={openPopup} setOpenPopup={setOpenPopup}>
				<FilePartners
					partners={partners}
					recordForEdit={recordForEdit}
					updateFileSharing={updateFileSharing}
				/>
			</Popup>
		</div>
	)
}

export default PortfolioFileList
