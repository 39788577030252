import React from 'react'
import { Grid } from '@mui/material'
import TWControls from '../controls/TWControls'
import { Form } from '../controls/useForm'

const PortfolioArchivePopup = ({ archivePortfolio, portfolio }) => {
	const submitHandler = e => {
		archivePortfolio(portfolio)
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<p>
						Archived portfolios are removed from the active portfolio list but can be recoverd. All
						of the portfolio's notes, partners, tasks, files and images are preserved and will be
						restored when the archived portfolio is recovered.{' '}
					</p>
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<TWControls.Button size="small" type="submit" text="Archive" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default PortfolioArchivePopup
