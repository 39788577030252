import React, { useReducer } from 'react'
import curry from 'utils/curry'

interface State {
	deleteDetail: boolean
	openPopup: boolean
	openDeletePopup: boolean
	popupTitle: string
	recordForEdit: { [key: string]: any }
}

const initialState: State = {
	deleteDetail: false,
	openPopup: false,
	openDeletePopup: false,
	popupTitle: '',
	recordForEdit: {},
}

function reducer(state: State, action: { type: string; payload: any }) {
	const { type, payload } = action
	if (type === 'deleteDetail') return { ...state, deleteDetail: payload }
	if (type === 'openPopup') return { ...state, openPopup: payload }
	if (type === 'openDeletePopup') return { ...state, openDeletePopup: payload }
	if (type === 'popupTitle') return { ...state, popupTitle: payload }
	if (type === 'recordForEdit') {
		let _payload = payload
		if (!_payload) _payload = {}
		return { ...state, recordForEdit: payload }
	}
	return state
}

type Context = { state: State; actions: any } | undefined
const DetailsContext = React.createContext<Context>(undefined)

const DetailsProvider = ({ children }: { children: React.ReactNode }) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	const setState = (type: string, payload: any = undefined) => dispatch({ type, payload })
	// curried setState
	const _setState = curry(setState)
	// action creators
	const actions = {
		setDeleteDetail: _setState('deleteDetail'),
		setOpenPopup: _setState('openPopup'),
		setOpenDeletePopup: _setState('openDeletePopup'),
		setPopupTitle: _setState('popupTitle'),
		setRecordForEdit: _setState('recordForEdit'),
	}

	return <DetailsContext.Provider value={{ state, actions }}>{children}</DetailsContext.Provider>
}

const useDetails = () => {
	const context = React.useContext(DetailsContext)
	if (context === undefined) throw new Error('useDetails must be used within a DetailsProvider')

	return context
}

export { DetailsProvider, useDetails }

/*
  # Usage
  import { useDetails } from 'features/Details/DetailsContext'
  const {
		state: { openPopup, popupTitle, recordForEdit },
		actions: { setOpenPopup, setPopupTitle, setRecordForEdit },
	} = useDetails()
*/
