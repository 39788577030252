import styled from 'styled-components'
import * as _ from 'styles/helpers'
import type { Props } from './ChatAddressBar'

const StyledChatAddressBar = styled.div<Partial<Props>>`
	display: flex;
	grid-gap: ${_.rem(8)};
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
	padding-bottom: ${_.rem(8)};
	border-bottom: ${_.rem(1)} solid rgba(0, 0, 0, 0.12);

	.ChatAddressBar__Autocomplete {
		flex: 1;
	}

	.ChatAddressBar__submit-button {
		margin: 0;
		padding: ${_.rem(8)};

		svg {
			margin: 0;
			font-size: ${_.rem(24)};
			color: black;
		}
	}
`

export default StyledChatAddressBar
