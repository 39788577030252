import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import MDTLogo from 'images/MyDealTeams-Pin-Logo.jpg'
import Button from 'components/Button'
import Message from 'components/Message'
import axios from 'axios'

const validationSchema = Yup.object().shape({
	email: Yup.string().email('Invalid email').required('Email is required'),
})

const ForgotPassword = () => {
	const [errorMessage, setErrorMessage] = useState('')
	const [submitted, setSubmitted] = useState(false)
	const [submitting, setSubmitting] = useState(false)

	const initialValues = {
		email: '',
	}

	// debugger

	return (
		<div>
			{submitted ? (
				<div>
					<h1>Email Sent</h1>
					<p>Instructions on how to reset your password have been sent</p>
				</div>
			) : (
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={async values => {
						const { email } = values
						if (!email) setErrorMessage('Passwords do not match')
						else {
							const config = {
								headers: {
									'Content-Type': 'application/json',
								},
							}
							const { data } = await axios.post(`/api/changePasswords/request`, { email }, config)
							if (data.success) {
								setSubmitted(data.success)
							}
						}
						setSubmitting(false)
					}}
				>
					{({ isSubmitting, errors }) => {
						/*
          TODO: handle form errors in UI
        */
						return (
							<Form>
								<img
									style={{ display: 'block', margin: '0 auto 16px' }}
									src={MDTLogo}
									alt="My Deal Teams logo"
									width={140}
								/>
								<h1 className="form-title">Forgot your password?</h1>
								<p>
									Enter your email below and we will send you instructions on how to set a new one
								</p>
								{errorMessage && <Message variant="danger">{errorMessage}</Message>}
								<div className="inputs">
									<div className="input">
										<Field type="text" name="email" placeholder="Email" />
									</div>
								</div>
								<div className="actions">
									<Button type="submit" isLarge disabled={isSubmitting}>
										Reset My Password
									</Button>
								</div>
							</Form>
						)
					}}
				</Formik>
			)}
		</div>
	)
}

export default ForgotPassword
