import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Spinner from 'components/Spinner'
import {
	useChat,
	ChatWindow,
	ChatWindowHeader,
	ChatMessageListContainer,
	ChatInputContainer,
} from 'features/Chat'
import { useParams } from 'react-router-dom'

const LocationChatScreen = () => {
	const { id, projectId } = useParams()
	const {
		actions: { setCurrentEventId },
	} = useChat()

	const [messages, setMessages] = useState([])

	const { location, loading } = useSelector(state => {
		return {
			loading: state.locations.isLoading,
			success: state.locations.isInitialized,
			location: state.locations.entities[id],
		}
	})

	const project = useSelector(state => state.projects.entities[projectId])

	useEffect(() => {
		if (project?.projectName) {
			setCurrentEventId(`~${project.projectName.replace(/ /g, '')}`)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location])

	if (loading) return <Spinner containerHeight="50vh" />

	if (project?.projectName) {
		return (
			<ChatWindow>
				<ChatWindowHeader label={`Chat: ${project.projectName}`} />
				<ChatMessageListContainer />
				<ChatInputContainer shouldAlwaysDisplay={true} />
			</ChatWindow>
		)
	}

	return null
}

export default LocationChatScreen
