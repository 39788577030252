import React from 'react'
import Avatar from 'react-avatar'
import styled from 'styled-components'
import * as _ from 'styles/helpers'
import Card from '../Card'
import S from '../Card.style'

export interface PartnerCardProps {
	name: string
	title: string
	companyName: string
	className?: string
	actions?: any
	avatarUrl?: string
}

export const PartnerCard = ({
	className = '',
	actions,
	name,
	title,
	companyName,
	avatarUrl,
}: PartnerCardProps) => {
	return (
		<Card actions={actions} isSquare isCentered isCenteredText>
			<StyledPartnerCard data-testid="PartnerCard" className={className}>
				<Avatar className="avatar" name={name} src={avatarUrl} round={true} size="100" />
				<S.Title>{name}</S.Title>
				<S.Description>
					{title} at
					<br />
					{companyName}
				</S.Description>
			</StyledPartnerCard>
		</Card>
	)
}

const StyledPartnerCard = styled.div`
	width: 100%;

	.avatar {
		margin-bottom: ${_.rem(16)};
	}
`

export default PartnerCard
