import React from 'react'
import { useChat, StartChatButton } from 'features/Chat'

const StartChatButtonContainer = () => {
	const {
		state: { startNewChat },
		actions: { handleStartNewChat },
	} = useChat()

	return <StartChatButton isDisabled={startNewChat} onClick={handleStartNewChat} />
}

export default StartChatButtonContainer
