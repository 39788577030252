import React, { useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'html-react-parser'
import { Avatar, IconButton } from '@mui/material'
import { Create as CreateIcon, ThumbUpAltOutlined as LikeIcon } from '@mui/icons-material'
import { updatePost } from 'slices/postSlice'

function Post({ post }) {
	const dispatch = useDispatch()

	const [input, setInput] = useState('')
	const [likes, setLikes] = useState(post.likes.length)

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	// const userId = userInfo._id

	const likeMyPostHandler = post => {
		const existsLike = post.likes.find(x => x === userInfo._id)
		let newLikes = []

		if (existsLike) newLikes = post.likes.filter(item => item !== userInfo._id)
		else newLikes = [...post.likes, userInfo._id]

		setLikes(newLikes.length)
		dispatch(updatePost({ ...post, likes: newLikes }))
	}

	const postCommentHandler = (e, post) => {
		e.preventDefault()
		setInput('') // clear comment input

		const newComments = [...post.comments, { user: userInfo._id, comment: input }]

		const updatedPosts = {
			...post,
			comments: newComments,
		}

		dispatch(updatePost(updatedPosts))
	}

	const buildPost = post => {
		let goodStr = ''
		let imgStr = 0
		let imgPosStart = 0
		let imgPosEnd = 0
		let imgURL = ''

		let chopStr = post

		while (chopStr.search('<img') > 0) {
			imgPosStart = chopStr.search('<img')
			goodStr = `${goodStr}${chopStr.substring(0, imgPosStart)}`
			chopStr = chopStr.slice(imgPosStart)
			imgPosEnd = chopStr.search('>')

			imgURL = chopStr.substring(10, imgPosEnd - 1)
			console.log(`imgURL: `, imgURL)
			imgStr = `<div class="note-image" style="background-image:url('${imgURL}')">${chopStr.substring(
				0,
				imgPosEnd + 1,
			)}</div>`
			goodStr = goodStr + imgStr
			chopStr = chopStr.slice(imgPosEnd + 8)
		}

		goodStr = goodStr + chopStr

		return goodStr
	}

	return (
		<div className="note">
			<div className="note__header">
				<Avatar src={post.user.image}>{post.user.name}</Avatar>
				<div className="note__info">
					<h2>{post.user.name}</h2>
					<p>{post.user.email}</p>
				</div>
			</div>
			<div className="note__body">
				<div className="sm:col-span-full">
					<p>{parse(buildPost(post.message))}</p>
				</div>
			</div>

			<div className="post__image">
				{post.video && (
					<div className="post__image-video">
						<video src={post.video} controls />
					</div>
				)}
				{post.image && (
					<div className="post__image-img" style={{ backgroundImage: `url(${post.image})` }} />
				)}
			</div>
			<div className="post__likeline">
				<div className="post_actions">
					<div className="post__buttons">
						<label>
							<IconButton
								className="post__likebutton"
								color="primary"
								component="span"
								onClick={() => likeMyPostHandler(post)}
							>
								<LikeIcon />
							</IconButton>
							{likes > 0 ? `${likes}` : null}
						</label>
					</div>
				</div>
				<div className="note__creator">
					<span className="note__date">
						<p>{moment(post.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</p>
					</span>
				</div>
			</div>

			<div className="feed_input">
				<form>
					<CreateIcon />
					<input
						value={input}
						onChange={e => setInput(e.target.value)}
						type="text"
						placeholder="Comment"
					/>
					<button onClick={e => postCommentHandler(e, post)} type="submit"></button>
				</form>
			</div>
			{post.comments.length === 0 ? null : (
				<>
					{post.comments.map(comment => (
						<div key={comment._id} className="feed__inputOptions">
							<div className="feed_avatar">
								<Avatar src={comment.user.image}></Avatar>
							</div>
							<div className="feed_comment">{comment.comment}</div>
						</div>
					))}
				</>
			)}
		</div>
	)
}

export default Post
