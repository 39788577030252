import React from 'react'
import { Checkbox as MuiCheckBox, FormControl, FormControlLabel } from '@mui/material'

export default function CheckBox(props) {
	const { name, label, value, onChange } = props

	const convertToDefEventPara = (name, value) => ({
		target: {
			name,
			value,
		},
	})

	return (
		<FormControl>
			<FormControlLabel
				control={
					<MuiCheckBox
						name={name}
						color="primary"
						checked={value}
						onChange={e => onChange(convertToDefEventPara(name, e.target.checked))}
					/>
				}
				label={label}
			/>
		</FormControl>
	)
}
