import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, IconButton, Tooltip } from '@mui/material'
import { MdAddTask } from 'react-icons/md'

import Spinner from 'components/Spinner'
import TemplateDetails from 'features/TemplateDetails'
import { useDetails } from 'features/TemplateDetails/TemplateDetailsContext'
import {
	createTemplateSiteDetail,
	initializeTemplateSiteDetails,
	updateTemplateSiteDetail,
	deleteTemplateSiteDetail,
} from 'slices/templateSlice'

const TemplateSiteDetail_Feed = ({ template }) => {
	const templateId = template._id
	const dispatch = useDispatch()

	const {
		actions: { setOpenPopup, setRecordForEdit, setPopupTitle },
	} = useDetails()

	const {
		entities: siteDetailEntities,
		ids: siteDetailIds,
		isInitialized: siteDetailsInitialized,
		isLoading: siteDetailsLoading,
		error,
	} = useSelector(state => state.templates.siteDetails)
	const sitedetails = siteDetailIds.map(id => siteDetailEntities[id])

	useEffect(() => {
		dispatch(initializeTemplateSiteDetails(templateId))
	}, [dispatch, templateId])

	const addEditSiteDetail = async (siteDetail, deleteDetail, resetForm) => {
		const detailLabel = siteDetail.detailLabel
		const description = siteDetail.description
		const detailType = siteDetail.detailType

		if (siteDetail._id === 0) {
			dispatch(
				createTemplateSiteDetail({
					templateId: templateId,
					detailLabel: detailLabel,
					description: description,
					detailType: detailType,
				}),
			)
			dispatch(initializeTemplateSiteDetails(templateId))
		} else {
			if (deleteDetail) {
				dispatch(deleteTemplateSiteDetail(siteDetail._id))
			} else {
				await dispatch(
					updateTemplateSiteDetail({
						_id: siteDetail._id,
						templateId: templateId,
						detailLabel: detailLabel,
						description: description,
						detailType: detailType,
					}),
				)
			}
		}
		resetForm()
		setOpenPopup(false)
		setRecordForEdit()
		dispatch(initializeTemplateSiteDetails(templateId))
	}

	if (siteDetailsLoading) return <Spinner containerHeight="50vh" />

	return (
		<div>
			<div className="task">
				<div className="task__header">
					<div className="task__info">
						<h2
							onClick={() => {
								// openInPopup(templateTask)
							}}
						>
							{template.templateName}
						</h2>
						<h2>{template.description}</h2>
					</div>
					<div className="task__button">
						<Tooltip title={`Add new Detail.`}>
							<IconButton
								className="feed__icon"
								color="primary"
								size="small"
								onClick={() => {
									setPopupTitle('Add Detail')
									setRecordForEdit(null)
									setOpenPopup(true)
								}}
							>
								<MdAddTask />
							</IconButton>
						</Tooltip>
					</div>
				</div>
			</div>

			<TemplateDetails details={sitedetails} handleSubmit={addEditSiteDetail} />
		</div>
	)
}

export default TemplateSiteDetail_Feed
