import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Layout from 'components/Layout'
import Popup from 'components/Popup'
import MapLocationPopup from 'components/popups/MapLocationPopup'
import { initializeApiKey } from 'slices/apiKeysSlice'

const SidebarArchiveLibraryTop = ({ location }) => {
	const dispatch = useDispatch()

	const [openMap, setOpenMap] = useState(false)
	const [recordForEdit, setRecordForEdit] = useState(null)
	const [center, setCenter] = useState([])
	const [mapTitle, setMapTitle] = useState(null)

	const { entity: apiKey } = useSelector(state => state.apiKey)

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	const locationId = location._id

	useEffect(() => {
		dispatch(initializeApiKey('REACT_APP_GOOGLE_API_KEY'))
	}, [location, locationId, dispatch])

	const openInMap = location => {
		setCenter([location.latitude, location.longitude])
		setRecordForEdit(location)

		if (location.retailer) {
			setMapTitle(
				`${location.retailer}` +
					` - ` +
					`${location.assetName}` +
					` - ` +
					`${location.address}` +
					`, ` +
					`${location.city}` +
					`, ` +
					`${location.stateProvince}`,
			)
		}

		setMapTitle(
			`${location.assetName}` +
				` - ` +
				`${location.address}` +
				`, ` +
				`${location.city}` +
				`, ` +
				`${location.stateProvince}`,
		)

		setOpenMap(true)
	}

	return (
		<div>
			<Layout.SidebarTop
				img={location.image}
				title={location.assetName}
				link={location.owner._id === userInfo._id && `/locations/${location._id}/edit`}
			>
				<p onClick={() => openInMap(location)}>
					<Link to="#">
						{location.address} {location.city}, {location.stateProvince}
					</Link>
				</p>
				{/* <p>{`Originator: ${location.owner[0].name}`}</p> */}
			</Layout.SidebarTop>
			<Popup title={mapTitle} openPopup={openMap} setOpenPopup={setOpenMap}>
				<MapLocationPopup apiKey={apiKey.key} location={recordForEdit} center={center} />
			</Popup>
		</div>
	)
}

export default SidebarArchiveLibraryTop
