import { axiosInterceptor, apiCall } from 'services'
import { API_BASE_URL } from './constants'

const siteDetailsService = () => {
	const api = axiosInterceptor(API_BASE_URL)

	// CREATE
	const createSiteDetail = async (siteDetail: any) =>
		await apiCall(api.post('/siteDetails', siteDetail))

	// READ
	const getSiteDetails = async ({
		userid,
		packageid,
		projectid,
	}: {
		userid: string
		packageid: string
		projectid: string
	}) =>
		await apiCall(
			api.get('/siteDetails/mypackageid', {
				params: {
					userid,
					packageid,
					projectid,
				},
			}),
		)

	// UPDATE
	const updateSiteDetail = async (siteDetail: any) =>
		await apiCall(api.put(`/siteDetails/${siteDetail._id}`, siteDetail))

	// DELETE
	const deleteSiteDetail = async (id: string) => await apiCall(api.delete(`/siteDetails/${id}`))

	// OTHER
	const loadTemplateIntoSiteDetails = async (template: any) =>
		await apiCall(api.post('/siteDetails/template', template))

	return {
		createSiteDetail,
		getSiteDetails,
		updateSiteDetail,
		deleteSiteDetail,
		loadTemplateIntoSiteDetails,
	}
}

export default siteDetailsService
