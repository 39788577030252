import React, { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import RenderSubRoutes from 'components/RenderSubRoutes'
import SidebarCommunityAccordian from 'components/Sidebars/SidebarCommunityAccordian'
import getTaskRoutes from './getTaskRoutes'
import Layout from 'components/Layout'
import routes from 'constants/routes'
import * as _ from 'styles/helpers'

const Tasks_Show = () => {
	const dispatch = useDispatch()
	const params = useParams()

	const currentRoute = useLocation()

	const pathArray = currentRoute.pathname.split('/')
	const isPartnerPage = pathArray[pathArray.length - 1] === 'partners'

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	// const locationDetails = useSelector(state => state.locationDetails)
	// const { location, loading: isLoading, success } = locationDetails

	const { id } = params
	const subRoutes = getTaskRoutes(id)

	// useEffect(() => {
	// 	dispatch(getLocationDetails(id))
	// }, [dispatch, id])

	return (
		<Layout>
			<Layout.SecondaryNav links={subRoutes} backLink={{ title: 'Tasks', link: routes.TASKS }} />
			<Layout.Page>
				<Layout.Sidebar>
					<SidebarCommunityAccordian />
				</Layout.Sidebar>
				<Layout.Body>
					<RenderSubRoutes routes={subRoutes} />
				</Layout.Body>
			</Layout.Page>
		</Layout>
	)
}

export default Tasks_Show
