import React from 'react'
import Layout from 'components/Layout'
import Locations from './components/Locations/Locations'
import SidebarLocationsRecent from 'components/Sidebars/SidebarLocationsRecent'

const Locations_Index = () => {
	return (
		<Layout>
			<Layout.Page>
				<Layout.Sidebar>
					<SidebarLocationsRecent />
				</Layout.Sidebar>
				<Layout.Body>
					<Layout.PageHeader>
						<h2>Locations</h2>
					</Layout.PageHeader>
					<Locations />
				</Layout.Body>
			</Layout.Page>
		</Layout>
	)
}

export default Locations_Index
