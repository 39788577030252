import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import Popup from 'components/Popup'
import FilePartners from 'components/FilePartners'
import Message from 'components/Message'
import Spinner from 'components/Spinner'
import useTableNoPage from 'components/controls/useTableNoPage'
import {
	FormGroup,
	IconButton,
	Menu,
	MenuItem,
	TableBody,
	TableCell,
	TableRow,
} from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material'
import FileTypeIcon from 'components/FileTypeIcon'
import Placeholder from 'components/Placeholder'
import EmptyLogo from 'images/empty_folder.png'
import { getFiles, deleteFile, updateFile } from 'slices/locationFileSlice'
import { patchLocation } from 'slices/locationSlice'
import moment from 'moment'
import RemoveDocumentPopup from './popups/RemoveDocumentPopup'
import Button from 'components/Button'
import { patchProject } from 'slices/projectSlice'

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: '#0080fe',
		boxShadow: theme.shadows[5],
		fontSize: 11,
	},
}))

const headerCells = [
	{ id: 'type', label: 'Type', disableSorting: true },
	{ id: 'name', label: 'File Name' },
	{ id: 'owner', label: 'Owner' },
	{ id: 'docDate', label: 'Created' },
	{ id: 'updatedAt', label: 'Updated' },
	{ id: 'reLoad', label: 'More', disableSorting: true },
]

const options = ['...', 'Manage File Sharing', 'Download File', 'Remove File']
const ITEM_HEIGHT = 48

const FileList = ({ location, project, userId, handlePreviewDocument }) => {
	const locationId = location._id
	const projectId = project._id
	const dispatch = useDispatch()

	const [openSharingPopup, setOpenSharingPopup] = useState(false)
	const [openModifyPopup, setOpenRemovePopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('Sharing')
	const [recordForEdit, setRecordForEdit] = useState(null)
	let partners = []
	let docs = []

	const {
		entities: fileEntities,
		ids: fileIds,
		isLoading: loading,
		isInitialized: filesInitialized,
		error,
	} = useSelector(state => state.locationFiles)
	const locationFiles = fileIds.map(id => fileEntities[id])

	if (locationFiles) {
		docs = locationFiles.map(item => {
			return {
				id: item._id,
				locationid: item.locationid,
				projectid: item.projectid,
				uuid: item.uuid,
				url: item.url,
				fileType: item.type,
				fileName: item.name,
				owner: item.userid.name,
				ownerId: item.userid._id,
				docDate: item.docDate,
				updated: item.updatedAt,
				secured: item.secured,
				partners: item.partners,
				size: item.size,
			}
		})
	}

	const [filterFn, setFilterFn] = useState({
		fn: items => {
			return items
		},
	})

	useEffect(() => {
		if (!filesInitialized) {
			dispatch(getFiles({ locationId, projectId, userId }))
		}
	}, [dispatch, filesInitialized])

	if (project.partners) {
		partners = project.partners.map(item => {
			return {
				id: item.partnerUserId._id,
				name: item.partnerUserId.name,
				image: item.partnerUserId.image,
				company: item.partnerUserId.company,
				trustedPartner: item.partner.trustedPartner,
			}
		})
	}

	const records = docs
	const { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting } = useTableNoPage(
		records,
		headerCells,
		filterFn,
	)

	const handleFileSharing = async (file, resetForm) => {
		const updatedFile = {
			id: file.id,
			ownerId: file.ownerId,
			locationid: file.locationid,
			projectid: file.projectid,
			name: file.fileName,
			uuid: file.uuid,
			size: file.size,
			url: file.url,
			type: file.fileType,
			docDate: file.docDate,
			secured: file.secured,
			partners: file.partners,
		}

		await dispatch(updateFile(updatedFile))
		resetForm()

		setRecordForEdit(null)
		setOpenSharingPopup(false)

		dispatch(
			patchLocation({ locationId: locationId, updates: { updateNote: 'File Sharing Updated' } }),
		)
		dispatch(
			patchProject({ projectId: projectId, updates: { updateNote: 'File Sharing Updated' } }),
		)
	}

	const handleDocumentRemove = async file => {
		const fileId = file.id

		await dispatch(deleteFile(fileId))

		setOpenRemovePopup(false)
		dispatch(
			patchLocation({ locationId: locationId, updates: { updateNote: 'Document(s) Removed' } }),
		)
	}

	return (
		<div>
			{loading ? (
				<Spinner />
			) : !records ? (
				<Message variant="danger">{error}</Message>
			) : error ? (
				<Message variant="danger">{error}</Message>
			) : locationFiles.length === 0 ? (
				<Placeholder aspectRatio="16:9" isFlexColumn isTransparent>
					<h2 className="h2">{`Select 'Upload New Document' to add your first document.`}</h2>
					<div>
						<img className="details__avatar_noHover" src={EmptyLogo} alt="Avatar" />
					</div>
				</Placeholder>
			) : (
				<FormGroup className="topPage__tableContainer">
					<TblContainer>
						<TblHeader />
						<TableBody>
							{recordsAfterPagingAndSorting().map(file => (
								<TableRow key={file.id}>
									<TableCell>
										<div>
											<LightTooltip title="Click to download document">
												<div>
													<a
														target="_blank"
														rel="noopener noreferrer"
														className="feed__fileName"
														href={file.url}
													>
														<FileTypeIcon fileName={file.fileName} />
													</a>
												</div>
											</LightTooltip>
										</div>
									</TableCell>
									<TableCell
										onClick={() => {
											handlePreviewDocument(file)
										}}
									>
										<div>
											<LightTooltip title="Click to preview document">
												<div>{file.fileName}</div>
											</LightTooltip>
										</div>
									</TableCell>
									<TableCell>{file.owner}</TableCell>
									<TableCell>
										{file.docDate ? moment(file.docDate).format('MM/DD/YYYY') : null}
									</TableCell>
									<TableCell>{moment(file.updatedAt).format('MM/DD/YYYY')}</TableCell>
									<TableCell>
										<div>
											{/* <LightTooltip title="Click for options"> */}
											{/* <div>{file.fileName}</div> */}
											<div>
												{file.ownerId === userId ? (
													<MenuButton
														file={file}
														setPopupTitle={setPopupTitle}
														setRecordForEdit={setRecordForEdit}
														setOpenSharingPopup={setOpenSharingPopup}
														setOpenRemovePopup={setOpenRemovePopup}
													/>
												) : null}
											</div>
											{/* </LightTooltip> */}
										</div>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</TblContainer>
				</FormGroup>
			)}

			{openSharingPopup ? (
				<Popup title={popupTitle} openPopup={openSharingPopup} setOpenPopup={setOpenSharingPopup}>
					<FilePartners
						partners={partners}
						recordForEdit={recordForEdit}
						updateFileSharing={handleFileSharing}
					/>
				</Popup>
			) : null}

			{openModifyPopup ? (
				<Popup title={popupTitle} openPopup={openModifyPopup} setOpenPopup={setOpenRemovePopup}>
					<RemoveDocumentPopup removeDocument={handleDocumentRemove} document={recordForEdit} />
				</Popup>
			) : null}
		</div>
	)
}

const MenuButton = ({
	file,
	setPopupTitle,
	setRecordForEdit,
	setOpenSharingPopup,
	setOpenRemovePopup,
}) => {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)

	const handleDownload = file => {
		const link = document.createElement('a')
		link.download = file.fileName
		link.href = file.url
		link.click()
	}

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = (option, file) => {
		switch (option) {
			case 'Manage File Sharing':
				setPopupTitle(`Manage File Sharing`)
				setRecordForEdit(file)
				setOpenSharingPopup(true)
				setAnchorEl(null)
				break

			case 'Download File':
				handleDownload(file)
				break

			case 'Remove File':
				setPopupTitle(`Remove Document`)
				setRecordForEdit(file)
				setOpenRemovePopup(true)
				setAnchorEl(null)
				break

			default:
				setAnchorEl(null)
		}
		setAnchorEl(null)
	}

	return (
		<>
			<IconButton
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				color="primary"
				size="small"
				onClick={handleClick}
			>
				<MoreHorizIcon />
			</IconButton>
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
						width: '20ch',
						color: '#0080fe',
					},
				}}
			>
				{options.map(option => (
					<MenuItem
						key={option}
						selected={option === '...'}
						onClick={() => handleClose(option, file)}
					>
						{option}
					</MenuItem>
				))}
			</Menu>
		</>
	)
}

export default FileList
