import type { IChatHeader } from 'features/Chat/Chat.types'

const getEventIdForReceiverIds = (chatHeaders: IChatHeader[], receiverIds: string[]) => {
	const formatArray = (arr: string[]) => arr.sort().join()
	const chatHeaderReceiverIds: { [key: string]: string } = chatHeaders.reduce(
		(acc, curr) => ({
			...acc,
			[curr._id]: formatArray(curr.receiverIds.map(receiverId => receiverId.receiverId._id)),
		}),
		{},
	)
	const findEventId = Object.entries(chatHeaderReceiverIds).find(
		chatHeaderReceiverId => chatHeaderReceiverId[1] === formatArray(receiverIds),
	)

	if (findEventId) return findEventId[0]
	return findEventId
}

export default getEventIdForReceiverIds
