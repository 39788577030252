import styled from 'styled-components'
import Button from 'components/Button'
import * as _ from 'styles/helpers'
import type { Props } from './StartChatButton'

const StyledStartChatButton = styled(Button)<Partial<Props>>`
	margin: 0;
	padding: 0;
	background-color: transparent;
	/* color: ${_.COLORS.white}; */

	> div > svg {
		margin: 0;
		font-size: 24px;
	}
`

export default StyledStartChatButton
