import styled from 'styled-components'
import type { Props } from './Task'
import * as _ from 'styles/helpers'

const button = (isIcon: boolean = false) => `
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  ${isIcon ? `font-size: ${_.rem(16)}` : ''}; // Changed from 24 to 16
`

const StyledTask = styled.div<Partial<Props>>`
	&.Task {
		margin-bottom: ${_.rem(10)}; // Changed from 16 to 10
		box-shadow: inset 0 0 0 ${_.rem(1)} ${_.COLORS.gray_100},
			0 ${_.rem(2)} ${_.rem(1)} ${_.rem(-1)} rgb(0 0 0 / 20%),
			0 ${_.rem(1)} ${_.rem(1)} 0 rgb(0 0 0 / 14%), 0 ${_.rem(1)} ${_.rem(3)} 0 rgb(0 0 0 / 12%);
		border-radius: ${_.rem(2)};
	}

	.Task__header {
		@media ${_.MQ.before_nav} {
			position: relative;
		}

		padding-bottom: ${_.rem(8)};
		padding-top: ${_.rem(8)};
		padding-left: ${_.rem(46)};
		grid-gap: ${_.rem(12)};
		color: ${_.COLORS.blue_500};
		background-color: ${_.COLORS.gray_100};
		font-size: ${_.rem(14)};
		font-weight: 500;
		border-radius: ${_.rem(2)};
		margin-bottom: ${_.rem(8)};
		box-shadow: inset 0 0 0 ${_.rem(1)} ${_.COLORS.gray_100},
			0 ${_.rem(2)} ${_.rem(1)} ${_.rem(-1)} rgb(0 0 0 / 20%),
			0 ${_.rem(1)} ${_.rem(1)} 0 rgb(0 0 0 / 14%), 0 ${_.rem(1)} ${_.rem(3)} 0 rgb(0 0 0 / 12%);

		@media ${_.MQ.nav} {
			display: flex;
			align-items: center;
		}

		.Task__title {
			flex: 0 0 ${_.rem(343)};
		}
		.Task__status {
			flex: 0 0 ${_.rem(110)};
		}
		.Task__date {
			flex: 0 0 ${_.rem(96)};
		}
		.Task__assignee {
			flex: 0 0 ${_.rem(90)};
		}
	}

	.Task__item {
		@media ${_.MQ.before_nav} {
			position: relative;
		}

		padding: ${_.rem(12)};
		grid-gap: ${_.rem(12)};

		@media ${_.MQ.nav} {
			display: flex;
			align-items: center;

			&:hover .Task__delete-button {
				opacity: 0.5;

				&:hover {
					opacity: 1;
				}
			}

			&:hover .Task__email-button {
				/* opacity: 0.5; */

				&:hover {
					opacity: 1;
				}
			}
		}
		&:hover {
			background-color: ${_.COLORS.gray_100};
		}

		&:hover .Task__title-button {
			/* border-radius: ${_.rem(3)};
			box-shadow: 0 0 0 ${_.rem(1)} rgba(0, 0, 0, 0.23);

			&:hover {
				box-shadow: 0 0 0 ${_.rem(1)} rgba(0, 0, 0, 0.87);
			} */
		}

		.Task__complete {
			flex: 0 0 ${_.rem(16)};
			/* font-weight: ${_.FONTS.bold}; */
			font-size: ${_.rem(15)}; // Changed from 18 to 16
		}
		.Task__status {
			flex: 0 0 ${_.rem(110)};
			/* font-weight: ${_.FONTS.bold}; */
			font-size: ${_.rem(14)}; // Changed from 18 to 16
		}
		.Task__date {
			flex: 0 0 ${_.rem(120)};
			/* font-weight: ${_.FONTS.bold}; */
			font-size: ${_.rem(14)}; // Changed from 18 to 16
		}
		.Task__assignee {
			flex: 0 0 ${_.rem(60)};
			/* font-weight: ${_.FONTS.bold}; */
			font-size: ${_.rem(14)}; // Changed from 18 to 16
		}
	}

	.Task__title {
		@media ${_.MQ.before_nav} {
			padding-right: ${_.rem(32)};
		}

		flex: 0 0 ${_.rem(351)};
		/* flex: 1; */
		min-width: 0; // for truncation
		padding: 0;

		// .Task__title-button
		&-button {
			${button()};
			flex: 1;
			width: 100%;
			padding: 0 ${_.rem(8)};
			font-weight: ${_.FONTS.bold};
			font-size: ${_.rem(16)}; // Changed from 18 to 16
			line-height: 1.33;
			color: ${_.COLORS.foreground};
			text-align: left;
			text-decoration: none;
			height: ${_.rem(32)};

			// .Task__title-button--isComplete
			&--isComplete {
				text-decoration: line-through;
				opacity: 0.5;
				pointer-events: none;
				box-shadow: none !important;
			}

			// .Task__title-button-text
			&-text {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		input {
			width: 100%;
			font-size: ${_.rem(16)}; // Changed from 18 to 16
			font-weight: bold;
			line-height: 1.33;
			padding: 0 ${_.rem(8)};
			border: none;
			box-shadow: none !important;
			border-radius: ${_.rem(3)};
			height: ${_.rem(32)};
		}
	}

	.Task__complete-button {
		${button(true)};
		color: ${_.COLORS.green_500};
		min-width: ${_.rem(24)};

		&--isComplete {
			opacity: 0.75;
		}
	}

	.Task__delete-button {
		@media ${_.MQ.before_nav} {
			position: absolute;
			top: 0;
			right: 0;
		}

		${button(true)};
		color: ${_.COLORS.red_500};

		@media ${_.MQ.nav} {
			opacity: 0;

			&:hover,
			&:focus {
				opacity: 1;
			}
		}
	}

	.Task__email-button {
		@media ${_.MQ.before_nav} {
			position: absolute;
			top: 0;
			right: 0;
		}

		${button(true)};
		color: ${_.COLORS.red_500};

		@media ${_.MQ.nav} {
			/* opacity: 0; */

			&:hover,
			&:focus {
				opacity: 1;
			}
		}
	}

	.Task__add-subtask {
		padding: ${_.rem(6)};

		&-button {
			font-weight: 500;
			background-color: transparent;

			svg {
				margin-right: ${_.rem(4)};
			}
		}
	}

	/* -----------------------------------------------------------------------------------------------
    SUBTASKS
  ----------------------------------------------------------------------------------------------- */

	.Task__subtasks {
		border-top: ${_.rem(2)} solid ${_.COLORS.blue};
	}

	&.Subtask {
		.Task__item {
			padding: ${_.rem(6)} ${_.rem(22)}; // Changed from 16 to 28
			border-bottom: ${_.rem(1)} solid ${_.COLORS.gray_200};

			&:hover {
				background-color: ${_.COLORS.gray_100};
			}

			.Task__delete-button {
				@media ${_.MQ.before_nav} {
					top: ${_.rem(12)};
					right: ${_.rem(16)};
				}
			}

			.Task__email-button {
				@media ${_.MQ.before_nav} {
					top: ${_.rem(12)};
					right: ${_.rem(16)};
				}
			}
		}

		.Task__title {
			flex: 0 0 ${_.rem(344)};
			/* flex: 1; */
			&-button {
				font-size: ${_.rem(14)}; // Changed from 15 to 12
				font-weight: ${_.FONTS.regular};
			}

			input {
				font-size: ${_.rem(14)}; // Changed from 15 to 12
				font-weight: ${_.FONTS.regular};
			}
		}

		.Task__complete {
			flex: 0 0 ${_.rem(14)};
			/* font-weight: ${_.FONTS.bold}; */
			font-size: ${_.rem(14)}; // Changed from 18 to 16
		}
		.Task__status {
			flex: 0 0 ${_.rem(110)};
			/* font-weight: ${_.FONTS.bold}; */
			font-size: ${_.rem(14)}; // Changed from 18 to 16
		}
		.Task__date {
			flex: 0 0 ${_.rem(120)};
			/* font-weight: ${_.FONTS.bold}; */
			font-size: ${_.rem(14)}; // Changed from 18 to 16
		}
		.Task__assignee {
			flex: 0 0 ${_.rem(60)};
			/* font-weight: ${_.FONTS.bold}; */
			font-size: ${_.rem(14)}; // Changed from 18 to 16
		}
	}

	/* -----------------------------------------------------------------------------------------------
    THIRD PARTY OVERRIDES
  ----------------------------------------------------------------------------------------------- */
	label#date-picker-dialog-label {
		font-size: ${_.rem(14)};
	}
	.MuiFormControl-root {
		width: 100%;
		margin-top: 0;
		margin-bottom: 0;
	}
	.MuiInputBase-root {
		font-size: ${_.rem(14)};
	}
	.MuiInputAdornment-root .MuiIconButton-root {
		padding: ${_.rem(6)};
	}
	.Task__date {
		.MuiSvgIcon-root {
			font-size: ${_.rem(18)};
		}
	}
	.MuiSelect-selectMenu,
	.MuiOutlinedInput-input {
		padding-top: ${_.rem(10)};
		padding-bottom: ${_.rem(8)};
		padding-left: ${_.rem(14)};
		padding-right: 0;
	}

	.Task__item:hover {
		.MuiOutlinedInput-notchedOutline {
			border-color: rgba(0, 0, 0, 0.23);
		}
		.MuiSvgIcon-root {
			opacity: 1;
		}
	}
	.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
		border-color: rgba(0, 0, 0, 0.87);
	}

	.MuiFormLabel-root {
		color: rgba(0, 0, 0, 0.54);
	}
	.MuiOutlinedInput-notchedOutline,
	.MuiOutlinedInput-root,
	.MuiInputBase-input {
		box-shadow: none;
		border-color: transparent;
	}
	.MuiSvgIcon-root {
		opacity: 0.5;
	}

	.MuiButtonBase-root:focus .MuiSvgIcon-root {
		opacity: 1;
	}

	.Task__item--isComplete {
		.MuiInputBase-input {
			color: rgba(0, 0, 0, 0.38);
			pointer-events: none;
		}
		.MuiOutlinedInput-notchedOutline,
		.MuiOutlinedInput-root,
		.MuiInputBase-input {
			box-shadow: none;
			border-color: transparent !important;
			pointer-events: none;
		}
		.MuiSvgIcon-root {
			opacity: 0.5 !important;
		}
	}

	*:disabled {
		pointer-events: none;
	}
`

export default StyledTask
