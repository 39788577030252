import React, { useEffect } from 'react'
import { useChat, ChatHeaderList } from 'features/Chat'

const ChatHeaderListContainer = ({
	showSelected = true,
	isLink = false,
}: {
	showSelected?: boolean
	isLink?: boolean
}) => {
	const {
		state: { chatHeaders, currentEventId, userId },
		actions: { getChatHeaders, getChatMessages },
	} = useChat()

	// console.log(`Chat - userId: `, userId)

	useEffect(() => {
		getChatHeaders()
		// eslint-disable-next-line
	}, [])

	const handleClick = chatHeader => {
		if (isLink) {
			window.location.href = `/chats?id=${chatHeader._id}`
		} else {
			getChatMessages(chatHeader._id, chatHeader.receiverIds)
		}
	}

	return (
		<ChatHeaderList
			chatHeaders={chatHeaders}
			userId={userId}
			currentEventId={currentEventId}
			showSelected={showSelected}
			onClick={handleClick}
		/>
	)
}

export default ChatHeaderListContainer
