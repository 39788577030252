import styled from 'styled-components'
import * as _ from 'styles/helpers'
import type { Props } from './ChatWindow'

const StyledChatWindow = styled.div<Partial<Props>>`
	display: flex;
	padding: 0 ${_.rem(24)};
	/* height: 100%; */

	> div {
		display: flex;
		flex-direction: column;
		flex: 1;
		height: 100%;
		min-height: 70vh;
	}
`

export default StyledChatWindow
