import React, { useReducer } from 'react'
import curry from 'utils/curry'

interface State {
	openPopup: boolean
	newTaskInput: string
	// recordForEdit: { [key: string]: any }
}

const initialState: State = {
	openPopup: false,
	newTaskInput: '',
	// recordForEdit: {},
}

function reducer(state: State, action: { type: string; payload: any }) {
	const { type, payload } = action
	if (type === 'openPopup') return { ...state, openPopup: payload }
	if (type === 'newTaskInput') return { ...state, newTaskInput: payload }
	// if (type === 'recordForEdit') {
	// 	let _payload = payload
	// 	if (!_payload) _payload = {}
	// 	return { ...state, recordForEdit: payload }
	// }
	return state
}

type Context = { state: State; actions: any } | undefined
const TasksContext = React.createContext<Context>(undefined)

const TasksProvider = ({ children }: { children: React.ReactNode }) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	const setState = (type: string, payload: any = undefined) => dispatch({ type, payload })
	// curried setState
	const _setState = curry(setState)
	// action creators
	const actions = {
		setOpenPopup: _setState('openPopup'),
		setNewTaskInput: _setState('newTaskInput'),
		// setRecordForEdit: _setState('recordForEdit'),
	}

	return <TasksContext.Provider value={{ state, actions }}>{children}</TasksContext.Provider>
}

const useTasks = () => {
	const context = React.useContext(TasksContext)
	if (context === undefined) throw new Error('useTasks must be used within a TasksProvider')

	return context
}

export { TasksProvider, useTasks }

/*
  # Usage
  import { useTasks } from 'features/Tasks/TasksContext'
  const {
		state: { openPopup, newTaskInput }, // recordForEdit
		actions: { setOpenPopup, setNewTaskInput }, // setRecordForEdit
	} = useTasks()
*/
