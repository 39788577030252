import React, { ChangeEventHandler, forwardRef } from 'react'
import TextField from '@mui/material/TextField'
import { ArrowUpward as ArrowUpwardIcon } from '@mui/icons-material'
import Button from 'components/Button'
import StyledChatInput from './ChatInput.style'

export interface Props {
	className?: string
	isDisabled?: boolean
	value: string
	handleChatInput: ChangeEventHandler
	handleSendChatMessage: Function
}

const ChatInput = forwardRef(
	(
		{ className = '', value, isDisabled = false, handleChatInput, handleSendChatMessage }: Props,
		ref,
	) => {
		return (
			<StyledChatInput data-testid="ChatInput" className={className}>
				<form>
					<TextField
						className="ChatInput__input"
						inputRef={ref}
						placeholder="Message"
						value={value}
						onChange={handleChatInput}
						variant="outlined"
						size="small"
						disabled={isDisabled}
					/>
					<Button
						className="ChatInput__submit-button"
						type="submit"
						variant="success"
						onClick={handleSendChatMessage}
						aria-label="Send message"
						disabled={isDisabled}
					>
						<ArrowUpwardIcon />
					</Button>
				</form>
			</StyledChatInput>
		)
	},
)

export default ChatInput
