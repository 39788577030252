import styled from 'styled-components'
import type { Props } from './FileUploader'
import * as _ from 'styles/helpers'

const StyledFileUploader = styled.div<Partial<Props>>`
	margin-bottom: ${_.rem(16)};

	/* Third-party overrides */
	.uploadcare--widget__text,
	.uploadcare--widget__button.uploadcare--widget__button_type_open,
	.uploadcare--widget__button.uploadcare--widget__button_type_cancel,
	.uploadcare--widget__button.uploadcare--widget__button_type_remove {
		display: none;
	}
	.uploadcare--widget__progress {
		color: ${_.COLORS.blue_500};
		border-color: ${_.COLORS.gray_100};
	}
`

export default StyledFileUploader
