import type { Props } from './ChatInput'

const fixture: Props = {
	className: 'ChatInput',
	value: '',
	isDisabled: false,
	handleChatInput: () => console.log('handleChatInput'),
	handleSendChatMessage: () => console.log('handleSendChatMessage'),
}

const isSendingMessageFixture: Props = {
	className: 'ChatInput',
	value: '',
	isDisabled: true,
	handleChatInput: () => console.log('handleChatInput'),
	handleSendChatMessage: () => console.log('handleSendChatMessage'),
}

const startNewChatFixture: Props = {
	className: 'ChatInput',
	value: '',
	isDisabled: false,
	handleChatInput: () => console.log('handleChatInput'),
	handleSendChatMessage: () => console.log('handleSendChatMessage'),
}

export default fixture
export { isSendingMessageFixture, startNewChatFixture }
