import React from 'react'
import { TextField } from '@material-ui/core'

export default function Input(props) {
	const { name, disabled, size, label, value, error = null, onChange, ...other } = props

	return (
		<TextField
			variant="outlined"
			disabled={disabled}
			size={size}
			name={name}
			label={label}
			type="text"
			value={value}
			onChange={onChange}
			{...other}
			{...(error && { error: true, helperText: error })}
		/>
	)
}
