import styled from 'styled-components'
import { Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions } from '@mui/material'
import type { Props } from './Popup'
import * as _ from 'styles/helpers'

const Popup = styled(Dialog)<Partial<Props>>`
	.MuiDialog-paper {
		padding: ${_.rem(8)} 0 ${_.rem(16)};
		min-width: ${_.rem(256)};
		width: ${({ isFullWidth }) => isFullWidth && '100%;'};
		border-top: ${_.rem(4)} solid ${_.COLORS.tradewind_blue};
		box-shadow: 0 0 0 3px rgba(255, 255, 255, 1), 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
			0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	}
`

const PopupHeader = styled.div<Partial<Props>>`
	display: flex;
	justify-content: space-between;

	.close-popup-button {
		height: ${_.rem(48)};
		background: transparent;

		svg {
			margin: 0;
			font-size: 24px;
			color: ${_.COLORS.black};
		}
	}
`

const PopupTitle = styled(DialogTitle)<Partial<Props>>``

const PopupContent = styled(DialogContent)<Partial<Props>>`
	${_.MIXINS.mod}
`

const PopupContentText = styled(DialogContentText)<Partial<Props>>``

const PopupActions = styled(DialogActions)<Partial<Props>>`
	margin-top: ${_.rem(8)};
`

const StyledPopup = {
	Popup,
	PopupHeader,
	PopupTitle,
	PopupContent,
	PopupContentText,
	PopupActions,
}

export default StyledPopup
