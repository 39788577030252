import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, Grid, Paper, TableBody, TableCell, TableRow, IconButton } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'
import moment from 'moment'
import Popup from 'components/Popup'
import TaskAddEditPopup from 'components/popups/TaskAddEditPopup'
import { initializeAssignedTasks, updateTask } from 'slices/tasksSlice'

const TasksTable = ({ tasks, TblContainer, TblHeader, TblPagination, recordCount, user }) => {
	const dispatch = useDispatch()

	const [parentid, setParentid] = useState('')
	const [popupTitle, setPopupTitle] = useState('')
	const [openPopup, setOpenPopup] = useState(false)
	const [recordForEdit, setRecordForEdit] = useState(null)

	const userId = user._id
	const currentUser = [
		{
			assignedTo: userId,
			assignee: user.name,
			email: user.email,
		},
	]

	const openInPopup = task => {
		setParentid(task._id)
		setPopupTitle(task.title)
		setRecordForEdit(task)
		setOpenPopup(true)
	}
	const editTask = async (task, resetForm) => {
		await dispatch(
			updateTask({
				_id: task._id,
				taskName: task.taskName,
				parentId: task.parentId,
				packageid: task.packageid === null ? task.portfolioid._id : task.packageid._id,
				portfolioid: task.portfolioid === null ? null : task.portfolioid._id,
				packageType: task.packageType,
				createdBy: task.createdBy._id,
				assignedTo: task.assignedTo,
				description: task.description,
				taskStatus: task.taskStatus,
				completedBy: task.completedBy,
				dateStarted: task.dateStarted,
				dateCompleted: task.dateCompleted,
				completed: task.completed,
				templateId: task.templateId,
				templateTaskId: task.templateTaskId,
				taskDays: task.taskDays,
				comments: task.comments,
			}),
		)

		// const newPortfolio = portfolio
		// newPortfolio.updateNote = 'Task(s) Updated.'
		// await dispatch(updatePortfolio(newPortfolio))

		// dispatch(getMyAssignedTasks())

		await dispatch(
			initializeAssignedTasks({
				userId: userId,
			}),
		)

		resetForm()
		setOpenPopup(false)
		setRecordForEdit(null)
	}

	const CompletedDays = ({ completedBy }) => {
		const timezoneOffset = new Date().getTimezoneOffset() / 60
		const hoursOut = moment(completedBy).add(timezoneOffset, `hours`).diff(moment(), 'hours')
		let daysOut = moment(completedBy).add(timezoneOffset, `hours`).diff(moment(), 'days')

		if (hoursOut < 0) {
			daysOut = daysOut - 1
		}

		const daysLable = daysOut > 1 || daysOut < -1 ? `days` : `day`
		const result = daysOut === 0 ? 'Today' : `${daysOut} ${daysLable}`

		return <div style={{ color: daysOut < 0 ? 'red' : 'inherit' }}>{result}</div>
	}

	return (
		<div>
			<Grid container>
				<TblContainer className="topPage__tableContainer">
					<TblHeader />
					<TableBody>
						{tasks.map(task => (
							<TableRow
								key={task._id}
								onClick={() => {
									openInPopup(task)
								}}
							>
								<TableCell>
									{task.taskName}
									{/* <Link to={`/tasks/${task._id}`}>{task.taskName}</Link> */}
								</TableCell>
								<TableCell>
									{task.packageid ? (
										<div>{task.packageid.assetName}</div>
									) : task.portfolioid ? (
										<div>{task.portfolioid.assetName}</div>
									) : null}
								</TableCell>
								<TableCell>
									<div>{task.projectid.projectName}</div>
								</TableCell>
								<TableCell>
									<div>{task.taskStatus}</div>
								</TableCell>
								<TableCell align="right">
									<CompletedDays completedBy={task.completedBy} />
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</TblContainer>
			</Grid>
			{recordCount > 4 ? <TblPagination /> : null}
			<Popup title={popupTitle} openPopup={openPopup} setOpenPopup={setOpenPopup}>
				<TaskAddEditPopup
					partners={currentUser}
					addEditTask={editTask}
					recordForEdit={recordForEdit}
					parentId={parentid}
				/>
			</Popup>
		</div>
	)
}

export default TasksTable
