import React from 'react'
import { Link } from 'react-router-dom'

const SidebarPartnerAppTop = ({ partnerApp }) => {
	return (
		<div className="locationSidebar__top">
			<img src={partnerApp.image} alt={partnerApp.vendor} />
			{/* <Link to={`/partnerApps/${partnerApp._id}/edit`}>
				<h4>{partnerApp.appName}</h4>
			</Link>
			<p>{partnerApp.vendor}</p> */}
			<br />
			<br />
		</div>
	)
}

export default SidebarPartnerAppTop
