import { axiosInterceptor, apiCall } from 'services'
import { API_BASE_URL } from './constants'

const tasksService = () => {
	const api = axiosInterceptor(API_BASE_URL)

	// CREATE
	const createTask = async (task: any) => await apiCall(api.post('/tasks', task))

	// READ
	const getTasks = async ({
		userid,
		packageid,
		projectid,
	}: {
		userid: string
		packageid: string
		projectid: string
	}) =>
		await apiCall(
			api.get('tasks/mypackageid', {
				params: {
					userid,
					packageid,
					projectid,
				},
			}),
		)

	// UPDATE
	const updateTask = async (task: any) => await apiCall(api.put(`/tasks/${task._id}`, task))

	// DELETE
	const deleteTask = async (id: string) => await apiCall(api.delete(`/tasks/${id}`))

	// CREATE SUBTASK
	const createSubtask = async (subtask: any) => await apiCall(api.post('/tasks/subtask', subtask))

	// READ SUBTASKS
	const getSubtasks = async ({ userid, packageid }: { userid: string; packageid: string }) =>
		await apiCall(
			api.get('tasks/mysubtasks', {
				params: {
					userid,
					packageid,
				},
			}),
		)

	// READ
	const getMyAssigned = async ({ assignedTo }: { assignedTo: string }) =>
		await apiCall(
			api.get('tasks/myassigned', {
				params: {
					assignedTo,
				},
			}),
		)

	// OTHER
	const loadTemplateIntoTasks = async (template: any) =>
		await apiCall(api.post('/tasks/template', template))

	return {
		createTask,
		getTasks,
		updateTask,
		deleteTask,
		createSubtask,
		getSubtasks,
		getMyAssigned,
		loadTemplateIntoTasks,
	}
}

export default tasksService
