import React from 'react'
import Button from 'components/Button'
import { StyledSibarList } from '../Actions.style'

export interface Props {
	handleAction: Function
	actionLable: string
	actionIcon: any
}

const ListAction = ({ handleAction, actionLable, actionIcon }: Props) => {
	return (
		<StyledSibarList data-testid="DetailsHeader">
			<Button onClick={handleAction} variant="text" isInput>
				{actionIcon} {actionLable}
			</Button>
		</StyledSibarList>
	)
}

export default ListAction
