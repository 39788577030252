import React from 'react'
import Layout from 'components/Layout'
import {
	ChatWindow,
	ChatWindowHeaderContainer,
	ChatAddressBarContainer,
	ChatHeaderListContainer,
	ChatInputContainer,
	ChatMessageListContainer,
	StartChatButtonContainer,
	ChatSpinnerContainer,
} from 'features/Chat'

const ChatPage = () => (
	<Layout>
		<Layout.Page>
			<Layout.Sidebar>
				<Layout.SidebarItem
					hasNoMaxHeight
					title="Messages"
					contentRight={<StartChatButtonContainer />}
					noPadding
					noElevation
				>
					<ChatHeaderListContainer />
				</Layout.SidebarItem>
			</Layout.Sidebar>
			<Layout.Body>
				<ChatWindow>
					<ChatWindowHeaderContainer />
					<ChatAddressBarContainer />
					<ChatMessageListContainer />
					<ChatSpinnerContainer />
					<ChatInputContainer />
				</ChatWindow>
			</Layout.Body>
		</Layout.Page>
	</Layout>
)

export default ChatPage
