import React from 'react'
import { Link } from 'react-router-dom'
import { Avatar, Grid, IconButton, TableBody, TableCell, TableRow } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import AltRetailerLogo from 'images/Old_Navy_Logo.png'

const PortfolioAddLocationsTable = ({
	locations,
	addLocation,
	TblContainer,
	TblHeader,
	TblPagination,
	recordCount,
}) => {
	return (
		<div>
			<Grid container spacing={3}>
				<Grid item>
					<TblContainer className="topPage__tableContainer">
						<TblHeader />
						<TableBody>
							{locations.map(location => (
								<TableRow key={location._id}>
									<TableCell>
										<Avatar alt={AltRetailerLogo} src={location.image} />
									</TableCell>
									<TableCell>
										<Link to={`/locations/${location._id}`}>{location.assetName}</Link>
									</TableCell>
									<TableCell>{location.address}</TableCell>
									<TableCell>{location.city}</TableCell>
									<TableCell>{location.stateProvince}</TableCell>
									<TableCell>{location.postalCode}</TableCell>
									<TableCell>
										<IconButton
											color="primary"
											size="small"
											onClick={() => addLocation(location._id)}
										>
											<AddIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</TblContainer>
				</Grid>
			</Grid>
			{/* {recordCount > 4 ? <TblPagination /> : null} */}
		</div>
	)
}

export default PortfolioAddLocationsTable
