import React from 'react'
import DocViewer, { DocViewerRenderers, docx } from '@cyntler/react-doc-viewer'

const DocumentPreviewPopup = ({ document }) => {
	return (
		<div>
			<DocViewer
				documents={document}
				pluginRenderers={DocViewerRenderers}
				style={{ height: 900 }}
				theme={{
					// primary: '#5296d8',
					primary: '#0080fe',
					secondary: '#ffffff',
					tertiary: '#5296d899',
					textPrimary: '#ffffff',
					textSecondary: '#5296d8',
					textTertiary: '#00000099',
					disableThemeScrollbar: false,
				}}
			/>
		</div>
	)
}

export default DocumentPreviewPopup
