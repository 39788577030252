import React, { useState } from 'react'
import SidebarLocationsRecent from 'components/Sidebars/SidebarLocationsRecent'
import SidebarPendingTasksLocation from 'components/Sidebars/SidebarPendingTasksLocation'
import Layout from 'components/Layout'
import ArchiveLibrary from './components/ArchiveLibrary/ArchiveLibrary'

const Locations_Index = () => {
	const [openPopup, setOpenPopup] = useState(false)

	return (
		<Layout>
			<Layout.Page>
				<Layout.Sidebar>
					<SidebarLocationsRecent />
					{/* <SidebarPendingTasksLocation /> */}
				</Layout.Sidebar>
				<Layout.Body>
					<Layout.PageHeader>
						<h2>Archive Library</h2>
					</Layout.PageHeader>
					<ArchiveLibrary openPopup={openPopup} setOpenPopup={setOpenPopup} />
				</Layout.Body>
			</Layout.Page>
		</Layout>
	)
}

export default Locations_Index
