import React from 'react'
import { useDispatch } from 'react-redux'
import TWControls from 'components/controls/TWControls'
import { Form, useForm } from 'components/controls/useForm'

import { Grid } from '@mui/material'

const PortfolioAdNew = ({ portfolioAdd }) => {
	const initialFValues = {
		id: 0,
		user: '',
		assetName: '',
		description: '',
	}

	const validate = () => {
		let temp = { ...errors }
		temp.assetName = values.assetName ? '' : '*Required field'
		temp.description = values.description ? '' : '*Required field'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialFValues,
		true,
		validate,
	)

	const submitHandler = e => {
		e.preventDefault()
		if (validate()) {
			portfolioAdd(values, resetForm)
		}
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container>
				<Grid item xs={12}>
					<TWControls.Input
						size="small"
						name="assetName"
						label="Name"
						value={values.assetName}
						onChange={handleInputChange}
						error={errors.assetName}
					/>
					<TWControls.InputMulti
						size="small"
						name="description"
						label="Description/Note"
						value={values.description}
						onChange={handleInputChange}
						error={errors.description}
					/>
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<TWControls.Button type="submit" text="Submit" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default PortfolioAdNew
