import React from 'react'
import { Link } from 'react-router-dom'
import { RiCloseCircleLine } from 'react-icons/ri'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import type { TableCellProps } from '@mui/material'

import Button from 'components/Button'
import { StyledDetailsTable } from '../TemplateDetails.style'
import moment from 'moment'

type TemplateDetails = {
	id: string
	key: string
	templateId: string
	textValue: string
	numericValue: string
	currencyValue: string
	dateValue: string
	description: string
	detailType: string
}

export type TableHeaders = { label: string; props?: TableCellProps }[]

export interface Props {
	details?: TemplateDetails[]
	handleEditDetail: Function
	handleDeleteDetail: Function
	tableHeaders?: TableHeaders
}

const defaultTableHeaders: TableHeaders = [
	{ label: 'Label' },
	{ label: 'Description' },
	{ label: 'Detail Type' },
	{ label: 'Sample Value' },
	{ label: 'Delete', props: { align: 'right' } },
]

const TemplateDetailsTable = ({
	details = [],
	handleEditDetail,
	handleDeleteDetail,
	tableHeaders = defaultTableHeaders,
}: Props) => {
	return (
		<StyledDetailsTable data-testid="DetailsTable">
			<TableContainer>
				<Table>
					{tableHeaders.length > 0 && (
						<TableHead>
							<TableRow>
								{tableHeaders.map((th, i) => (
									<TableCell key={i} {...th.props}>
										{th.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
					)}
					<TableBody>
						{details.map(detail => (
							<TableRow key={detail.id} className="table-row">
								<TableCell component="th" scope="row" onClick={() => handleEditDetail(detail.id)}>
									<Link to="#">{detail.key}</Link>
								</TableCell>
								<TableCell component="th" scope="row">
									{detail.description}
								</TableCell>
								<TableCell component="th" scope="row">
									{detail.detailType}
								</TableCell>
								<TableCell>
									{detail.detailType === 'Text'
										? detail.textValue
										: detail.detailType === 'Number'
										? detail.numericValue
										: detail.detailType === 'Currency'
										? `$${detail.currencyValue}`
										: detail.detailType === 'Date'
										? moment(detail.dateValue).format('MM/DD/YYYY')
										: null}
								</TableCell>
								<TableCell align="right">
									<div className="edit-button">
										<Button
											onClick={() => handleDeleteDetail(detail.id)}
											variant="ghost-red"
											isOutline
											aria-label="Delete"
										>
											<RiCloseCircleLine />
										</Button>
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</StyledDetailsTable>
	)
}

export default TemplateDetailsTable
