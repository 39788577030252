import React from 'react'

const FourOhFour = () => {
	return (
		<div>
			<h1>404</h1>
		</div>
	)
}

export default FourOhFour
