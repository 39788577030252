import React, { useRef } from 'react'
import { Chart as ChartJS } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { getNumberAsThousandsStr } from '../utils'
import { ANIMATION_DURATION } from './constants'

export interface Props {
	data: any
	options?: any
}

const PieChart = ({ data, options }: Props) => {
	const chartRef = useRef<ChartJS>(null)

	const defaultBarChartOptions = {
		responsive: true,
		animation: {
			duration: ANIMATION_DURATION,
			easing: 'easeOutQuart',
			onComplete: function () {
				const chart = chartRef.current
				const ctx = chart?.ctx

				if (ctx) {
					ctx.font = 'bold 12px Inter, sans-serif'
					ctx.fillStyle = '#000'
					ctx.textAlign = 'center'
					ctx.textBaseline = 'bottom'

					chart.data.datasets.forEach((dataset, index) => {
						for (let i = 0; i < dataset.data.length; i++) {
							const meta = chart.getDatasetMeta(index) as any
							const model = meta.data[i]

							ctx.fillText(getNumberAsThousandsStr(dataset.data[i]), model.x, model.y - 0)
						}
					})
				}
			},
		},
		hover: { mode: null },
		plugins: {
			legend: {
				display: !!options?.showLegend,
			},
			tooltip: {
				enabled: false,
			},
		},
		scales: {
			yAxes: {
				ticks: {
					callback: function (label, index, labels) {
						return label === 0 ? '0' : `${getNumberAsThousandsStr(label)}`
					},
				},
			},
		},
	}

	const barChartOptions = {
		...defaultBarChartOptions,
	}

	// @ts-ignore
	return <Bar ref={chartRef} options={barChartOptions} data={data} />
}

export default PieChart
