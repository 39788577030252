import React from 'react'
import { useSelector } from 'react-redux'
import BulkLoadLocations from '../components/BulkLoadLocations'

const BulkLoadLocationsWrapper = () => {
	const userLogin = useSelector(state => state.userLogin)
	const { userInfo: user } = userLogin
	const userId = user._id

	return <BulkLoadLocations userId={userId} />
}

export default BulkLoadLocationsWrapper
