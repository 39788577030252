import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SidebarLocationTop from '../../components/Sidebars/SidebarLocationTop'
import RenderSubRoutes from 'components/RenderSubRoutes'
import getLocationCenterRoutes from './getRoutes/getLocationCenterRoutes'
import getLocationRoutes from './getRoutes/getLocationRoutes'
import Layout from 'components/Layout'
import { BusinessCard } from 'components/Card'
import routes from 'constants/routes'
import useMediaQuery from 'hooks/useMediaQuery'
import * as _ from 'styles/helpers'
import { initializeActiveLocations } from 'slices/locationSlice'
import { initializeUsers } from 'slices/usersSlice'

const Locations_Show = () => {
	const dispatch = useDispatch()
	const params = useParams()
	const { id } = params

	const [backTitle, setBackTitle] = useState(null)
	const [backLink, setBackLink] = useState(null)

	const currentRoute = useLocation()
	const getBackLink = () => {
		if (currentRoute.state) {
			if (backTitle === null) {
				setBackTitle(currentRoute.state.backButtonTitle)
				setBackLink(currentRoute.state.backButtonLink)
			}
			return { title: currentRoute.state.backButtonTitle, link: currentRoute.state.backButtonLink }
		}
		if (backTitle !== null) {
			return { title: backTitle, link: backLink }
		}
		return { title: 'Locations', link: routes.LOCATIONS }
	}

	const pathArray = currentRoute.pathname.split('/')
	const isPartnerPage = pathArray[pathArray.length - 1] === 'partners'

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	const { isLoading, isInitialized, location } = useSelector(state => {
		return {
			isLoading: state.locations.isLoading,
			isInitialized: state.locations.isInitialized,
			location: state.locations.entities[id],
		}
	})

	const { success: userListInitialized } = useSelector(state => {
		return {
			success: state.userList.isInitialized,
		}
	})

	useEffect(() => {
		if (!isInitialized) {
			dispatch(initializeActiveLocations())
		}
		if (!userListInitialized) {
			dispatch(initializeUsers())
		}
		// dispatch(initializeProjects({ locationId: id }))
	}, [dispatch, id, isInitialized, userListInitialized])

	const partners = location?.partners
	const assetOwner = location?.owner

	const isMobile = useMediaQuery(_.MQ.before_nav)

	if (!location || isLoading) {
		return null
	}

	var subRoutes = []
	if (location.assetType === 'center') {
		subRoutes = getLocationCenterRoutes(id)
	} else {
		subRoutes = getLocationRoutes(id)
	}

	return (
		<Layout>
			<Layout.SecondaryNav links={subRoutes} backLink={getBackLink()} />
			<Layout.Page>
				<Layout.Sidebar isOffset>
					{location && (
						<SidebarLocationTop location={location} originator={`Location Originator:  `} />
					)}
				</Layout.Sidebar>
				<Layout.Body>
					{isMobile && isLoading === false && (
						<div style={{ marginBottom: '24px' }}>
							<BusinessCard
								isHorizontal
								image={location?.image}
								name={location?.assetName}
								address1={location?.address}
								address2={`${location?.city}, ${location?.stateProvince}`}
							/>
						</div>
					)}
					<RenderSubRoutes routes={subRoutes} state={currentRoute.state} />
				</Layout.Body>
			</Layout.Page>
		</Layout>
	)
}

export default Locations_Show
