import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import LocationDocumentsLibrary from '../contentPages/LocationDocumentsLibrary'

const LocationDocuments = () => {
	const { id } = useParams()
	const { projectId } = useParams()
	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	const { isLoading: loading, location } = useSelector(state => {
		return {
			isLoading: state.locations.isLoading,
			location: state.locations.entities[id],
		}
	})

	const { projectLoading, project } = useSelector(state => {
		return {
			projectLoading: state.projects.isLoading,
			project: state.projects.entities[projectId],
		}
	})

	// TODO: Create skeleton loader
	if (loading || !location) return null
	if (projectLoading || !project) return null

	return <LocationDocumentsLibrary user={userInfo} location={location} project={project} />
}

export default LocationDocuments
