import React, { ReactNode } from 'react'
import StyledButton from './Button.style'

export interface Props {
	className?: string
	variant?: 'primary' | 'success' | 'text' | 'danger' | 'ghost' | 'ghost-red'
	isOutline?: boolean
	isLarge?: boolean
	isInput?: boolean
	onClick?: Function
	children: ReactNode
	[x: string]: any
}

const Button = ({
	className = '',
	variant = 'primary',
	isOutline = false,
	isLarge = false,
	isInput = false,
	children,
	onClick,
	...props
}: Props) => {
	const handleClick = e => onClick && onClick(e)

	return (
		<StyledButton
			data-testid="Button"
			className={className}
			variant={variant}
			isOutline={isOutline}
			isLarge={isLarge}
			isInput={isInput}
			onClick={handleClick}
			{...props}
		>
			<div>{children}</div>
		</StyledButton>
	)
}

export default Button
