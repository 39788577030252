import React from 'react'
import { CloseOutlined as CloseIcon } from '@mui/icons-material'
import Button from 'components/Button'
import S from './Popup.style'

export interface Props {
	className?: string
	children: any
	title: string | null
	openPopup: boolean
	actions?: any
	popupWidth?: string
	setOpenPopup?: Function
	isFullWidth?: boolean
}

const Popup = ({
	className = '',
	title,
	children,
	actions,
	popupWidth = '',
	openPopup,
	setOpenPopup,
	isFullWidth = false,
}: Props) => {
	return (
		<S.Popup
			data-testid="Popup"
			className={className}
			isFullWidth={isFullWidth}
			maxWidth={popupWidth === 'lg' ? 'lg' : 'md'}
			open={openPopup}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<S.PopupHeader>
				<S.PopupTitle id="alert-dialog-title" sx={{ color: '#0073e6', fontWeight: '500' }}>
					{title}
				</S.PopupTitle>
				{setOpenPopup && (
					<Button
						className="close-popup-button"
						onClick={() => setOpenPopup(false)}
						variant="ghost"
						aria-label="Close Popup"
					>
						<CloseIcon />
					</Button>
				)}
			</S.PopupHeader>
			<S.PopupContent>
				{typeof children === 'string' ? (
					<S.PopupContentText id="alert-dialog-description">{children}</S.PopupContentText>
				) : (
					children
				)}
			</S.PopupContent>
			{actions && <S.PopupActions>{actions}</S.PopupActions>}
			{/* <Dialog>
				<DialogTitle>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
							{title}
						</Typography>
						<TWControls.ActionButton
							onClick={() => {
								setOpenPopup(false)
							}}
						>
							<CloseIcon />
						</TWControls.ActionButton>
					</div>
				</DialogTitle>
				<DialogContent>{children}</DialogContent>
			</Dialog> */}
		</S.Popup>
	)
}

export default Popup
