import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import swal from 'sweetalert'
import * as Yup from 'yup'
import Button from 'components/Button'
import MDTLogo from 'images/MDT_FullLogo.png'
import { emailSendConfirmEmail } from 'functions/loginRegisterEmails'

const validationSchema = Yup.object().shape({
	name: Yup.string().required('Full name is Required'),
	email: Yup.string().email('Invalid email').required('Email is required'),
})

const RegistrationForm = () => {
	const userLogin = useSelector((state: any) => state.userLogin)
	const { loading: userLoginLoading } = userLogin

	const SERVER_BASE_URL =
		process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://app.mydealteams.com'

	const url = new URL(window.location.href)
	const urlParams = new URLSearchParams(url.search) // We might need a polyfill to use this

	const initialValues = {
		name: urlParams.get('name') || '',
		email: urlParams.get('email') || '',
	}

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values, { setSubmitting }) => {
				const { name, email } = values

				const pushLink = `${SERVER_BASE_URL}/emailconfirm/?name=${name}&email=${email}`

				swal({
					title: `New Registration!`,
					text: `An email confirmation has been sent to ${name} at ${email}. Once you've confirmed your email you will be able to complete your registration.`,
					icon: 'success',
				})

				emailSendConfirmEmail(values, pushLink)

				setSubmitting(false)
			}}
		>
			{({ isSubmitting }) => {
				/*
          TODO: handle form errors in UI
        */
				return (
					<Form>
						<img
							style={{ display: 'block', margin: '0 auto 16px' }}
							src={MDTLogo}
							alt="My Deal Teams logo"
							width={360}
						/>
						<h1 className="form-title">Create a new account</h1>
						<div className="inputs">
							<div className="input">
								<Field type="text" name="name" placeholder="Full Name" />
							</div>
							<div className="input">
								<Field type="email" name="email" placeholder="Email" />
							</div>
						</div>
						<div className="actions">
							<Button type="submit" isLarge disabled={isSubmitting || userLoginLoading}>
								{isSubmitting || userLoginLoading ? 'Submitting...' : 'Create Account'}
							</Button>
						</div>
					</Form>
				)
			}}
		</Formik>
	)
}

export default RegistrationForm
