import React from 'react'
import { useSelector } from 'react-redux'
import PartnerProjectCards from 'screens/locations/contentPages/PartnerProjectCards'
import { useParams } from 'react-router-dom'

const LocationPartnersScreen = () => {
	const { id } = useParams()
	const { projectId } = useParams()

	const { isInitialized, location, loading } = useSelector(state => {
		return {
			isInitialized: state.locations.isInitialized,
			loading: state.locations.isLoading,
			location: state.locations.entities[id],
		}
	})

	const { projectInitialized, projectLoading, project } = useSelector(state => {
		return {
			projectInitialized: state.projects.isInitialized,
			projectLoading: state.projects.isLoading,
			project: state.projects.entities[projectId],
		}
	})

	// TODO: Create skeleton loader
	if (loading || !location) return null
	if (projectLoading || !projectInitialized || !project) return null

	return <PartnerProjectCards partnerType="locations" location={location} project={project} />
}

export default LocationPartnersScreen
