import React, { useState } from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import TWControls from 'components/controls/TWControls'
import { Form, useForm } from 'components/controls/useForm'
import { Grid, Paper, InputAdornment } from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'

const CompanyAddNewPopup = ({ companyAdd }) => {
	const [address, setAddress] = useState('')

	const companyTypes = [
		{ id: 1 },
		{ selectValue: 'Commercial Center' },
		{ id: 2 },
		{ selectValue: 'Commercial Location' },
		{ id: 3 },
		{ selectValue: 'Retail Center' },
		{ id: 4 },
		{ selectValue: 'Retail Location' },
	]

	const initialFValues = {
		id: 0,
		companyName: '',
		reportingName: '',
		address: '',
		address2: '',
		city: '',
		stateProvince: '',
		postalCode: '',
		country: '',
		phoneNumber: '',
		website: '',
		logo: '',
		companyType: '',
		updateNote: 'New Company',
	}

	const validate = () => {
		let temp = { ...errors }
		temp.companyName = values.companyName ? '' : '*Required field'
		temp.address = values.address ? '' : '*Required field'
		temp.city = values.city ? '' : '*Required field'
		temp.stateProvince = values.stateProvince ? '' : '*Required field'
		temp.postalCode = values.postalCode ? '' : '*Required field'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialFValues,
		true,
		validate,
	)

	const submitHandler = e => {
		e.preventDefault()
		if (validate()) {
			companyAdd(values, resetForm)
		}
	}

	const handleSelect = async value => {
		const results = await geocodeByAddress(value)
		const latLng = await getLatLng(results[0])
		const place = results[0]

		setAddress(value)

		var newName = value.split(',')
		values.companyName = newName[0]
		values.address = ''
		values.postalCode = ''
		values.city = ''
		values.stateProvince = ''
		values.country = ''
		values.latitude = ''
		values.longitude = ''

		for (const component of place.address_components) {
			const componentType = component.types[0]

			switch (componentType) {
				case 'street_number': {
					values.address = `${component.long_name} ${values.address}`
					break
				}
				case 'route': {
					values.address += component.short_name
					break
				}
				case 'postal_code': {
					values.postalCode = `${component.long_name}${values.postalCode}`
					break
				}
				case 'postal_code_suffix': {
					values.postalCode = `${values.postalCode}-${component.long_name}`
					break
				}
				case 'locality':
					values.city = component.long_name
					break
				case 'administrative_area_level_1': {
					values.stateProvince = component.short_name
					break
				}
				case 'country':
					values.country = component.short_name
					break
				default:
					break
			}
		}
		values.latitude = latLng.lat
		values.longitude = latLng.lng
		setAddress('')
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container spacing={1}>
				<Grid item xs={8}>
					<PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
						{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
							<div>
								<TWControls.Input
									size="small"
									type="search"
									label="Search"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
									}}
									{...getInputProps()}
								/>

								<Paper>
									{loading ? <div> ...loading</div> : null}

									{suggestions.map(suggestion => {
										const style = suggestion.active
											? { backgroundColor: '#41b6e6' }
											: { backgroundColor: '#FFF' }

										return (
											<div {...getSuggestionItemProps(suggestion, { style })}>
												{suggestion.description}
											</div>
										)
									})}
								</Paper>
							</div>
						)}
					</PlacesAutocomplete>
				</Grid>
				<Grid item xs={4}>
					<TWControls.SelectType
						size="small"
						name="companyType"
						label="Company Type"
						value={values.companyType}
						onChange={handleInputChange}
						error={errors.companyType}
						options={companyTypes}
					/>
				</Grid>

				<Grid item xs={6}>
					<TWControls.Input
						size="small"
						name="companyName"
						label="Company Name"
						value={values.companyName}
						onChange={handleInputChange}
						error={errors.companyName}
					/>
				</Grid>
				<Grid item xs={6}>
					<TWControls.Input
						size="small"
						name="address"
						label="Address"
						value={values.address}
						onChange={handleInputChange}
						error={errors.address}
					/>
				</Grid>
				<Grid item xs={4}>
					<TWControls.Input
						size="small"
						name="city"
						label="City"
						value={values.city}
						onChange={handleInputChange}
						error={errors.city}
					/>
				</Grid>
				<Grid item xs={4}>
					<TWControls.Input
						size="small"
						name="stateProvince"
						label="State/Prov"
						value={values.stateProvince}
						onChange={handleInputChange}
						error={errors.stateProvince}
					/>
				</Grid>
				<Grid item xs={4}>
					<TWControls.Input
						size="small"
						name="postalCode"
						label="Postal Code"
						value={values.postalCode}
						onChange={handleInputChange}
						error={errors.postalCode}
					/>
				</Grid>
				<Grid item xs={4}>
					<TWControls.Input
						size="small"
						name="latitude"
						label="Latitude"
						value={values.latitude}
						onChange={handleInputChange}
						error={errors.latitude}
					/>
				</Grid>
				<Grid item xs={4}>
					<TWControls.Input
						size="small"
						name="longitude"
						label="Longitude"
						value={values.longitude}
						onChange={handleInputChange}
						error={errors.longitude}
					/>
				</Grid>
				<Grid item xs={4}>
					<TWControls.Input
						size="small"
						name="country"
						label="Country"
						value={values.country}
						onChange={handleInputChange}
						error={errors.country}
					/>
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<TWControls.Button type="submit" text="Submit" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default CompanyAddNewPopup
