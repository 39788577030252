import React, { useState } from 'react'
import { Grid } from '@mui/material'
import TWControls from '../controls/TWControls'
import { Form, useForm } from '../controls/useForm'
import RTEComponent from 'screens/locations/contentPages/RTEComponent'

const NoteAddPopup = ({ addNote }) => {
	const [input, setInput] = useState('')

	const initialValues = {
		id: 0,
		content: '',
	}

	const validate = () => {
		let temp = { ...errors }
		temp.content = values.content ? '' : 'This field is required.'

		setErrors({
			...temp,
		})

		return Object.values(temp).every(x => x === '')
	}

	const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(
		initialValues,
		true,
		validate,
	)

	const submitHandler = e => {
		e.preventDefault()

		values.content = input

		if (validate()) {
			addNote(input, resetForm)
		}
	}

	return (
		<Form onSubmit={submitHandler}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<RTEComponent input={input} setInput={setInput} />
				</Grid>
				<Grid item xs={12}>
					<div className="newLocation__imageHolder">
						<TWControls.Button size="small" type="submit" text="Post" />
					</div>
				</Grid>
			</Grid>
		</Form>
	)
}

export default NoteAddPopup
