import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FaPlus } from 'react-icons/fa'
import Popup from 'components/Popup'
import Placeholder from 'components/Placeholder'
import Spinner from 'components/Spinner'
import Message from 'components/Message'
import useTableNoPage from 'components/controls/useTableNoPage'
import { Avatar, IconButton, Menu, MenuItem, TableBody, TableCell, TableRow } from '@mui/material'
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material'
import { Grid } from '@mui/material'
import Layout from 'components/Layout/Layout'
import ListAction from 'features/Actions/components/ListAction'
import EmptyLogo from 'images/empty_folder.png'
import { patchLocation } from 'slices/locationSlice'
import { useParams } from 'react-router-dom'
import { createProject, deleteProject, initializeProjects, patchProject } from 'slices/projectSlice'
import ProjectAddPopup from 'components/popups/ProjectAddPopup'
import ProjectArchivePopup from 'components/popups/ProjectArchivePopup'
import ProjectDeletePopup from 'components/popups/ProjectDeletePopup'
import ProjectModifyPopup from 'components/popups/ProjectModifyPopup'
import styled from 'styled-components'

const headerCells = [
	{ id: 'projectName', label: 'Project' },
	{ id: 'projectDescription', label: 'Description' },
	{ id: 'projectType', label: 'Project Type' },
	{ id: 'more', label: 'Owner/Actions', disableSorting: true },
]

const StyledDiv = styled.div`
	& > * {
		margin: 0.5rem;
	}
`

const options = ['...', 'Edit', 'Archive', 'Delete']
const ITEM_HEIGHT = 56

const LoctionProjects = ({ userId, location }) => {
	const dispatch = useDispatch()

	const [filterFn, setFilterFn] = useState({
		fn: items => {
			return items
		},
	})

	const { id: locationId } = useParams()
	const ownerId = location.owner._id

	const [openPopup, setOpenPopup] = useState(false)
	const [openArchivePopup, setOpenArchivePopup] = useState(false)
	const [openDeletePopup, setOpenDeletePopup] = useState(false)
	const [openModifyPopup, setOpenModifyPopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('')
	const [recordForEdit, setRecordForEdit] = useState(null)

	const {
		entities: projectEntities,
		ids: projectIds,
		isLoading: projectsLoading,
		isInitialized,
		error: projectsError,
	} = useSelector(state => state.projects)
	const projects = projectIds.map(id => projectEntities[id])

	useEffect(() => {
		dispatch(initializeProjects({ locationId }))
	}, [isInitialized, dispatch])

	const handleProjectsAddNew = () => {
		setPopupTitle('New Project')
		setOpenPopup(true)
	}

	const records = projects
	const { TblContainer, TblHeader, recordsAfterPagingAndSorting } = useTableNoPage(
		records,
		headerCells,
		filterFn,
	)

	const handleArchiveProject = async newProject => {
		await dispatch(
			patchProject({
				projectId: newProject._id,
				updates: { isActive: false },
			}),
		)

		dispatch(
			initializeProjects({
				locationId: locationId,
			}),
		)

		setOpenArchivePopup(false)
		dispatch(patchLocation({ locationId: locationId, updates: { updateNote: 'Project Archived' } }))
	}

	const handleCreateProject = async newProject => {
		await dispatch(
			createProject({
				owner: userId,
				projectName: newProject.projectName,
				projectDescription: newProject.projectDescription,
				projectType: newProject.projectType,
				projectAsset: locationId,
				image: location.image,
				updateNote: 'New Project',
			}),
		)
		dispatch(
			initializeProjects({
				locationId: locationId,
			}),
		)

		setOpenPopup(false)
		dispatch(
			patchLocation({ locationId: locationId, updates: { updateNote: 'New Project(s) Added' } }),
		)
	}

	const handleDeleteProject = async newProject => {
		const projectId = newProject._id
		await dispatch(deleteProject(projectId))
		dispatch(
			initializeProjects({
				locationId: locationId,
			}),
		)

		setOpenDeletePopup(false)
		dispatch(patchLocation({ locationId: locationId, updates: { updateNote: 'Project Deleted' } }))
	}

	const handleEditProject = async newProject => {
		await dispatch(
			patchProject({
				projectId: newProject._id,
				updates: {
					projectName: newProject.projectName,
					projectDescription: newProject.projectDescription,
					projectType: newProject.projectType,
					image: location.image,
					updateNote: 'Project edited.',
				},
			}),
		)
		dispatch(
			initializeProjects({
				locationId: locationId,
			}),
		)

		setOpenModifyPopup(false)
		dispatch(patchLocation({ locationId: locationId, updates: { updateNote: 'Project Edited' } }))
	}

	if (projectsLoading) return <Spinner containerHeight="50vh" />
	if (projectsError) return <Message variant="danger">{projectsError}</Message>

	const hasProjects = projects.length > 0

	return (
		<StyledDiv>
			<Grid container spacing={3}>
				{hasProjects ? (
					<Grid item xs={9}>
						<TblContainer>
							<TblHeader />
							<TableBody>
								{recordsAfterPagingAndSorting().map(project => (
									<TableRow key={project._id}>
										<TableCell>
											<Link to={`/locations/${locationId}/projects/${project._id}`}>
												{project.projectName}
											</Link>
										</TableCell>
										<TableCell>{project.projectDescription}</TableCell>
										<TableCell>{project.projectType}</TableCell>
										<TableCell>
											{project.owner._id === userId ? (
												<MenuButton
													project={project}
													setPopupTitle={setPopupTitle}
													setRecordForEdit={setRecordForEdit}
													setOpenArchivePopup={setOpenArchivePopup}
													setOpenDeletePopup={setOpenDeletePopup}
													setOpenModifyPopup={setOpenModifyPopup}
												/>
											) : (
												<Avatar src={project.owner.image} />
											)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</TblContainer>
					</Grid>
				) : (
					<Grid item xs={9}>
						<Placeholder aspectRatio="16:9" isFlexColumn isTransparent>
							<h2 className="h2">We don't have any projects, yet.</h2>
							<div>
								<img className="details__avatar" src={EmptyLogo} alt="Avatar" />
							</div>
						</Placeholder>
					</Grid>
				)}
				<Grid item xs={3}>
					<Layout.SidebarItem title="Actions" hasNoMaxHeight>
						<ul>
							<li className="actionList__item">
								<ListAction
									handleAction={handleProjectsAddNew}
									actionLable={'Create New Project'}
									actionIcon={<FaPlus />}
								/>
							</li>
						</ul>
					</Layout.SidebarItem>
				</Grid>
			</Grid>

			{openPopup ? (
				<Popup title={popupTitle} openPopup={openPopup} setOpenPopup={setOpenPopup}>
					<ProjectAddPopup addProject={handleCreateProject} />
				</Popup>
			) : null}

			{openArchivePopup ? (
				<Popup title={popupTitle} openPopup={openArchivePopup} setOpenPopup={setOpenArchivePopup}>
					<ProjectArchivePopup archiveProject={handleArchiveProject} project={recordForEdit} />
				</Popup>
			) : null}

			{openDeletePopup ? (
				<Popup title={popupTitle} openPopup={openDeletePopup} setOpenPopup={setOpenDeletePopup}>
					<ProjectDeletePopup deleteProject={handleDeleteProject} project={recordForEdit} />
				</Popup>
			) : null}

			{openModifyPopup ? (
				<Popup title={popupTitle} openPopup={openModifyPopup} setOpenPopup={setOpenModifyPopup}>
					<ProjectModifyPopup editProject={handleEditProject} recordForEdit={recordForEdit} />
				</Popup>
			) : null}
		</StyledDiv>
	)
}

const MenuButton = ({
	project,
	setPopupTitle,
	setRecordForEdit,
	setOpenArchivePopup,
	setOpenDeletePopup,
	setOpenModifyPopup,
}) => {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = (option, project) => {
		switch (option) {
			case 'Archive':
				setPopupTitle(`Archive Project: ${project.projectName}`)
				setRecordForEdit(project)
				setOpenArchivePopup(true)
				setAnchorEl(null)
				break

			case 'Delete':
				setPopupTitle(`Delete Project: ${project.projectName}`)
				setRecordForEdit(project)
				setOpenDeletePopup(true)
				setAnchorEl(null)
				break

			case 'Edit':
				setPopupTitle(`Edit Project: ${project.projectName}`)
				setRecordForEdit(project)
				setOpenModifyPopup(true)
				setAnchorEl(null)
				break

			default:
				setAnchorEl(null)
		}
		setAnchorEl(null)
	}

	return (
		<>
			<IconButton
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				color="primary"
				size="small"
				onClick={handleClick}
			>
				<MoreHorizIcon />
			</IconButton>
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
						width: '15ch',
						color: 'blue',
					},
				}}
			>
				{options.map(option => (
					<MenuItem
						key={option}
						selected={option === '...'}
						onClick={() => handleClose(option, project)}
					>
						{option}
					</MenuItem>
				))}
			</Menu>
		</>
	)
}

export default LoctionProjects
