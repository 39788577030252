import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Layout from 'components/Layout'
import Card from 'components/Card'
import * as _ from 'styles/helpers'
import styled from 'styled-components'
import Popup from 'components/Popup'
import {
	Avatar,
	Button,
	ButtonGroup,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
} from '@mui/material'
import PartnerShowPopup from 'components/popups/PartnerShowPopup'
import CommunityPostsFeed from './contentPages/CommunityPostsFeed'
import { FaUsers } from 'react-icons/fa'
import { initializeMyPartners } from 'slices/myPartnersSlice'
import { initializeAllGroups, initializeMyGroups } from 'slices/groupSlice'
import { initializePosts } from 'slices/postSlice'
import { patchUser } from 'slices/authSlice'

const Community_Page = () => {
	const dispatch = useDispatch()

	const [openPopup, setOpenPopup] = useState(false)
	const [popupTitle, setPopupTitle] = useState('')
	const [recordForEdit, setRecordForEdit] = useState(null)
	const [allGroups, setAllGroups] = useState(false)

	const userLogin = useSelector((state: any) => state.userLogin)
	const { userInfo: user } = userLogin
	const userId = user._id

	const { entities: myGroups, ids: myGroupIds, isInitialized: myGroupsInitialized } = useSelector(
		(state: any) => state.groups,
	)
	const myGroupList = myGroupIds.map(id => myGroups[id])

	const {
		entities: myPartners,
		ids: myPartnerIds,
		isInitialized: myPartnersInitialized,
	} = useSelector((state: any) => state.myPartners)
	const myPartnersList = myPartnerIds.map(id => myPartners[id])

	useEffect(() => {
		if (!myGroupsInitialized) {
			dispatch(initializeMyGroups())
		}

		if (!allGroups) {
			dispatch(initializeMyGroups())
		} else {
			dispatch(initializeAllGroups())
		}

		if (!myPartnersInitialized) {
			dispatch(initializeMyPartners())
		}
	}, [dispatch, allGroups, myPartnersInitialized])

	const handelGroupSelect = async group => {
		const groupId = group._id
		dispatch(initializePosts({ groupId }))
		dispatch(patchUser({ userid: userId, updates: { lastGroup: groupId } }))
	}

	const handelPartnerPopup = async partner => {
		setRecordForEdit(partner)
		setOpenPopup(true)
	}

	return (
		<Layout>
			<Layout.Page>
				<Layout.Sidebar>
					<Layout.SidebarUserCard />
					<StyledSidebar>
						<div className="SidebarContent">
							<Card
								className="SidebarContent__card SidebarContent__card--partners"
								// actions={<Link to="/groups">View all Groups &rarr;</Link>}
							>
								<div className="SidebarContent__card-header">
									<h2>Groups</h2>
									<div>
										<ButtonGroup disableElevation size="small" color="primary">
											<Button
												color="primary"
												variant={!allGroups ? 'contained' : 'outlined'}
												onClick={() => {
													setAllGroups(false)
												}}
											>
												Mine
											</Button>
											<Button
												color="primary"
												variant={allGroups ? 'contained' : 'outlined'}
												onClick={() => {
													setAllGroups(true)
												}}
											>
												All
											</Button>
										</ButtonGroup>
									</div>
								</div>
								<div className="SidebarContent__card-body">
									{myGroupList && myGroupList.length > 0 && (
										<List dense disablePadding>
											{myGroupList.map((group, index) => (
												<ListItem
													key={index}
													component="a"
													onClick={() => {
														handelGroupSelect(group)
													}}
												>
													<ListItemText
														primary={group.groupName}
														// secondary={group.groupDescription}
													/>
												</ListItem>
											))}
										</List>
									)}
								</div>
							</Card>
						</div>
					</StyledSidebar>
				</Layout.Sidebar>
				<Layout.Body>{myGroupsInitialized && <CommunityPostsFeed />}</Layout.Body>
				<Layout.Sidebar>
					<StyledSidebar>
						<div className="SidebarContent">
							{myPartnersList.length > 0 ? (
								<Card
									className="SidebarContent__card SidebarContent__card--partners"
									actions={<Link to="/partners">View all friends &rarr;</Link>}
								>
									<div className="SidebarContent__card-header">
										<h2>
											<FaUsers /> Friends
										</h2>
									</div>
									<div className="SidebarContent__card-body">
										{myPartnersList && myPartnersList.length > 0 && (
											<List dense disablePadding>
												{myPartnersList.map((partner, index) => (
													<ListItem
														key={index}
														component="a"
														onClick={() => {
															handelPartnerPopup(partner.partner)
														}}
													>
														<ListItemAvatar>
															<Avatar src={partner.partner.image} />
														</ListItemAvatar>
														{partner.isAccepted ? (
															<ListItemText
																primary={partner.partner.name}
																secondary={partner.partner.company}
															/>
														) : (
															<ListItemText primary={partner.partner.name} secondary="Pending" />
														)}
													</ListItem>
												))}
											</List>
										)}
									</div>
								</Card>
							) : (
								<Card
									className="SidebarContent__card SidebarContent__card--partners"
									actions={<Link to="/partners">Connect with a new partner &rarr;</Link>}
								>
									<div className="SidebarContent__card-header">
										<h2>
											<FaUsers /> Friends
										</h2>
									</div>
									<div className="SidebarContent__card-empty-partner">
										<Link to="/partners" style={{ textDecoration: 'none' }}>
											<h3>Add Friends. . .</h3>
										</Link>
									</div>
								</Card>
							)}
						</div>
					</StyledSidebar>
				</Layout.Sidebar>
			</Layout.Page>
			{openPopup ? (
				<Popup title={popupTitle} openPopup={openPopup} setOpenPopup={setOpenPopup}>
					<PartnerShowPopup recordForEdit={recordForEdit} />
				</Popup>
			) : null}
		</Layout>
	)
}

const StyledSidebar = styled.div`
	.SidebarHeader {
		${_.MIXINS.vc}
		margin-bottom: ${_.rem(32)};

		&__avatar {
			width: ${_.rem(68)};
			height: ${_.rem(68)};
			margin-right: ${_.rem(24)};
			border: ${_.rem(4)} solid #0000001a;
		}

		&__greeting {
			${_.MIXINS.mod}
			display: flex;
			justify-content: center;
			flex-direction: column;

			&__username {
				${_.TYPOGRAPHY.p}
			}
			&__title {
				${_.TYPOGRAPHY.h1}
			}
		}
	}

	.SidebarContent {
		display: grid;
		grid-gap: ${_.rem(24)};
		grid-template-columns: repeat(12, [col] 1fr);

		&__card {
			border-top: ${_.rem(4)} solid ${_.COLORS.tradewind_blue};
			height: ${_.rem(480)};
		}

		&__card-header {
			${_.MIXINS.vc};
			justify-content: space-between;
			margin-bottom: ${_.rem(16)};

			h2 {
				${_.MIXINS.vc};
				${_.TYPOGRAPHY.h2};
				font-size: ${_.rem(22)};
				margin: 0;
				grid-gap: ${_.rem(8)};
			}

			p {
				margin: 0;
				font-size: ${_.rem(11)};
				font-style: italic;
			}
		}

		&__card-body {
			height: 100%;
			max-height: ${_.rem(390)};
			overflow-y: scroll;
		}

		&__task-link {
			height: 100%;
			max-height: ${_.rem(390)};
			overflow-y: scroll;

			h3 {
				font-size: ${_.rem(22)};
				font-weight: 400;
				color: ${_.COLORS.tradewind_blue};
			}

			h3:hover {
				font-weight: 500;
				cursor: pointer;
			}
		}

		&__card-empty {
			${_.MIXINS.vhc};
			height: 100%;

			h3 {
				font-size: ${_.rem(16)};
				font-weight: 300;
				color: rgba(0, 0, 0, 0.54);
			}
		}

		&__card-empty-partner {
			${_.MIXINS.vhc};
			height: 100%;

			h3 {
				text-align: center;
				font-size: ${_.rem(22)};
				font-weight: 400;
				color: ${_.COLORS.tradewind_blue};
				margin: ${_.rem(16)};
				padding: ${_.rem(6)};
				border-radius: ${_.rem(15)};
			}

			h3:hover {
				font-weight: 500;
				cursor: pointer;
			}
		}

		&__card {
			&--partners {
				grid-column: col / span 12;
			}

			&-content {
				padding: 0 ${_.rem(12)};
			}
		}
	}
`

export default Community_Page
